import React from 'react';
import { useSelector } from 'react-redux';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { Typography, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import { Content } from './ConnectToBank.styled';

const ConnectToBank = () => {
  const {
    klarnaKosma: { transactionFlow },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();

  return (
    <Content>
      {transactionFlow.status === 'inProgress' ? (
        <Stack>
          <Typography weight={600} size="xl">
            {translate('Onboarding.ConnectingToKlarna')}
          </Typography>
          <SpinnerCircular color={theme.colors.secondaryGray} />
        </Stack>
      ) : (
        <Stack>
          <Typography weight={600} size="xl">
            {translate('Onboarding.BankIntegrationTitle')}
          </Typography>
          <Typography>
            {translate('Onboarding.BankIntegrationText1')}
          </Typography>
          <Typography>
            {translate('Onboarding.BankIntegrationText2')}
          </Typography>
        </Stack>
      )}
    </Content>
  );
};

export default ConnectToBank;
