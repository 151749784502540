import {
  SET_API_USER_INFO_STATUS,
  SET_COMPANIES_DATA,
  SET_PERSONAL_NUMBER,
  UPDATE_ACTIVE_COMPANY,
  UPDATE_ACTIVE_COMPANY_LOAN,
  UPDATE_USER_DATA,
  UserAction,
} from '~/store/types/userTypes';
import {
  ApiObjectData,
  Company,
  IApplicationIfClientNotExist,
  Loan,
  User,
} from '~/Interfaces';
import {
  apiGetApplicationsIfClientNotFound,
  apiGetClient,
  apiPutClient,
} from '~/services/api';
import {
  ApiStatus,
  AppThunk,
  GetStateFunc,
  Language,
} from '~/store/types/sharedTypes';
import { history } from '~/App';
import {
  defaultLanguageToMarket,
  setLanguage,
} from '~/store/slices/intl.slice';
import { companyDataMapper, userDataMapper } from '~/helpers/mappers.helper';
import { AppliedLoanStatus } from '@qred/shared-enums';
import { GlobalErrorType } from '~/enums';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import { toggleGlobalErrorOn } from '../slices/globalError.slice';

export const updateUserData = (userData: Partial<User>): UserAction => ({
  type: UPDATE_USER_DATA,
  payload: userData,
});

export const updateUserPersonalNumber = (
  personalNumber: string
): UserAction => ({
  type: SET_PERSONAL_NUMBER,
  payload: personalNumber,
});

export const updateCompaniesData = (
  companiesData: Array<Company>
): UserAction => ({
  type: SET_COMPANIES_DATA,
  payload: companiesData,
});

export const updateApiUserInfoStatus = (status: ApiStatus): UserAction => ({
  type: SET_API_USER_INFO_STATUS,
  payload: status,
});

export const updateActiveCompany = (company: Company): UserAction => {
  localStorage.setItem('active_company', company.id.toString());

  return {
    type: UPDATE_ACTIVE_COMPANY,
    payload: company,
  };
};

export const updateActiveCompanyLoan = (loan: Loan[]): UserAction => ({
  type: UPDATE_ACTIVE_COMPANY_LOAN,
  payload: loan,
});

const getActiveCompany = (companies: Company[]) => {
  const activeCompanyId = localStorage.getItem('active_company');

  if (activeCompanyId) {
    const activeCompany = companies.find(
      (c) => c.id === Number(activeCompanyId)
    );

    if (activeCompany) {
      return activeCompany;
    }
  }

  return companies[0];
};

// TODO: Temporary until the backend do this filter on their side
const filterCompanies = (companies: Company[]) => {
  const set = new Set();
  const hasDuplicateCompanies = companies.some(
    (c) => set.size === set.add(c.id).size
  );

  if (hasDuplicateCompanies) {
    const duplicatedCompanies = companies.filter(
      (value, index, self) =>
        self.findIndex((cc) => cc.id === value.id) !== index
    );

    if (
      duplicatedCompanies.every((value) =>
        value.loanStatus.startsWith('Closed_')
      )
    ) {
      return companies.filter((c) => !duplicatedCompanies.includes(c));
    }

    return companies
      .filter((c) => !c.loanStatus?.startsWith('Closed_'))
      .filter((v, i, a) => a.findIndex((cc) => cc.id === v.id) === i);
  }

  return companies;
};

export const fetchUserData = (): AppThunk => (
  dispatch,
  getState: GetStateFunc
) => {
  dispatch(updateApiUserInfoStatus(ApiStatus.Started));
  apiGetClient()
    .then((res: any) => {
      const data = res.data.client;
      const userInfo = data ? data.info : undefined;
      const companiesInfo = data ? data.companies : undefined;

      if (data) {
        if (userInfo) {
          dispatch(updateUserData(userDataMapper(userInfo)));
          /*
              Deactivating this condition in order to get an action dispatched even if language gotten from
              API and the language in the redux state are the same. This behaviour is needed for GTM solution to work.
            */
          // if (userInfo.language !== getState().intl.language) {
          dispatch(setLanguage(userInfo.language));
          // }
        }

        if (companiesInfo && companiesInfo.length > 0) {
          const companiesData: Array<Company> = companiesInfo.map(
            (c: ApiObjectData) => companyDataMapper(c)
          );

          companiesData.sort((a, b) => b.id - a.id);

          const filteredCompanies = filterCompanies(companiesData);
          dispatch(updateActiveCompany(getActiveCompany(filteredCompanies)));

          dispatch(updateCompaniesData(filteredCompanies));
        } else {
          // User has no registered company, hence no account
          dispatch(toggleGlobalErrorOn(GlobalErrorType.USER_HAS_NO_ACCOUNT));
        }
      } else {
        throw new Error('No user data found');
      }
      dispatch(updateApiUserInfoStatus(ApiStatus.Completed));
    })
    .catch((err: any) => {
      // This will set the language based on the market an is necessary for GTM to work
      // when user has no account and also for the popup to show up in local language.
      dispatch(defaultLanguageToMarket(getState().intl.market));
      if (err.response && err.response.status === 404) {
        // Dont log these errors to sentry. If we want to know how often this happens we can send a gtm event?
        try {
          // TODO now we are redirecting users to offer page if we find any offer in the list. maybe later user want to see all applications list and choose
          apiGetApplicationsIfClientNotFound().then((res: any) => {
            const data = res.data.applications;
            if (data && data.length > 0) {
              const notSignedApplication = data.find(
                (application: IApplicationIfClientNotExist) =>
                  application.status === AppliedLoanStatus.WaitClientAccept
              );
              const signedApplication = data.some(
                (application: IApplicationIfClientNotExist) =>
                  application.status === AppliedLoanStatus.Confirmed ||
                  application.status === AppliedLoanStatus.WaitUserLastAccept ||
                  application.status === AppliedLoanStatus.WaitSignerAccept
              );
              if (notSignedApplication) {
                history.push(
                  `/onboarding-offer/${notSignedApplication.application_uuid}`
                );
              } else if (signedApplication) {
                dispatch(
                  toggleGlobalErrorOn(
                    GlobalErrorType.USER_SIGNED_OFFER_AND_NO_ACCOUNT
                  )
                );
              } else {
                dispatch(
                  toggleGlobalErrorOn(GlobalErrorType.USER_HAS_NO_ACCOUNT)
                );
              }
            } else {
              dispatch(
                toggleGlobalErrorOn(GlobalErrorType.USER_HAS_NO_ACCOUNT)
              );
            }
          });
        } catch (error) {
          dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
          dispatch(
            pushToSentryAction(error, 'apiGetApplicationsIfClientNotFound')
          );
        }
      } else {
        dispatch(pushToSentryAction(err, 'apiGetClient'));
        dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
      }
      dispatch(updateApiUserInfoStatus(ApiStatus.Failed));
    });
};

export const saveLanguage = (language: Language): AppThunk => async (
  dispatch,
  getState: GetStateFunc
) => {
  const userInfo = getState().user.info;
  try {
    await apiPutClient({ ...userInfo, language });
    dispatch(updateUserData({ ...userInfo, language }));
    dispatch(setLanguage(language));
    localStorage.setItem('qredLoginLanguage', language);
  } catch (err) {
    dispatch(pushToSentryAction(err, 'apiPutClient'));
    dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
  }
};
