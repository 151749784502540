import React, { useMemo, useState } from 'react';
import {
  PostHogContext,
  PostHogEvent,
} from '~/contexts/PostHogProvider/posthog.context';
import posthog from 'posthog-js';
import { PostHogProvider as NativePostHogProvider } from 'posthog-js/react';

interface PostHogProviderProps {
  children: React.ReactNode;
}

if (!window.Cypress) {
  posthog.init(import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
    capture_pageview: false,
    capture_pageleave: false,
    autocapture: false,
    session_recording: {
      maskAllInputs: true,
      maskInputFn: (text, element) => {
        if (element?.dataset['record'] === 'true') {
          return text;
        }
        return '*'.repeat(text.length);
      },
      maskTextSelector: '*',
    },
  });
}

const PostHogProvider = ({ children }: PostHogProviderProps) => {
  const [event, setEvent] = useState<PostHogEvent>(null as any);
  const memoizedValue = useMemo(() => ({ event, setEvent }), [event, setEvent]);

  return (
    <NativePostHogProvider client={posthog}>
      <PostHogContext.Provider value={memoizedValue}>
        {children}
      </PostHogContext.Provider>
    </NativePostHogProvider>
  );
};

export default PostHogProvider;
