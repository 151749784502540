import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { ApplicationStatus } from '~/enums';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { useHistory } from 'react-router';
import constVars from '~/constants/constVars';
import Confetti from '~/components/shared/Confetti/Confetti';
import { logToSentry } from '~/helpers/loggers.helper';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { getCookieValue } from '~/helpers/utils';
import { updateApplicationStatus } from '~/store/slices/onboardingApplication.slice';
import OfferInfoCard from './OfferInfoCard';
import { ConfirmationBodyContainer } from './ConfirmationPage.styled';
import { accessToken } from '~/store/apis/utils/accessToken';

const ConfirmationPage = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [step, setStep] = useState(1);
  const [barStepAnimation, setBarStepAnimation] = useState(true);

  const websocket = useRef<WebSocket>(null as any);

  const {
    onboardingApplication,
    intl: { market },
  } = useSelector((state: RootState) => state);

  const callbackId = onboardingApplication.form.applicationUuid;

  useEffect(() => {
    if (
      onboardingApplication.apiStatus.patch === ApiStatus.Completed &&
      onboardingApplication.applicationStatus ===
        ApplicationStatus.ApplicationFinished
    ) {
      dispatch(
        updateApplicationStatus(ApplicationStatus.ApplicationUnderReview)
      );
      setStep(2);
    }
  }, [
    onboardingApplication.apiStatus.patch,
    onboardingApplication.applicationStatus,
  ]);

  useEffect(() => {
    if (
      onboardingApplication.applicationStatus ===
      ApplicationStatus.ApplicationFinished
    ) {
      websocket.current = new WebSocket(
        import.meta.env.VITE_WEBSOCKET_ONBOARDING_URL
      );
      websocket.current.onopen = async () => {
        setErrorMessage('');
        const userData = {
          action: 'authorization',
          accessToken: onboardingApplication.form.applicantAuthenticated
            ? await accessToken.get()
            : getCookieValue('publicToken'),
          callbackId,
          countryCode: market.toLowerCase(),
        };
        if (websocket.current) {
          websocket.current && websocket.current.send(JSON.stringify(userData));
        }
      };
      websocket.current.onclose = () => {
        setBarStepAnimation(false);
      };
      websocket.current.onerror = (error) => {
        setBarStepAnimation(false);
        logToSentry(error, 'websocket onerror', {
          product: 'onboarding',
        });

        setErrorMessage(t('Onboarding.Disconnected') as string);
      };
      websocket.current.onmessage = (event) => {
        const messageData = JSON.parse(event.data);
        try {
          if (messageData && messageData.message) {
            setErrorMessage('');
            if (messageData.message.offerExist) {
              dispatch(
                updateApplicationStatus(ApplicationStatus.OfferApproved)
              );
              dispatch(
                pushToGtmOnboardingAction({ actionName: 'auto_approved' })
              );
              setStep(3);
              setTimeout(() => {
                history.push(
                  `/${constVars.ROUTE_ONBOARDING_OFFER}/${callbackId}`
                );
              }, 4000);
            } else {
              dispatch(
                pushToGtmOnboardingAction({ actionName: 'not_auto_approved' })
              );
              setBarStepAnimation(false);
              dispatch(
                updateApplicationStatus(ApplicationStatus.OfferNotApproved)
              );
            }
          }
        } catch (error) {
          logToSentry(error, 'websocket try/catch error', {
            product: 'onboarding',
          });
          setErrorMessage(t('ApiFailureError.ErrorMessageTitle') as string);
          console.error(error);
        }
      };
    }
  }, [onboardingApplication.applicationStatus]);

  useEffect(() => {
    return () => {
      if (websocket?.current) {
        websocket.current.close();
      }
    };
  }, []);

  return (
    <ConfirmationBodyContainer>
      {onboardingApplication.applicationStatus ===
        ApplicationStatus.OfferApproved && (
        <Confetti
          play={
            onboardingApplication.applicationStatus ===
            ApplicationStatus.OfferApproved
          }
        />
      )}
      <OfferInfoCard
        errorMessage={errorMessage}
        step={step}
        barStepAnimation={barStepAnimation}
      />
    </ConfirmationBodyContainer>
  );
};
export default ConfirmationPage;
