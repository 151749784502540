import React from 'react';
import styled from 'styled-components';
import useTranslate from '~/hooks/useTranslate';
import { theme } from '~/styles/themes';
import {
  FontWeights,
  Typography,
} from '~/components/shared/Typography/Typography';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { CompanyTitles } from '~/constants/markets';
import MeansOfSupport from '../../MeansOfSupport/MeansOfSupport';

const TextWrapper = styled.div`
  margin-bottom: 1em;
`;

const HelpTextWrapper = styled.div`
  margin: 0 1.5em 1em 1.5em;
`;

const AppplyForLoanText = styled.div`
  margin-top: 1em;
  padding-top: 1em;
  border-top: 0.0125em solid #dddddd;
`;

interface Props {
  shouldShowOnboardingLink: boolean;
}

const NoAccountError = ({ shouldShowOnboardingLink }: Props) => {
  const market = useSelector((state: RootState) => state.intl.market);
  const translate = useTranslate();

  return (
    <>
      <Typography
        fontSize={1.2}
        color={theme.colors.dark}
        fontWeight={600}
        lineHeight={1.5}
        centerAlign
        element="h4"
      >
        {translate('NoAccountError.ErrorMessageTitle', {
          title: CompanyTitles[market],
        })}
      </Typography>
      <TextWrapper>
        <Typography
          fontSize={1}
          lineHeight={1.4}
          color={theme.colors.dark}
          element="p"
        >
          {translate('NoAccountError.ErrorMessageText1' as string)}
        </Typography>
      </TextWrapper>
      <HelpTextWrapper>
        <Typography
          fontSize={0.8}
          fontWeight={FontWeights.Thick}
          lineHeight={1.4}
          color={theme.colors.dark}
          element="p"
          centerAlign
        >
          {translate('ContactNeedHelp' as string)}
        </Typography>
      </HelpTextWrapper>
      <MeansOfSupport />
      {!shouldShowOnboardingLink && (
        <AppplyForLoanText>
          <Typography
            lineHeight={1.4}
            color={theme.colors.dark}
            element="p"
            centerAlign
          >
            {translate('NoAccountError.NewLoanText' as string)}
          </Typography>
        </AppplyForLoanText>
      )}
    </>
  );
};

export default NoAccountError;
