import styled from 'styled-components';
import Stack from '~/components/shared/Layout/Stack';
import { MediaQueries } from '~/constants/constVars';
import { ClickableText } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';

export const LaunchViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
`;

export const FormBottomContent = styled(Stack)`
  gap: 5px;

  @media ${MediaQueries.tablet} {
    width: 366px;
    gap: 35px;
  }

  @media ${MediaQueries.desktop} {
    width: 336px;
  }
`;

export const FormBottomContentButtons = styled(Stack)``;

export const Title = styled.span`
  font-size: 1.25em;
  line-height: 1.5em;

  @media ${MediaQueries.tablet} {
    font-size: 1.5em;
  }

  @media ${MediaQueries.desktop} {
    font-size: 2.25em;
  }
`;

export const FormTopContent = styled(Stack)`
  gap: 20px;

  @media ${MediaQueries.tablet} {
    gap: 30px;
  }

  @media ${MediaQueries.desktop} {
    gap: 40px;
  }
`;

export const LaunchViewFormContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 3;
`;

export const LaunchViewFormContent = styled(Stack)`
  margin: 0 40px;
  gap: 0;
  justify-content: space-around;
  height: 100%;

  @media ${MediaQueries.tablet} {
    width: 500px;
    height: 100%;
    margin: 0;
    max-height: 500px;
  }

  @media ${MediaQueries.desktop} {
    margin-right: 60px;
    margin-left: 60px;
    gap: 60px;
  }
`;

export const LoanAmountInputWrapper = styled(Stack)`
  gap: 0;
  @media ${MediaQueries.tablet} {
    width: 366px;
  }

  @media ${MediaQueries.desktop} {
    width: 336px;
  }
`;

export const ClickableTextLogin = styled(ClickableText)`
  color: ${theme.colors.accent};
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px;
  margin-left: 4px;
`;
