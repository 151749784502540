import initialState from '~/store/initialState';
import {
  OfferAction,
  SET_ACCESS_TOKEN_ERROR,
  SET_HAS_OFFER,
  SET_OFFER_DATA,
} from '~/store/types/loanOfferTypes';
import { Offer, OfferUserData } from '~/Interfaces';

interface OfferState {
  readonly overview: Offer[];
  readonly userData: OfferUserData;
  readonly hasOffer: boolean;
  readonly isSubmitting: boolean;
  readonly hasAccessTokenError: boolean;
}

const loanOfferReducer = (
  state = initialState.loanOffer,
  action: OfferAction
): OfferState => {
  switch (action.type) {
    case SET_OFFER_DATA:
      return {
        ...state,
        overview: action.payload,
      };

    case SET_HAS_OFFER:
      return { ...state, hasOffer: action.payload };

    case SET_ACCESS_TOKEN_ERROR:
      return { ...state, hasAccessTokenError: action.payload };

    default:
      return state;
  }
};

export default loanOfferReducer;
