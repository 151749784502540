import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { Group, Icon, Typography } from '@qred/components-library';
import useModals from '~/hooks/useModals';
import useTranslate from '~/hooks/useTranslate';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';
import OwnerManagementModal from './OwnerManagementModal';
import { IOnboardingBeneficialOwner } from '~/interfaces/Onboarding';

interface AdditionalOwnerProps {
  owner: IOnboardingBeneficialOwner;
}

const AdditionalOwner = ({ owner }: AdditionalOwnerProps) => {
  const dispatch = useDispatch();

  const {
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const t = useTranslate();
  const modals = useModals();
  const { marketHasPersonalNumber } = useOnboardingMarketConfig();

  const openOwnerManagementModal = () => {
    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: 'card_offer_edit_owner',
      })
    );

    modals.openModal({
      header: <Typography>{t('OnboardingOffer.EditOwner')}</Typography>,
      children: (
        <OwnerManagementModal
          initialOwner={owner}
          isAlternativeBeneficialOwner={owner.isAlternativeBeneficialOwner}
          askForPersonalNumber={marketHasPersonalNumber}
          askForDateOfBirth={!marketHasPersonalNumber}
        />
      ),
      size: 'md',
      fullScreen: mobile,
      filledBackground: true,
      hasOwnFooter: true,
    });
  };

  return (
    <Group justify="space-between">
      <Typography>
        {owner.fullName}
        {!owner.isAlternativeBeneficialOwner && <> ({owner.percentage}%)</>}
      </Typography>
      <Icon src="Pen" onClick={openOwnerManagementModal} />
    </Group>
  );
};

export default AdditionalOwner;
