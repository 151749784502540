import { KlarnaKosmaFlow } from '~/hooks/useKlarnaFlow/types';

export const UPDATE_TRANSACTION_FLOW = 'UPDATE_TRANSACTION_FLOW';

export interface UpdateTransactionFlowAction {
  type: typeof UPDATE_TRANSACTION_FLOW;
  payload: KlarnaKosmaFlow;
}

export type KlarnaKosmaAction = UpdateTransactionFlowAction;
