import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';
import { theme } from '~/styles/themes';

export const StepFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em 0;
  background-color: ${theme.colors.white};
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);

  @media ${MediaQueries.smallScreenPortrait} {
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 2px solid ${theme.colors.neutral12};
  }
`;

export const StepFooterContent = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  padding: 0 2em;
`;

export const StepContent = styled.div<{
  lockStep?: boolean;
  footerHeight: number;
}>`
  pointer-events: ${(props) => (props.lockStep ? 'none' : 'initial')};
  padding: 1.5em 3em;
  @media ${MediaQueries.smallScreenPortrait} {
    padding: ${(props) => `2em 2em ${props.footerHeight + 20}px`};
  }
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
`;
