import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { StepStatus } from '~/enums';

import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { patchApplication } from '~/store/actions/onboardingActions';
import { goToNextStep } from '~/store/slices/onboardingApplication.slice';

const useGoToNextStep = () => {
  const dispatch = useDispatch();

  const onNextStepClick = useCallback(async () => {
    try {
      dispatch(
        pushToGtmOnboardingAction({ actionName: 'next_button_pressed' })
      );
      await dispatch(patchApplication());
      dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Finished }));
      dispatch(goToNextStep());
    } catch (_err: any) {
      dispatch(
        pushToGtmOnboardingAction({
          stepStatus: StepStatus.Error,
          errorMessage: _err.response?.data?.message,
        })
      );
    }
  }, [dispatch]);

  return onNextStepClick;
};

export default useGoToNextStep;
