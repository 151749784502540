import React from 'react';
import styled from 'styled-components';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { theme } from '~/styles/themes';

const HeaderControl = styled.button`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  height: 3.5em;
  width: 3.5em;
  border-radius: 0.2em;

  svg {
    color: ${theme.colors.dark};
  }

  &:hover {
    background-color: ${theme.colors.backdrop};
  }

  &:disabled {
    pointer-events: none;

    svg {
      color: ${theme.colors.secondaryGray};
    }
  }
`;

const HeaderLevel = styled.button`
  border: 0;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.backdrop};
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  height: 3.5em;
  color: ${theme.colors.dark};
  font-size: 0.8em;
  border-radius: 0.2em;
  flex: 1;

  &:hover {
    background-color: ${theme.colors.hoverSecondary};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
`;

interface CalendarHeaderProps extends React.ComponentPropsWithoutRef<'div'> {
  hasPrevious: boolean;
  hasNext: boolean;

  onNext?(): void;

  onPrevious?(): void;

  onNextLevel?(): void;

  label?: string;
  nextLevelDisabled?: boolean;
  __staticSelector?: string;
  nextLabel?: string;
  previousLabel?: string;
  preventLevelFocus?: boolean;
  preventFocus?: boolean;
}

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  hasNext,
  hasPrevious,
  onNext,
  onPrevious,
  onNextLevel,
  label,
  nextLevelDisabled,
  nextLabel,
  previousLabel,
  preventLevelFocus = false,
  preventFocus,
  ...others
}) => (
  <Header {...others}>
    <HeaderControl disabled={!hasPrevious} onClick={onPrevious}>
      <Icon src={SvgSrc.ChevronLeft} />
    </HeaderControl>
    <HeaderLevel onClick={onNextLevel}>{label}</HeaderLevel>
    <HeaderControl disabled={!hasNext} onClick={onNext}>
      <Icon src={SvgSrc.ChevronRight} />
    </HeaderControl>
  </Header>
);

export default CalendarHeader;
