import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import { theme } from '~/styles/themes';
import { Typography } from '~/components/shared/Typography/Typography';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import MeansOfSupport from '../../MeansOfSupport/MeansOfSupport';

const MainContainer = styled.div`
  margin-bottom: 1em;
`;

const ApiFailureError = () => {
  const translate = useTranslate();
  const { globalErrorPersistent } = useSelector(
    (state: RootState) => state.globalError
  );

  return (
    <>
      <MainContainer>
        <Typography
          fontSize={1}
          color={theme.colors.dark}
          fontWeight={600}
          lineHeight={2}
          centerAlign
          element="h4"
        >
          {translate('ApiFailureError.ErrorMessageTitle')}
        </Typography>
        <Typography
          fontSize={1}
          lineHeight={2}
          color={theme.colors.dark}
          centerAlign
          element="p"
        >
          {translate('ApiFailureError.ErrorMessageText')}
        </Typography>
      </MainContainer>
      <MeansOfSupport closePopupsOnChatOpen={!globalErrorPersistent} />
    </>
  );
};

export default ApiFailureError;
