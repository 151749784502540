import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormStatus, ValidationContext } from '~/components/hoc/withValidation';
import Button, { ButtonType } from '~/components/shared/Button/button';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import SpinnerLinear from '~/components/shared/Spinner/SpinnerLinear';
import constVars from '~/constants/constVars';
import useTranslate from '~/hooks/useTranslate';
import { ShippingDetails } from '~/Interfaces';
import { sendShippingDetailsData } from '~/store/actions/shippingDetailsActions';
import { RootState } from '~/store/types/sharedTypes';

interface Props {
  applicationUuid: string;
  shippingDetails: ShippingDetails;
  isPublic: boolean;
}

const CreateCardCard = (props: Props) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const validationContext = useContext(ValidationContext);
  const isSubmitting = useSelector(
    (state: RootState) => state.shippingDetails.isSubmitting
  );

  /* Handlers */
  const handleSubmit = () => {
    // TODO: find a way to consolidate FormStatus of validation context with submitting status in redux
    validationContext.setFormStatus(FormStatus.SUBMITTING);
    if (validationContext.isFormValid) {
      history.push(`/${constVars.ROUTE_CREATING_CARD}`);
      dispatch(sendShippingDetailsData(props.applicationUuid, props.isPublic));
    } else {
      validationContext.setFormStatus(FormStatus.SUBMITTED);
    }
  };

  return (
    <Card noPadding dataTestId="shipping-details-pincode-card">
      <CardHeader value={translate('CreateCardCard.Title') as string} />
      <CardSection borderTop>
        <Button
          type={ButtonType.Filled}
          onClick={handleSubmit}
          disabled={props.shippingDetails.cardAlreadyCreated}
        >
          {isSubmitting ? (
            <SpinnerLinear />
          ) : (
            translate('CreateCardCard.ButtonText')
          )}
        </Button>
      </CardSection>
    </Card>
  );
};

export default CreateCardCard;
