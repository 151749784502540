import React, { useState } from 'react';
import withGtmEvent from '~/components/hoc/withGtmEvent';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '~/components/shared/LogoOld/LogoOld';
import PageHeader from '~/components/shared/PageHeader/PageHeader';
import PageTitle from '~/components/shared/PageTitle/PageTitle';
import useTranslate from '~/hooks/useTranslate';
import { BodyContainer, MainContainer } from '~/styles/BaseStyledComponents';
import { compose } from 'redux';
import { useParams } from 'react-router';
import { RootState } from '~/store/types/sharedTypes';
import SetCreditCardPinCard from './components/SetCreditCardPinCard';

const SetCreditCardPin = () => {
  const [showSetPinComponent, setShowSetPinComponent] = useState(false);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { applicationUuid } = useParams<{ applicationUuid: string }>();
  const isCardCreated = useSelector(
    (state: RootState) => state.shippingDetails.isCardCreated
  );

  return (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
        <PageTitle mb={2}>{translate('CreatingQredCard')}</PageTitle>
      </PageHeader>
      <BodyContainer>
        <SetCreditCardPinCard />
      </BodyContainer>
    </MainContainer>
  );
};

export default compose(withGtmEvent)(SetCreditCardPin);
