import { useEffect } from 'react';
import { XS2AConfig } from '~/hooks/useKlarnaFlow/types';

export const useKlarnaFlow = (options?: XS2AConfig) => {
  useEffect(() => {
    if (options) {
      window.onXS2AReady = () => {
        window.XS2A.configure(options);
      };
    }

    const script = document.createElement('script');
    document.body.appendChild(script);
    script.src =
      'https://x.klarnacdn.net/xs2a/app-launcher/v0/xs2a-app-launcher.js';
    script.async = true;

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
