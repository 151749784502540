import { Contracts, Contract } from '~/models/contracts/contracts';
import {
  ContractDto,
  ContractLine,
  ContractsGetResponseDto,
} from '~/store/apis/dtos/contracts/contracts.dto';

export const mapContracts = (response: ContractsGetResponseDto): Contracts => {
  const { contracts } = response;

  if (!contracts) {
    return {
      previousLoans: [],
      allContracts: [],
    };
  }

  const allContracts = contracts
    .map((contract) => ({
      startDate: contract.start_date,
      endDate: contract.end_date,
      status: contract.status,
      id: contract.contract_id,
      amountTotal: contract.amount,
      closedDate: contract.closed_date,
      amountLeft: contract.amount_left,
      term: contract.term,
      contractNo: contract.contract_no,
      invoices: contract.contract_line?.map((invoice: ContractLine) => ({
        contractLineId: invoice.r_contract_line_id,
        startDate: invoice.start_date,
        endDate: invoice.end_date,
        datePaid: invoice.date_paid,
        termDate: invoice.term_date,
        monthlyFee: invoice.monthly_fee,
        lineNo: invoice.line_no,
        amount: invoice.amount,
        monthIndex: invoice.month_index,
      })),
    }))
    .reverse() as Contract[];

  const previousLoans = allContracts.filter(
    (previousLoan) =>
      (previousLoan.status.split('_')[0] === 'Closed' &&
        previousLoan.status !== 'CLOSED_CHANGE_PAYMENT_PLAN') ||
      previousLoan.status === 'OPEN_IN_COLLECTION'
  );

  return {
    allContracts: allContracts,
    previousLoans: previousLoans,
  };
};
