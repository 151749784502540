import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ConfirmLabels,
  ModalsContext,
  ModalsContextProps,
  ModalSettings,
  OpenConfirmModal,
} from '~/contexts/modals.context';
import { RootState } from '~/store/types/sharedTypes';
import { randomId } from '~/helpers/utils';
import ConfirmModal from '~/components/shared/Modal/ConfirmModal';
import {
  closeAllModals,
  closeModal,
  openModal,
} from '~/store/slices/globalModal.slice';
import Modal, { ModalProps } from './Modal';

export interface ModalsProviderProps {
  children: React.ReactNode;
  modalProps?: ModalSettings;
  labels?: ConfirmLabels;
}

const separateConfirmModalProps = (props: OpenConfirmModal) => {
  if (!props) {
    return { confirmProps: {}, modalProps: {} };
  }

  const {
    id,
    children,
    onCancel,
    onConfirm,
    closeOnConfirm,
    closeOnCancel,
    labels,
    ...others
  } = props;

  return {
    confirmProps: {
      id,
      children,
      onCancel,
      onConfirm,
      closeOnConfirm,
      closeOnCancel,
      labels,
    },
    modalProps: {
      id,
      ...others,
    },
  };
};

export const ModalProvider = (props: ModalsProviderProps) => {
  const { modalProps, labels, children } = props;
  const modalsState = useSelector((state: RootState) => state.globalModals);
  const dispatch = useDispatch();

  const open = (settings: ModalSettings) => {
    const id = settings.id || randomId();

    dispatch(
      openModal({
        id,
        props: settings,
        type: 'content',
      })
    );

    return id;
  };

  const openConfirm = (openConfirmModalProps: OpenConfirmModal) => {
    const id = openConfirmModalProps.id || randomId();

    dispatch(
      openModal({
        id,
        type: 'confirm',
        props: openConfirmModalProps,
      })
    );

    return id;
  };

  const closeAll = () => {
    modalsState.modals.forEach((modal) => {
      modal.props?.onClose?.();
    });

    dispatch(closeAllModals());
  };

  const close = (id: string) => {
    const modal = modalsState.modals.find((item) => item.id === id);

    modal?.props?.onClose?.();

    if (modal) {
      dispatch(closeModal(modal.id));
    } else {
      closeAll();
    }
  };

  const ctx: ModalsContextProps = {
    modals: modalsState.modals,
    openModal: open,
    openConfirmModal: openConfirm,
    closeAll,
    closeModal: close,
  };

  const getCurrentModal = () => {
    switch (modalsState.current?.type) {
      case 'confirm': {
        const {
          modalProps: separatedModalProps,
          confirmProps: separatedConfirmProps,
        } = separateConfirmModalProps(modalsState.current.props);

        return {
          modalProps: separatedModalProps,
          content: (
            <ConfirmModal
              {...separatedConfirmProps}
              id={modalsState.current.id}
              labels={modalsState.current.props.labels || labels}
            />
          ),
        };
      }
      case 'content': {
        const { children: currentModalChildren, ...rest } = modalsState.current
          ?.props as ModalProps;

        return {
          modalProps: rest,
          content: <>{currentModalChildren}</>,
        };
      }
      default:
        return {
          modalProps: {},
          content: null,
        };
    }
  };

  const { modalProps: currentModalProps, content } = getCurrentModal();

  return (
    <ModalsContext.Provider value={ctx}>
      <Modal
        {...modalProps}
        {...currentModalProps}
        onClose={() => close(modalsState.current?.id!)}
        opened={modalsState.modals.length > 0}
      >
        {content}
      </Modal>
      {children}
    </ModalsContext.Provider>
  );
};
