import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import {
  Accordion,
  Divider,
  Group,
  Icon,
  Stack,
  Typography,
} from '@qred/components-library';
import { onChangeInputEvent, onFocusSelectEvent } from '~/types/types';
import { ValidationContext } from '~/components/hoc/withValidation';
import {
  validateOfferApplicant,
  validateOfferSigner,
} from '~/helpers/onboarding.helper';
import Signer from './Signer';
import Applicant from '../Applicant/Applicant';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';
import { IOnboardingSigner } from '~/interfaces/Onboarding';

const Signers = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const validationContext = useContext(ValidationContext);

  const [accordionValue, setAccordionValue] = useState<string | null>(null);
  const { form } = useSelector((state: RootState) => state.cardOnboardingOffer);

  /**
   * Get all emails from all signers and applicant
   */
  const allEmails = [...form.signers.map((s) => s.email), form.applicant.email];

  /**
   * Get all phone numbers from all signers and applicant
   */
  const allPhones = [
    ...form.signers.map((s) => s.phone),
    form.applicant.phoneNumber,
  ];

  const onBlur = (event: onChangeInputEvent | onFocusSelectEvent | string) => {
    let name;
    if (typeof event === 'string') {
      name = event;
    } else {
      name = event.target.name;
    }
    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: `${name}_change`,
      })
    );
  };

  /**
   * Check if the signer is valid and if their email or phone number
   * already exists on other signers or the applicant
   */
  const signerIsValidAndUnique = (signer: IOnboardingSigner) => {
    const signerHasRequiredData = validateOfferSigner(signer);
    const signerHasUniqueEmail =
      allEmails.filter((_email) => _email === signer.email).length === 1;
    const signerHasUniquePhone =
      allPhones.filter((_phone) => _phone === signer.phone).length === 1;

    return (
      signerHasRequiredData && signerHasUniqueEmail && signerHasUniquePhone
    );
  };

  const findFirstInvalidAccordionItem = () => {
    const applicantIsValid = validateOfferApplicant(form.applicant);
    if (!applicantIsValid) {
      return form.applicant.id;
    }
    const invalidSigner = form.signers.find(
      (signer) => !signerIsValidAndUnique(signer)
    );

    if (invalidSigner) {
      return invalidSigner.signerId.toString();
    }

    return null;
  };

  useEffect(() => {
    const applicantHasErrors = !validateOfferApplicant(form.applicant);
    if (applicantHasErrors) {
      validationContext.addPropertyToValidationErrors('applicant');
    }
    const signersHasError = form.signers.some(
      (signer) => !signerIsValidAndUnique(signer)
    );

    if (signersHasError) {
      validationContext.addPropertyToValidationErrors('signers');
    }

    const firstInvalidAccordionItem = findFirstInvalidAccordionItem();
    setAccordionValue(firstInvalidAccordionItem);

    return () => {
      validationContext.removePropertyFromValidationErrors('applicant');
      validationContext.removePropertyFromValidationErrors('signers');
    };
  }, [form.signers, form.applicant]);

  return (
    <Accordion value={accordionValue} onChange={setAccordionValue}>
      <Stack spacing="xxl">
        <Stack>
          <Typography>
            {translate('OnboardingOffer.AdditionalSigners')}
          </Typography>
          <div>
            <Accordion.Item
              value={form.applicant.id}
              key={form.applicant.id}
              style={{ marginTop: 0 }}
            >
              <Accordion.Control style={{ backgroundColor: 'transparent' }}>
                <Group align="center">
                  <Icon
                    src={
                      validateOfferApplicant(form.applicant)
                        ? 'CheckCircle'
                        : 'CheckCircleUnfilled'
                    }
                    size="lg"
                    iconSize={16}
                  />
                  <Typography component="h3">
                    {form.applicant.fullName} (
                    {(translate('You') as string).toLowerCase()})
                  </Typography>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Applicant
                  key={form.applicant.id}
                  applicant={form.applicant}
                  onBlur={onBlur}
                />
              </Accordion.Panel>
              {!!form.signers.length && (
                <Divider color="neutral.16" mt="0.5em" mb="0.5em" />
              )}
            </Accordion.Item>

            {!!form.signers.length &&
              form.signers.map((signer, index) => (
                <Accordion.Item
                  value={signer.signerId.toString()}
                  key={signer.signerId}
                  style={{ marginTop: 0 }}
                >
                  <Accordion.Control style={{ backgroundColor: 'transparent' }}>
                    <Group align="center">
                      <Icon
                        src={
                          signerIsValidAndUnique(signer)
                            ? 'CheckCircle'
                            : 'CheckCircleUnfilled'
                        }
                        size="lg"
                        iconSize={16}
                      />
                      <Typography component="h3">{signer.name}</Typography>
                    </Group>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Signer
                      key={signer.signerId}
                      signer={signer}
                      onBlur={onBlur}
                    />
                  </Accordion.Panel>
                  {index !== form.signers.length - 1 && (
                    <Divider color="neutral.16" mt="0.5em" mb="0.5em" />
                  )}
                </Accordion.Item>
              ))}
          </div>
        </Stack>
      </Stack>
    </Accordion>
  );
};

export default Signers;
