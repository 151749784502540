import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  companyIsSoleTrader,
  companyIsStockCompany,
} from '~/constants/markets';
import {
  isBeneficialOwner,
  personsAreTheSame,
} from '~/helpers/onboarding.helper';
import { useSelector } from 'react-redux';
import { RootState } from '../types/sharedTypes';
import {
  SupportedCountryCodes,
  stepConfigPerMarket,
} from '~/pages/CardOnboardingOffer/components/stepConfigs';
import { StepConfigItem } from '~/Interfaces';
import { SignerType } from '~/enums';

const selectApplicantHasAnsweredBeneficialOwnersQuestion = createSelector(
  [
    (state: RootState) =>
      state.cardOnboardingOffer.form.companyHasOtherBeneficialOwners,
  ],
  (companyHasOtherBeneficialOwners) =>
    typeof companyHasOtherBeneficialOwners === 'boolean'
);

const selectApplicantHasAnswered100PercentOwnershipQuestion = createSelector(
  [
    (state: RootState) =>
      state.cardOnboardingOffer.form.applicantOwns100PercentOfShares,
  ],
  (applicantOwns100PercentOfShares) =>
    typeof applicantOwns100PercentOfShares === 'boolean'
);

const selectCompanyIsStockCompany = createSelector(
  [
    (state: RootState) => state.cardOnboardingOffer.overview.companyType,
    (state: RootState) => state.intl.market,
  ],
  (companyType, market) => companyIsStockCompany(market, companyType)
);

const selectApplicantIsABeneficialOwner = createSelector(
  [(state: RootState) => state.cardOnboardingOffer.form.applicant.percentage],
  (applicantPercentage) => isBeneficialOwner(applicantPercentage)
);

const selectApplicantIsSoloBeneficialOwner = createSelector(
  [
    (state: RootState) => state.cardOnboardingOffer.form.applicant.percentage,
    (state: RootState) =>
      state.cardOnboardingOffer.form.companyHasOtherBeneficialOwners,
  ],
  (applicantPercentage, companyHasOtherBeneficialOwners) =>
    isBeneficialOwner(applicantPercentage) &&
    (companyHasOtherBeneficialOwners === false ||
      Number(applicantPercentage) >= 75)
);

const selectOwnersDataIsFromRoaring = createSelector(
  [(state: RootState) => state.cardOnboardingOffer.overview.beneficialOwners],
  (beneficialOwners) => !!beneficialOwners.length
);
const selectTotalAmountOfShares = createSelector(
  [
    (state: RootState) => state.cardOnboardingOffer.form.applicant.percentage,
    (state: RootState) => state.cardOnboardingOffer.form.owners,
    selectOwnersDataIsFromRoaring,
  ],
  (applicantPercentage, owners, ownersDataIsFromRoaring) => {
    if (ownersDataIsFromRoaring) {
      return owners
        .filter((owner) => !owner.isAlternativeBeneficialOwner)
        .reduce((acc, owner) => acc + Number(owner.percentage), 0);
    }
    return owners
      .filter((owner) => !owner.isAlternativeBeneficialOwner)
      .reduce(
        (acc, owner) => acc + Number(owner.percentage),
        Number(applicantPercentage)
      );
  }
);
const selectOwnersFromRoaringHasBeenChanged = createSelector(
  [
    (state: RootState) => state.cardOnboardingOffer.form.owners,
    (state: RootState) => state.cardOnboardingOffer.overview.beneficialOwners,
  ],
  (owners, beneficialOwners) => {
    if (!beneficialOwners.length) {
      return false;
    }

    const hasChanged =
      beneficialOwners.length !== owners.length ||
      beneficialOwners.some((fetchedOwner) => {
        const owner = owners.find((o) => o.id === fetchedOwner.id);

        if (
          !owner ||
          owner.fullName !== fetchedOwner.fullName ||
          owner.personalNumber !== fetchedOwner.personalNumber ||
          owner.percentage !== fetchedOwner.percentage ||
          owner.countryOfResidence !== fetchedOwner.countryOfResidence ||
          moment(owner.dateOfBirth).format('YYYY-MM-DD') !==
            moment(fetchedOwner.dateOfBirth).format('YYYY-MM-DD') ||
          owner.yearOfBirth !== fetchedOwner.yearOfBirth
        ) {
          return true;
        }

        return false;
      });

    return hasChanged;
  }
);

export const selectAlternativeOwnerManuallyAdded = createSelector(
  [(state: RootState) => state.onboardingOffer.form.owners],
  (owners) => {
    return owners.some((owner) => owner.isAlternativeBeneficialOwner);
  }
);

export const selectStepConfig = createSelector(
  [
    (state: RootState) => state.cardOnboardingOffer.form.owners,
    (state: RootState) => state.cardOnboardingOffer.form.applicant,
    (state: RootState) => state.cardOnboardingOffer.overview.companyType,
    (state: RootState) => state.cardOnboardingOffer.overview.additionalSigners,
    (state: RootState) => state.intl.market,
  ],
  (owners, applicant, companyType, signers, market) => {
    const stepConfig = stepConfigPerMarket[
      market as SupportedCountryCodes
    ].filter((step: StepConfigItem) => {
      let hideStep = false;

      switch (step.name) {
        case 'ownership_step': // hide ownership step if company is sole trader
          hideStep = companyIsSoleTrader(market, companyType);
          break;
        case 'additional_signers_and_guarantors_step': {
          // hide step if there are no additional signers and the applicant is an owner.
          // If the applicant is an owner, it means that the AML/KYC data will be added to the applicant in the owners step.
          const noAdditionalSigners = !signers.filter(
            (signer) => signer.type === SignerType.Signer
          ).length;
          const applicantIsAnOwner = !!owners.find((owner) =>
            personsAreTheSame(owner, applicant)
          );

          hideStep = noAdditionalSigners && applicantIsAnOwner;
          break;
        }
        default:
          break;
      }

      return !hideStep;
    });
    return stepConfig;
  }
);

export const useCardOnboardingOfferSelectors = () => ({
  applicantHasAnsweredBeneficialOwnersQuestion: useSelector(
    selectApplicantHasAnsweredBeneficialOwnersQuestion
  ),
  applicantHasAnswered100PercentOwnershipQuestion: useSelector(
    selectApplicantHasAnswered100PercentOwnershipQuestion
  ),
  companyIsStockCompany: useSelector(selectCompanyIsStockCompany),
  applicantIsABeneficialOwner: useSelector(selectApplicantIsABeneficialOwner),
  applicantIsSoloBeneficialOwner: useSelector(
    selectApplicantIsSoloBeneficialOwner
  ),
  totalAmountOfShares: useSelector(selectTotalAmountOfShares),
  ownersDataIsFromRoaring: useSelector(selectOwnersDataIsFromRoaring),
  ownersFromRoaringHasBeenChanged: useSelector(
    selectOwnersFromRoaringHasBeenChanged
  ),
  alternativeOwnerManuallyAdded: useSelector(
    selectAlternativeOwnerManuallyAdded
  ),
  stepConfig: useSelector(selectStepConfig),
});
