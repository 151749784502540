import React from 'react';
import styled from 'styled-components';
import { Size, theme } from '~/styles/themes';

interface StackProps {
  align?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyContent'];
  spacing?: Size;
  grow?: boolean;
  alignContent?: React.CSSProperties['alignContent'];
  wrap?: React.CSSProperties['flexWrap'];
}

const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  gap: ${(props) => theme.spacing[props.spacing!]};
  flex-grow: ${(props) => (props.grow ? 1 : 0)};
  align-content: ${(props) => props.alignContent}
  flex-wrap: ${(props) => props.wrap};
`;

Stack.defaultProps = {
  spacing: 'md',
  justify: 'top',
  align: 'stretch',
  alignContent: 'initial',
  wrap: 'initial',
};

export default Stack;
