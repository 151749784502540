import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';
import { theme } from '~/styles/themes';

export const TrustPilotWidget = styled.div`
  background-color: ${theme.colors.backdrop};
  padding: 10px 0;

  @media ${MediaQueries.tablet} {
    padding: 20px 0;
  }
`;
