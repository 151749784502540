import axios, { AxiosResponse } from 'axios';
import { ContentType, HeaderKeys } from '@qred/shared-enums';
import {
  CardOfferSigner,
  CardOfferUserData,
  OfferUserData,
  ShippingDetailsUserData,
  User,
} from '~/Interfaces';
import { PostCardOfferUserData } from '~/store/types/cardOfferTypes';
import { V2 } from '~/constants/constVars';
import { getCookieValue } from '~/helpers/utils';
import { LoanResponse } from '~/services/dto/loanResponse.dto';
import { CountryCode } from '~/enums';
import { onboardingDTOSchemas } from '~/schemas/DTO/Onboarding';
import { TOnboardingDTO } from '~/types/DTO/onboarding';
import { logToSentry } from '~/helpers/loggers.helper';
import { accessToken } from '~/store/apis/utils/accessToken';

axios.defaults.baseURL = import.meta.env.VITE_API_MYPAGES_URL;

declare module 'axios' {
  export interface AxiosRequestConfig {
    retry?: {
      retryCount: number;
      retryDelay: number;
      retryUntil: (data: any) => boolean;
    };
  }

  export interface AxiosResponse {
    maxRetriesReached?: boolean;
  }
}

export const axiosWithRetry = axios.create();
const retryInterceptor = axiosWithRetry.interceptors.response.use(
  async (response) => {
    const { config, data } = response;

    const maxRetriesReached = config.retry?.retryCount === 0;
    const responseFulfilled = config.retry?.retryUntil(data);

    if (!config.retry || maxRetriesReached || responseFulfilled) {
      axiosWithRetry.interceptors.request.eject(retryInterceptor);
      return Promise.resolve({
        ...response,
        maxRetriesReached: maxRetriesReached && !responseFulfilled,
      });
    }

    config.retry.retryCount -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, config.retry?.retryDelay);
    });
    await delayRetryRequest;
    return axiosWithRetry(config);
  },
  undefined
);

// =================================== HELPERS ===================================
const generateOfferFormData = (data: OfferUserData, offerInterface: string) => {
  const formData = new URLSearchParams();
  formData.append('amount', String(data.amount));
  formData.append('client_id', String(data.clientId));
  formData.append('term', String(data.loanPeriod));
  formData.append('client_account', data.accountNumber);
  formData.append('invoice_email', data.invoiceEmail);
  formData.append('loan_purpose', data.purposeOfLoan);
  formData.append('loan_purpose_manual', data.loanPurposeManual);

  if (data.signers && data.signers.length) {
    data.signers.forEach((signer) => {
      formData.append('signer_block', String(signer.signer_id));
      formData.append(`signer_email_${signer.signer_id}`, signer.email || '');
      formData.append(`signer_phone_${signer.signer_id}`, signer.phone || '');
    });
  }

  formData.append('owner_type', data.ownerType);
  formData.append('owner_block_O1', 'O1');
  formData.append('owner_block_name_O1', data.clientName);
  formData.append('owner_block_personal_no_O1', data.personalNumber);
  formData.append('owner_block_percent_O1', data.yourOwnShares);

  for (let index = 2; index <= data.owners.length; index += 1) {
    const indexString = index.toString().padStart(2, 'O');
    formData.append(`owner_block_${indexString}`, indexString);
    formData.append(
      `owner_block_name_${indexString}`,
      data.owners[index - 1].name
    );
    formData.append(
      `owner_block_personal_no_${indexString}`,
      data.owners[index - 1].personalNumber
    );
    formData.append(
      `owner_block_percent_${indexString}`,
      data.owners[index - 1].percentage
    );
  }

  formData.append(
    'need_paper_invoice',
    data.regularMailForInvoices ? '1' : '0'
  );
  formData.append('is_terms_approved', data.offerIsAgreed ? '1' : '0');
  formData.append(
    'is_personal_guarantee_approved',
    data.personalLiabilityIsAgreed ? '1' : '0'
  );
  formData.append('is_document_approved', data.offerIsAgreed ? '1' : '0');
  formData.append('interface', offerInterface);

  return formData;
};

const generateShippingDetailsPostData = (
  userData: ShippingDetailsUserData
) => ({
  address: {
    city: userData.address?.city,
    street: userData.address?.street,
    zipCode: userData.address?.zipCode,
  },
  email: userData.email,
  mobilePhone: userData.mobilePhone,
  shouldNotHaveCompanyNameOnCard: !userData.shouldHaveCompanyNameOnCard, // BO interpret the value as should NOT have company name on card
  companyNameOnCard: userData.companyNameOnCard,
  cardHolderFullName: userData.cardHolderSelectedFullName,
});

/**
 *
 * @param userData
 *
 * Removes extra information from userData and sends only what is needed to the api
 */
function generateCardOfferPostData(userData: CardOfferUserData) {
  return {
    applicant: userData.applicant,
    additionalSigners: userData.additionalSigners,
    applicationUuid: userData.applicationUuid,
    beneficialOwners: userData.beneficialOwners.map((owner) => ({
      ...owner,
      ownerShare: owner.percentage, // TODO: Need to test the additional signer flow
    })),
    companyType: userData.companyType,
    creditLimit: userData.creditLimit,
    offeredCreditLimit: userData.offeredCreditLimit,
    hasAcceptedTerms: userData.hasAcceptedTerms,
    hasAcceptedPersonalGuarantee: userData.hasAcceptedPersonalGuarantee,
    isPersonalGuaranteeRequired: userData.isPersonalGuaranteeRequired,
    interest: userData.interest,
    invoiceAddress: userData.invoiceAddress,
    invoiceDay: userData.invoiceDay,
    invoiceEmail: userData.invoiceEmail,
    isPaperInvoiceChosen: userData.isPaperInvoiceChosen,
    shippingAddress: userData.shippingAddress,
    clientId: userData.companyId,
    companyName: userData.companyName,
    applicationReasons: userData.applicationReasons,
    // TODO: these two following properties have different names than the object in redux. fix it!
    applicantOwnerShare: userData.applicantOwnerShare,
    ownerStructure: userData.ownerStructure,
    companyNo: userData.companyNo,
  };
}

const getMarket = () => {
  const market = window.__QRED_MARKET__
    ? window.__QRED_MARKET__.toLowerCase()
    : undefined;

  if (!market) {
    return Promise.reject(new Error('No market was found!'));
  }

  return market;
};

export const getApiVersionPathParam = (apiVersion: string | undefined) =>
  apiVersion === V2 ? `${V2}/` : '';

/** *******************************************************************************
 *
 * GET requests should be prefixed with 'apiGet' and POST requests with 'apiPost'
 *
 ******************************************************************************** */

// =================================== GET ===================================
export const apiGetClient = async () => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  let loginLanguage;

  if (!localStorage.getItem('loginLanguageSent')) {
    loginLanguage = localStorage.getItem('qredLoginLanguage');
    localStorage.setItem('loginLanguageSent', 'yes');
  }

  return axios.get('/client', {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
    params: {
      loginLanguage,
    },
  });
};

// call when response of getclient api is 404
export const apiGetApplicationsIfClientNotFound = async () => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }
  return axios.get('/applications/applicant', {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
  });
};

export const apiGetLoan = async (): Promise<AxiosResponse<LoanResponse>> => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  return axios.get<LoanResponse>('/loan', {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
  });
};

export const apiGetApplicationStatus = async () => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  return axios.get(`/applications/client`, {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
  });
};

export const apiGetOffer = async (clientId: number) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const market = getMarket();

  return axios.get(`/loans/offer/${clientId}`, {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
    params: {
      market,
      environment: import.meta.env.MODE,
    },
  });
};

export const apiGetCardOfferData = async (clientId: number) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const market = getMarket();

  return axios.get('/cards/application/offer', {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
    params: {
      clientId,
      market,
      environment: import.meta.env.MODE,
    },
  });
};

export const apiGetPublicCardOfferData = (clientId: number, market: string) => {
  const ACCESSS_TOKEN = getCookieValue('publicToken');

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  return axios.get('/cards/application/offer', {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
    params: {
      clientId,
      market,
      environment: import.meta.env.MODE,
    },
  });
};

export const apiGetShippingDetails = (
  applicationUuid: string,
  market: CountryCode,
  hash: string
) =>
  axios.get(`/cards/application/details/${applicationUuid}/${market}/${hash}`);

export const apiGetTerminatedContractById = async (
  contractId: number,
  clientId: number
) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  return axios.get('/contracts/termination', {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
    params: {
      contractId,
      clientId,
    },
  });
};

export const apiGetPreviousLoans = async (clientId: number) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  return axios.get(`/contracts/client/${clientId}`, {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
  });
};

export const apiGetAiiaIntegrationUrl = (
  language: string,
  redirectUri: string,
  qcode?: string
) =>
  axios.get(`${import.meta.env.VITE_API_AIIA_BASE_URL}/connect`, {
    params: {
      qcode,
      country: language,
      redirectUri,
    },
  });

export const apiGetEskatIntegrationUrl = (qcode: string) =>
  axios.get(`${import.meta.env.VITE_API_ESKAT_INTEGRATION_BASE_URL}/${qcode}`);

export const apiGetClientDataFromQcode = (qCode: string, market: CountryCode) =>
  axios.get(`/client-data/qcode/${qCode}/${market}`, {});

// =================================== POST ===================================
export const apiPostOffer = async (data: OfferUserData) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const formData = generateOfferFormData(data, 'private');

  return axios.post(
    `/loans/offer/application/${data.applicationId}/${data.clientId}/`,
    formData,
    {
      withCredentials: false,
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
        [HeaderKeys.ContentTypeKey]: 'application/x-www-form-urlencoded',
      },
    }
  );
};

export const apiPostCardOfferUserData = async ({
  userData,
  applicationUuid,
  market,
  clientId,
  isPublic,
  apiVersion,
}: PostCardOfferUserData) => {
  const token = await accessToken.get();

  const ACCESSS_TOKEN = isPublic ? getCookieValue('publicToken') : token;

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const data = generateCardOfferPostData(userData);

  const version = getApiVersionPathParam(apiVersion);

  return axios.post(`/cards/${version}application/offer/`, data, {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
    params: {
      application_uuid: applicationUuid,
      market,
      client_id: clientId,
    },
  });
};

export const apiPostSignerConfirmation = () => {
  const ACCESSS_TOKEN =
    getCookieValue('additionalSignerAccessToken') ||
    getCookieValue('publicToken');
  const signerId =
    getCookieValue('additionalSignerId') || getCookieValue('iSig');
  const market = getMarket();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  return axios.post('/applications/signer', null, {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
    params: {
      market,
      signerId,
      userAgreementConsent: true,
      coSigningConsent: true,
    },
  });
};

export const apiPostAiiaIntegrationData = (
  qcode: string,
  code: string,
  consentId: string,
  redirectUri: string,
  language: string,
  customerLink: boolean
) =>
  axios.post(
    `${import.meta.env.VITE_API_AIIA_BASE_URL}/bank-account-snapshots`,
    null,
    {
      params: {
        code,
        consentId,
        redirectUri,
        country: language,
        sessionCookie: qcode,
        customerLink: !!customerLink,
      },
    }
  );

export const apiPostShippingDetails = (
  userData: ShippingDetailsUserData,
  applicationUuid: string,
  market: CountryCode,
  hash: string | undefined
) => {
  const data = generateShippingDetailsPostData(userData);

  if (!hash) {
    return Promise.reject(new Error('No HASH_CODE found!'));
  }

  return axios.post(
    `/cards/application/details/${applicationUuid}/${market}/${hash}`,
    data
  );
};

export const apiPostSignerCardOfferData = (
  data: CardOfferSigner,
  market: string,
  apiVersion?: string
) => {
  const ACCESSS_TOKEN =
    getCookieValue('additionalSignerAccessToken') ||
    getCookieValue('publicToken');

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const requestData = {
    cardApplicationUuid: data.cardApplicationUuid,
    hasAcceptedPersonalGuarantee: true,
    hasAcceptedTerms: true,
    interest: data.interest,
    offeredCreditLimit: data.offeredCreditLimit,
    signerUuid: data.signerUuid,
  };

  const version = getApiVersionPathParam(apiVersion);

  return axios.post(
    `/cards/${version}application/signer/${data.signerUuid}/agreement`,
    requestData,
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
      params: {
        market,
      },
    }
  );
};

export const apiPostInitiateOTP = async (
  market: string,
  signerId: string | null,
  hashCode: string | null,
  productType: string | null
) => {
  const response = await axios.post<TOnboardingDTO['postInitiateOTPRes']>(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${market.toLowerCase()}/generate-additional-signer-otp?signerId=${signerId}&hashCode=${hashCode}&regenerateOtp=${false}&product=${productType}`
  );

  const parseResponseResult = onboardingDTOSchemas.postInitiateOTPRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiPostInitiateOTP parseResponseResult'
    );
  }
  return response;
};

export const apiPostRegenerateOTP = async (
  market: string,
  signerId: string | null,
  hashCode: string | null,
  productType: string | null
) => {
  const response = await axios.post<TOnboardingDTO['postRegenerateOTPRes']>(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${market.toLowerCase()}/generate-additional-signer-otp?signerId=${signerId}&hashCode=${hashCode}&regenerateOtp=${true}&product=${productType}`
  );

  const parseResponseResult = onboardingDTOSchemas.postRegenerateOTPRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiPostRegenerateOTP parseResponseResult'
    );
  }
  return response;
};

export const apiPostVerifyOTP = async (
  market: string,
  otp: string,
  signerId: string | null,
  hashCode: string | null,
  productType: string | null
) => {
  const response = await axios.post<TOnboardingDTO['postVerifyOTPRes']>(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${market}/verify-additional-signer-otp/${otp}?signerId=${signerId}&hashCode=${hashCode}&product=${productType}`
  );

  const parseResponseResult = onboardingDTOSchemas.postVerifyOTPRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiPostVerifyOTP parseResponseResult'
    );
  }
  return response;
};

export const apiGetAdditionalSignerLoanApplication = async (
  market: string | null,
  signerId: string | null
) => {
  const ACCESSS_TOKEN = getCookieValue('additionalSignerAccessToken');

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const response = await axios.get<
    TOnboardingDTO['getAdditionalSignerLoanApplicationDataRes']
  >(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${market}/get-additional-signer-application?product=loan&signerId=${signerId}`,
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = onboardingDTOSchemas.getAdditionalSignerLoanApplicationDataRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetAdditionalSignerLoanApplication parseResponseResult'
    );
  }
  return response;
};

export const apiGetAdditionalSignerCardApplication = async (
  market: string | null,
  signerId: string | null
) => {
  const ACCESSS_TOKEN = getCookieValue('additionalSignerAccessToken');

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const response = await axios.get<
    TOnboardingDTO['getAdditionalSignerCardApplicationDataRes']
  >(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${market}/get-additional-signer-application?product=card&signerId=${signerId}`,
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = onboardingDTOSchemas.getAdditionalSignerCardApplicationDataRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetAdditionalSignerCardApplication parseResponseResult'
    );
  }
  return response;
};

// =================================== PUT ===================================
export const apiPutClient = async (data: Partial<User>) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  return axios.put('/client', data, {
    headers: {
      [HeaderKeys.ContentTypeKey]: ContentType.ApplicationJson,
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
  });
};
