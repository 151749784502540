import { paymentObjectMapper } from '~/helpers/mappers.helper';
import { ApiListData, Payment } from '~/Interfaces';

export function getNextPaymentInDays(
  nextPaymentDate: Date,
  fromDate?: Date
): number {
  // if we do not provide fromDate, now is considered as the value of "from"
  const from = fromDate ? new Date(fromDate) : new Date();
  const to = new Date(nextPaymentDate);
  // Important: we can use this formula because we know the date format from the api is like 2020-10-25
  // and this means midnight. We could not simply use this if the date had hours and seconds.
  const days = Math.ceil((to.getTime() - from.getTime()) / 86400000);
  return days;
}

export function findNextPaymentObj(
  loanDetails: ApiListData,
  fromDate?: Date
): Payment | null {
  const nextPaymentObj = loanDetails?.find(
    (monthlyPayment) =>
      !monthlyPayment.date_paid &&
      getNextPaymentInDays(monthlyPayment.due_date, fromDate) >= 0
  );
  if (!nextPaymentObj) {
    return null;
  }
  return paymentObjectMapper(nextPaymentObj);
}

export function findOverduePayments(
  loanDetails: ApiListData,
  fromDate?: Date
): Array<Payment> | null {
  const overduePayments = loanDetails?.filter(
    (monthlyPayment) =>
      !monthlyPayment.date_paid &&
      !monthlyPayment.postponed_date &&
      getNextPaymentInDays(monthlyPayment.due_date, fromDate) < 0
  );
  if (overduePayments?.length > 0) {
    return overduePayments.map((overduePayment) =>
      paymentObjectMapper(overduePayment)
    );
  }

  return null;
}
