import { PostHogContext } from '~/contexts/PostHogProvider/posthog.context';
import { useContext } from 'react';
import { capturePostHogEvent } from '~/store/actions/posthogActions';
import { useDispatch } from 'react-redux';

export const usePostHogEvents = () => {
  const ctx = useContext(PostHogContext);
  const dispatch = useDispatch();

  const init = (name: string, properties?: Record<string, any>) => {
    ctx.setEvent({ name, properties });
    dispatch(
      capturePostHogEvent({
        eventName: name,
        properties: {
          ...properties,
          visit: true,
        },
      })
    );
  };

  const capture = (properties?: Record<string, any>, name?: string) => {
    if (!ctx.event?.name) {
      throw new Error('Event name of the page is required');
    }

    dispatch(
      capturePostHogEvent({
        eventName: name ?? ctx.event.name,
        properties,
      })
    );
  };

  return {
    init,
    capture,
  };
};
