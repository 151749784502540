import React, { useCallback } from 'react';
import { Company } from '~/Interfaces';
import { useDispatch } from 'react-redux';
import { updateActiveCompany } from '~/store/actions/userActions';
import { Menu } from '@qred/qit-ui';
import { pushToGtmAction } from '~/store/actions/gtmActions';
import { scrollToTop } from '~/helpers/scrollPage';
import {
  fetchApplicationStatus,
  fetchLoansData,
  fetchPreviousLoans,
} from '~/store/actions/loansActions';
import { fetchOfferData } from '~/store/actions/loanOfferActions';

export const CompanyItem: React.FC<{
  company: Company;
}> = ({ company }) => {
  const dispatch = useDispatch();

  const onSwitchCompany = useCallback(() => {
    dispatch(updateActiveCompany(company));
    dispatch(fetchApplicationStatus());
    dispatch(fetchLoansData());
    dispatch(fetchPreviousLoans(company.id as number));
    dispatch(fetchOfferData(company.id as number));
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'company_changed',
      })
    );
    scrollToTop();
  }, [company]);

  return <Menu.Item onClick={onSwitchCompany}>{company.name}</Menu.Item>;
};
