import React from 'react';
import { getMonthsNames } from '~/helpers/utils';
import dayjs from 'dayjs';
import CalendarHeader from '~/components/shared/DatePicker/CalendarHeader';
import styled from 'styled-components';
import { theme } from '~/styles/themes';
import { isMonthInRange } from '~/helpers/date.helper';

export function formatYear(year: number, format: string) {
  return dayjs(new Date(year, 1, 1)).format(format);
}

const MonthPickerControls = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const MonthPickerControl = styled.button`
  flex: 0 0 33.3333%;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-size: 0.7em;
  height: 2.5em;
  width: 2em;
  border-radius: 0.2em;
  color: ${theme.colors.dark};

  &:hover {
    background-color: ${theme.colors.backdrop};
  }

  &:disabled {
    pointer-events: none;
    color: ${theme.colors.secondaryGray};
  }

  &[data-active] {
    background-color: ${theme.colors.secondary};
    color: white;
  }
`;

interface MonthPickerProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
  value: { year: number; month: number };

  onChange(value: number): void;

  locale: string;
  year: number;

  onYearChange(year: number): void;

  onNextLevel(): void;

  minDate?: Date;
  maxDate?: Date;
  nextYearLabel?: string;
  previousYearLabel?: string;
  preventFocus?: boolean;
  yearLabelFormat?: string;
}

const MonthPicker: React.FC<MonthPickerProps> = ({
  value,
  onChange,
  locale,
  year,
  onYearChange,
  onNextLevel,
  minDate,
  maxDate,
  nextYearLabel,
  previousYearLabel,
  preventFocus,
  yearLabelFormat = 'YYYY',
  ...others
}) => {
  const range = getMonthsNames(locale);
  const minYear = minDate instanceof Date ? minDate.getFullYear() : undefined;
  const maxYear = maxDate instanceof Date ? maxDate.getFullYear() : undefined;

  const months = range.map((month, index) => (
    <MonthPickerControl
      key={month}
      onClick={() => onChange(index)}
      data-active={(index === value.month && year === value.year) || undefined}
      disabled={
        !isMonthInRange({ date: new Date(year, index), minDate, maxDate })
      }
      onMouseDown={(event) => preventFocus && event.preventDefault()}
    >
      {month}
    </MonthPickerControl>
  ));

  return (
    <div {...others}>
      <CalendarHeader
        label={formatYear(year, yearLabelFormat)}
        hasNext={typeof maxYear === 'number' ? year < maxYear : true}
        hasPrevious={typeof minYear === 'number' ? year > minYear : true}
        onNext={() => onYearChange(year + 1)}
        onPrevious={() => onYearChange(year - 1)}
        onNextLevel={onNextLevel}
        nextLabel={nextYearLabel}
        previousLabel={previousYearLabel}
        preventFocus={preventFocus}
      />
      <MonthPickerControls data-cy="date_picker_month">
        {months}
      </MonthPickerControls>
    </div>
  );
};

export default MonthPicker;
