import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import { StepStatus } from '~/enums';
import { orgNrAreTheSame } from '~/helpers/utils';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import {
  fetchClientApplication,
  patchApplication,
  postApplication,
} from '~/store/actions/onboardingActions';
import { goToNextStep } from '~/store/slices/onboardingApplication.slice';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';

const CompanySelectorFooter = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    onboardingApplication: {
      form,
      apiStatus: applicationApiStatus,
      currentApplicationOrgNumber,
      overview,
    },
  } = useSelector((state: RootState) => state);

  const applicationApiRequestInProgress = [
    applicationApiStatus.application,
    applicationApiStatus.patch,
    applicationApiStatus.post,
  ].some((apiStatus) => apiStatus === ApiStatus.Started);

  const onNextStepClick = useCallback(async () => {
    const selectedCompany = overview.clients.find((client) =>
      orgNrAreTheSame(form.organizationNumber, client.company_no)
    );

    try {
      dispatch(
        pushToGtmOnboardingAction({ actionName: 'next_button_pressed' })
      );
      // TODO: if user comes back to step 1 and doesnt change company and clicks next, we should PATCH not GET
      if (selectedCompany?.has_offer_to_sign) {
        dispatch(
          pushToGtmOnboardingAction({ actionName: 'redirect_to_offer_page' })
        );
        history.push(
          selectedCompany.application_uuid
            ? `/onboarding-offer/${selectedCompany.application_uuid}`
            : `/offer/${selectedCompany.client_id}`
        );
        return;
      }

      const companyHasChanged = !orgNrAreTheSame(
        form.organizationNumber,
        currentApplicationOrgNumber
      );

      if (
        selectedCompany?.application_uuid &&
        selectedCompany?.has_inprogress_application &&
        companyHasChanged
      ) {
        dispatch(
          pushToGtmOnboardingAction({
            actionName: 'active_application_continue',
          })
        );
        dispatch(
          pushToGtmOnboardingAction({ stepStatus: StepStatus.Finished })
        );
        dispatch(fetchClientApplication(selectedCompany.application_uuid));
      } else {
        if (companyHasChanged) {
          await dispatch(postApplication());
        } else {
          await dispatch(patchApplication());
        }
        dispatch(
          pushToGtmOnboardingAction({ stepStatus: StepStatus.Finished })
        );
        dispatch(goToNextStep());
      }
    } catch (_err: any) {
      dispatch(
        pushToGtmOnboardingAction({
          stepStatus: StepStatus.Error,
          errorMessage: _err.response?.data?.message,
        })
      );
    }
  }, [
    currentApplicationOrgNumber,
    dispatch,
    form.organizationNumber,
    history,
    overview.clients,
  ]);

  return (
    <>
      <NextButton
        label="Onboarding.Next"
        showLoadingSpinner={applicationApiRequestInProgress}
        action={onNextStepClick}
      />
    </>
  );
};

export default CompanySelectorFooter;
