import constVars from '@qred/shared-constants';
import React from 'react';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import {
  FontWeights,
  Typography,
} from '~/components/shared/Typography/Typography';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import { useHistory, useLocation } from 'react-router-dom';
import useTranslate from '~/hooks/useTranslate';
import { MenuItem } from '~/Interfaces';
import { logoutButton } from '~/constants/cy/components/menu-items';
import useLogout from '~/hooks/useLogout';
import {
  MenuItemContainer,
  MenuWrapper,
  TransitionedUnderline,
} from './StyledComponentsMenu';

const MenuItems = () => {
  const history = useHistory();
  const translate = useTranslate();
  const logout = useLogout();
  const location = useLocation();

  const onProfileClicked = () => {
    history.push(`/${constVars.ROUTE_PROFILE}`);
  };

  const onHomeClicked = () => {
    history.push('/');
  };

  const onHelpClicked = () => {
    history.push(`/${constVars.ROUTE_CONTACT}`);
  };

  const MenuItemOptions: Array<MenuItem> = [
    {
      label: translate('Home') as string,
      icon: SvgSrc.Home,
      onClick: onHomeClicked,
      active: location.pathname === '/',
    },
    {
      label: translate('Profile') as string,
      icon: SvgSrc.User,
      onClick: onProfileClicked,
      active: location.pathname.startsWith(`/${constVars.ROUTE_PROFILE}`),
    },
    {
      label: translate('Help') as string,
      icon: SvgSrc.User,
      onClick: onHelpClicked,
      active: location.pathname === `/${constVars.ROUTE_CONTACT}`,
    },
    {
      label: translate('Logout') as string,
      icon: SvgSrc.SignOut,
      onClick: logout,
      dataCy: logoutButton,
    },
  ];

  return (
    <MenuWrapper>
      {MenuItemOptions.map((item: MenuItem) => (
        <MenuItemContainer
          key={item.label}
          onClick={() => item.onClick()}
          data-cy={item.dataCy}
        >
          <TransitionedUnderline>
            <Icon
              mr={0.5}
              src={item.icon}
              color={
                item.active ? theme.colors.dark : theme.colors.secondaryGray
              }
              size={ComponentSize.MenuIcon}
            />
            <Typography
              fontSize={1}
              fontWeight={item.active ? FontWeights.Thick : FontWeights.Normal}
            >
              {item.label}
            </Typography>
          </TransitionedUnderline>
        </MenuItemContainer>
      ))}
    </MenuWrapper>
  );
};

export default MenuItems;
