import React from 'react';
import Card from '~/components/shared/Card/Card';
import styled from 'styled-components';
import Image from '~/components/shared/Image/Image';
import CardContentContractSigned from '~/pages/ContractSigned/components/CardContentContractSigned';

const ImageContainer = styled.div`
  width: 4em;
  margin: 1em auto;
`;

const ContractSignedCard = () => (
  <Card>
    <ImageContainer>
      <Image src="hero.png" />
    </ImageContainer>
    <CardContentContractSigned />
  </Card>
);

export default ContractSignedCard;
