import { Application, Company, Loan, PreviousLoans, User } from '~/Interfaces';
import { ApiStatus } from './sharedTypes';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_AVATAR_URL = 'UPDATE_AVATAR_URL';
export const SET_COMPANIES_DATA = 'SET_COMPANIES_DATA';
export const SET_API_USER_INFO_STATUS = 'SET_API_USER_INFO_STATUS';
export const SET_PERSONAL_NUMBER = 'SET_PERSONAL_NUMBER';
export const UPDATE_ACTIVE_COMPANY = 'UPDATE_ACTIVE_COMPANY';
export const UPDATE_ACTIVE_COMPANY_LOAN = 'UPDATE_ACTIVE_COMPANY_LOAN';
export const UPDATE_ACTIVE_COMPANY_PREVIOUS_LOANS =
  'UPDATE_ACTIVE_COMPANY_PREVIOUS_LOANS';
export const UPDATE_ACTIVE_COMPANY_APPLICATION =
  'UPDATE_ACTIVE_COMPANY_APPLICATION';

export interface UpdateUserDataAction {
  type: typeof UPDATE_USER_DATA;
  payload: Partial<User>;
}

export interface UpdateCompaniesDataAction {
  type: typeof SET_COMPANIES_DATA;
  payload: Array<Company>;
}

export interface UpdateAvatarUrlAction {
  type: typeof UPDATE_AVATAR_URL;
  payload: string;
}

interface UpdateApiLoansStatusAction {
  type: typeof SET_API_USER_INFO_STATUS;
  payload: ApiStatus;
}

interface UpdatePersonalNumberAction {
  type: typeof SET_PERSONAL_NUMBER;
  payload: string;
}

interface UpdateActiveCompanyAction {
  type: typeof UPDATE_ACTIVE_COMPANY;
  payload: Company;
}

interface UpdateActiveCompanyLoanAction {
  type: typeof UPDATE_ACTIVE_COMPANY_LOAN;
  payload: Loan[];
}

interface UpdateActiveCompanyPreviousLoansAction {
  type: typeof UPDATE_ACTIVE_COMPANY_PREVIOUS_LOANS;
  payload: PreviousLoans[];
}

interface UpdateActiveCompanyApplicationAction {
  type: typeof UPDATE_ACTIVE_COMPANY_APPLICATION;
  payload: Application[];
}

export type UserAction =
  | UpdateCompaniesDataAction
  | UpdateUserDataAction
  | UpdateAvatarUrlAction
  | UpdateApiLoansStatusAction
  | UpdatePersonalNumberAction
  | UpdateActiveCompanyAction
  | UpdateActiveCompanyLoanAction
  | UpdateActiveCompanyPreviousLoansAction
  | UpdateActiveCompanyApplicationAction;
