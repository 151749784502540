import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, TextInput, Typography } from '@qred/components-library';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';
import { ValidationContext } from '~/components/hoc/withValidation';
import { onChangeInputEvent } from '~/types/types';
import { RootState } from '~/store/types/sharedTypes';
import useTranslate from '~/hooks/useTranslate';
import { validateBankAccountNumber } from '~/helpers/validators.helper';

const AccountNumberInput = () => {
  const dispatch = useDispatch();
  const {
    onboardingOffer: { form },
    intl: { market },
  } = useSelector((state: RootState) => state);
  const validationContext = useContext(ValidationContext);
  const [isTouched, setIsTouched] = useState(false);
  const isMarketSweden = market === 'SE';
  const translate = useTranslate();

  useEffect(() => {
    const hasValidAccountNumber = validateBankAccountNumber(
      form.accountNumber,
      market
    );

    if (!hasValidAccountNumber) {
      validationContext.addPropertyToValidationErrors('InvalidAccountNumber');
    }
    return () => {
      validationContext.removePropertyFromValidationErrors(
        'InvalidAccountNumber'
      );
    };
  }, [form.accountNumber]);

  const onAccountNumberChange = (event: onChangeInputEvent) => {
    const { value } = event.target;
    dispatch(updateForm({ accountNumber: value, account: {} }));
  };

  const accountNumberIsValid = validateBankAccountNumber(
    form.accountNumber,
    market
  );

  return (
    <Stack mt="2em">
      <TextInput
        value={form.accountNumber || ''}
        onChange={onAccountNumberChange}
        onBlur={() => setIsTouched(true)}
        label={
          <Typography weight={500}>
            {translate('OnboardingOffer.BankAccountInputLabel')}
          </Typography>
        }
        hint={
          //TODO: This is a temporary solution to hide the description for the SE market, this should be updated in OB-2669
          !isMarketSweden && (
            <Typography weight={400} color="textDark.500">
              {translate('OnboardingOffer.BankAccountInputDescription')}
            </Typography>
          )
        }
        error={
          isTouched &&
          !accountNumberIsValid &&
          translate('ValidationErrors.AccountNumber')
        }
      />
    </Stack>
  );
};

export default AccountNumberInput;
