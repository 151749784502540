import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import Icon from '../Icon/Icon';

const BaseAlert = styled.div`
  color: ${theme.colors.white};
  padding: 0.55em 1em;
  transform: translateY(50%);
  border-radius: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const HappyAlert = styled(BaseAlert)`
  background-color: ${theme.colors.accent};
`;

const WarningAlert = styled(BaseAlert)`
  background-color: ${theme.colors.warning};
`;

const DangerAlert = styled(BaseAlert)`
  background-color: ${theme.colors.error};
`;

const DisabledAlert = styled(BaseAlert)`
  background-color: ${theme.colors.secondaryGray};
`;

const Span = styled.span`
  color: 'inherit';
  letter-spacing: 0.05em;
  font-size: 0.7em;
  font-weight: 600;
`;

export enum AlertColor {
  Danger,
  Warning,
  Happy,
  Disabled,
}

interface Props {
  message: string;
  svgSrc?: IconProp;
  color?: AlertColor;
}

const getAlertContainer = (color?: AlertColor) => {
  if (color && color === AlertColor.Happy) {
    return HappyAlert;
  }
  if (color && color === AlertColor.Warning) {
    return WarningAlert;
  }
  if (color && color === AlertColor.Disabled) {
    return DisabledAlert;
  }
  return DangerAlert;
};

const AlertBox: React.FC<Props> = (props) => {
  const AlertContainer = getAlertContainer(props.color);

  return (
    <AlertContainer>
      {/* Alert box can be rendered with or without an icon */}
      {props.svgSrc && (
        <Icon
          src={props.svgSrc}
          size={ComponentSize.Small}
          mr={0.5}
          color={theme.colors.white}
        />
      )}
      <Span>{props.message}</Span>
    </AlertContainer>
  );
};

export default AlertBox;
