import { InvoiceState } from '~/store/types/sharedTypes';
import { Invoices, PaidInvoice, UnpaidInvoice } from '~/Interfaces';
import { FETCH_INVOICES, InvoicesAction } from '~/store/types/invoicesTypes';
import dayjs from 'dayjs';
import { LoanResponse } from '~/services/dto/loanResponse.dto';

export const fetchInvoices = (invoices: Invoices): InvoicesAction => ({
  type: FETCH_INVOICES,
  payload: invoices,
});

export const getMappedInvoices = (
  activeCompanyId: number | undefined,
  data: LoanResponse
) => {
  const getInvoiceState = (
    dueDate: string,
    dateAuthorized: string
  ): InvoiceState => {
    const date = dayjs(dueDate);
    const now = dayjs();
    const nbDaysBetweenDueDateAndNow = date.diff(now, 'days');

    if (dateAuthorized) {
      return 'inProcess';
    }

    if (date.isBefore(now)) {
      return 'overdue';
    }

    if (nbDaysBetweenDueDateAndNow <= 3) {
      return 'due-in';
    }

    return 'new';
  };

  const loan = data.loan.loans?.find((l) => l.company_id === activeCompanyId);

  if (!loan) {
    return {
      unpaid: [] as UnpaidInvoice[],
      paid: [] as PaidInvoice[],
    };
  }

  const unpaid: UnpaidInvoice[] =
    loan.loan_status
      ?.filter((c) => !c.date_paid)
      .map(
        (c) =>
          ({
            id: c.invoice?.invoice_id ?? c.contract_line_id,
            contractLineId: c.contract_line_id,
            contractId: c.contract_id,
            generated: c.invoice?.invoice_id !== undefined,
            amount: c.amount,
            dueDate: c.due_date,
            monthIndex: dayjs(c.due_date).month(),
            dateAuthorized: c.date_authorized,
            amortization: c.amortization,
            reference: c.reference,
            state: getInvoiceState(c.due_date, c.date_authorized),
            lateFee: c.invoice?.letter_fee,
            latePenaltyFee: c.invoice
              ? c.invoice?.penalty
              : loan.penalty_actual,
            monthlyFee: c.invoice
              ? c.invoice?.monthly_interest
              : c.monthly_interest,
            total: c.invoice ? c.invoice?.invoice_total : c.total_month,
            sentDate: c.sent_date,
          } as UnpaidInvoice)
      ) ?? [];

  const paid: PaidInvoice[] =
    loan.loan_status
      ?.filter((c) => c.date_paid)
      .map(
        (c) =>
          ({
            id: c.invoice?.invoice_id ?? c.contract_line_id,
            contractLineId: c.contract_line_id,
            amount: c.amount,
            paymentDate: dayjs(c.date_paid).format('YYYY-MM-DD'),
            amortization: c.invoice?.amortization,
            lateFee: c.invoice?.letter_fee,
            latePenaltyFee: c.invoice
              ? c.invoice?.penalty
              : loan.penalty_actual,
            monthlyFee: c.invoice?.monthly_interest,
            total: c.invoice?.invoice_total ?? c.total_month,
          } as PaidInvoice)
      ) ?? [];

  paid.sort(
    (a, b) => dayjs(b.paymentDate).unix() - dayjs(a.paymentDate).unix()
  );

  return {
    unpaid,
    paid,
    relatedLoanId: loan.loan_id,
  };
};
