import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Typography } from '~/components/shared/Typography/Typography';
import { RootState } from '~/store/types/sharedTypes';
import {
  ArrangementProps,
  BaseArrangement,
} from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';

const ProgressBarContainer = styled(BaseArrangement)`
  background-color: ${theme.colors.primaryGray};
  border-radius: 1em;
  @media (orientation: landscape) {
    width: 50%;
    margin: auto;
  }
`;

const CreateCardProgressBar: React.FC<ArrangementProps> = (props) => {
  const [progress, setProgress] = useState(10);
  const [intervalId, setIntervalId] = useState<any>();
  const { isCardCreated, isCardCreationFailed } = useSelector(
    (state: RootState) => state.shippingDetails
  );

  useEffect(() => {
    let id: any;
    if (!isCardCreated) {
      let p = progress;
      clearInterval(id);
      const cb = () => {
        if (p >= 90) {
          clearInterval(id);
          return;
        }
        p = p + Math.floor(Math.random() * 10) + 1;
        setProgress(p);
      };
      id = setInterval(cb, 1000);
      setIntervalId(id);
    }

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (isCardCreated || isCardCreationFailed) {
      clearInterval(intervalId);
      setProgress(100);
    }
  }, [isCardCreated, isCardCreationFailed]);

  const barStyles = {
    width: `${progress}%`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: isCardCreationFailed
      ? theme.colors.error
      : theme.colors.primaryGreen,
    color: 'white',
    borderRadius: '1em',
    padding: '0.5em',
    height: '1.2em',
    transition: 'width 1s ease-in-out',
  };

  return (
    <ProgressBarContainer mt={props.mt} mb={props.mb}>
      {/* Using styled comonent to render the bar re-renders the element which results to transition not taking effect */}
      <div style={barStyles}>
        <Typography fontSize={0.8} fontWeight={600} color={theme.colors.white}>
          {progress > 15 ? `${progress}%` : null}
        </Typography>
      </div>
    </ProgressBarContainer>
  );
};

export default CreateCardProgressBar;
