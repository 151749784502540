import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`;

interface Props {
  src: string;
}

const Image: React.FC<Props> = (props) => <ImageContainer src={props.src} />;

export default Image;
