import React, { RefObject } from 'react';
import { StepConfigItem } from '~/Interfaces';
import { StepFooterContent, StepFooterWrapper } from '../Stepper.styled';
import NextButton from './NextButton';

interface StepFooterProps {
  CTALabel: string;
  currentStepConfig: StepConfigItem;
  CTAAction: () => void;
  footerRef: RefObject<HTMLDivElement>;
  stepIsLoading?: boolean;
  extraContent?: React.ReactNode;
}

const StepFooter = ({
  CTALabel,
  currentStepConfig,
  CTAAction,
  footerRef,
  stepIsLoading,
  extraContent,
}: StepFooterProps) => (
  <StepFooterWrapper ref={footerRef}>
    <StepFooterContent>
      {extraContent}
      {currentStepConfig.customFooter ? (
        <currentStepConfig.customFooter />
      ) : (
        <NextButton
          label={CTALabel}
          action={CTAAction}
          showLoadingSpinner={stepIsLoading}
        />
      )}
    </StepFooterContent>
  </StepFooterWrapper>
);

export default StepFooter;
