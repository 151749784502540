import { LoanOwner, Offer, OfferUserData } from '~/Interfaces';
import { Signer } from '~/types/types';

export const SET_OFFER_DATA = 'SET_OFFER_DATA';
export const UPDATE_OFFER_DATA = 'UPDATE_OFFER_DATA';
export const ADD_EMPTY_OWNER_PLACEHOLDER = 'ADD_EMPTY_OWNER_PLACEHOLDER';
export const REMOVE_OWNER_PLACEHOLDER = 'REMOVE_OWNER_PLACEHOLDER';
export const UPDATE_OWNER = 'UPDATE_OWNER';
export const UPDATE_YOUR_OWN_SHARES = 'UPDATE_YOUR_OWN_SHARES';
export const SET_HAS_OFFER = 'SET_HAS_OFFER';
export const UPDATE_OFFER_SIGNERS = 'UPDATE_OFFER_SIGNERS';
export const SET_OFFER_IS_AGREED = 'SET_OFFER_IS_AGREED';
export const SET_PERSONAL_LIABILITY_IS_AGREED =
  'SET_PERSONAL_LIABILITY_IS_AGREED';
export const SET_REGULAR_MAIL_FOR_INVOICES = 'SET_REGULAR_MAIL_FOR_INVOICES';
export const SET_OFFER_SUBMIT_STATUS = 'SET_OFFER_SUBMIT_STATUS';
export const SET_ACCESS_TOKEN_ERROR = 'SET_ACCESS_TOKEN_ERROR';

interface UpdateSigners {
  type: typeof UPDATE_OFFER_SIGNERS;
  payload: Partial<Signer>;
}

// never types added becuase typescript complained about not having payload

interface SetOfferData {
  type: typeof SET_OFFER_DATA;
  payload: Offer[];
}

interface UpdateOfferData {
  type: typeof UPDATE_OFFER_DATA;
  payload: Partial<OfferUserData>;
}

interface AddEmptyOwnerPlaceholder {
  type: typeof ADD_EMPTY_OWNER_PLACEHOLDER;
  payload: null;
}

interface RemoveOwnerPlaceholder {
  type: typeof REMOVE_OWNER_PLACEHOLDER;
  payload: string;
}

interface UpdateOwner {
  type: typeof UPDATE_OWNER;
  payload: LoanOwner;
}

interface UpdateYourOwnShares {
  type: typeof UPDATE_YOUR_OWN_SHARES;
  payload: string;
}

interface SetHasOffer {
  type: typeof SET_HAS_OFFER;
  payload: boolean;
}

interface ToggleOfferIsAgreed {
  type: typeof SET_OFFER_IS_AGREED;
  payload: boolean;
}
interface TogglePersonalLiabilityIsAgreed {
  type: typeof SET_PERSONAL_LIABILITY_IS_AGREED;
  payload: boolean;
}

interface ToggleRegularMailForInvoices {
  type: typeof SET_REGULAR_MAIL_FOR_INVOICES;
  payload: boolean;
}

interface SetSubmitStatusAction {
  type: typeof SET_OFFER_SUBMIT_STATUS;
  payload: boolean;
}
interface SetErrorAccessTokenStatus {
  type: typeof SET_ACCESS_TOKEN_ERROR;
  payload: boolean;
}

export type OfferAction =
  | SetOfferData
  | UpdateOfferData
  | AddEmptyOwnerPlaceholder
  | RemoveOwnerPlaceholder
  | UpdateOwner
  | UpdateYourOwnShares
  | SetHasOffer
  | ToggleOfferIsAgreed
  | TogglePersonalLiabilityIsAgreed
  | ToggleRegularMailForInvoices
  | UpdateSigners
  | SetSubmitStatusAction
  | SetErrorAccessTokenStatus;
