import React, { useState } from 'react';
import Popup from '~/components/shared/Popup/Popup';
import { Typography } from '~/components/shared/Typography/Typography';
import useTranslate from '~/hooks/useTranslate';
import { theme } from '~/styles/themes';

const CardOfferErrorModal: React.FC = () => {
  /* States */
  const [modalIsOpen, setModalIsOpen] = useState(true);

  /* Other hooks */
  const translate = useTranslate();

  return (
    <>
      <Popup isOpen={modalIsOpen} onOkButtonClick={() => setModalIsOpen(false)}>
        <Typography
          fontSize={1.4}
          color={theme.colors.dark}
          fontWeight={600}
          lineHeight={1.5}
          centerAlign
          element="h4"
        >
          {translate('CardOfferIdentificationErrorModal.Title') as string}
        </Typography>
        <Typography fontSize={1.2} lineHeight={1.4} color={theme.colors.dark}>
          {translate('CardOfferIdentificationErrorModal.Text') as string}
        </Typography>
        <Typography fontSize={1.2} lineHeight={1.4} color={theme.colors.dark}>
          {translate('CardOfferIdentificationErrorModal.Text2')}
        </Typography>
      </Popup>
    </>
  );
};

export default CardOfferErrorModal;
