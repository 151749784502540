import React from 'react';
import { useSelector } from 'react-redux';
import DatePicker from '~/components/shared/DatePicker/DatePicker';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { RootState } from '~/store/types/sharedTypes';
import useTranslate from '~/hooks/useTranslate';
import { getDatePlaceholder } from '~/constants/markets';

interface DateOfBirthProps {
  dateOfBirthOnChangeHandler: (date: Date | null) => void;
  value: Date | string;
  useModal: boolean;
  openOnFocus?: boolean;
  showValidationInfoWhenUntouched?: boolean;
  disabled?: boolean;
  dataCy?: string;
}

const DateOfBirth = ({
  dateOfBirthOnChangeHandler,
  value,
  useModal,
  openOnFocus,
  showValidationInfoWhenUntouched,
  disabled,
  dataCy,
}: DateOfBirthProps) => {
  const translate = useTranslate();

  const { language, market } = useSelector((state: RootState) => state.intl);

  return (
    <DatePicker
      dataCy={dataCy}
      showValidationInfoWhenUntouched={showValidationInfoWhenUntouched}
      useModal={useModal}
      rightText={<Icon src={SvgSrc.Calendar} />}
      label={translate('DateOfBirth') as string}
      name="dateOfBirth"
      validationType="DateOfBirth"
      locale={language}
      maxDate={new Date()}
      placeholder={getDatePlaceholder(market, language)}
      onChange={dateOfBirthOnChangeHandler}
      value={value}
      openOnFocus={openOnFocus}
      inputFormat="DD-MM-YYYY"
      initialLevel="year"
      disabled={disabled}
    />
  );
};

export default React.memo(DateOfBirth);
