import { Icon, IconKey } from '@qred/qit-ui';
import { Typography } from '@qred/components-library';
import React from 'react';
import { HelpItemWrapper, IconContainer } from './HelpSection.styled';

interface HelpItemProps {
  icon: IconKey;
  text: string;
  element?: any;
  href?: string;
  onClick?: () => void;
}

const HelpItem = ({ icon, text, element, href, onClick }: HelpItemProps) => (
  <HelpItemWrapper as={element} href={href} onClick={onClick}>
    <IconContainer>
      <Icon size="lg" src={icon} color="white" />
    </IconContainer>
    <Typography>{text}</Typography>
  </HelpItemWrapper>
);

export default HelpItem;
