import styled from 'styled-components';
import { theme } from '~/styles/themes';

export const HelpPopupContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const HelpPopupContent = styled.div`
  width: 90%;
  padding: 2em;
  border-radius: 1.5em;
  background-color: ${theme.colors.white};
  @media (orientation: landscape) {
    max-width: 30em;
  }
`;

export const MeansOfSupportWrapper = styled.div`
  margin: 1em 0;
`;
