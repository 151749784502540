/* eslint-disable @typescript-eslint/no-explicit-any */
import { KlarnaKosmaFlow } from '~/hooks/useKlarnaFlow/types';
import {
  KlarnaKosmaAction,
  UPDATE_TRANSACTION_FLOW,
} from '~/store/types/klarnaKosmaTypes';
import {
  apiInitKlarnaKosmaTransactions,
  apiStoreKlarnaKosmaTransactions,
} from '~/services/api/klarnaKosma';
import { GlobalErrorType } from '~/enums';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import { AppThunk, GetStateFunc } from '../types/sharedTypes';
import { toggleGlobalErrorOn } from '../slices/globalError.slice';

export const updateTransactionFlow = (
  data: KlarnaKosmaFlow
): KlarnaKosmaAction => ({
  type: UPDATE_TRANSACTION_FLOW,
  payload: data,
});

export const processKlarnaKosmaTransactionFlow = (
  organizationNumber: string,
  clientId: number,
  applicationUuid?: string
): AppThunk => async (dispatch, getState: GetStateFunc) => {
  const {
    intl: { language, market },
  } = getState();
  dispatch(updateTransactionFlow({ status: 'inProgress' }));

  try {
    const response = await apiInitKlarnaKosmaTransactions(
      market,
      language,
      clientId
    );

    const {
      client_token: clientToken,
      session_id: sessionId,
      flow_id: flowId,
    } = response.data;

    window.XS2A.startFlow(clientToken, {
      onFinished: async () => {
        try {
          dispatch(updateTransactionFlow({ status: 'completed' }));
          await apiStoreKlarnaKosmaTransactions(
            market,
            sessionId,
            flowId,
            organizationNumber,
            clientId,
            applicationUuid
          );
        } catch (err) {
          dispatch(
            pushToSentryAction(err, 'apiStoreKlarnaKosmaTransactions', {
              organizationNumber,
            })
          );
        }
      },
      onError: (err) => {
        dispatch(
          pushToSentryAction(err, 'processKlarnaKosmaTransactionFlow flow', {
            organizationNumber,
          })
        );
        dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
        dispatch(updateTransactionFlow({ status: 'error', error: err }));
      },
      onAbort: () => {
        dispatch(updateTransactionFlow({ status: 'idle' }));
      },
    });
  } catch (err) {
    dispatch(
      pushToSentryAction(err, 'apiInitKlarnaKosmaTransactions', {
        organizationNumber,
      })
    );
    dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    dispatch(updateTransactionFlow({ status: 'error', error: err }));
  }
};
