import { ShippingDetails, ShippingDetailsUserData } from '~/Interfaces';
import initialState from '../initialState';
import {
  SET_IS_CARD_CREATED_STATUS,
  SET_IS_CARD_CREATION_FAILED_STATUS,
  SET_SET_PIN_STATUS,
  SET_SHIPPING_DETAILS_DATA,
  SET_SHIPPING_DETAILS_HASH,
  SET_SHIPPING_DETAILS_USER_DATA,
  ShippingDetailsAction,
  UPDATE_SHIPPING_DETAILS_USER_DATA,
} from '../types/shipingDetailsTypes';

interface ShippingDetailsState {
  readonly overview: ShippingDetails | undefined;
  readonly userData: ShippingDetailsUserData;
  readonly isSubmitting: boolean;
  readonly isCardCreated: boolean;
  readonly isCardCreationFailed: boolean;
  readonly hash: string | undefined;
}

const shippingDetailsReducer = (
  state: ShippingDetailsState = initialState.shippingDetails,
  action: ShippingDetailsAction
): ShippingDetailsState => {
  switch (action.type) {
    case SET_SHIPPING_DETAILS_DATA:
      return { ...state, overview: action.payload };

    case SET_SHIPPING_DETAILS_HASH:
      return { ...state, hash: action.payload };

    case SET_SHIPPING_DETAILS_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
          companyNameOnCard: action.payload.companyName?.substring(0, 26),
          shouldHaveCompanyNameOnCard:
            action.payload.companyName !==
            `${action.payload.cardHolderFullName.firstName} ${action.payload.cardHolderFullName.lastName}`,
          address: action.payload.addresses[0],
        },
      };

    case UPDATE_SHIPPING_DETAILS_USER_DATA:
      return { ...state, userData: { ...state.userData, ...action.payload } };

    case SET_SET_PIN_STATUS:
      return { ...state, isSubmitting: action.payload };

    case SET_IS_CARD_CREATED_STATUS:
      return { ...state, isCardCreated: action.payload };

    case SET_IS_CARD_CREATION_FAILED_STATUS:
      return { ...state, isCardCreationFailed: action.payload };

    default:
      return state;
  }
};

export default shippingDetailsReducer;
