import { CountryCode } from '~/enums';
import { ValidLanguagesPerMarket } from '~/constants/markets';
import { marketAndLanguagePerReferrerUrl } from '~/pages/Login/constants';
import { Language } from '~/store/types/sharedTypes';
import { LanguageMap } from '~/types/types';

export const defaultLanguagePerMarket: LanguageMap = {
  [CountryCode.BE]: Language.DutchBE,
  [CountryCode.DK]: Language.Danish,
  [CountryCode.FI]: Language.Finnish,
  [CountryCode.NL]: Language.Dutch,
  [CountryCode.SE]: Language.Swedish,
  [CountryCode.NO]: Language.Norwegian,
  [CountryCode.DE]: Language.German,
};

export const languageIsValidForMarket = (
  language: Language,
  market: CountryCode
) => ValidLanguagesPerMarket[market].some((lang) => lang === language);

export const languageIsValid = (language: Language | string) =>
  Object.values(Language).some(
    (existingLanguage) => existingLanguage === language
  );

export const marketIsValid = (market: CountryCode | string) =>
  Object.values(CountryCode).some(
    (existingMarket) => existingMarket === market
  );

export const parseMarket = (market?: string | null) => {
  const parsedMarket = market?.toUpperCase();
  if (parsedMarket && marketIsValid(parsedMarket)) {
    return parsedMarket as CountryCode;
  }
  return null;
};

export const parseLanguage = (language?: string | null) => {
  const parsedLanguage = language?.toUpperCase();
  if (parsedLanguage && languageIsValid(parsedLanguage)) {
    return parsedLanguage as Language;
  }
  return null;
};

export const getLanguageFromLocalStorage = () => {
  const qredLanguage = localStorage.getItem('qredLanguage');
  return parseLanguage(qredLanguage);
};

export const getMarketFromLocalStorage = () => {
  const qredMarket = localStorage.getItem('qredMarket');
  return parseMarket(qredMarket);
};

export const getLanguageBasedOnReferrerUrl = () => {
  const referrerUrl = document.referrer;
  if (!referrerUrl) return null;
  const url = new URL(referrerUrl);
  const marketAndLanguage = marketAndLanguagePerReferrerUrl[url.origin];

  return marketAndLanguage?.language;
};
