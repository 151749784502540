import React from 'react';
import { theme } from '~/styles/themes';
import { ContactEmails } from '~/constants/markets';
import { RootState } from '~/store/types/sharedTypes';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useTranslate from '~/hooks/useTranslate';
import QredAppStoreButtonAndQrCodeView from './StoreButtonAndQrCodeView/StoreButtonAndQrCodeView';

export const LinkTag = styled.a`
  cursor: pointer;
  color: ${theme.colors.midwayLinkLight};
`;

const ThankyouCardWaitingAdditionalSigner = () => {
  const { market } = useSelector((state: RootState) => state.intl);
  const translate = useTranslate();

  const QredSupportLink = () => (
    <LinkTag href={`mailto:${ContactEmails[market]}`}>
      {ContactEmails[market]}
    </LinkTag>
  );

  return (
    <QredAppStoreButtonAndQrCodeView
      title={'OnboardingCardOffer.youAreAllSet'}
      description1={
        'OnboardingCardOffer.ThankyouCardAdditionalSignerDescription1'
      }
      description2={
        translate(
          'OnboardingCardOffer.ThankyouCardAdditionalSignerDescription2',
          {
            qredSupportEmail: <QredSupportLink />,
          }
        ) as string
      }
    />
  );
};

export default ThankyouCardWaitingAdditionalSigner;
