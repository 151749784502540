import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Group,
  Modal,
  Typography,
  notifications,
  useDisclosure,
} from '@qred/components-library';
import { ApiStatus, Language, RootState } from '~/store/types/sharedTypes';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from '~/hooks/useQueryParams';
import {
  initiateOTP,
  verifyOTP,
} from '~/store/actions/additionalSignerActions';
import AdditionalSignerOTPTimer from './AdditionalSignerOTPTimer';
import AdditionalSignerOTPErrorModal from './AdditionalSignerOTPErrorModal';
import { theme } from '~/styles/themes';
import { Redirect } from 'react-router';
import constVars from '~/constants/constVars';
import { getCookieValue } from '~/helpers/utils';
import { CountryCode, OTPErrorType, ProductType } from '~/enums';
import {
  setApiStatus,
  setOTPError,
} from '~/store/slices/additionalSigner.slice';
import useTranslate from '~/hooks/useTranslate';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { parseLanguage } from '~/helpers/market.helper';
import { setLanguage } from '~/store/slices/intl.slice';
import AdditionalSignerOTPInputSection from './AdditionalSignerOTPInputSection';
import { timestampIsInThePast } from '~/helpers/date.helper';

const AdditionalSignerOTP = () => {
  const {
    matchedMedia: { mobile },
    intl: { market, languageIsSet },
    additionalSigner: { otp, apiStatus, otpExpiryTimestamp, otpError },
  } = useSelector((state: RootState) => state);

  const queryParams = useQueryParams();
  const hashCode = queryParams.get('hashCode');
  const signerId = queryParams.get('signerId');
  const productType = queryParams.get('product');
  const languageParam = queryParams.get('lang');

  const dispatch = useDispatch();
  const translate = useTranslate();
  const [
    opened,
    { open: openAdditionalSignerOTPErrorModal, close },
  ] = useDisclosure(false);

  useEffect(() => {
    if (languageParam) {
      let parsedLanguage = parseLanguage(queryParams.get('lang'));
      // Dutch language is not supported in Belgium in our code, so we need to change it to DutchBE
      if (parsedLanguage === Language.Dutch && market === CountryCode.BE) {
        parsedLanguage = Language.DutchBE;
      }
      parsedLanguage && dispatch(setLanguage(parsedLanguage));
    }
    document.cookie = `additionalSignerHashCode=${hashCode}; Path=/; Max-Age=86400`;
    document.cookie = `additionalSignerId=${signerId}; Path=/; Max-Age=86400`;
    document.cookie = `additionalSignerProductType=${productType}; Path=/; Max-Age=86400`;
  }, []);

  const isValidOtpLength = otp.length === 6;
  const otpIsExpired = timestampIsInThePast(otpExpiryTimestamp);

  const additionalSignerAccessToken = getCookieValue(
    'additionalSignerAccessToken'
  );

  useEffect(() => {
    const urlParametersAreValidAndUserDoesNotHaveAToken =
      hashCode && signerId && productType && !additionalSignerAccessToken;

    if (urlParametersAreValidAndUserDoesNotHaveAToken) {
      dispatch(initiateOTP(market));
    }
  }, []);

  useEffect(() => {
    if (apiStatus.regenerateOTP === ApiStatus.Completed) {
      notifications.show({
        state: 'success',
        message: translate('AdditionalSigner.CodeSent'),
      });
      dispatch(setApiStatus({ regenerateOTP: ApiStatus.Idle }));
    }
  }, [apiStatus]);

  useEffect(() => {
    if (otpError === OTPErrorType.UNKNOWN) {
      dispatch(setOTPError(null));
      openAdditionalSignerOTPErrorModal(); // generic error popup
    }
  }, [otpError]);

  const onClickVerifyOTP = () => {
    if (signerId && hashCode && isValidOtpLength) {
      dispatch(verifyOTP(market, otp));
    }
  };

  if (otpError === OTPErrorType.SIGNER_ALREADY_SIGNED) {
    return <Redirect to={constVars.ROUTE_SIGNER_ERROR} />;
  }

  if (additionalSignerAccessToken) {
    if (productType === ProductType.CARD) {
      return <Redirect to={constVars.ROUTE_CARD_ADDITIONAL_SIGNER} />;
    } else if (productType === ProductType.LOAN) {
      return <Redirect to={constVars.ROUTE_LOAN_ADDITIONAL_SIGNER} />;
    }
  }

  return languageIsSet ? (
    <Box>
      <Group
        mt={mobile ? 0 : 80}
        w={mobile ? 397 : 500}
        h={400}
        p={mobile ? 'md' : 'xxl'}
        spacing={0}
        direction="column"
        style={{
          borderRadius: '4px 4px 0px 0px',
          backgroundColor: `${theme.colors.neutral2}`,
        }}
      >
        <Typography size={mobile ? 'xl' : 'xxl'} weight={500} lineHeight={36}>
          {translate('AdditionalSigner.EnterOtp') as string}
        </Typography>
        <Typography size="md" weight={400} letterSpacing={0.25}>
          {translate('AdditionalSigner.OtpTitle') as string}
        </Typography>
        <AdditionalSignerOTPInputSection />
        {otpExpiryTimestamp && !otpIsExpired && (
          <AdditionalSignerOTPTimer otpExpiryTimestamp={otpExpiryTimestamp} />
        )}
      </Group>
      <Group
        w={mobile ? 397 : 500}
        h={mobile ? 76 : 80}
        p={mobile ? 'md' : 'lg'}
        justify="flex-end"
        style={{
          borderRadius: '0px 0px 4px 4px',
          backgroundColor: `${theme.colors.neutral10}`,
          border: `1px solid ${theme.colors.neutral16}`,
        }}
      >
        <Button
          variant="primary"
          onClick={onClickVerifyOTP}
          size="md"
          fullWidth={mobile}
          disabled={!isValidOtpLength || otpIsExpired || !otpExpiryTimestamp}
          isLoading={apiStatus.verifyOTP === ApiStatus.Started}
        >
          {translate('AdditionalSigner.VerifyButton') as string}
        </Button>
      </Group>
      <Modal
        id="otp-something-went-wrong-modal"
        opened={opened}
        onClose={close}
        size="md"
        title={translate('AdditionalSigner.ErrorTitle') as string}
      >
        <AdditionalSignerOTPErrorModal onClose={close} />
      </Modal>
    </Box>
  ) : (
    <SpinnerCircular />
  );
};

export default AdditionalSignerOTP;
