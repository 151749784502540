import React, { forwardRef } from 'react';
import {
  DayModifiers,
  FirstDayOfWeek,
  getMonthDays,
  getWeekdaysNames,
} from '~/helpers/utils';
import { capitalizeFirstLetter } from '~/helpers/formatters.helper';
import styled from 'styled-components';
import Day from '~/components/shared/DatePicker/Day';
import { theme } from '~/styles/themes';
import { getDayProps } from '~/helpers/date.helper';

const StyledMonth = styled.table`
  margin: auto;
`;

export interface DayKeydownPayload {
  rowIndex: number;
  cellIndex: number;
  date: Date;
}

export interface MonthSettings {
  dayClassName?(date: Date, modifiers: DayModifiers): string;

  dayStyle?(date: Date, modifiers: DayModifiers): React.CSSProperties;

  disableOutsideEvents?: boolean;
  minDate?: Date;
  maxDate?: Date;

  excludeDate?(date: Date): boolean;

  hideWeekdays?: boolean;
  fullWidth?: boolean;
  preventFocus?: boolean;
  focusable?: boolean;
  firstDayOfWeek?: FirstDayOfWeek;
  hideOutsideDates?: boolean;
  weekendDays?: number[];

  isDateInRange?(date: Date, modifiers: DayModifiers): boolean;

  isDateFirstInRange?(date: Date, modifiers: DayModifiers): boolean;

  isDateLastInRange?(date: Date, modifiers: DayModifiers): boolean;
}

export interface MonthProps
  extends MonthSettings,
    Omit<React.ComponentPropsWithoutRef<'table'>, 'onChange' | 'value'> {
  month: Date;
  value?: Date;

  onChange?(value: Date): void;

  onDayMouseEnter?(date: Date, event: React.MouseEvent): void;

  daysRefs?: HTMLButtonElement[][];

  onDayKeyDown?(
    payload: DayKeydownPayload,
    event: React.KeyboardEvent<HTMLButtonElement>
  ): void;

  renderDay?(date: Date): React.ReactNode;

  locale?: string;
  weekdayLabelFormat?: string;
}

const WeekDayCell = styled.th`
  box-sizing: border-box;
  font-weight: normal;
  padding: 0 0 0.6em;
  text-align: center;
  cursor: default;
  user-select: none;
`;

const WeekDay = styled.span`
  font-weight: 600;
  font-size: 0.8em;
  color: ${theme.colors.dark};
`;

const Month = forwardRef<HTMLTableElement, MonthProps>(
  (
    {
      value,
      month,
      firstDayOfWeek,
      locale,
      weekdayLabelFormat,
      maxDate,
      minDate,
      excludeDate,
      disableOutsideEvents,
      weekendDays,
      preventFocus,
      onChange,
      onDayMouseEnter,
      onDayKeyDown,
      ...rest
    },
    ref
  ) => {
    const days = getMonthDays(month, firstDayOfWeek);
    const hasValue = value instanceof Date;

    const finalLocale = locale || 'en';

    const weekdays = getWeekdaysNames(
      finalLocale,
      firstDayOfWeek,
      weekdayLabelFormat
    ).map((weekday) => (
      <WeekDayCell key={weekday}>
        <WeekDay>
          {weekday.length >= 2 ? capitalizeFirstLetter(weekday) : weekday}
        </WeekDay>
      </WeekDayCell>
    ));

    const rows = days.map((row, rowIndex) => {
      const cells = row.map((date, cellIndex) => {
        const dayProps = getDayProps({
          date,
          month,
          hasValue,
          minDate,
          maxDate,
          value,
          excludeDate,
          disableOutsideEvents,
          weekendDays,
        });

        const onKeyDownPayload = { rowIndex, cellIndex, date };

        return (
          <td key={cellIndex}>
            <Day
              onClick={() => typeof onChange === 'function' && onChange(date)}
              value={date}
              outside={dayProps.outside}
              weekend={dayProps.weekend}
              selected={dayProps.selected}
              onMouseDown={(event) => preventFocus && event.preventDefault()}
              onKeyDown={(event) =>
                typeof onDayKeyDown === 'function' &&
                onDayKeyDown(onKeyDownPayload, event)
              }
              disabled={dayProps.disabled}
              onMouseEnter={
                typeof onDayMouseEnter === 'function'
                  ? onDayMouseEnter
                  : () => false
              }
            />
          </td>
        );
      });

      return <tr key={rowIndex}>{cells}</tr>;
    });

    return (
      <StyledMonth {...rest} ref={ref}>
        <thead>
          <tr>{weekdays}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </StyledMonth>
    );
  }
);

Month.defaultProps = {
  disableOutsideEvents: false,
  hideWeekdays: false,
  fullWidth: false,
  preventFocus: false,
  focusable: true,
  firstDayOfWeek: 'monday',
  hideOutsideDates: false,
  weekendDays: [0, 6],
};

export default Month;
