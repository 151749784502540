import styled from 'styled-components';

export const SlideshowContainer = styled.div`
  position: relative;
  flex: 2;
`;

export const SlideshowImageContainer = styled.div<{
  active: boolean;
  src: string;
}>`
  position: absolute;
  opacity: ${(props) => (props.active ? 1 : 0)};
  height: 100%;
  width: 100%;

  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
`;
