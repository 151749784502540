import { createSlice } from '@reduxjs/toolkit';
import { MediaQueries } from '~/constants/constVars';
import { MatchedMediaBreakPoints } from '~/Interfaces';
import { matchedMediaInitialState } from '../initialState';

const matchedMediaSlice = createSlice({
  name: 'matchedMedia',
  initialState: matchedMediaInitialState,
  reducers: {
    updateMatchedMedia: (state) => {
      Object.keys(MediaQueries).forEach((query) => {
        state[query as keyof MatchedMediaBreakPoints] = window.matchMedia(
          MediaQueries[query as keyof MatchedMediaBreakPoints]
        ).matches;
      });
    },
  },
});

export const { updateMatchedMedia } = matchedMediaSlice.actions;

export default matchedMediaSlice.reducer;
