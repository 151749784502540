import { history } from '~/store';
import { AppState, Auth0ProviderOptions } from '@auth0/auth0-react';
import posthog from 'posthog-js';

const onRedirectCallback = (appState: AppState) => {
  // Use the router's history module to replace the urls
  const { pathname, search } = history.location;

  const { hash, market, returnTo } = appState || {};

  posthog.capture('myqred_login', {
    state: 'authenticated_on_redirect',
  });

  if (hash && market) {
    history.replace({ pathname, search, state: { hash, market } });
    return;
  }

  const newUrl = new URL(window.location.href);

  newUrl.searchParams.delete('code');
  newUrl.searchParams.delete('state');

  if (returnTo) {
    const returnToPathname = returnTo.split('?')[0];
    const returnToSearch = returnTo.split('?')[1];
    if (returnToPathname) {
      newUrl.pathname = returnToPathname;
    }
    if (returnToSearch) {
      newUrl.search = returnToSearch;
    }
  }

  history.replace({
    pathname: newUrl.pathname,
    search: newUrl.search,
  });
};

const auth0Config: Auth0ProviderOptions = {
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirectUri: window.location.origin,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  },
  cacheLocation: window.Cypress && 'localstorage',
  onRedirectCallback,
};

export default auth0Config;
