import { useState, useEffect, useContext } from 'react';
import { ValidationContext } from '~/components/hoc/withValidation';

interface DebounceOptions {
  manuallyInvalidateFormDuringDebounce: boolean;
}

const useDebounce = <T>(value: T, debounceOptions: DebounceOptions): T => {
  const delay = 500;
  const [debouncedValue, setDebouncedValue] = useState(value);

  const validationContext = useContext(ValidationContext);

  useEffect(() => {
    if (debounceOptions.manuallyInvalidateFormDuringDebounce) {
      validationContext.manuallyInvalidateForm(true);
    }

    const handler = setTimeout(() => {
      setDebouncedValue(value);
      if (debounceOptions.manuallyInvalidateFormDuringDebounce) {
        validationContext.manuallyInvalidateForm(false);
      }
    }, delay);

    return () => {
      if (debounceOptions.manuallyInvalidateFormDuringDebounce) {
        validationContext.manuallyInvalidateForm(false);
      }
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
