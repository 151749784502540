import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Spinner from '~/components/shared/Spinner/SpinnerCircular';
import constVars from '~/constants/constVars';
import { Redirect } from 'react-router';
import { QitUiProvider } from '@qred/qit-ui';
import {
  ComponentsLibraryProvider,
  Loader,
  ModalsProvider,
  Notifications,
} from '@qred/components-library';
import CardOffer from './pages/CardOffer/CardOffer';
import CustomerLink from './pages/CustomerLink/CustomerLink';
import ThankYou from './pages/ThankYou/ThankYou';
import Eskat from './pages/Eskat/Eskat';
import PublicCardOffer from './pages/CardOffer/PublicCardOffer';
import SetCreditCardPin from './pages/SetCreditCardPin/SetCreditCardPin';
import CreateCardProgress from './pages/CreateCardProgress/CreateCardProgress';
import PublicShippingDetails from './pages/ShippingDetails/PublicShippingDetails';
import ProtectedRoute from './ProtectedRoute';
import useQueryParams from './hooks/useQueryParams';
import Onboarding from './pages/Onboarding/Onboarding';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import ContractSigned from './pages/ContractSigned/ContractSigned';
import MainLayout from './components/shared/Layout/MainLayout';
import BankIntegrationPublic from './pages/BankIntegrationPublic/BankIntegrationPublic';
import CardOnboardingOffer from './pages/CardOnboardingOffer/CardOnboardingOffer';
import ThankyouCardOnboardingOffer from './pages/CardOnboardingOffer/components/CardOnboardingOfferLandingPages/ThankyouCardOnboardingOffer';
import ThankyouCardWaitingAdditionalSigner from './pages/CardOnboardingOffer/components/CardOnboardingOfferLandingPages/ThankyouCardWaitingAdditionalSigner';
import LoanAdditionalSigner from './pages/AdditionalSigner/LoanAdditionalSigner/LoanAdditionalSigner';
import AppShell from './components/shared/Navigation/AppShell';
import AdditionalSignerOTP from './pages/AdditionalSigner/AdditionalSignerOTP/AdditionalSignerOTP';
import AuthenticatedRoute from '~/components/core/AuthenticatedRoute';
import SecondaryLayout from './components/shared/Layout/SecondaryLayout';
import Maintenance from './pages/Maintenance/Maintenance';
import CardAdditionalSigner from './pages/AdditionalSigner/CardAdditionalSigner/CardAdditionalSigner';
import { useMaintenanceFeatureFlagEnabled } from './hooks/useMaintenanceFeatureFlagEnabled';
import AdditionalSignerError from './pages/AdditionalSigner/AdditionalSignerError';
import LoanAdditionalSignerSuccess from './pages/AdditionalSigner/LoanAdditionalSigner/LoanAdditionalSignerSuccess';
import posthog from 'posthog-js';

const Contact = React.lazy(() => import('./pages/Contact/Contact'));
const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const Profile = React.lazy(
  () => import('./pages/Dashboard/components/Sections/ProfileSection/Profile')
);
const OnboardingOffer = React.lazy(
  () => import('./pages/OnboardingOffer/OnboardingOffer')
);
const PageNotFound = React.lazy(
  () => import('./pages/PageNotFound/PageNotFound')
);
const ApplyLoanIncrease = React.lazy(
  () => import('./pages/ApplyLoanIncrease/ApplyLoanIncrease')
);
const Login = React.lazy(() => import('./pages/Login/Login'));
const Document = React.lazy(() => import('./pages/Documents/Documents'));
const Invoices = React.lazy(() => import('./pages/Invoices/Invoices'));
const HelpSupport = React.lazy(() => import('./pages/HelpSupport/HelpSupport'));
const HelpSupportDetail = React.lazy(
  () => import('./pages/HelpSupport/pages/HelpSupportDetail')
);

const Routes: React.FC = () => {
  const queryParams = useQueryParams();
  const isFlagEnabled = useMaintenanceFeatureFlagEnabled();
  if (
    isFlagEnabled &&
    window.location.pathname !== `/${constVars.ROUTE_MAINTENANCE}`
  ) {
    return <Redirect to={`/${constVars.ROUTE_MAINTENANCE}`} />;
  }

  return (
    <Switch>
      <Route exact path={`/${constVars.ROUTE_MAINTENANCE}`}>
        <ComponentsLibraryProvider>
          <SecondaryLayout>
            <Maintenance />
          </SecondaryLayout>
        </ComponentsLibraryProvider>
      </Route>
      <Route exact path={`/${constVars.ROUTE_TERMS_AND_CONDITIONS}`}>
        <TermsAndConditions />
      </Route>
      <ProtectedRoute
        exact
        path={[`/${constVars.ROUTE_CONTACT}`]}
        render={() => (
          <MainLayout legacyStyles>
            <Suspense fallback={<Spinner />}>
              <Contact />
            </Suspense>
          </MainLayout>
        )}
      />
      <ProtectedRoute exact path={`/${constVars.ROUTE_CARD_OFFER}/:companyId`}>
        <MainLayout legacyStyles>
          <CardOffer />
        </MainLayout>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/${constVars.ROUTE_ONBOARDING_OFFER}/:applicationUuid`}
      >
        <QitUiProvider>
          <ComponentsLibraryProvider>
            <ModalsProvider>
              <Notifications />
              <Suspense fallback={<Spinner />}>
                <OnboardingOffer />
              </Suspense>
            </ModalsProvider>
          </ComponentsLibraryProvider>
        </QitUiProvider>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/${constVars.ROUTE_CARD_ONBOARDING_OFFER}/:clientId`}
      >
        <QitUiProvider>
          <ComponentsLibraryProvider>
            <Suspense fallback={<Spinner />}>
              <CardOnboardingOffer />
            </Suspense>
          </ComponentsLibraryProvider>
        </QitUiProvider>
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/${constVars.ROUTE_SET_PIN}/:applicationUuid`}
      >
        <MainLayout legacyStyles>
          <SetCreditCardPin />
        </MainLayout>
      </ProtectedRoute>
      <Route exact path={`/${constVars.ROUTE_PUBLIC_CARD_OFFER}`}>
        <PublicCardOffer />
      </Route>
      <Route path={`/${constVars.ROUTE_THANK_YOU}`}>
        <ThankYou />
      </Route>
      <Route path={`/${constVars.ROUTE_THANK_YOU_CONTINUE_IN_APP}`}>
        <ComponentsLibraryProvider>
          <ThankyouCardOnboardingOffer />
        </ComponentsLibraryProvider>
      </Route>
      <Route path={`/${constVars.ROUTE_THANK_YOU_WAITING_ADDITIONAL_SIGNER}`}>
        <ThankyouCardWaitingAdditionalSigner />
      </Route>
      <Route path={`/${constVars.ROUTE_CONTRACT_SIGNED}`}>
        <ContractSigned />
      </Route>
      <Route path={`/${constVars.ROUTE_CARD_CREDIT_LIMIT_INCREASED}`}>
        <ContractSigned />
      </Route>

      {/* ADDITIONAL SIGNER PAGES */}
      <Redirect
        exact
        from={`/${constVars.ROUTE_ADDITIONAL_SIGNER_AUTH_OLD}`}
        to={{
          pathname: `/${constVars.ROUTE_ADDITIONAL_SIGNER_AUTH}`,
          search: window.location.search,
        }}
      />
      <Route exact path={`/${constVars.ROUTE_ADDITIONAL_SIGNER_AUTH}`}>
        <ComponentsLibraryProvider>
          <Notifications />
          <SecondaryLayout>
            <AdditionalSignerOTP />
          </SecondaryLayout>
        </ComponentsLibraryProvider>
      </Route>

      <Redirect
        exact
        from={`/${constVars.ROUTE_LOAN_ADDITIONAL_SIGNER_OLD}`}
        to={{
          pathname: `/${constVars.ROUTE_LOAN_ADDITIONAL_SIGNER}`,
          search: window.location.search,
        }}
      />
      <Route exact path={`/${constVars.ROUTE_LOAN_ADDITIONAL_SIGNER}`}>
        <ComponentsLibraryProvider>
          <ModalsProvider>
            <Notifications />
            <SecondaryLayout>
              <LoanAdditionalSigner />
            </SecondaryLayout>
          </ModalsProvider>
        </ComponentsLibraryProvider>
      </Route>

      <Redirect
        exact
        from={`/${constVars.ROUTE_LOAN_ADDITIONAL_SIGNER_OLD}/:type(${constVars.PARAMS_SIGNER_SIGNED_SUCCESS})`}
        to={`/${constVars.ROUTE_LOAN_ADDITIONAL_SIGNER}/:type(${constVars.PARAMS_SIGNER_SIGNED_SUCCESS})`}
      />
      <Route
        exact
        path={`/${constVars.ROUTE_LOAN_ADDITIONAL_SIGNER}/:type(${constVars.PARAMS_SIGNER_SIGNED_SUCCESS})`}
      >
        <ComponentsLibraryProvider>
          <SecondaryLayout>
            <LoanAdditionalSignerSuccess />
          </SecondaryLayout>
        </ComponentsLibraryProvider>
      </Route>

      <Redirect
        exact
        from={`/${constVars.ROUTE_LOAN_ADDITIONAL_SIGNER_OLD}/:type(${constVars.PARAMS_SIGNER_ALREADY_SIGNED})`}
        to={`/${constVars.ROUTE_SIGNER_ERROR}`}
      />
      <Route exact path={`/${constVars.ROUTE_SIGNER_ERROR}`}>
        <ComponentsLibraryProvider>
          <SecondaryLayout>
            <AdditionalSignerError />
          </SecondaryLayout>
        </ComponentsLibraryProvider>
      </Route>

      <Redirect
        exact
        from={`/${constVars.ROUTE_CARD_ADDITIONAL_SIGNER_OLD}`}
        to={{
          pathname: `/${constVars.ROUTE_CARD_ADDITIONAL_SIGNER}`,
          search: window.location.search,
        }}
      />
      <Route exact path={`/${constVars.ROUTE_CARD_ADDITIONAL_SIGNER}`}>
        <ComponentsLibraryProvider>
          <SecondaryLayout>
            <CardAdditionalSigner />
          </SecondaryLayout>
        </ComponentsLibraryProvider>
      </Route>

      {/* ADDITIONAL SIGNER PAGES END */}

      <Route
        exact
        path={[
          `/${constVars.ROUTE_CUSTOMER_LINK}/:type(redirected)/:qcode`,
          `/${constVars.ROUTE_CUSTOMER_LINK}/:type(redirected)`,
          `/${constVars.ROUTE_CUSTOMER_LINK}`,
        ]}
      >
        <CustomerLink />
      </Route>
      <Route
        exact
        path={[
          `/${constVars.ROUTE_ESKAT}`,
          `/${constVars.ROUTE_ESKAT}/redirected`,
        ]}
      >
        <Eskat />
      </Route>
      <Route
        exact
        path={`/${constVars.ROUTE_PUBLIC_SHIPPING_DETAILS}/:applicationUuid`}
      >
        <PublicShippingDetails />
      </Route>
      <Route exact path={`/${constVars.ROUTE_CREATING_CARD}`}>
        <CreateCardProgress />
      </Route>
      <Route exact path={`/${constVars.ROUTE_ONBOARDING}`}>
        <QitUiProvider>
          <ComponentsLibraryProvider>
            <Onboarding />
          </ComponentsLibraryProvider>
        </QitUiProvider>
      </Route>
      <Route exact path={`/:type(${constVars.PARAMS_SIGNED})`}>
        <Redirect to="/" />
      </Route>
      <Route exact path={`/${constVars.ROUTE_KOSMA_PUBLIC}`}>
        <QitUiProvider>
          <ComponentsLibraryProvider>
            <BankIntegrationPublic />
          </ComponentsLibraryProvider>
        </QitUiProvider>
      </Route>
      <Route
        exact
        path="/404"
        render={() => (
          <Suspense fallback={<Spinner />}>
            <PageNotFound />
          </Suspense>
        )}
      />
      <ComponentsLibraryProvider>
        <ModalsProvider>
          <Switch>
            <Route exact path={`/${constVars.ROUTE_LOGIN}`}>
              {queryParams.get('code') && queryParams.get('state') ? (
                <Loader />
              ) : (
                <Suspense fallback={<Loader />}>
                  <Login />
                </Suspense>
              )}
            </Route>
            <AuthenticatedRoute>
              <AppShell>
                <Route exact path={`/`}>
                  <Suspense fallback={<Loader />}>
                    <Dashboard />
                  </Suspense>
                </Route>
                <Route exact path={`/${constVars.ROUTE_PROFILE}`}>
                  <Suspense fallback={<Loader />}>
                    <Profile />
                  </Suspense>
                </Route>
                <Route exact path={`/${constVars.ROUTE_DOCUMENTS}`}>
                  <Suspense fallback={<Loader />}>
                    <Document />
                  </Suspense>
                </Route>
                <Route exact path={`/${constVars.ROUTE_APPLYING_LOAN}`}>
                  <Suspense fallback={<Loader />}>
                    <ApplyLoanIncrease />
                  </Suspense>
                </Route>
                {posthog.isFeatureEnabled('InvoicesPage_release') && (
                  <Route exact path={`/${constVars.ROUTE_INVOICES}`}>
                    <Suspense fallback={<Loader />}>
                      <Invoices />
                    </Suspense>
                  </Route>
                )}
                <Route exact path={`/${constVars.ROUTE_HELP_AND_SUPPORT}`}>
                  <Suspense fallback={<Loader />}>
                    <HelpSupport />
                  </Suspense>
                </Route>
                <Route
                  exact
                  path={`/${constVars.ROUTE_HELP_AND_SUPPORT}/:category`}
                >
                  <Suspense fallback={<Loader />}>
                    <HelpSupportDetail />
                  </Suspense>
                </Route>
              </AppShell>
            </AuthenticatedRoute>
          </Switch>
        </ModalsProvider>
        <Notifications containerWidth="24em" />
      </ComponentsLibraryProvider>
      <Route
        exact
        path="*"
        render={() => (
          <Suspense fallback={<Spinner />}>
            <PageNotFound />
          </Suspense>
        )}
      />
    </Switch>
  );
};

export default Routes;
