import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties | undefined;
  topFixedDisabled?: boolean;
}

export const TopFixedContainer = (props: Props) => {
  const { children, style, topFixedDisabled = false } = props;

  const [isTop, setIsTop] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const childrenRef = useRef<HTMLDivElement | null>(null);

  const placeholderHeight = childrenRef.current
    ? childrenRef.current.clientHeight
    : 0;

  useEffect(() => {
    const checkPosition = () => {
      if (ref.current) {
        const scrolledToTop = window.scrollY > ref.current.offsetTop;
        setIsTop(scrolledToTop);
      }
    };

    window.addEventListener('scroll', checkPosition);

    return () => window.removeEventListener('scroll', checkPosition);
  }, []);

  if (topFixedDisabled) {
    return <div style={style}>{children}</div>;
  }

  return (
    <div ref={ref} style={style}>
      <div
        style={{ height: placeholderHeight, display: isTop ? 'flex' : 'none' }}
      ></div>
      <ChildrenContainer
        ref={childrenRef}
        style={
          isTop
            ? { position: 'fixed', top: 0, minWidth: style?.minWidth }
            : { position: 'relative' }
        }
      >
        {children}
      </ChildrenContainer>
    </div>
  );
};

const ChildrenContainer = styled('div')({
  display: 'flex',
  width: '100%',
  zIndex: 99,
  minWidth: '320px',
  backgroundColor: 'inherit',
});
