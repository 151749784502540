import { theme } from '~/styles/themes';
import styled from 'styled-components';

export const Link = styled.span`
  cursor: pointer;
  color: ${theme.colors.midwayLinkLight};
`;

export const ConfirmTermsAndConditionsPricelistTextStyle = styled.div`
  margin-bottom: 2em;
  text-align: center;
`;
