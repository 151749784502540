import React from 'react';
import {
  Button,
  Group,
  Icon,
  Input,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import useCountries from '~/hooks/useCountries';
import Address from '../Address/Address';
import { IOnboardingAMLData } from '~/interfaces/Onboarding';

interface AMLFieldsProps {
  trackFieldChange: (fieldName: string) => void;
  showAddressFields?: boolean;
  hideFields?: string[];
}

const AMLFields = ({
  trackFieldChange,
  showAddressFields,
  hideFields,
}: AMLFieldsProps) => {
  const {
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const { control } = useFormContext<IOnboardingAMLData>();

  const translate = useTranslate();

  const { countriesList } = useCountries();

  return (
    <Stack spacing="md">
      {!hideFields?.includes('countryOfCitizenship') && (
        <Controller
          name="countryOfCitizenship"
          control={control}
          rules={{
            required: translate('ValidationErrors.Required') as string,
          }}
          render={({ field, fieldState }) => (
            <Select
              label={translate('AML.CountryOfCitizenship')}
              placeholder={translate('AML.SelectCountryPlaceholder') as string}
              options={countriesList}
              onChange={(value) => {
                trackFieldChange(field.name);
                field.onChange(value?.value || '');
              }}
              value={countriesList.find((item) => item.value === field.value)}
              error={fieldState.error?.message}
            />
          )}
        />
      )}
      {!hideFields?.includes('countryOfResidence') && (
        <Controller
          name="countryOfResidence"
          control={control}
          rules={{ required: translate('ValidationErrors.Required') as string }}
          render={({ field, fieldState }) => (
            <Select
              label={translate('AML.CountryOfResidence')}
              placeholder={translate('AML.SelectCountryPlaceholder') as string}
              options={countriesList}
              onChange={(value) => {
                trackFieldChange(field.name);
                field.onChange(value?.value || '');
              }}
              value={countriesList.find((item) => item.value === field.value)}
              error={fieldState.error?.message}
            />
          )}
        />
      )}

      {!hideFields?.includes('countryOfTaxResidence') && (
        <Controller
          name="countryOfTaxResidence"
          control={control}
          rules={{ required: translate('ValidationErrors.Required') as string }}
          render={({ field, fieldState }) => (
            <Select
              label={
                <Group justify="space-between">
                  {translate('AML.CountryOfTaxResidence')}
                  <Tooltip
                    label={translate('AML.CountryOfTaxResidenceTooltip')}
                    events={{
                      focus: false,
                      touch: true,
                      hover: true,
                    }}
                    width={mobile ? '87.2%' : '350px'}
                    position="top-end"
                  >
                    <Icon src="QuestionMarkCircle" size="lg" />
                  </Tooltip>
                </Group>
              }
              placeholder={translate('AML.SelectCountryPlaceholder') as string}
              options={countriesList}
              onChange={(value) => {
                trackFieldChange(field.name);
                field.onChange(value?.value || '');
              }}
              value={countriesList.find((item) => item.value === field.value)}
              error={fieldState.error?.message}
            />
          )}
        />
      )}

      {showAddressFields && <Address trackFieldChange={trackFieldChange} />}

      {!hideFields?.includes('isPEP') && (
        <Input.Wrapper>
          <Stack spacing="md">
            <Group justify="space-between">
              <Typography> {translate('AML.IsPEPOrRCA')}</Typography>
              <Tooltip
                label={translate('AML.IsPEPOrRCATooltip')}
                events={{
                  focus: false,
                  touch: true,
                  hover: true,
                }}
                width={mobile ? '87.2%' : '350px'}
                position="top-end"
              >
                <Icon src="QuestionMarkCircle" size="lg" />
              </Tooltip>
            </Group>
            <Controller
              name="isPEP"
              control={control}
              rules={{ validate: (value) => value !== undefined }}
              render={({ field, fieldState }) => (
                <>
                  <Group direction="row" justify="space-between">
                    <Button
                      variant="secondary"
                      data-active={!!field.value}
                      style={{ flexGrow: 1 }}
                      onClick={() => {
                        trackFieldChange(field.name);
                        field.onChange(true);
                      }}
                      leftIcon={field.value ? 'check' : undefined}
                    >
                      {translate('Yes')}
                    </Button>
                    <Button
                      variant="secondary"
                      data-active={field.value === false}
                      style={{ flexGrow: 1 }}
                      onClick={() => {
                        trackFieldChange(field.name);
                        field.onChange(false);
                      }}
                      leftIcon={field.value === false ? 'check' : undefined}
                    >
                      {translate('No')}
                    </Button>
                  </Group>
                  {field.value && (
                    <Group nowrap spacing="sm">
                      <Icon
                        src="ExclamationTriangle"
                        size="xl"
                        color="warning.700"
                      />
                      <Typography color="warning.700" size="sm">
                        {translate('AML.IsPEPOrRCAWarning')}
                      </Typography>
                    </Group>
                  )}
                  {fieldState.error && (
                    <Typography color="error.700" size="xs">
                      {translate('ValidationErrors.Required')}
                    </Typography>
                  )}
                </>
              )}
            />
          </Stack>
        </Input.Wrapper>
      )}
    </Stack>
  );
};

export default AMLFields;
