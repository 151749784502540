import { Contracts } from '~/models/contracts/contracts';
import { myPagesBaseApi } from '../../_baseApi/myPages.base.api';
import {
  ContractsGetRequestDto,
  ContractsGetResponseDto,
} from '~/store/apis/dtos/contracts/contracts.dto';
import { mapContracts } from './contracts.mapper';

export const contractsApi = myPagesBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getContracts: build.query<Contracts, ContractsGetRequestDto>({
      query: ({ clientId }) => `/contracts/client/${clientId}`,
      providesTags: ['Contracts'],
      transformResponse: (response: ContractsGetResponseDto) =>
        mapContracts(response),
    }),
  }),
});

export const { useGetContractsQuery } = contractsApi;
