import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import { Typography } from '~/components/shared/Typography/Typography';
import { getSigningMethod } from '~/constants/markets';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const ItemLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ItemValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding-left: 0.5em;
`;

interface Props {
  companyName?: string;
}

const CreditCardIntroductionCard: React.FC<Props> = (props) => {
  const translate = useTranslate();
  const { market, language } = useSelector((state: RootState) => state.intl);

  return (
    <Card dataTestId="credit-card-introduction-card">
      <CardHeader
        value={
          translate('CreditCardIntroductionCard.Title', {
            companyName: props.companyName,
          }) as string
        }
        noPadding
      />
      <ListContainer>
        <ItemLabelContainer>
          <Typography lineHeight={1.7} fontSize={1}>
            1.
          </Typography>
          <Typography lineHeight={1.7} fontSize={1}>
            2.
          </Typography>
          <Typography lineHeight={1.7} fontSize={1}>
            3.
          </Typography>
        </ItemLabelContainer>

        <ItemValueContainer>
          <Typography lineHeight={1.7} fontSize={1}>
            {translate('CreditCardIntroductionCard.ApproveOffer')}
          </Typography>

          <Typography lineHeight={1.7} fontSize={1}>
            {translate('CreditCardIntroductionCard.SignUsingBankId')}
          </Typography>

          <Typography lineHeight={1.7} fontSize={1}>
            {translate('CreditCardIntroductionCard.CustomizeYourCard', {
              signingMethod: getSigningMethod(market, language),
            })}
          </Typography>
        </ItemValueContainer>
      </ListContainer>
    </Card>
  );
};

export default CreditCardIntroductionCard;
