import React from 'react';
import Image from '~/components/shared/Image/Image';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { openAppStore, openGooglePlay } from '~/services/links';
import { LanguageValues } from '~/constants/markets';
import {
  MainOnboardingContainer,
  OnboardingStepsContainer,
} from '~/styles/OnboardingBaseStyledComponents';
import Card from '~/components/shared/Card/Card';
import { FlexContentPlacement } from '~/enums';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { StepContent } from '~/components/shared/Stepper/Stepper.styled';

import { BaseArrangement } from '@qred/qit-ui/lib/theme/BaseStyledComponents';
import { Typography } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import {
  BadgeContainer,
  Container,
  HorizontalLine,
} from './StoreButtonAndQrCodeView.styled';

const AppStoreBadges: LanguageValues = {
  SV: 'app-store-badge-swedish.svg',
  EN: 'app-store-badge-english.svg',
  FI: 'app-store-badge-finnish.svg',
  DA: 'app-store-badge-danish.svg',
  NO: 'app-store-badge-norwegian.svg',
  FR: 'app-store-badge-french.svg',
  NL_BE: 'app-store-badge-dutch.svg',
  NL: 'app-store-badge-dutch.svg',
  // TODO: Add German app store badge
  DE: 'app-store-badge-german.svg',
};

const GooglePlayStoreBadges: LanguageValues = {
  SV: 'google-play-badge-swedish.png',
  EN: 'google-play-badge-english.png',
  FI: 'google-play-badge-finnish.png',
  DA: 'google-play-badge-danish.png',
  NO: 'google-play-badge-norwegian.png',
  FR: 'google-play-badge-french.png',
  NL_BE: 'google-play-badge-dutch.png',
  NL: 'google-play-badge-dutch.png',
  // TODO: Add German google play badge
  DE: 'google-play-badge-german.png',
};

const GooglePlayQRCode: LanguageValues = {
  SV: 'all_google_play.png',
  EN: 'all_google_play.png',
  FI: 'all_google_play.png',
  DA: 'all_google_play.png',
  NO: 'all_google_play.png',
  FR: 'all_google_play.png',
  NL_BE: 'all_google_play.png',
  NL: 'all_google_play.png',
  DE: 'all_google_play.png',
};
const AppleStoreQRCode: LanguageValues = {
  SV: 'app_store_qr_code_swedish.png',
  EN: 'app_store_qr_code_english.png',
  FI: 'app_store_qr_code_finish.png',
  DA: 'app_store_qr_code_english.png',
  NO: 'app_store_qr_code_english.png',
  FR: 'app_store_qr_code_english.png',
  NL_BE: 'app_store_qr_code_english.png',
  NL: 'app_store_qr_code_english.png',
  DE: 'app_store_qr_code_english.png',
};
interface IStoreButtonAndQrCodeView {
  title: string;
  description1: string;
  description2?: string;
}
const StoreButtonAndQrCodeView: React.FC<IStoreButtonAndQrCodeView> = ({
  title,
  description1,
  description2,
}) => {
  const {
    intl: { language },
    matchedMedia,
  } = useSelector((state: RootState) => state);
  const t = useTranslate();
  const QredAppStoreBadge = () => (
    <Container onClick={() => openAppStore(language)}>
      <Image src={AppStoreBadges[language]} />
    </Container>
  );
  const QredAppGooglePlayStoreBadge = () => (
    <Container onClick={() => openGooglePlay()}>
      <Image src={GooglePlayStoreBadges[language]} />
    </Container>
  );
  const QRCodeGooglePlay = () => (
    <Container>
      <Image src={GooglePlayQRCode[language]} />
    </Container>
  );
  const QRCodeAppleStore = () => (
    <Container>
      <Image src={AppleStoreQRCode[language]} />
    </Container>
  );

  return (
    <MainOnboardingContainer style={{ paddingTop: '3.5em' }}>
      <OnboardingStepsContainer>
        <Card
          justifyContent={FlexContentPlacement.Center}
          grow={matchedMedia.smallScreenPortrait}
        >
          <StepContent footerHeight={0}>
            <StepHeader title={title} description={description1} />
            {description2 && (
              <BaseArrangement mb={1}>
                <Typography>{description2}</Typography>
              </BaseArrangement>
            )}
            <BadgeContainer>
              <QRCodeAppleStore />
              <QRCodeGooglePlay />
            </BadgeContainer>

            <BadgeContainer>
              <Container>
                <HorizontalLine>
                  <span>{t('OnboardingCardOffer.Or')}</span>
                </HorizontalLine>
              </Container>

              <Container>
                <HorizontalLine>
                  <span>{t('OnboardingCardOffer.Or')}</span>
                </HorizontalLine>
              </Container>
            </BadgeContainer>

            <BadgeContainer>
              <QredAppStoreBadge />
              <QredAppGooglePlayStoreBadge />
            </BadgeContainer>
          </StepContent>
        </Card>
      </OnboardingStepsContainer>
    </MainOnboardingContainer>
  );
};

export default StoreButtonAndQrCodeView;
