import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import {
  fetchClientApplication,
  fetchClientCompanies,
  patchApplication,
} from '~/store/actions/onboardingActions';
import withValidation from '~/components/hoc/withValidation';
import constVars from '~/constants/constVars';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { Redirect, useHistory } from 'react-router';
import {
  ApplicationStatus,
  ClientType,
  CountryCode,
  FlowTypes,
  OnboardingAuthType,
  StepStatus,
} from '~/enums';
import { logToSentry } from '~/helpers/loggers.helper';
import { getMarketBasedOnHostname } from '~/helpers/utils';
import useQueryParams from '~/hooks/useQueryParams';
import {
  defaultLanguageToMarket,
  setLanguage,
  setMarket,
} from '~/store/slices/intl.slice';
import { MainOnboardingContainer } from '~/styles/OnboardingBaseStyledComponents';
import {
  formatAmountWithoutCurrency,
  formatPersonalNumber,
} from '~/helpers/formatters.helper';
import { theme } from '~/styles/themes';
import {
  removeClearedState,
  setClientId,
  setUserId,
  updateApplicantData,
  updateApplicationStatus,
  updateCurrentStep,
  updateFlowType,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import { isTokenSet } from '~/store/slices/globalError.slice';
import { onboardingLoginMethods } from '~/helpers/authentication.helper';
import {
  getLanguageBasedOnReferrerUrl,
  getLanguageFromLocalStorage,
  parseLanguage,
} from '~/helpers/market.helper';
import Steps from './components/Steps/Steps';
import ConfirmationPage from './components/ConfirmationPage/ConfirmationPage';
import LaunchView from './components/LaunchView/LaunchView';
import { TOKEN, accessToken } from '~/store/apis/utils/accessToken';
import { usePostHog } from 'posthog-js/react';

const onboardingMarkets = [
  CountryCode.SE,
  CountryCode.FI,
  CountryCode.NL,
  CountryCode.NO,
  CountryCode.BE,
  CountryCode.DK,
];

// NOTE: Use this to test a new market in onboarding in non-prod environments
if (import.meta.env.MODE !== 'production') {
  onboardingMarkets.push(CountryCode.DE);
}

const Onboarding = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const posthog = usePostHog();

  const [ready, setReady] = useState(false);
  const [pageIsRefreshing, setPageIsRefreshing] = useState(false);

  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const queryParams = useQueryParams();
  const { user } = useAuth0();
  const marketFromDomain = getMarketBasedOnHostname();

  const { onboardingApplication, intl } = useSelector(
    (state: RootState) => state
  );
  const {
    flowType,
    applicationStatus,
    currentStep,
    apiStatus,
    form,
  } = onboardingApplication;
  const { languageIsSet, market } = intl;

  const authTypeParam = queryParams.get('authType');
  const applicationUuidParam = queryParams.get('applicationUuid');
  const flowTypeParam = queryParams.get('flow_type');
  const loanAmountParam = queryParams.get('loan_amount');
  const authMethodIdParam = queryParams.get('auth_method');
  const eskatParam = queryParams.get('eskat');

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    if (loanAmountParam) {
      dispatch(
        updateForm({
          amount: formatAmountWithoutCurrency(loanAmountParam),
        })
      );
      currentUrl.searchParams.delete('loan_amount');
      window.history.replaceState({}, document.title, currentUrl);
    }

    if (flowTypeParam && loanAmountParam) {
      currentUrl.searchParams.delete('flow_type');
      if (flowTypeParam === FlowTypes.Manual) {
        dispatch(updateFlowType(FlowTypes.Manual));
        dispatch(updateCurrentStep(1));
        window.history.replaceState({}, document.title, currentUrl);
      } else if (flowTypeParam === FlowTypes.Authenticated) {
        let authMethodConnectionName;

        if (authMethodIdParam) {
          currentUrl.searchParams.delete('auth_method');
          authMethodConnectionName = onboardingLoginMethods?.[market]?.find(
            (method) => method.id === authMethodIdParam
          )?.connectionName;
        }

        if (!authMethodConnectionName) {
          authMethodConnectionName =
            onboardingLoginMethods?.[market]?.[0]?.connectionName;
        }

        if (authMethodConnectionName) {
          currentUrl.searchParams.set(
            'authType',
            OnboardingAuthType.AuthenticatedFlow
          );
          currentUrl.searchParams.set('loan_amount', loanAmountParam);

          loginWithRedirect({
            authorizationParams: {
              connection: authMethodConnectionName,
              redirectUri: currentUrl.toString(),
            },
          });
          setPageIsRefreshing(true);
        } else {
          window.history.replaceState({}, document.title, currentUrl);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (marketFromDomain) {
      dispatch(setMarket(marketFromDomain));
      const languageFromReferrerUrl = getLanguageBasedOnReferrerUrl();
      const languageFromLocalStorage = getLanguageFromLocalStorage();
      const language = languageFromLocalStorage ?? languageFromReferrerUrl;
      if (language) {
        dispatch(setLanguage(language));
      } else {
        dispatch(defaultLanguageToMarket(marketFromDomain));
      }
    } else {
      window.location.replace(
        window.location.href.replace('.com', `.${CountryCode.SE}`)
      );
      setPageIsRefreshing(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(
        updateApplicantData({
          fullName: `${user.given_name} ${user.family_name}`,
          phone: user.phone_number,
          email: user.email,
        })
      );
      const userLanguage = parseLanguage(user.locale);
      if (userLanguage && !languageIsSet) {
        dispatch(setLanguage(userLanguage));
      }
    }
  }, [user]);

  const handleTopDomain = (clientMarket: CountryCode) => {
    const currentUrl = window.location.href;
    if (
      clientMarket &&
      clientMarket.toUpperCase() !== marketFromDomain &&
      window.location.hostname !== 'localhost'
    ) {
      const currentTopLevelDomain = `.${marketFromDomain?.toLowerCase()}`;
      const newTopLevelDomain = `.${clientMarket?.toLowerCase()}`;

      window.location.replace(
        currentUrl.replace(currentTopLevelDomain, newTopLevelDomain)
      );
      setPageIsRefreshing(true);
    }
  };

  const handleAuthenticationStatus = async () => {
    dispatch(
      updateForm({
        applicantIdentified: isAuthenticated,
        applicantAuthenticated: isAuthenticated,
      })
    );

    if (isAuthenticated) {
      const decodedToken = await accessToken.getDecoded();

      const clientId = decodedToken[constVars.AUTH_CLIENT_ID];
      if (clientId) {
        dispatch(setUserId(clientId));
        if (!onboardingApplication.clientId) {
          dispatch(setClientId(clientId));
        }
      } else {
        dispatch(updateForm({ clientType: ClientType.NLC }));
      }

      const userCode = decodedToken[TOKEN.USER_CODE];
      userCode && dispatch(updateForm({ userCode }));

      const clientMarket = decodedToken[constVars.AUTH_MARKET];
      handleTopDomain(clientMarket);

      dispatch(
        updateApplicantData({
          personalNumber: formatPersonalNumber(
            decodedToken[constVars.AUTH_USER_PERSONAL_NUMBER],
            clientMarket
          ),
          dateOfBirth: decodedToken[constVars.AUTH_USER_DATE_OF_BIRTH],
        })
      );
      dispatch(isTokenSet(true));
    }
  };

  const removeAuthTypeParam = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('authType');
    history.replace({ search: currentUrl.search });
  };

  const reauthenticate = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('authType', OnboardingAuthType.Reauthenticate);
    loginWithRedirect({
      authorizationParams: {
        redirectUri: url.toString(),
        prompt: 'none',
      },
    });
  };

  const handleManualSubmit = async () => {
    dispatch(updateFlowType(FlowTypes.Manual));
    dispatch(updateApplicationStatus(ApplicationStatus.ApplicationFinished));
    // we need to wait for fetchClientCompanies action because it will set clientType depending on response
    await dispatch(fetchClientCompanies());
    dispatch(patchApplication({ finalized: true }));
  };

  const handleIsAuthenticatedFlow = async () => {
    dispatch(updateFlowType(FlowTypes.Authenticated));
    const userJustAuthenticated =
      !applicationUuidParam &&
      authTypeParam === OnboardingAuthType.AuthenticatedFlow;
    if (userJustAuthenticated) {
      dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Finished }));
      dispatch(updateCurrentStep(1));
    }
  };

  const handleAuthTypeParam = () => {
    const isManualSubmit =
      authTypeParam === OnboardingAuthType.ManualSubmit && isAuthenticated;
    const isAuthenticatedFlow =
      (authTypeParam === OnboardingAuthType.AuthenticatedFlow ||
        authTypeParam === OnboardingAuthType.Reauthenticate) &&
      isAuthenticated;

    if (isManualSubmit) {
      handleManualSubmit();
    }
    if (isAuthenticatedFlow) {
      handleIsAuthenticatedFlow();
    }
    removeAuthTypeParam();
  };

  useEffect(() => {
    const handleAuthenticatedState = async () => {
      // If we don't know if user is logged in or not.

      const isAuthenticatedValueInLocalStorage =
        localStorage.getItem('isAuthenticated') === '1';

      if (
        isAuthenticatedValueInLocalStorage &&
        !isAuthenticated &&
        !authTypeParam &&
        !flowTypeParam
      ) {
        reauthenticate();
        return;
      }

      await handleAuthenticationStatus();

      // NOTE: this needs to be done before final patch
      // if its done after final patch, we will not have uuid when doing the final patch.
      // Any problem with doing this before handling url auth type?
      if (applicationUuidParam) {
        await dispatch(fetchClientApplication(applicationUuidParam));
      }

      if (authTypeParam) {
        handleAuthTypeParam();
      } else if (isAuthenticated) {
        // If users is authenticated from MyQred and visits onboarding.
        dispatch(updateFlowType(FlowTypes.Authenticated));
      }

      if (eskatParam && applicationUuidParam) {
        sessionStorage.removeItem('eskat-flow-onboarding');
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete('eskat');
        history.replace({ search: currentUrl.search });
        if (eskatParam === 'connected') {
          dispatch(
            pushToGtmOnboardingAction({
              actionName: 'eskat_connected',
            })
          );

          dispatch(updateForm({ eskatConnected: true }));
          await dispatch(patchApplication({ currentStepCompleted: false }));
        } else {
          dispatch(
            pushToGtmOnboardingAction({
              actionName: 'eskat_connect_cancelled',
            })
          );
        }
      }

      setReady(true);
    };
    if (!isLoading) {
      handleAuthenticatedState();
    }
  }, [isAuthenticated, isLoading]);

  /**
   * Add applicationUuid to url when its set in redux.
   */
  useEffect(() => {
    const { applicationUuid } = onboardingApplication.form;
    if (applicationUuid) {
      const url = new URL(window.location.href);
      url.searchParams.set('applicationUuid', applicationUuid);
      history.replace({ search: url.search });
    }
  }, [onboardingApplication.form.applicationUuid]);

  /**
   * Remove 'applicationUuid' from URL if action 'clearOnboardingState' has been called.
   * TODO: find out if this logic should be somewhere else to reduce file size of Onboarding.tsx?
   */
  useEffect(() => {
    if (onboardingApplication.cleared) {
      const url = new URL(window.location.href);
      url.searchParams.delete('applicationUuid');
      history.replace({ search: url.search });
      dispatch(removeClearedState());
      document.cookie = `publicToken=; Max-Age=0`;
      localStorage.removeItem('isAuthenticated');
      if (isAuthenticated) {
        dispatch(updateFlowType(FlowTypes.Authenticated));
      }
      handleAuthenticationStatus();
    }
  }, [onboardingApplication.cleared]);

  const viewHandler = useMemo(() => {
    const shouldShowSteps =
      currentStep === 1 || (currentStep > 1 && form.applicationUuid);
    switch (applicationStatus) {
      case ApplicationStatus.None:
        if (shouldShowSteps) {
          return <Steps />;
        }
        return <LaunchView />;
      case ApplicationStatus.ApplicationFinished:
      case ApplicationStatus.ApplicationUnderReview:
      case ApplicationStatus.OfferNotApproved:
      case ApplicationStatus.OfferApproved:
        return <ConfirmationPage />;

      default:
        logToSentry(
          Error('viewHandler switch ended up in default'),
          `viewHandler in Onboarding.tsx, applicationStatus is ${applicationStatus}`,
          { product: 'onboarding' }
        );
        return <LaunchView />;
    }
  }, [applicationStatus, flowType, currentStep, form.applicationUuid]);

  // TODO: decide if this should be moved to CompanySelector component
  useEffect(() => {
    if (
      flowType === FlowTypes.Authenticated &&
      onboardingApplication.userId &&
      apiStatus.clients === ApiStatus.Idle
    ) {
      dispatch(fetchClientCompanies());
    }
  }, [onboardingApplication.userId, flowType]);

  useEffect(() => {
    if (ready) {
      const currentPostHogPersonId = posthog.get_distinct_id();
      const userCodeInForm = onboardingApplication.form.userCode;

      if (userCodeInForm && currentPostHogPersonId !== userCodeInForm) {
        posthog.identify(userCodeInForm);
        Sentry.setUser({ id: userCodeInForm });
      } else if (!userCodeInForm && posthog._isIdentified()) {
        posthog.reset();
        Sentry.setUser(null);
      }
    }
  }, [onboardingApplication.form.userCode, ready]);

  const isMarketValid = useMemo(
    () =>
      onboardingMarkets.some(
        (availableMarket: CountryCode) => availableMarket === market
      ),
    [market]
  );

  useEffect(() => {
    if (!isMarketValid) {
      logToSentry(
        Error(`Unsupported market for onboarding`),
        `Unsupported onboarding market ${market}`,
        {
          product: 'onboarding',
        }
      );
    }
  }, [isMarketValid]);

  return isMarketValid ? (
    <>
      {ready && !pageIsRefreshing ? (
        <MainOnboardingContainer>{viewHandler}</MainOnboardingContainer>
      ) : (
        <SpinnerCircular color={theme.colors.secondaryGray} />
      )}
    </>
  ) : (
    <Redirect to="/404" />
  );
};

export default withValidation(Onboarding);
