import initialState from '~/store/initialState';
import { KlarnaKosmaFlow } from '~/hooks/useKlarnaFlow/types';
import {
  KlarnaKosmaAction,
  UPDATE_TRANSACTION_FLOW,
} from '../types/klarnaKosmaTypes';

interface KlarnaKosmaState {
  readonly transactionFlow: KlarnaKosmaFlow;
}

const klarnaKosmaReducer = (
  state = initialState.klarnaKosma,
  action: KlarnaKosmaAction
): KlarnaKosmaState => {
  switch (action.type) {
    case UPDATE_TRANSACTION_FLOW:
      return {
        ...state,
        transactionFlow: {
          ...state.transactionFlow,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default klarnaKosmaReducer;
