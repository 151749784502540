import React, { useEffect, useRef, useState } from 'react';
import { StepConfigItem } from '~/Interfaces';
import Step from './components/Step';
import StepFooter from './components/StepFooter';

interface StepperProps {
  currentStepConfig: StepConfigItem;
  stepIsLoading?: boolean;
  currentStep: number;
  footerCTAAction: () => void;
  footerCTALabel: string;
  extraFooterContent?: React.ReactNode;
}

const Stepper = ({
  currentStepConfig,
  stepIsLoading,
  currentStep,
  footerCTAAction,
  footerCTALabel,
  extraFooterContent,
}: StepperProps) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const [footerHeight, setFooterHeight] = useState(100);

  const handleFooterResize = (entries: ResizeObserverEntry[]) => {
    const clientHeight = entries?.[0]?.target?.clientHeight;
    if (clientHeight) {
      setFooterHeight(clientHeight);
    }
  };

  useEffect(() => {
    if (footerRef.current) {
      new ResizeObserver(handleFooterResize).observe(footerRef.current);
    }
  }, [footerRef]);

  return (
    <>
      <Step
        currentStepConfig={currentStepConfig}
        lockStep={stepIsLoading}
        currentStep={currentStep}
        footerHeight={footerHeight}
      />
      <StepFooter
        stepIsLoading={stepIsLoading}
        currentStepConfig={currentStepConfig}
        CTAAction={footerCTAAction}
        CTALabel={footerCTALabel}
        footerRef={footerRef}
        extraContent={extraFooterContent}
      />
    </>
  );
};
export default Stepper;
