import React, { useEffect, useState } from 'react';
import {
  AppShell,
  mediaQueries,
  ModalProvider,
  PageWrapper,
  QitUiProvider,
  useWindowProperties,
} from '@qred/qit-ui';
import styled from 'styled-components';
import SidebarContent from '~/components/shared/Sidebar/SidebarContent';
import { useDispatch, useSelector } from 'react-redux';
import { pushToGtmAction } from '~/store/actions/gtmActions';
import { StepStatus } from '~/enums';
import { RootState } from '~/store/types/sharedTypes';
import { appShellSidebar } from '~/constants/cy/components/sidebar';
import { useAuth0 } from '@auth0/auth0-react';
import SidebarContentLoggedOut from '../Sidebar/SidebarContentLoggedOut';

const LegacyBaseStyles = styled.div`
  font-size: 125%;

  @media${mediaQueries.mobile.media} {
    font-size: 3.5vmin;
  }
`;

interface MainLayoutProps {
  legacyStyles?: boolean;
  newStyle?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  legacyStyles,
  newStyle,
  children,
}) => {
  const { isMobile, isTablet } = useWindowProperties();
  const { activeCompany } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [eventStarted, setEventStarted] = useState(false);

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!eventStarted && activeCompany) {
      dispatch(
        pushToGtmAction({
          eventName: 'sidebar',
          actionName: 'view_sidebar',
          stepStatus: StepStatus.Started,
        })
      );
      setEventStarted(true);
    }
  }, [activeCompany?.id]);

  return (
    <QitUiProvider>
      <ModalProvider>
        <AppShell
          isMobileOrTablet={isMobile || isTablet}
          navbarContent={
            isAuthenticated ? <SidebarContent /> : <SidebarContentLoggedOut />
          }
          dataCy={appShellSidebar}
          navbarWidth="260px"
        >
          {legacyStyles ? (
            <LegacyBaseStyles>{children}</LegacyBaseStyles>
          ) : (
            <PageWrapper>{children}</PageWrapper>
          )}
        </AppShell>
      </ModalProvider>
    </QitUiProvider>
  );
};

export default MainLayout;
