import initialState from '~/store/initialState';
import { InvoicesAction } from '~/store/types/invoicesTypes';

const invoicesReducer = (
  state = initialState.invoices,
  action: InvoicesAction
) => {
  switch (action.type) {
    case 'FETCH_INVOICES':
      return {
        ...state,
        ...action.payload,
      };
    case 'DOWNLOAD_FINAL_INVOICE_STATUS':
      return {
        ...state,
        apiDownloadFinalInvoiceStatus: action.payload,
      };
    default:
      return state;
  }
};

export default invoicesReducer;
