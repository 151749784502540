import { IAnalyticEventParams, IEvent } from '~/Interfaces';

const pushToGtmHelper = (
  event: IEvent | string | object, // string and objects are for old events
  key?: IAnalyticEventParams
) => {
  const data = typeof event === 'string' ? { event, eventName: event } : event;

  window.dataLayer.push({ ...data, ...key, product: 'loan' });
};

export default pushToGtmHelper;
