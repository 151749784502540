import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '~/styles/themes';
import { Typography } from '../Typography/Typography';

const radioButtonOuterCircleDiameter = 1.5;
const radioButtonInnerCircleDiameter = 1;
const radioButtonCircleTop = 0.8;

export enum RadioButtonsType {
  RadioButtonsOnLeftSide = 'RADIO_BUTTONS_ON_LEFT_SIDE',
  RadioButtonsOnRightSide = 'RADIO_BUTTONS_ON_RIGHT_SIDE',
}

const Label = styled.div<{ type: RadioButtonsType }>`
  margin-left: ${(props) =>
    props.type === RadioButtonsType.RadioButtonsOnLeftSide && '2.5em'};
`;

const LabelContainer = styled.label<{
  borderless?: boolean;
  borderBottom?: boolean;
  radioButtonCircleRight: number;
  radioButtonCircleLeft: number;
  type: RadioButtonsType;
  disabled?: boolean;
}>`
  position: relative;
  padding-right: 2em;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  line-height: 1.7em;
  display: flex;
  width: 100%;
  padding: 0.5em ${(props) => (props.borderless ? 0 : 1)}em;
  padding-right: ${(props) => (props.borderless ? 0.5 : 3)}em;
  border: 1.5px solid transparent;
  border-radius: ${(props) => (props.borderless ? 'unset' : '0.5em')};
  border-bottom: ${(props) => props.borderBottom && '0.125em solid #f2f3f0'};

  &:hover {
    border-color: ${(props) =>
      props.borderless
        ? !props.borderBottom
          ? 'transparent'
          : '0.125em solid #f2f3f0'
        : theme.colors.hoverGreen};
  }

  &.active {
    border-color: ${(props) =>
      props.borderless
        ? !props.borderBottom
          ? 'transparent'
          : '0.125em solid #f2f3f0'
        : theme.colors.dark};
  }

  &::before {
    content: '';
    position: absolute;
    top: ${radioButtonCircleTop}em;
    width: ${radioButtonOuterCircleDiameter}em;
    height: ${radioButtonOuterCircleDiameter}em;
    right: ${(props) =>
      props.type === RadioButtonsType.RadioButtonsOnRightSide &&
      props.radioButtonCircleRight}em;
    left: ${(props) =>
      props.type === RadioButtonsType.RadioButtonsOnLeftSide &&
      props.radioButtonCircleLeft}em;
    border: 0.1em solid
      ${(props) =>
        props.disabled ? theme.colors.neutral : theme.colors.newSecondaryGray};
    border-radius: 100%;
    background: #fff;
    box-sizing: border-box;
  }

  &::after {
    content: '';
    width: ${radioButtonInnerCircleDiameter}em;
    height: ${radioButtonInnerCircleDiameter}em;
    top: ${radioButtonOuterCircleDiameter / 2 -
    radioButtonInnerCircleDiameter / 2 +
    radioButtonCircleTop}em;
    right: ${(props) =>
      props.type === RadioButtonsType.RadioButtonsOnRightSide &&
      radioButtonOuterCircleDiameter / 2 -
        radioButtonInnerCircleDiameter / 2 +
        props.radioButtonCircleRight}em;
    left: ${(props) =>
      props.type === RadioButtonsType.RadioButtonsOnLeftSide &&
      radioButtonOuterCircleDiameter / 2 -
        radioButtonInnerCircleDiameter / 2 +
        props.radioButtonCircleRight}em;
    background: ${(props) =>
      props.disabled ? theme.colors.neutral : theme.colors.dark};
    position: absolute;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        &::before {
          border-color: ${theme.colors.primaryGreen};
        }
      }
    `}

  img {
    margin-right: 1em;
  }
`;

/* Hide the browser's default radio button */
const Radio = styled.input`
  position: absolute;
  left: -9999px;

  &:not(:checked) + ${LabelContainer}:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:checked:not(:disabled) + ${LabelContainer}:before {
    border-color: ${theme.colors.dark};
  }

  &:checked + ${LabelContainer}:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

interface RadioButtonItem {
  value: number | string;
  label: string | ReactNode;
  alt?: string;
  iconSrc?: string;
  disabled?: boolean;
}

interface Props {
  name: string;
  options: Array<RadioButtonItem>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: number | string;
  borderless?: boolean;
  type: RadioButtonsType;
  borderBottom?: boolean;
  dataCy?: string;
}

const RadioButtonGroup: React.FC<Props> = (props) => (
  <>
    {props.options.map((option, index) => (
      <div key={option.value}>
        <Radio
          type="radio"
          data-cy={props.dataCy}
          name={props.name}
          value={option.value}
          id={props.name + index}
          checked={option.value === props.value}
          onChange={props.onChange}
          disabled={option.disabled}
        />
        <LabelContainer
          htmlFor={props.name + index}
          className={option.value === props.value ? 'active' : ''}
          borderless={props.borderless}
          radioButtonCircleRight={props.borderless ? 0.5 : 1}
          radioButtonCircleLeft={props.borderless ? 0.5 : 1}
          borderBottom={props.borderBottom}
          type={props.type}
          disabled={option.disabled}
        >
          {option.iconSrc && <img alt={option.alt} src={option.iconSrc} />}
          <Label type={props.type}>
            {React.isValidElement(option.label) ? (
              option.label
            ) : (
              <Typography fontSize={1}>{option.label}</Typography>
            )}
          </Label>
        </LabelContainer>
      </div>
    ))}
  </>
);

export default RadioButtonGroup;
