import { getMarketHasAddress } from '~/constants/markets';
import { ClientType, CountryCode } from '~/enums';
import {
  formatOrganizationNumber,
  formatAmountWithoutCurrency,
  removeNonDigits,
  removeWhitespace,
} from '~/helpers/formatters.helper';
import { normalizeOrgNumberHelper } from '~/helpers/normalizeOrgNumber.helper';
import {
  buildStreetValue,
  transformGuarantorAddress,
} from '~/helpers/onboarding.helper';
import { getCookieValue } from '~/helpers/utils';
import {
  ILoanOnboardingApplicationForm,
  ILoanOnboardingApplicationGuarantor,
} from '~/interfaces/LoanOnboardingApplication';
import { TLoanOnboardingApplicationDTO } from '~/types/DTO/loanOnboardingApplication';

const applicationFromDTO = (
  data: TLoanOnboardingApplicationDTO['getApplicationRes'],
  currentForm: ILoanOnboardingApplicationForm
): ILoanOnboardingApplicationForm => {
  let applicant = { ...currentForm.applicant };
  if (data.applicant) {
    const { firstName, lastName, ...restOfApplicantFromData } = data.applicant;
    const fullName = `${firstName || ''} ${lastName || ''}`.trim();
    applicant = {
      ...applicant,
      ...restOfApplicantFromData,
      ...(fullName && { fullName }),
    };
  }

  return {
    ...currentForm,
    applicant,
    applicationUuid: data.applicationUuid,
    bankIntegration: data.bankIntegration,
    organizationNumber: formatOrganizationNumber(
      data.organization.organizationNumber || ''
    ),
    companyType: data.organization.companyType || '',
    companyTypeCategory: data.companyTypeCategory,
    purposeOfLoan: data.purposeOfLoan,
    reason: data.reason || '',
    sessionId: data.sessionId,
    guarantors:
      data.guarantors?.map(
        (g): ILoanOnboardingApplicationGuarantor => ({
          ...g,
          guarantorId: g.guarantorId,
          isChecked: true,
        })
      ) || [],
    turnover: data.turnover,
    amount:
      currentForm.amount ||
      formatAmountWithoutCurrency(removeNonDigits(data.amount.toString())), // TODO: skip formatting here, and format when displayed to user instead?
    clientType:
      currentForm.clientType !== ClientType.Unknown
        ? currentForm.clientType
        : data.clientType || ClientType.Unknown,
    applicationId: data.applicationId,
    ...(!!data.bankFilesPaths?.length && {
      bankFilesPaths: data.bankFilesPaths,
    }),
    ...(!!data.bankFilesUploaded && {
      bankFilesUploaded: data.bankFilesUploaded,
    }),
    qCode: data.qCode,
    userCode: data.userCode,
    eskatConnected: data.eskatConnected,
    incomeReceivedInForeignCurrencies: data.incomeReceivedInForeignCurrencies,
  };
};

const applicationToDTO = ({
  form,
  currentStep,
  market,
  shouldPostApplication,
  finalized,
  currentStepCompleted,
  basicApplicationSent,
  clientId,
  isCompanyTypeCategoryRequested,
}: {
  basicApplicationSent: boolean;
  clientId?: number;
  currentStep: number;
  currentStepCompleted: boolean;
  finalized: boolean;
  form: ILoanOnboardingApplicationForm;
  market: CountryCode;
  shouldPostApplication: boolean;
  isCompanyTypeCategoryRequested?: boolean;
}): TLoanOnboardingApplicationDTO['postPatchApplicationReq'] => {
  const {
    organizationNumber,
    companyType,
    guarantors,
    amount = '',
    turnover,
    applicant,
    ...rest
  } = form;

  const marketHasAddress = getMarketHasAddress(market);

  const requestData: TLoanOnboardingApplicationDTO['postPatchApplicationReq'] = {
    organization: {
      organizationNumber: normalizeOrgNumberHelper(organizationNumber),
      companyType,
    },
    amount: Number(removeNonDigits(amount)),
    completedStep: currentStepCompleted ? currentStep : currentStep - 1,
    turnover: removeWhitespace(turnover),
    market,
    currentStep,
    finalized,
    basicApplicationSent,
    isCompanyTypeCategoryRequested,
    clientId,
    ...rest,
  };

  const { fullName, ...applicantRest } = applicant || {};
  requestData.applicant = applicantRest;
  if (fullName) {
    const [firstName, ...restOfName] = fullName.split(' ');
    requestData.applicant.firstName = firstName;
    requestData.applicant.lastName = restOfName.join(' ');
  }

  if (market === CountryCode.NL && requestData.applicant) {
    const { address, addressFields } = requestData.applicant;

    if (address) {
      requestData.applicant.address = {
        city: address.city,
        street: address.street,
        zipCode: removeWhitespace(address.zipCode),
      };
    }

    if (addressFields) {
      requestData.applicant.addressFields = {
        city: addressFields.city || '',
        houseNumber: addressFields.houseNumber || '',
        houseNumberAddition: addressFields.houseNumberAddition || '',
        streetName: addressFields.streetName || '',
        zipCode: addressFields.zipCode
          ? removeWhitespace(addressFields.zipCode)
          : '',
      };
    }
  }

  if (shouldPostApplication) {
    const trafficCookie = getCookieValue('_ga') || '';
    requestData.trafficCookie = trafficCookie;
  }

  if (guarantors && guarantors.length) {
    requestData.guarantors = guarantors
      .filter((guarantor) => guarantor.isChecked)
      .map(
        (
          formGuarantor
        ): TLoanOnboardingApplicationDTO['postPatchApplicationReqGuarantor'] => ({
          guarantorId: formGuarantor.guarantorId,
          fullName: formGuarantor.fullName || '',
          email: formGuarantor.email || '',
          phone: formGuarantor.phone || '',
          personalNumber: formGuarantor.personalNumber,
          maskedPersonalNumber: formGuarantor.maskedPersonalNumber,
          dateOfBirth: formGuarantor.dateOfBirth,
          maskedDateOfBirth: formGuarantor.maskedDateOfBirth,
          addressFields: formGuarantor.addressFields,
        })
      );
  }

  if (marketHasAddress) {
    if (form.applicant.addressFields) {
      const { city, zipCode } = form.applicant.addressFields;
      requestData.applicant = {
        ...requestData.applicant,
        address: {
          street: buildStreetValue(form.applicant.addressFields),
          zipCode: zipCode ? removeWhitespace(zipCode) : '',
          city: city || '',
        },
      };
    }

    if (requestData.guarantors?.length) {
      requestData.guarantors = requestData.guarantors.map(
        (
          guarantor
        ): TLoanOnboardingApplicationDTO['postPatchApplicationReqGuarantor'] => {
          if (guarantor.addressFields) {
            return {
              ...guarantor,
              fullAddress: transformGuarantorAddress(guarantor.addressFields),
            };
          }
          return guarantor;
        }
      );
    }
  }

  return requestData;
};

export const loanOnboardingApplicationDTOMappers = {
  applicationFromDTO,
  applicationToDTO,
};
