import { Center, Loader } from '@qred/components-library';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { logToSentry } from '~/helpers/loggers.helper';
import { RootState } from '~/store';

interface PDFViewerProps {
  file: string;
}

const PDFViewer = ({ file }: PDFViewerProps) => {
  const [totalPages, setTotalPages] = useState<number>();
  const { mobile } = useSelector((state: RootState) => state.matchedMedia);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
  };

  const onDocumentLoadError = (error: Error) => {
    logToSentry(error, 'PDFViewerError');
  };

  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onDocumentLoadError}
      loading={
        <Center>
          <Loader size={'xl'} />
        </Center>
      }
    >
      {[...Array(totalPages)]
        .map((_, i) => i + 1)
        .map((page, key) => (
          <Page
            key={key}
            pageNumber={page}
            width={mobile ? window.screen.width : undefined}
            scale={mobile ? 1 : 1.25}
          />
        ))}
    </Document>
  );
};

export default PDFViewer;
