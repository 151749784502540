import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { theme } from '~/styles/themes';

export enum FontWeights {
  Thin = 400,
  Normal = 500,
  Thick = 600,
  Bold = 700,
}

interface Props extends React.ComponentPropsWithoutRef<'span'> {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: number;
  color?: React.CSSProperties['color'];
  centerAlign?: boolean;
  uppercase?: boolean;
  underlined?: boolean;
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span';
  noMargin?: boolean;
  verticalAlign?: string;
  dataCy?: string;
  dataTestId?: string;
}

const Span = styled.span<Props>`
  margin: ${(props) => (props.noMargin ? '0' : '0.2em 0')};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'inherit')};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}em` : 'inherit')};
  font-weight: ${(props) => props.fontWeight || 'inherit'};
  text-align: ${(props) => (props.centerAlign ? 'center' : 'inherit')};
  line-height: ${(props) =>
    props.lineHeight ? `${props.lineHeight}em` : 'inherit'};
  color: ${(props) => props.color || theme.colors.dark};
  text-decoration: ${(props) => (props.underlined ? 'underline' : undefined)};
  display: ${(props) => (props.element ? undefined : 'inline-block')};
  vertical-align: ${(props) => props.verticalAlign || 'middle'};
`;

export const Typography = forwardRef<HTMLSpanElement, Props>((props, ref) => {
  const { element, ...rest } = props;
  return (
    <Span
      data-cy={props.dataCy}
      data-testid={props.dataTestId}
      ref={ref}
      as={props.element}
      element={props.element}
      {...rest}
    >
      {props.children}
    </Span>
  );
});
