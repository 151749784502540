import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { parseJwt } from '~/helpers/decodeToken.helper';
import { getAuth0Audience } from '~/helpers/utils';

let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string>;

export const accessToken = {
  get: () =>
    getAccessTokenSilently({
      authorizationParams: {
        audience: getAuth0Audience(),
      },
    }),
  getDecoded: async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: getAuth0Audience(),
      },
    });
    return parseJwt(token);
  },
  // eslint-disable-next-line no-return-assign
  set: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
  ) => (getAccessTokenSilently = func),
};

export const TOKEN = {
  EMAIL: 'https://mypages/email',
  SSN: 'https://mypages/ssn',
  MARKET: 'https://mypages/market',
  CLIENT_ID: 'https://onboarding/clientId',
  USER_CODE: 'https://onboarding/userCode',
  DATE_OF_BIRTH: 'https://onboarding/dateOfBirth',
};
