import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { accessToken } from '~/store/apis/utils/accessToken';

export const baseQuery = (
  baseUrl: string = import.meta.env.VITE_API_BASE_URL
) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      const token = await accessToken.get();

      if (!headers.has('Content-Type') && !headers.has('unsetHeader')) {
        headers.set('Content-Type', 'application/json');
      }

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;
