import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { onChangeInputEvent, onFocusSelectEvent } from '~/types/types';
import { Button, Stack } from '@qred/components-library';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import AMLFields from '../AMLFields/AMLFields';
import { ILoanOnboardingOfferApplicant } from '~/interfaces/LoanOnboardingoffer';
import { updateApplicant } from '~/store/slices/loanOnboardingOffer.slice';

interface ApplicantProps {
  applicant: ILoanOnboardingOfferApplicant;
  onBlur: (name: onChangeInputEvent | onFocusSelectEvent | string) => void;
}

const Applicant = ({ applicant, onBlur }: ApplicantProps) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const {
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const formMethods = useForm<ILoanOnboardingOfferApplicant>({
    defaultValues: applicant,
    mode: 'onTouched',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = formMethods;

  const saveApplicant: SubmitHandler<ILoanOnboardingOfferApplicant> = (
    formData
  ) => {
    dispatch(updateApplicant(formData));

    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'edit_applicant_save_button_pressed',
      })
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(saveApplicant)}>
        <Stack ml="2em" mr="2em" mt="0.5em">
          <AMLFields trackFieldChange={onBlur} />
        </Stack>
        <Stack align="center">
          <Button
            mt="2em"
            variant="secondary"
            data-cy="onboarding_offer_save_signer_button"
            fullWidth={mobile}
            size="md"
            type="submit"
            disabled={isSubmitting || isSubmitSuccessful}
          >
            {translate('Save')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default Applicant;
