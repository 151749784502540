import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { theme } from '~/styles/themes';
import { randomInRangeHelper } from '~/helpers/randomInRange.helper';

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 9999,
};

const getAnimationSettings = (originXA, originXB) => {
  return {
    startVelocity: 18,
    spread: 2000,
    ticks: 400,
    zIndex: 9999,
    particleCount: 20,
    colors: [
      theme.colors.accent,
      theme.colors.dark,
      theme.colors.active,
      theme.colors.hoverGreen,
      theme.colors.warning,
    ],
    origin: {
      x: randomInRangeHelper(originXA, originXB),
      y: Math.random() - 0.2,
    },
  };
};

const Confetti = (props) => {
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  useEffect(() => {
    if (props.play) {
      startAnimation();
    } else {
      stopAnimation();
    }
  }, [props.play]);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 1.3));
      refAnimationInstance.current(getAnimationSettings(0.7, 1.9));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 400));
    }
  }, [intervalId, nextTickAnimation]);

  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
    refAnimationInstance.current && refAnimationInstance.current.reset();
  }, [intervalId]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  return <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />;
};

export default Confetti;
