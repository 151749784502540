import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';
import { theme } from '~/styles/themes';

export const UnorderedListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.neutral12};
  border-radius: 0.5em;
  border: 1px solid ${theme.colors.backdrop};
  padding: 1em 1.5em;

  @media ${MediaQueries.smallScreenPortrait} {
    padding: 1em;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 1.375em;
    letter-spacing: 0.015em;
  }
`;

export const ListItem = styled.li`
  padding: 0.3em 0;
  line-height: 1.7em;
  display: flex;
  flex-direction: row;
`;

export const BoldSpan = styled.span`
  font-weight: 700;
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.14em;
`;

export const BoldSpanNoLetterSpacing = styled(BoldSpan)`
  letter-spacing: 0;
`;
