import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import { closeHelpPopup } from '~/store/slices/helpPopup.slice';
import Button, { ButtonType } from '../Button/button';
import MeansOfSupport from '../MeansOfSupport/MeansOfSupport';
import { FontWeights, Typography } from '../Typography/Typography';
import {
  HelpPopupContainer,
  HelpPopupContent,
  MeansOfSupportWrapper,
} from './StyledComponents';

const HelpPopup = () => {
  const translate = useTranslate();
  const { isOpen } = useSelector((state: RootState) => state.helpPopup);
  const dispatch = useDispatch();

  return isOpen ? (
    <HelpPopupContainer>
      <HelpPopupContent>
        <Typography
          fontSize={1.4}
          color={theme.colors.dark}
          fontWeight={FontWeights.Thick}
          lineHeight={1.5}
          centerAlign
          element="h4"
        >
          {translate('Help')}
        </Typography>
        <Typography
          fontSize={1}
          color={theme.colors.dark}
          fontWeight={FontWeights.Thin}
          lineHeight={1}
          centerAlign
        >
          {translate('ContactNeedHelp')}
        </Typography>
        <MeansOfSupportWrapper>
          <MeansOfSupport />
        </MeansOfSupportWrapper>

        <Button
          onClick={() => dispatch(closeHelpPopup())}
          type={ButtonType.Filled}
        >
          {translate('Ok')}
        </Button>
      </HelpPopupContent>
    </HelpPopupContainer>
  ) : (
    <></>
  );
};
export default HelpPopup;
