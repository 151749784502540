import { Button, Group, Stack, Typography } from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { getHomePageByMarket } from '~/constants/markets';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';

const Maintenance = () => {
  const translate = useTranslate();
  const { language, market } = useSelector((state: RootState) => state.intl);

  const { mobile } = useSelector((state: RootState) => state.matchedMedia);

  return (
    <Group
      spacing={0}
      h={mobile ? 200 : 240}
      w={mobile ? 560 : 580}
      p={mobile ? 'md' : 'xxl'}
      m={mobile ? 'md' : ''}
      style={{
        backgroundColor: `${theme.colors.neutral2}`,
        borderRadius: '4px',
        marginBlock: mobile ? '0' : '7em',
      }}
    >
      <Stack>
        <Typography weight={600} size="xl" lineHeight={32}>
          {translate('Onboarding.MaintenanceTitle')}
        </Typography>
        <Typography lineHeight={24}>
          {translate('Onboarding.MaintenanceText')}
        </Typography>
      </Stack>

      <Button
        mt={24}
        fullWidth={mobile}
        onClick={() =>
          window.location.assign(getHomePageByMarket(market, language))
        }
        variant="primary"
        size="md"
      >
        {translate('Onboarding.MaintenanceTextBack')}
      </Button>
    </Group>
  );
};

export default Maintenance;
