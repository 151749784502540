import { currencyFormatter } from '@qred/fe-cp-formatting-helpers';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';

export const useCurrencyFormatter = () => {
  const { market } = useSelector((state: RootState) => state.intl);

  const formatter = currencyFormatter(market);

  return formatter;
};
