import React from 'react';
import SkipButton from '~/components/shared/Stepper/components/SkipButton';
import { useDispatch, useSelector } from 'react-redux';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import useSkip from '../../Steps/useSkip';
import useSubmit from '../../Steps/useSubmit';
import useGoToNextStep from '../../Steps/useGoToNextStep';
import { Button } from '@qred/components-library';
import { useLoanOnboardingApplicationSelectors } from '~/store/selectors/loanOnboardingApplication.selector';
import useTranslate from '~/hooks/useTranslate';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { getEskatIntegrationUrl } from '~/store/actions/eskatActions';

const ConnectToEskatFooter = () => {
  const {
    eskat: { apiStatus: eskatApiStatus },
    onboardingApplication: {
      form: { eskatConnected },
      currentStep,
      apiStatus: onboardingApplicationApiStatus,
    },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const t = useTranslate();
  const dispatch = useDispatch();
  const {
    onboardingApplication: {
      form: { qCode, applicationUuid },
    },
    eskat,
  } = useSelector((state: RootState) => state);

  const connectToEskatOnClickHandler = () => {
    if (qCode && applicationUuid) {
      dispatch(
        pushToGtmOnboardingAction({
          actionName: 'eskat_connect_pressed',
        })
      );
      sessionStorage.setItem('eskat-flow-onboarding', applicationUuid);
      dispatch(getEskatIntegrationUrl(qCode));
    }
  };

  const onSkip = useSkip();
  const onSubmit = useSubmit();
  const onNextStepClick = useGoToNextStep();
  const { stepConfig } = useLoanOnboardingApplicationSelectors();

  const hideDefaultCta = !eskatConnected;
  const isLastStep = stepConfig.length === currentStep;

  return (
    <>
      {hideDefaultCta ? (
        <>
          <SkipButton
            label="Onboarding.Skip"
            action={onSkip}
            disabled={eskatApiStatus === ApiStatus.Started}
          />
          <Button
            variant="primary"
            size="md"
            dataCy="connect_to_eskat_button"
            onClick={connectToEskatOnClickHandler}
            disabled={eskatConnected || eskat.apiStatus === ApiStatus.Started}
            isLoading={eskat.apiStatus === ApiStatus.Started}
            fullWidth={mobile}
            mt={5}
          >
            {t('Onboarding.ConnectToEskat')}
          </Button>
        </>
      ) : (
        <NextButton
          label={isLastStep ? 'Onboarding.Submit' : 'Onboarding.Next'}
          showLoadingSpinner={
            onboardingApplicationApiStatus.patch === ApiStatus.Started
          }
          action={isLastStep ? onSubmit : onNextStepClick}
        />
      )}
    </>
  );
};

export default ConnectToEskatFooter;
