import { generateId } from '~/helpers/generators.helper';
import {
  CardOffer,
  CardOfferUserData,
  CardSigner,
  ILegacyOnboardingOwner,
} from '~/Interfaces';
import initialState from '../initialState';
import {
  CardOfferAction,
  SET_CARD_OFFER_DATA,
  SET_CARD_OFFER_SUBMIT_STATUS,
  SET_CARD_OFFER_USER_DATA,
  SET_HAS_CARD_OFFER,
  UPDATE_CARD_OFFER_USER_DATA,
  UPDATE_CARD_OFFER_USER_DATA_ADD_OWNER_PLACEHOLDER,
  UPDATE_CARD_OFFER_USER_DATA_REMOVE_OWNER_PLACEHOLDER,
  UPDATE_CARD_OFFER_USER_DATA_UPDATE_OWNER,
  UPDATE_CARD_OFFER_USER_DATA_UPDATE_SIGNER,
} from '../types/cardOfferTypes';

interface CardOfferState {
  readonly overview: Array<CardOffer>;
  readonly userData: CardOfferUserData;
  readonly hasOffer: boolean;
  readonly isSubmitting: boolean;
}

const cardOfferReducer = (
  state = initialState.cardOffer,
  action: CardOfferAction
): CardOfferState => {
  switch (action.type) {
    case SET_CARD_OFFER_DATA:
      return {
        ...state,
        overview: action.payload,
      };

    case SET_CARD_OFFER_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
          beneficialOwners: action.payload.beneficialOwners.map((o) => ({
            ...o,
            id: generateId(),
          })),
        },
      };
    case SET_HAS_CARD_OFFER:
      return { ...state, hasOffer: action.payload };

    case UPDATE_CARD_OFFER_USER_DATA:
      return { ...state, userData: { ...state.userData, ...action.payload } };

    case UPDATE_CARD_OFFER_USER_DATA_ADD_OWNER_PLACEHOLDER:
      return {
        ...state,
        userData: {
          ...state.userData,
          beneficialOwners: state.userData.beneficialOwners.concat([
            {
              fullName: '',
              ownerShare: 0,
              personalNumber: '',
              id: generateId(),
              isAddedByUser: true,
            },
          ] as any),
        },
      };
    case UPDATE_CARD_OFFER_USER_DATA_REMOVE_OWNER_PLACEHOLDER:
      return {
        ...state,
        userData: {
          ...state.userData,
          beneficialOwners: state.userData.beneficialOwners.filter(
            (owner: ILegacyOnboardingOwner) => owner.id !== action.payload
          ),
        },
      };

    case UPDATE_CARD_OFFER_USER_DATA_UPDATE_OWNER:
      return {
        ...state,
        userData: {
          ...state.userData,
          beneficialOwners: state.userData.beneficialOwners.map(
            (owner: ILegacyOnboardingOwner) => {
              if (owner.id === action.payload.id) {
                return { ...owner, ...action.payload };
              }
              return owner;
            }
          ),
        },
      };

    case UPDATE_CARD_OFFER_USER_DATA_UPDATE_SIGNER:
      return {
        ...state,
        userData: {
          ...state.userData,
          additionalSigners: state.userData.additionalSigners.map(
            (signer: CardSigner) => {
              if (signer.signerId === action.payload.signerId) {
                return { ...signer, ...action.payload };
              }
              return signer;
            }
          ),
        },
      };

    case SET_CARD_OFFER_SUBMIT_STATUS:
      return { ...state, isSubmitting: action.payload };

    default:
      return state;
  }
};

export default cardOfferReducer;
