import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormStatus,
  SimpleEvent,
  ValidationContext,
} from '~/components/hoc/withValidation';
import InputField from '~/components/shared/Input/InputField';
import RadioButtonGroup, {
  RadioButtonsType,
} from '~/components/shared/RadioButtonGroup/RadioButtonGroup';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { onChangeInputEvent } from '~/types/types';
import { LoanPurposes } from '~/enums';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';

const LoanPurpose = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const validationContext = useContext(ValidationContext);

  const { validationErrors } = validationContext;

  const otherLoanPurposeInput = useRef<HTMLInputElement>(null);

  const { form } = useSelector((state: RootState) => state.onboardingOffer);

  const otherPurposeReasonTextMaxCharLimit = 190;

  useEffect(() => {
    const fakeEvent: SimpleEvent = {
      target: {
        name: 'LoanPurpose',
        value: form.purposeOfLoan || [],
        dataset: { validationType: 'LoanPurpose' },
      },
    };
    validationContext.onChangeHOC(fakeEvent);

    return () => {
      validationContext.removePropertyFromValidationErrors('LoanPurpose');
    };
  }, [form.purposeOfLoan]);

  useEffect(() => {
    if (otherLoanPurposeInput?.current) {
      otherLoanPurposeInput.current.scrollIntoView();
      otherLoanPurposeInput.current.focus();
    }
  }, [otherLoanPurposeInput?.current]);

  const selectedPurposeOfLoan = {
    [LoanPurposes.Marketing]: t('LoanPurposeMarketing') as string,
    [LoanPurposes.Hiring]: t('LoanPurposeStaff') as string,
    [LoanPurposes.Expansion]: t('LoanPurposeExpansion') as string,
    [LoanPurposes.Equipment]: t('LoanPurposeEquipment') as string,
    [LoanPurposes.RepayOtherLoan]: t('LoanPurposeDebt') as string,
    [LoanPurposes.Inventory]: t('LoanPurposeInventory') as string,
    [LoanPurposes.Working]: t('LoanPurposeWorkingCapital') as string,
    [LoanPurposes.Other]: t('LoanPurposeOther') as string,
  };

  const options = [
    {
      label: selectedPurposeOfLoan[LoanPurposes.Equipment],
      value: LoanPurposes.Equipment,
    },
    {
      label: selectedPurposeOfLoan[LoanPurposes.RepayOtherLoan],
      value: LoanPurposes.RepayOtherLoan,
    },
    {
      label: selectedPurposeOfLoan[LoanPurposes.Hiring],
      value: LoanPurposes.Hiring,
    },
    {
      label: selectedPurposeOfLoan[LoanPurposes.Marketing],
      value: LoanPurposes.Marketing,
    },
    {
      label: selectedPurposeOfLoan[LoanPurposes.Inventory],
      value: LoanPurposes.Inventory,
    },
    {
      label: selectedPurposeOfLoan[LoanPurposes.Expansion],
      value: LoanPurposes.Expansion,
    },
    {
      label: selectedPurposeOfLoan[LoanPurposes.Working],
      value: LoanPurposes.Working,
    },
    {
      label: selectedPurposeOfLoan[LoanPurposes.Other],
      value: LoanPurposes.Other,
    },
  ];

  const validationErrorShouldBeShown =
    validationContext.formStatus === FormStatus.SUBMITTED &&
    validationErrors.LoanPurpose;

  const onChangePurposeHandler = (event: onChangeInputEvent) => {
    const { value } = event.target;

    dispatch(
      updateForm({
        purposeOfLoan: value,
      })
    );

    if (value !== LoanPurposes.Other) {
      dispatch(
        updateForm({
          loanPurposeManual: '',
        })
      );
    }
    dispatch(
      pushToGtmOnboardingAction({
        stepActionNumber: 0.1,
        actionName: 'loan_purpose_change',
      })
    );
  };

  const onChangeOtherPurposeHandler = (event: onChangeInputEvent) => {
    dispatch(updateForm({ loanPurposeManual: event.target.value }));
  };

  const onBlurOtherPurposeHandler = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'loan_purpose_other_change',
        stepActionNumber: 0.2,
      })
    );
  };

  return (
    <>
      <RadioButtonGroup
        name="purposes of loan"
        dataCy="onboarding_offer_loan_purpose"
        options={options}
        onChange={onChangePurposeHandler}
        value={form.purposeOfLoan}
        borderless
        type={RadioButtonsType.RadioButtonsOnLeftSide}
        borderBottom
      />

      {validationErrorShouldBeShown && (
        <ValidationErrorMessage>
          {t('ValidationErrors.LoanPurpose')}
        </ValidationErrorMessage>
      )}
      {form.purposeOfLoan === LoanPurposes.Other && (
        <InputField
          name="SpecifyLoanPurpose"
          dataCy="other_loan_purpose"
          onChange={onChangeOtherPurposeHandler}
          onBlur={onBlurOtherPurposeHandler}
          validationType="LoanPurpose"
          placeholder={t('Onboarding.LoanPurposeInputPlaceholder') as string}
          maxLength={otherPurposeReasonTextMaxCharLimit}
          value={form.loanPurposeManual || ''}
          ref={otherLoanPurposeInput}
          characterCounter
        />
      )}
    </>
  );
};

export default LoanPurpose;
