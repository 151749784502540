import React, { useContext } from 'react';
import { Button, Group, Stack, Typography } from '@qred/components-library';
import { useDispatch, useSelector } from 'react-redux';
import { formatValueNumbersOnly } from '~/helpers/formatters.helper';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import InputField from '~/components/shared/Input/InputField';
import { ValidationContext } from '~/components/hoc/withValidation';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import { theme } from '~/styles/themes';
import { useCardOnboardingOfferSelectors } from '~/store/selectors/cardOnboardingOffer.selector';
import {
  updateApplicant,
  updateForm,
} from '~/store/slices/cardOnboardingOffer.slice';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';

interface OwnershipPercentagePanelProps {
  onSharesChange: (percentage: string) => void;
}

const OwnershipPercentagePanel = ({
  onSharesChange,
}: OwnershipPercentagePanelProps) => {
  const dispatch = useDispatch();
  const {
    cardOnboardingOffer: {
      form: { applicantOwns100PercentOfShares, applicant },
    },
  } = useSelector((state: RootState) => state);

  const t = useTranslate();
  const {
    applicantHasAnswered100PercentOwnershipQuestion,
    companyIsStockCompany: show100PercentOwnershipQuestion,
  } = useCardOnboardingOfferSelectors();

  const validationContext = useContext(ValidationContext);

  const onOwn100PercentChange = (own100Percent: boolean) => {
    if (applicantOwns100PercentOfShares !== own100Percent) {
      onSharesChange(own100Percent ? '100' : '');
      dispatch(updateForm({ applicantOwns100PercentOfShares: own100Percent }));
      dispatch(updateApplicant({ percentage: own100Percent ? '100' : '' }));

      dispatch(
        pushToGtmCardOnboardingAction({
          actionName: `card_offer_applicant_100_percentage_ownership_${
            own100Percent ? 'yes' : 'no'
          }_selected`,
        })
      );
    }
  };

  const onApplicantSharesChange = (percentage: string) => {
    onSharesChange(percentage);
    dispatch(
      updateApplicant({ percentage: formatValueNumbersOnly(percentage) })
    );

    if (percentage === '100') {
      onOwn100PercentChange(true);
    }
  };
  const onApplicantSharesBlur = () => {
    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: 'card_offer_applicant_percentage_change',
      })
    );
  };

  const showPercentageInput =
    (applicantHasAnswered100PercentOwnershipQuestion &&
      !applicantOwns100PercentOfShares) ||
    !show100PercentOwnershipQuestion;

  return (
    <Stack mt={20}>
      {show100PercentOwnershipQuestion && (
        <>
          <Typography>
            {t('OnboardingOffer.YourOwnershipPanelDescription')}
          </Typography>
          <Group direction="row" justify="flex-start">
            <Button
              onClick={() => onOwn100PercentChange(true)}
              variant="secondary"
              data-active={applicantOwns100PercentOfShares}
              style={{
                border: `border: 0.093em solid ${theme.colors.midwayPrimary}`,
              }}
            >
              {t('Yes')}
            </Button>
            <Button
              onClick={() => onOwn100PercentChange(false)}
              variant="secondary"
              data-active={applicantOwns100PercentOfShares === false}
            >
              {t('No')}
            </Button>
          </Group>
        </>
      )}

      {showPercentageInput && (
        <Stack mt="0.5" spacing="xs">
          <InputField
            name="applicantPercentage"
            dataCy="onboarding_offer_ownership_percentage"
            label={t('OnboardingOffer.YourOwnershipPanelInputLabel') as string}
            onBlur={onApplicantSharesBlur}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onApplicantSharesChange(e.currentTarget.value)
            }
            validationType="PercentageShareFormat"
            value={applicant.percentage || ''}
            rightText="%"
            maxLength={3}
            inputMode="numeric"
            thin
          />
          {validationContext.validationErrors
            .applicantOwnershipPercentageMoreThan100 && (
            <ValidationErrorMessage>
              {t('OnboardingOffer.TotalOwnershipSharesMoreThan100')}
            </ValidationErrorMessage>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default OwnershipPercentagePanel;
