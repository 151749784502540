import { createSlice } from '@reduxjs/toolkit';
import { contractsApi } from '~/store/apis/endpoints/myPages/contracts/contracts.api';

interface DashboardState {
  hasPreviousLoans: boolean;
}

const initialState: DashboardState = {
  hasPreviousLoans: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      contractsApi.endpoints.getContracts.matchFulfilled,
      (state, { payload }) => ({
        ...state,
        hasPreviousLoans: payload.previousLoans?.length > 0,
      })
    );
  },
});

export default dashboardSlice.reducer;
