import React, { useMemo } from 'react';
import { Select } from '@qred/components-library';
import { useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';

const percentageRangeOptionsDefault = [
  {
    label: 'OnboardingOffer.Interval1',
    value: '1',
  },
  {
    label: 'OnboardingOffer.Interval2',
    value: '26',
  },
  {
    label: 'OnboardingOffer.Interval3',
    value: '51',
  },
  {
    label: 'OnboardingOffer.Interval4',
    value: '76',
  },
  {
    label: 'OnboardingOffer.Interval5',
    value: '100',
  },
];

interface OwnershipPercentageSelectProps {
  error: string | undefined;
  onChange: (value: { label: string; value: string }) => void;
  value: string;
}

const OwnershipPercentageSelect = ({
  error,
  onChange,
  value,
}: OwnershipPercentageSelectProps) => {
  const {
    intl: { language },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();

  const percentageRangeOptions = useMemo(
    () =>
      percentageRangeOptionsDefault.map((item) => ({
        ...item,
        label: translate(item.label) as string,
      })),
    [translate, language]
  );

  return (
    <Select
      label={translate('OnboardingOffer.SharesPercentages')}
      value={percentageRangeOptions.find((item) => item.value === value)}
      error={error}
      onChange={onChange}
      options={percentageRangeOptions}
    />
  );
};

export default OwnershipPercentageSelect;
