import posthog from 'posthog-js';
import { AppThunk, GetStateFunc } from '~/store/types/sharedTypes';
import { Dispatch } from 'redux';
import { StepStatus } from '~/enums';

interface EventBase {
  stepStatus?: StepStatus;
  actionName?: string;
}

export interface CapturePostHogEventProps extends EventBase {
  eventName: string;
  properties?: Record<string, any>;
}

export const capturePostHogEvent = ({
  eventName,
  properties,
}: CapturePostHogEventProps): AppThunk => (
  _dispatch: Dispatch,
  getState: GetStateFunc
) => {
  const {
    account: { selectedCompany, user },
    intl: { market },
  } = getState();

  const _eventName = `myqred_${eventName}`;

  const eventProperties = {
    market,
    userId: user.id,
    companyId: selectedCompany.id,
    ...properties,
  };

  posthog.capture(_eventName, eventProperties);
};
