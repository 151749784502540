import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { RootState } from '~/store/types/sharedTypes';
import { compose } from 'redux';
import Logo from '~/components/shared/LogoOld/LogoOld';
import Button, { ButtonType } from '~/components/shared/Button/button';
import {
  BodyContainer,
  MainContainer,
  PageFooter,
} from '~/styles/BaseStyledComponents';
import withValidation, {
  WithValidationProps,
} from '~/components/hoc/withValidation';
import withGtmEvent from '~/components/hoc/withGtmEvent';
import {
  fetchCardOfferData,
  setCardOfferUserData,
} from '~/store/actions/cardOfferActions';
import DownArrow from '~/components/shared/DownArrow/DownArrow';
import PageHeader from '../../components/shared/PageHeader/PageHeader';
import PageTitle from '../../components/shared/PageTitle/PageTitle';
import CreditCardIntroductionCard from './components/CreditCardIntroductionCard';
import CreditCardTermsAndConditionsCard from './components/CreditCardTermsAndConditionsCard';
import CardAdditionalSignersCard from './components/CreditCardAdditionalSignersCard';
import CreditCardUsageCard from './components/CreditCardUsageCard';
import CreditCardDetailsCard from './components/CreditCardDetailsCard';
import CreditCardInvoiceInfoCard from './components/CreditCardInvoiceInfoCard';
import CreditCardBeneficialOwnersCard from './components/CreditCardBeneficialOwnersCard';
import CreditCardPurposeCard from './components/CreditCardPurposeCard';

const CardOffer: React.FC<WithValidationProps & RouteComponentProps<any>> = (
  props
) => {
  const companyId = Number(props.match.params.companyId);

  /* Selectors */

  /** *********************************************************************************************

  ATTENTION: YOU MUST NOT ADD ANY OTHER SELECTOR TO THIS COMPONENT OR ANY OF ITS SUB-COMPONETS

  ********************************************************************************************** */

  const cardOffer = useSelector((state: RootState) =>
    state.cardOffer.overview.find((o) => o.companyId === companyId)
  );
  // We need this from userData not overview cause it will be changed by user actions
  const {
    hasAcceptedTerms,
    hasAcceptedPersonalGuarantee,
    isPersonalGuaranteeRequired,
    cardOfferAlreadySigned,
  } = useSelector((state: RootState) => state.cardOffer.userData);
  const isTokenSet = useSelector(
    (state: RootState) => state.globalError.isTokenSet
  );
  const market = useSelector((state: RootState) => state.intl.market);
  const { isSubmitting } = useSelector((state: RootState) => state.cardOffer);

  const additionalSigners = cardOffer && cardOffer.additionalSigners;

  /* Other hooks */
  const history = useHistory();
  const t = useTranslate();
  const dispatch = useDispatch();

  /* Effects */
  /*
    This effect is a bit special!
    All other APIs are called in the ProtectedContent component with personal number gotten from Auth0. ProtectedContent component is always executed when
    when a page is open by a deeplink. For example if we link to https://mypages-test.qred.com/loans/42 loan API is already called then using the clientId in
    the url we can select the right loan object fron the list of loans.
    But this is not the case for card offer, we call the card offer API from Home component which means opening this link(https://mypages-test.qred.com/cardoffer/8)
    in the browser does not load the data but data is only loaded when user visits the Home component. Therefore we need to call the API and fetch the data
    here, but in order to that we need to wait until the token is available in the localStorage. This is not the case for other pages like loan page because in the
    ProtectedContent component we wait until the token is ready and then call the API. Moreover, if data is already available - meaning the user is coming
    from Home not a deeplink - we don't want to call the API again.
  */
  useEffect(() => {
    if (companyId && isTokenSet && !cardOffer) {
      dispatch(fetchCardOfferData([companyId]));
    }
  }, [companyId, isTokenSet, cardOffer]);

  // It is important that we watch cardOffer from 'overview' and not from 'userData', otherwise all subsequent changes
  // in userdata will cause this effect to run and dispatch the action over and over again
  useEffect(() => {
    if (companyId && cardOffer) {
      dispatch(setCardOfferUserData(cardOffer));
    }
  }, [companyId, cardOffer]);

  /* Handlers */
  const onBackButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    history.push('/');
  };

  return (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
        <PageTitle mb={2}>{t('ConfirmQredCard')}</PageTitle>
      </PageHeader>
      {cardOffer && market && companyId && (
        <BodyContainer>
          <CreditCardIntroductionCard companyName={cardOffer.companyName} />
          <DownArrow />
          <CreditCardDetailsCard
            companyName={cardOffer.companyName}
            offeredCreditLimit={cardOffer.offeredCreditLimit}
            isSigner={false}
          />
          <DownArrow />
          <CreditCardUsageCard interest={cardOffer.interest} market={market} />
          <DownArrow />
          <CreditCardInvoiceInfoCard />
          <DownArrow />
          {additionalSigners && additionalSigners.length > 0 && (
            <>
              <CardAdditionalSignersCard />
              <DownArrow />
            </>
          )}
          <CreditCardBeneficialOwnersCard companyType={cardOffer.companyType} />
          {cardOffer.companyType !== 'EF' && <DownArrow />}
          <CreditCardPurposeCard />
          <DownArrow />
          <CreditCardTermsAndConditionsCard
            isPublic={false}
            applicationUuid={cardOffer.applicationUuid}
            market={market}
            clientId={companyId}
            hasAcceptedTerms={hasAcceptedTerms}
            hasAcceptedPersonalGuarantee={hasAcceptedPersonalGuarantee}
            isPersonalGuaranteeRequired={isPersonalGuaranteeRequired}
            isSubmitting={isSubmitting}
          />
        </BodyContainer>
      )}
      <PageFooter>
        <Button type={ButtonType.Outlined} onClick={onBackButtonClick}>
          {t('BackButton')}
        </Button>
      </PageFooter>
    </MainContainer>
  );
};

export default compose(withGtmEvent, withValidation, withRouter)(CardOffer);
