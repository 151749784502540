import React, { useContext, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormStatus, ValidationContext } from '~/components/hoc/withValidation';
import Button, { ButtonType } from '~/components/shared/Button/button';
import CheckBox from '~/components/shared/CheckBox/CheckBox';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { Typography, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { fetchGuarantorsAndBoardMembers } from '~/store/actions/onboardingActions';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { BaseArrangement, ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import useModals from '~/hooks/useModals';
import AddOrEditGuarantorModalBody from '~/pages/Onboarding/components/SelectGuarantor/AddOrEditGuarantorModalBody';
import { ClientType, CountryCode, FlexContentPlacement } from '~/enums';
import Group from '~/components/shared/Layout/Group';
import { companyIsSoleTrader } from '~/constants/markets';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { onChangeInputEvent } from '~/types/types';
import useDateFormatter from '~/hooks/useDateFormatter';
import {
  getGuarantorContentByMarket,
  getGuarantorText,
} from '~/helpers/onboarding.helper';
import { updateForm } from '~/store/slices/onboardingApplication.slice';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { CheckboxWrapper } from './SelectGuarantor.styled';
import { ILoanOnboardingApplicationGuarantor } from '~/interfaces/LoanOnboardingApplication';

const SelectGuarantor = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const modals = useModals();

  const formatDate = useDateFormatter();

  const {
    marketsHasAddGuarantorButtonNonFooter,
    marketHasGuarantorSubheader,
  } = useOnboardingMarketConfig();

  const validationContext = useContext(ValidationContext);

  const {
    onboardingApplication: { form, apiStatus },
    intl: { market },
    matchedMedia,
  } = useSelector((state: RootState) => state);

  const companyHasBoardMembers = !companyIsSoleTrader(market, form.companyType);
  const guarantorApiHasNotBeenCalled = apiStatus.guarantors === ApiStatus.Idle;

  const shouldCallGuarantorApi =
    form.organizationNumber &&
    guarantorApiHasNotBeenCalled &&
    (companyHasBoardMembers || form.clientType === ClientType.RLC);

  const shouldNotCallGuarantorApi =
    market === CountryCode.BE && form.clientType !== ClientType.RLC;

  /**
   * Sole traders do not have board members, but they can have existing guarantors
   * if they are a RLC
   */
  useEffect(() => {
    if (shouldNotCallGuarantorApi) {
      return;
    }
    if (shouldCallGuarantorApi) {
      dispatch(fetchGuarantorsAndBoardMembers());
    }
  }, [dispatch, shouldCallGuarantorApi, shouldNotCallGuarantorApi]);

  const handleOpenAddOrEditGuarantorModal = (
    guarantor?: ILoanOnboardingApplicationGuarantor
  ) => {
    validationContext.setFormStatus(FormStatus.IDLE);
    const modalTitleKey = getGuarantorText(guarantor);

    modals.openModal({
      id: 'addOrEditGuarantor-modal',
      header: (
        <Typography size="xl" weight={600} lineHeight="xxl">
          {t(`Onboarding.${modalTitleKey}`)}
        </Typography>
      ),
      children: <AddOrEditGuarantorModalBody initialGuarantor={guarantor} />,
      size: 'md',
      fullScreen: matchedMedia.smallScreenPortrait,
      fixedHeaderAndFooter: true,
      hasOwnFooter: true,
      filledBackground: true,
    });

    dispatch(
      pushToGtmOnboardingAction({
        actionName: `${
          guarantor ? 'open_edit_guarantor_modal' : 'open_add_guarantor_modal'
        }`,
      })
    );
  };

  const handleOnChange = (
    event: onChangeInputEvent,
    guarantor: ILoanOnboardingApplicationGuarantor
  ) => {
    if (!guarantor.email || !guarantor.phone) {
      // open edit modal
      handleOpenAddOrEditGuarantorModal(guarantor);
      return;
    }
    const checkedGuarantors = (
      form.guarantors || []
    ).map((g: ILoanOnboardingApplicationGuarantor) =>
      g.guarantorId === guarantor.guarantorId
        ? { ...g, isChecked: event.target.checked }
        : { ...g }
    );

    dispatch(
      updateForm({
        guarantors: checkedGuarantors,
      })
    );
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'select_guarantor',
        stepActionNumber: 0.1,
      })
    );
  };

  return apiStatus.guarantors !== ApiStatus.Started ? (
    <>
      <BaseArrangement mb={2}>
        <Typography>
          {t(`Onboarding.${getGuarantorContentByMarket(form, market)}`)}
        </Typography>
      </BaseArrangement>
      {marketHasGuarantorSubheader && (
        <BaseArrangement mb={2}>
          <Typography>
            {t(`Onboarding.AddAdditionalGuarantorsSubheader`)}
          </Typography>
        </BaseArrangement>
      )}
      {form.guarantors &&
        form.guarantors.map(
          (guarantor: ILoanOnboardingApplicationGuarantor) => (
            <CheckboxWrapper key={guarantor.guarantorId}>
              <CheckBox
                checked={guarantor.isChecked || false}
                onChange={(event) => handleOnChange(event, guarantor)}
                name={guarantor.personalNumber}
                disabled={guarantor.latestGuarantor}
                explanation={
                  guarantor.latestGuarantor
                    ? (t('Onboarding.PreSelectedGuarantor') as string)
                    : ''
                }
                align={FlexContentPlacement.Center}
              >
                <Stack spacing="xs">
                  <Typography>{guarantor.fullName}</Typography>
                  {(!!guarantor.maskedPersonalNumber ||
                    !!guarantor.personalNumber) && (
                    <Typography>
                      {guarantor.personalNumber ||
                        guarantor.maskedPersonalNumber}
                    </Typography>
                  )}

                  {(!!guarantor.dateOfBirth ||
                    !!guarantor.maskedDateOfBirth) && (
                    <Typography>
                      {guarantor.dateOfBirth
                        ? formatDate(guarantor.dateOfBirth, {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })
                        : guarantor.maskedDateOfBirth}
                    </Typography>
                  )}

                  {!guarantor.latestGuarantor && (
                    <Button
                      dataCy="select_guarantor_step_edit_button"
                      onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        handleOpenAddOrEditGuarantorModal(guarantor);
                      }}
                      type={ButtonType.Text}
                    >
                      {t(`Onboarding.${getGuarantorText(guarantor)}`)}
                    </Button>
                  )}
                </Stack>
              </CheckBox>
            </CheckboxWrapper>
          )
        )}

      {marketsHasAddGuarantorButtonNonFooter && (
        <Button
          type={ButtonType.Tertiary}
          dataCy="select_guarantor_step_add_button"
          mt={1.5}
          onClick={() => handleOpenAddOrEditGuarantorModal()}
        >
          <Group spacing="sm" align="center" justify="center">
            <Icon src={SvgSrc.Plus} size={ComponentSize.Small} />
            <Typography>{t('Onboarding.AddNewGuarantor')}</Typography>
          </Group>
        </Button>
      )}
    </>
  ) : (
    <SpinnerCircular color={theme.colors.secondaryGray} />
  );
};

export default SelectGuarantor;
