import { DefaultTheme, SharedDefaultTheme } from 'styled-components';

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const theme: Partial<DefaultTheme> & SharedDefaultTheme = {
  borderRadius: '5px',
  spacing: {
    xs: '0.2em',
    sm: '0.5em',
    md: '1em',
    lg: '1.5em',
    xl: '1.7em',
    xxl: '1.875em',
  },
  modal: {
    width: {
      xs: '20em',
      sm: '25em',
      md: '35em',
      lg: '45em',
      xl: '70em',
      xxl: '80em',
    },
  },
  // All colors and color names will be gradually change to names from brand manual
  colors: {
    primaryGreen: '#2FD67A',
    primaryGray: '#e5e5e5',
    secondaryBlack: '#5A5A5A',
    newSecondaryGray: '#757575',
    secondaryGray: '#AAAAAA',
    secondaryLightGray: '#D0D0D0',
    secondaryGreen: '#8CDDBC',
    hoverGreen: '#46EA7D',
    hoverDark: '#2d2d2d',
    tertiaryGray: '#F6F6F6',
    white: '#ffffff',
    secondary: '#214749',
    warning: '#F5BB58',
    error: '#EB4C6A',
    accent: '#1387AC',
    document: '#F8F9F5',
    backdrop: '#f2f3f0',
    active: '#0B9358',
    neutral: '#A6A09B',
    dark: '#444444',
    secondaryActive: '#1A3637',
    secondaryHover: '#2C5D60',
    secondaryDisabled: '#96A5A6',
    secondaryFocus: '#2C5D60',
    hoverSecondary: '#2C5D601A',
    neutral2: '#FDFDFD',
    neutral10: '#F7F8F8',
    neutral12: '#F1F3F3',
    neutral16: '#E6EAEA',
    midwayBlack: '#1C1C1C',
    midwaySuccess: '#006611',
    midwayNeutral: '#ECEFEF',
    midwaySecondary: '#6B7C85',
    midwaySecondary200: '#B6BEC3',
    midwayLabelDark: '#434343',
    midwayLinkLight: '#3898FF',
    midwayLinkDark: '#005DC0',
    midwayPrimary: '#1F292E',
    midwayTextDark75: '#A2A2A2',
  },
};
