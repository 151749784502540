import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketCurrency } from '~/helpers/formatters.helper';
import useTranslate from '~/hooks/useTranslate';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';
import { RootState } from '~/store/types/sharedTypes';
import {
  Card,
  Group,
  NumberInput,
  Slider,
  Stack,
  Typography,
} from '@qred/components-library';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { useCurrencyFormatter } from '~/hooks/formatter/useCurrencyFormatter';
import { ValidationContext } from '~/components/hoc/withValidation';
import { findClosestValueInArray, generateSliderValues } from '~/helpers/utils';

const LoanOfferAdjustmentForm = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const currencyFormatter = useCurrencyFormatter();
  const currency = getMarketCurrency();
  const validationContext = useContext(ValidationContext);

  const {
    onboardingOffer: { overview, form },
  } = useSelector((state: RootState) => state);

  const loanAmountSliderStep = currency.currency === 'SEK' ? 1000 : 100;
  const loanTermSliderStep = 1;
  const loanAmountInputLabel =
    overview.type === 'CREDIT_LIMIT_CHANGE'
      ? translate('OnboardingOffer.PayoutAmountLabel', {
          currency: currency.unit,
        })
      : translate('OnboardingOffer.LoanAmountLabel', {
          currency: currency.unit,
        });

  useEffect(() => {
    if (form.payoutAmount < overview.minPayoutAmount) {
      validationContext.addPropertyToValidationErrors('LoanAmountTooLow');
    } else if (form.payoutAmount > overview.maxPayoutAmount) {
      validationContext.addPropertyToValidationErrors('LoanAmountTooHigh');
    }

    if (form.loanPeriod < overview.minTerm) {
      validationContext.addPropertyToValidationErrors('LoanTermTooLow');
    } else if (form.loanPeriod > overview.maxTerm) {
      validationContext.addPropertyToValidationErrors('LoanTermTooHigh');
    }

    return () => {
      validationContext.removePropertyFromValidationErrors('LoanAmountTooLow');
      validationContext.removePropertyFromValidationErrors('LoanAmountTooHigh');
      validationContext.removePropertyFromValidationErrors('LoanTermTooLow');
      validationContext.removePropertyFromValidationErrors('LoanTermTooHigh');
    };
  }, [form.payoutAmount, form.loanPeriod]);

  const { loanAmountError, loanTermError } = useMemo(() => {
    let _loanAmountError = '';
    let _loanTermError = '';

    if (validationContext.validationErrors.LoanAmountTooLow) {
      _loanAmountError = translate('ValidationErrors.LoanAmountTooLow', {
        minAmount: currencyFormatter.format(overview.minPayoutAmount),
      }) as string;
    } else if (validationContext.validationErrors.LoanAmountTooHigh) {
      _loanAmountError = translate('ValidationErrors.LoanAmountTooHigh', {
        maxAmount: currencyFormatter.format(overview.maxPayoutAmount),
      }) as string;
    }

    if (validationContext.validationErrors.LoanTermTooLow) {
      _loanTermError = translate('ValidationErrors.LoanTermTooLow', {
        minTerm: overview.minTerm,
      }) as string;
    } else if (validationContext.validationErrors.LoanTermTooHigh) {
      _loanTermError = translate('ValidationErrors.LoanTermTooHigh', {
        maxTerm: overview.maxTerm,
      }) as string;
    }

    return {
      loanAmountError: _loanAmountError,
      loanTermError: _loanTermError,
    };
  }, [validationContext.validationErrors]);

  const onAmountChange = (payoutAmount: number) => {
    dispatch(
      updateForm({
        payoutAmount,
      })
    );
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'loan_offer_amount_change',
      })
    );
  };

  const onTermsChange = (value: number) => {
    dispatch(
      updateForm({
        loanPeriod: value,
      })
    );
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'loan_offer_terms_change',
      })
    );
  };

  const { sliderStep, generatedSliderValues } = useMemo(() => {
    const values = generateSliderValues(
      overview.minPayoutAmount,
      overview.maxPayoutAmount,
      loanAmountSliderStep
    );

    return {
      sliderStep: 100 / (values.length - 1),
      generatedSliderValues: values,
    };
  }, [
    overview.minPayoutAmount,
    overview.maxPayoutAmount,
    loanAmountSliderStep,
  ]);

  const currentSliderValue = useMemo(() => {
    let indexOfAmount = generatedSliderValues.indexOf(form.payoutAmount);
    if (indexOfAmount === -1) {
      const closestValue = findClosestValueInArray(
        generatedSliderValues,
        form.payoutAmount
      );
      indexOfAmount = generatedSliderValues.indexOf(closestValue);
    }

    return (indexOfAmount / (generatedSliderValues.length - 1)) * 100;
  }, [form.payoutAmount, generatedSliderValues]);

  const handleLoanAmountChange = (sliderValue: number) => {
    const index = Math.round(
      (sliderValue / 100) * (generatedSliderValues.length - 1)
    );
    const mappedValue = generatedSliderValues[index];
    onAmountChange(mappedValue);
  };

  return (
    <Card color="neutral.10" border={false}>
      <Stack spacing={'xxl'}>
        {!overview.amountCannotBeChanged && (
          <Stack spacing={'xl'} data-testid="adjust-loan-amount-section">
            <NumberInput
              placeholder={currencyFormatter.format(
                overview.defaultPayoutAmount
              )}
              value={form.payoutAmount}
              size="lg"
              onChange={onAmountChange}
              formatter={(val) => currencyFormatter.format(+val)}
              precision={2}
              step={loanAmountSliderStep}
              label={loanAmountInputLabel}
              noClampOnBlur
              error={loanAmountError}
              data-testid="loan-amount-input"
              min={0}
            />
            <Stack spacing={'sm'}>
              <Slider
                value={currentSliderValue}
                min={0}
                max={100}
                step={sliderStep}
                precision={2}
                onChange={handleLoanAmountChange}
              />
              <Group justify="space-between">
                <Typography
                  size="sm"
                  color="textDark.200"
                  data-testid="loan-amount-min"
                >
                  {currencyFormatter.format(overview.minPayoutAmount)}
                </Typography>
                <Typography
                  size="sm"
                  color="textDark.200"
                  data-testid="loan-amount-max"
                >
                  {currencyFormatter.format(overview.maxPayoutAmount)}
                </Typography>
              </Group>
            </Stack>
          </Stack>
        )}
        <Stack spacing={'xl'} data-testid="adjust-loan-term-section">
          <NumberInput
            placeholder={String(overview.defaultTerm)}
            value={form.loanPeriod}
            size="lg"
            onChange={onTermsChange}
            type={'number'}
            label={translate('OnboardingOffer.LoanTermLabel')}
            error={loanTermError}
            data-testid="loan-term-input"
            min={overview.minTerm}
            max={overview.maxTerm}
          />
          <Stack spacing={'sm'}>
            <Slider
              min={overview.minTerm}
              max={overview.maxTerm}
              value={form.loanPeriod}
              onChange={onTermsChange}
              step={loanTermSliderStep}
            />
            <Group justify="space-between">
              <Typography
                size="sm"
                color="textDark.200"
                data-testid="loan-term-min"
              >
                {overview.minTerm} {translate('Months')}
              </Typography>
              <Typography
                size="sm"
                color="textDark.200"
                data-testid="loan-term-max"
              >
                {overview.maxTerm} {translate('Months')}
              </Typography>
            </Group>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default LoanOfferAdjustmentForm;
