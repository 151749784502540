import React from 'react';
import { useSelector } from 'react-redux';
import { ContactNumbers, ContactNumbersFormatted } from '~/constants/markets';
import { RootState } from '~/store/types/sharedTypes';
import {
  BodyContainer,
  ComponentSize,
  MainContainer,
  PageFooter,
} from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import Button, { ButtonType } from '../Button/button';
import Icon, { SvgSrc } from '../Icon/Icon';
import Logo from '../LogoOld/LogoOld';
import PageHeader from '../PageHeader/PageHeader';
import PageTitle from '../PageTitle/PageTitle';

const ErrorFallback = () => {
  const market = useSelector((state: RootState) => state.intl.market);

  return (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
        <PageTitle mb={6}>Something went wrong!</PageTitle>
      </PageHeader>
      <BodyContainer>
        <Button
          mb={1}
          type={ButtonType.Filled}
          link={`tel:${ContactNumbers[market]}`}
        >
          <Icon
            src={SvgSrc.Phone}
            size={ComponentSize.Small}
            mr={0.5}
            color={theme.colors.white}
          />
          {ContactNumbersFormatted[market]}
        </Button>
      </BodyContainer>
      <PageFooter />
    </MainContainer>
  );
};

export default ErrorFallback;
