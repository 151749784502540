import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import { theme } from '~/styles/themes';
import { Typography } from '~/components/shared/Typography/Typography';
import { BaseArrangement } from '~/styles/BaseStyledComponents';
import Stack from '../../Layout/Stack';

interface CustomGlobalErrorMessageProps {
  title: string;
  description?: string;
}

const CustomGlobalError = ({
  title,
  description,
}: CustomGlobalErrorMessageProps) => {
  const translate = useTranslate();

  return (
    <BaseArrangement mb={4}>
      <Stack spacing="sm">
        <Typography
          fontSize={1}
          color={theme.colors.dark}
          fontWeight={600}
          lineHeight={1.5}
          centerAlign
          element="h4"
        >
          {translate(title)}
        </Typography>

        {description && (
          <Typography
            fontSize={1}
            lineHeight={1.4}
            color={theme.colors.dark}
            element="p"
            centerAlign
          >
            {translate(description)}
          </Typography>
        )}
      </Stack>
    </BaseArrangement>
  );
};

export default CustomGlobalError;
