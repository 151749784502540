import React from 'react';
import styled from 'styled-components';
import { theme } from '~/styles/themes';

const SpinnerOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  overflow: hidden;
`;

const SpinnerInnerContainer = styled.div`
  font-size: 1em;

  display: inline-block;
  position: relative;
  width: 8em;
  height: 8em;

  & div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 4em 4em;
  }

  & div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 0.7em;
    height: 0.7em;
    border-radius: 50%;
    background: ${(props) => props.color || theme.colors.secondaryGray};
    margin: -0.4em 0 0 -0.4em;
  }

  & div:nth-child(1) {
    animation-delay: -0.036s;
  }

  & div:nth-child(1):after {
    top: 6.3em;
    left: 6.3em;
  }

  & div:nth-child(2) {
    animation-delay: -0.072s;
  }

  & div:nth-child(2):after {
    top: 6.8em;
    left: 5.6em;
  }

  & div:nth-child(3) {
    animation-delay: -0.108s;
  }

  & div:nth-child(3):after {
    top: 7.1em;
    left: 4.8em;
  }

  & div:nth-child(4) {
    animation-delay: -0.144s;
  }

  & div:nth-child(4):after {
    top: 7.2em;
    left: 4em;
  }

  & div:nth-child(5) {
    animation-delay: -0.18s;
  }

  & div:nth-child(5):after {
    top: 7.1em;
    left: 3.2em;
  }

  & div:nth-child(6) {
    animation-delay: -0.216s;
  }

  & div:nth-child(6):after {
    top: 6.8em;
    left: 2.4em;
  }

  & div:nth-child(7) {
    animation-delay: -0.252s;
  }

  & div:nth-child(7):after {
    top: 6.3em;
    left: 1.7em;
  }

  & div:nth-child(8) {
    animation-delay: -0.288s;
  }

  & div:nth-child(8):after {
    top: 5.6em;
    left: 1.2em;
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerCircular: React.FC<{ color?: string }> = ({ color }) => (
  <SpinnerOuterContainer
    data-testid="loading-spinner"
    data-cy="loading_spinner"
  >
    <SpinnerInnerContainer color={color}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </SpinnerInnerContainer>
  </SpinnerOuterContainer>
);

export default SpinnerCircular;
