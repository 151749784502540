import { Stack } from '@qred/components-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { FlowTypes } from '~/enums';
import { orgNrAreTheSame } from '~/helpers/utils';
import { useShouldDisplayTestVariant } from '~/hooks/useShouldDisplayTestVariant';
import { setCompanyIsNew } from '~/store/slices/onboardingApplication.slice';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import CompanySearch from './components/CompanySearch';
import CompanySearchVariant from './components/CompanySearch.variant';
import CompanySelectorRadioButtons from './components/CompanySelectorRadioButtons';

const CompanySelector = () => {
  const dispatch = useDispatch();

  const showTestVariantOfCompanySearch = useShouldDisplayTestVariant([
    'LoanOnboardingApplication_CompanySelectorInput_IncreasedConversion_DK',
    'LoanOnboardingApplication_CompanySelectorInput_IncreasedConversion_FI',
  ]);

  const {
    form,
    apiStatus,
    overview,
    companyIsNew,
    flowType,
    userId,
  } = useSelector((state: RootState) => state.onboardingApplication);

  // IMPORTANT TODO: we need to set companyIsNew to true if user in auth flow has
  // new company and reloads page and goes back to company selector
  useEffect(() => {
    const isAuthenticatedNlc =
      flowType === FlowTypes.Authenticated &&
      form.organizationNumber &&
      apiStatus.clients === ApiStatus.Completed &&
      !overview.clients.some((client) =>
        orgNrAreTheSame(client.company_no, form.organizationNumber)
      );

    if (flowType === FlowTypes.Manual || !userId || isAuthenticatedNlc) {
      dispatch(setCompanyIsNew(true));
    }
  }, []);

  return (
    <>
      {apiStatus.clients !== ApiStatus.Started ? (
        <Stack spacing="xxl" mt={'md'}>
          {apiStatus.clients === ApiStatus.Completed &&
            !!overview.clients?.length && (
              <Stack spacing="xs">
                <CompanySelectorRadioButtons clients={overview.clients} />
              </Stack>
            )}

          {companyIsNew &&
            (showTestVariantOfCompanySearch ? (
              <CompanySearchVariant />
            ) : (
              <CompanySearch />
            ))}
        </Stack>
      ) : (
        <SpinnerCircular color={theme.colors.secondaryGray} />
      )}
    </>
  );
};
export default CompanySelector;
