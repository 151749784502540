import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';
import { theme } from '~/styles/themes';

export const Bottom = styled.div`
  background-color: ${theme.colors.secondary};
  padding-bottom: 100px;
  position: relative;
`;

export const BottomInner = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media ${MediaQueries.tablet} {
    flex-direction: row;
    justify-content: center;
    padding-bottom: 70px;
  }
`;

export const HelpItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-wrap: wrap;

  @media ${MediaQueries.tablet} {
    flex-direction: row;
    gap: 40px;
  }
`;

/**
 * This div is to get rid of the unwanted gray line between top and bottom DIVs that appears
 * in some screen sizes.
 */
export const HackyOverlay = styled.div`
  background-color: ${theme.colors.white};
  width: 80%;
  max-width: 1200px;
  position: absolute;
  height: 2px;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
`;

export const HelpItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media ${MediaQueries.tablet} {
    text-align: center;
    flex-direction: column;
    flex: 1 1 0px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  border-radius: 50%;
  background-color: ${theme.colors.primaryGreen};
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const Top = styled.div`
  background-color: ${theme.colors.document};
`;

export const TopInner = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: ${theme.colors.white};
  padding: 30px 20px 0;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const TopInnerContent = styled.div`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
