import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { generateLocale } from '~/helpers/generators.helper';
import { logToSentry } from '~/helpers/loggers.helper';
import { RootState } from '~/store/types/sharedTypes';

const myQredLocaleParam = 'myQred_locale';

const useHubspotChat = () => {
  const { market, language } = useSelector((state: RootState) => state.intl);
  const history = useHistory();

  const setHubspotLocaleParam = () => {
    const locale = generateLocale(language, market);
    const url = new URL(window.location.href);
    url.searchParams.set(myQredLocaleParam, locale);
    history.replace({ search: url.search });
  };

  const removeHubspotLocaleParam = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete(myQredLocaleParam);
    history.replace({ search: url.search });
  };

  const openChat = () => {
    if (window.HubSpotConversations) {
      const { open, load, status } = window.HubSpotConversations.widget;
      if (status().loaded) {
        open();
      } else {
        setHubspotLocaleParam();
        load({ widgetOpen: true });
        removeHubspotLocaleParam();
      }
    } else {
      logToSentry(
        Error('window.HubSpotConversations is not defined'),
        'window.HubSpotConversations is not defined',
        { product: 'HubspotChat' }
      );
    }
  };

  const refreshChat = () => {
    if (window.HubSpotConversations) {
      const { refresh } = window.HubSpotConversations.widget;
      setHubspotLocaleParam();
      refresh();
      removeHubspotLocaleParam();
    }
  };

  return { openChat, setHubspotLocaleParam, refreshChat };
};

export default useHubspotChat;
