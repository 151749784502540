import React, { useEffect, useMemo } from 'react';
import withValidation from '~/components/hoc/withValidation';
import { useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import Card from '~/components/shared/Card/Card';
import Stepper from '~/components/shared/Stepper/Stepper';
import { ApiStatus, Language, RootState } from '~/store/types/sharedTypes';
import { FlexContentPlacement, GlobalErrorType, StepStatus } from '~/enums';
import { getMarketBasedOnHostname } from '~/helpers/utils';
import {
  MainOnboardingContainer,
  OnboardingStepsContainer,
} from '~/styles/OnboardingBaseStyledComponents';
import {
  defaultLanguageToMarket,
  setLanguage,
} from '~/store/slices/intl.slice';
import {
  goToPreviousStep,
  setApiStatus,
} from '~/store/slices/cardOnboardingOffer.slice';
import {
  fetchCardOfferData,
  postCardOnboardingOffer,
  postKYCAnswers,
} from '~/store/actions/cardOnboardingActions';
import { parseLanguage } from '~/helpers/market.helper';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { theme } from '~/styles/themes';
import { Typography } from '@qred/components-library';
import { Divider } from '@qred/qit-ui';
import useTranslate from '~/hooks/useTranslate';
import constVars, { V2 } from '~/constants/constVars';
import { toggleGlobalErrorOn } from '~/store/slices/globalError.slice';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';
import useGoToNextStep from './hooks/useGoToNextStep';
import OnboardingHeader from '../Onboarding/components/OnboardingNavbar/OnboardingNavbar';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import { useCardOnboardingOfferSelectors } from '~/store/selectors/cardOnboardingOffer.selector';

const CardOnboardingOffer = () => {
  const dispatch = useDispatch();

  const onNextStepClick = useGoToNextStep();
  const t = useTranslate();
  const clientId = Number(useParams<{ clientId: string }>().clientId);
  const isTokenSet = useSelector(
    (state: RootState) => state.globalError.isTokenSet
  );
  const {
    cardOnboardingOffer: { currentStep, apiStatus, overview },
    intl: { market, languageIsSet },
    matchedMedia,
  } = useSelector((state: RootState) => state);

  const { applicationUuid, isPersonalGuaranteeRequired } = overview;
  useEffect(() => {
    if (clientId && isTokenSet) {
      dispatch(fetchCardOfferData(clientId));
    }
  }, [clientId, isTokenSet]);

  const { stepConfig } = useCardOnboardingOfferSelectors();
  const currentStepConfig = stepConfig[currentStep - 1];

  // TODO: Break up into multiple functions for readability
  const confirmButtonHandler = async () => {
    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: 'confirm_button_pressed',
      })
    );

    await ((dispatch(postKYCAnswers(clientId)) as unknown) as Promise<void>);

    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: 'kyc_answers_submitted',
      })
    );

    if (applicationUuid && market && clientId) {
      const cardOfferPayload = {
        applicationUuid,
        market,
        clientId,
        isPublic: false,
      };

      if (isPersonalGuaranteeRequired) {
        dispatch(
          postCardOnboardingOffer(
            {
              ...cardOfferPayload,
            },
            (data: { signatureUrl: string }) => {
              if (data && data.signatureUrl) {
                window.location.assign(data.signatureUrl);
                dispatch(setApiStatus({ post: ApiStatus.Completed }));
              } else {
                // We shouldn't end up here, maybe we shoud log something to Sentry in this case?
                dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
                dispatch(setApiStatus({ post: ApiStatus.Failed }));
              }
            }
          )
        );
      } else {
        dispatch(
          postCardOnboardingOffer(
            {
              ...cardOfferPayload,
              apiVersion: V2,
            },
            (data: { signatureUrl: string }) => {
              if (data && data.signatureUrl === '') {
                window.location.assign(
                  `/${constVars.ROUTE_THANK_YOU_CONTINUE_IN_APP}`
                );
                dispatch(setApiStatus({ post: ApiStatus.Completed }));
              } else {
                // We shouldn't end up here, maybe we shoud log something to Sentry in this case?
                dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
                dispatch(setApiStatus({ post: ApiStatus.Failed }));
              }
            }
          )
        );
      }
    }
  };

  const stepIsLoading = useMemo(
    () =>
      apiStatus.post === ApiStatus.Started ||
      apiStatus.postKycAnswers === ApiStatus.Started,
    [apiStatus.post, apiStatus.postKycAnswers]
  );
  const onBackButtonClick = () => {
    dispatch(
      pushToGtmCardOnboardingAction({ actionName: 'back_button_pressed' })
    );
    dispatch(goToPreviousStep());
    dispatch(pushToGtmCardOnboardingAction({ stepStatus: StepStatus.Edit }));
  };
  useEffect(() => {
    const onboardingLanguage = parseLanguage(
      localStorage.getItem('onboardingLanguage')
    );
    if (onboardingLanguage) {
      dispatch(setLanguage(onboardingLanguage));
    } else if (!languageIsSet) {
      const marketFromDomain = getMarketBasedOnHostname();
      if (marketFromDomain) {
        dispatch(defaultLanguageToMarket(marketFromDomain));
      } else {
        dispatch(setLanguage(Language.English));
      }
    }
  }, [dispatch, languageIsSet]);

  const isLastStep = stepConfig.length === currentStep;

  const extraFooterContent = useMemo(() => {
    if (isLastStep) {
      return <TermsAndConditions />;
    }
    return undefined;
  }, [isLastStep]);

  return (
    <MainOnboardingContainer>
      <OnboardingHeader
        onBackClick={onBackButtonClick}
        showBackButton={currentStep > 1}
        fixedPosition={matchedMedia.smallScreenPortrait}
        filledBackground
        progressStepperDots={{
          steps: stepConfig,
          currentStep,
        }}
      />
      <OnboardingStepsContainer>
        {
          {
            [ApiStatus.Idle]: (
              <SpinnerCircular color={theme.colors.secondaryGray} />
            ),
            [ApiStatus.Started]: (
              <SpinnerCircular color={theme.colors.secondaryGray} />
            ),
            [ApiStatus.Completed]: (
              <Card
                isOnboarding
                justifyContent={FlexContentPlacement.SpaceBetween}
                grow={matchedMedia.smallScreenPortrait}
              >
                <Stepper
                  currentStepConfig={currentStepConfig}
                  stepIsLoading={stepIsLoading}
                  currentStep={currentStep}
                  footerCTAAction={
                    isLastStep ? confirmButtonHandler : onNextStepClick
                  }
                  footerCTALabel={
                    isLastStep
                      ? 'OnboardingCardOffer.Confirm'
                      : 'OnboardingOffer.Next'
                  }
                  extraFooterContent={extraFooterContent}
                />
                <Divider />
              </Card>
            ),
            [ApiStatus.Failed]: (
              <Typography weight={600} align="center" size="xxl">
                {t('OnboardingOffer.NoOfferFound')}
              </Typography>
            ),
          }[apiStatus.offer]
        }
      </OnboardingStepsContainer>
    </MainOnboardingContainer>
  );
};

export default compose(withValidation, withRouter)(CardOnboardingOffer);
