import { ShippingDetails, ShippingDetailsUserData } from '~/Interfaces';

export const SET_SHIPPING_DETAILS_DATA = 'SET_SHIPPING_DETAILS_DATA';
export const SET_SHIPPING_DETAILS_USER_DATA = 'SET_SHIPPING_DETAILS_USER_DATA';
export const UPDATE_SHIPPING_DETAILS_USER_DATA =
  'UPDATE_SHIPPING_DETAILS_USER_DATA';
export const SET_SET_PIN_STATUS = 'SET_SET_PIN_STATUS';
export const SET_IS_CARD_CREATED_STATUS = 'SET_IS_CARD_CREATED_STATUS';
export const SET_IS_CARD_CREATION_FAILED_STATUS =
  'SET_IS_CARD_CREATION_FAILED_STATUS';
export const SET_SHIPPING_DETAILS_HASH = 'SET_SHIPPING_DETAILS_HASH';

interface SetShippingDetailsData {
  type: typeof SET_SHIPPING_DETAILS_DATA;
  payload: ShippingDetails;
}
interface SetShippingDetailsUserData {
  type: typeof SET_SHIPPING_DETAILS_USER_DATA;
  payload: ShippingDetails;
}

interface UpdateShippingDetailsUserData {
  type: typeof UPDATE_SHIPPING_DETAILS_USER_DATA;
  payload: Partial<ShippingDetailsUserData>;
}

interface SetSetPinStatusAction {
  type: typeof SET_SET_PIN_STATUS;
  payload: boolean;
}

interface SetIsCardCreatedStatusAction {
  type: typeof SET_IS_CARD_CREATED_STATUS;
  payload: boolean;
}

interface SetIsCardCreationFailedStatusAction {
  type: typeof SET_IS_CARD_CREATION_FAILED_STATUS;
  payload: boolean;
}

interface SetShippingDetailsHashAction {
  type: typeof SET_SHIPPING_DETAILS_HASH;
  payload: string;
}

export type ShippingDetailsAction =
  | SetShippingDetailsData
  | SetShippingDetailsUserData
  | UpdateShippingDetailsUserData
  | SetSetPinStatusAction
  | SetIsCardCreatedStatusAction
  | SetIsCardCreationFailedStatusAction
  | SetShippingDetailsHashAction;
