import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { ClientType } from '~/enums';
import { orgNrAreTheSame } from '~/helpers/utils';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import {
  setClientId,
  setCompanyIsNew,
  setCompanyName,
  updateApplicantData,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import { BadgeWrapper } from '../CompanySelector.styled';
import { ILoanOnboardingApplicationClient } from '~/interfaces/LoanOnboardingApplication';
import { Badge, Group, Radio, Typography } from '@qred/components-library';

interface CompanySelectorRadioButtonsProps {
  clients: ILoanOnboardingApplicationClient[];
}

const CompanySelectorRadioButtons = ({
  clients,
}: CompanySelectorRadioButtonsProps) => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const { form, companyIsNew, currentApplicationOrgNumber } = useSelector(
    (state: RootState) => state.onboardingApplication
  );

  const updateOrganizationInRedux = (org: ILoanOnboardingApplicationClient) => {
    const isRLC = org.active;

    dispatch(
      updateForm({
        organizationNumber: org.company_no,
        companyType: org.company_type,
        companyTypeCategory: undefined,
        clientType: isRLC ? ClientType.RLC : ClientType.NLC,
      })
    );

    dispatch(
      updateApplicantData({
        email: isRLC && org.email ? org.email : '',
        phone: isRLC && org.phone ? org.phone : '',
      })
    );
  };

  useEffect(() => {
    if (clients.length && !form.organizationNumber) {
      updateOrganizationInRedux(clients[0]);
    }
  }, [clients]);

  useEffect(() => {
    if (
      !orgNrAreTheSame(form.organizationNumber, currentApplicationOrgNumber)
    ) {
      dispatch(setCompanyName(''));
    }
  }, [companyIsNew]);

  const onChange = (value: string) => {
    if (companyIsNew) {
      dispatch(setCompanyIsNew(false));
    }
    if (orgNrAreTheSame(form.organizationNumber, value)) return;
    const selectedClient = clients.find(
      (client: ILoanOnboardingApplicationClient) =>
        orgNrAreTheSame(client.company_no, value)
    );
    if (selectedClient) {
      updateOrganizationInRedux(selectedClient);
      if (selectedClient.client_id) {
        dispatch(setClientId(selectedClient.client_id));
      }
      dispatch(
        pushToGtmOnboardingAction({
          stepActionNumber: 0.1,
          actionName: 'company_change',
        })
      );
    }
  };

  const handleNewCompany = () => {
    dispatch(setCompanyIsNew(true));
    dispatch(
      updateForm({
        organizationNumber: '',
        clientType: ClientType.NLC,
        companyTypeCategory: undefined,
      })
    );
    dispatch(
      updateApplicantData({
        email: '',
        phone: '',
      })
    );
    dispatch(
      pushToGtmOnboardingAction({
        stepActionNumber: 0.1,
        actionName: 'company_change',
      })
    );
  };

  return (
    <Radio.Group
      direction="column"
      dataCy="onboarding_company_selector_checkbox"
      value={companyIsNew ? 'company_new' : form.organizationNumber}
    >
      {clients.map((company: ILoanOnboardingApplicationClient) => (
        <Radio
          key={company.company_no}
          value={company.company_no}
          onClick={() => onChange(company.company_no)}
          label={
            <Group>
              <Typography lineHeight={'sm'} whiteSpace="normal">
                {company.company_name}
              </Typography>

              {company.application_uuid &&
                (company.has_inprogress_application ||
                  company.has_offer_to_sign) && (
                  <BadgeWrapper>
                    <Badge
                      color={
                        company.has_offer_to_sign
                          ? 'success.500'
                          : 'warning.500'
                      }
                    >
                      {company.has_offer_to_sign
                        ? t('Onboarding.ActiveOffer')
                        : t('Onboarding.ActiveApplication')}
                    </Badge>
                  </BadgeWrapper>
                )}
            </Group>
          }
          hint={<Typography size={'sm'}>{company.company_no}</Typography>}
        ></Radio>
      ))}
      <Radio
        value="company_new"
        onClick={handleNewCompany}
        dataCy="onboarding_company_selector_checkbox_new_company"
        label={<Typography>{t('Onboarding.NewCompany')}</Typography>}
      ></Radio>
    </Radio.Group>
  );
};

export default CompanySelectorRadioButtons;
