import { useSelector } from 'react-redux';
import { isValidDate } from '~/helpers/date.helper';
import { generateLocale } from '~/helpers/generators.helper';
import { RootState } from '~/store/types/sharedTypes';

const useDateFormatter = () => {
  const { language, market } = useSelector((state: RootState) => state.intl);

  return (date: Date | string, options?: Intl.DateTimeFormatOptions) => {
    const dateIsValid = isValidDate(date);
    if (!dateIsValid) return date.toString();

    const locale = generateLocale(language, market);
    return new Intl.DateTimeFormat(locale, options).format(new Date(date));
  };
};

export default useDateFormatter;
