import { ApiStatus } from './sharedTypes';

export const SET_ESKAT_API_STATUS = 'SET_ESKAT_API_STATUS';

export interface UpdateApiStatusAction {
  type: typeof SET_ESKAT_API_STATUS;
  payload: ApiStatus;
}

export type EskatAction = UpdateApiStatusAction;
