import React, { forwardRef } from 'react';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import Group from '~/components/shared/Layout/Group';
import { Typography } from '~/components/shared/Typography/Typography';
import styled from 'styled-components';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface ValidationOptions {
  color: string;
  icon: IconDefinition;
  name: string;
}

const validationOptions: Record<'error' | 'info', ValidationOptions> = {
  error: {
    name: 'error',
    color: theme.colors.error,
    icon: SvgSrc.CircleExclamation,
  },
  info: {
    name: 'info',
    color: theme.colors.accent,
    icon: SvgSrc.CircleExclamation,
  },
};
interface Props {
  children: React.ReactNode;
  type?: 'error' | 'info';
}

const FlexValidationMessage = styled(Typography)`
  flex: 1;
`;

const ValidationErrorMessage = forwardRef<HTMLSpanElement, Props>(
  ({ children, type = 'error' }, ref) => (
    <Group
      data-cy={
        validationOptions[type].name === 'error'
          ? 'error_message'
          : 'info_message'
      }
      align="center"
      spacing="sm"
    >
      <Icon
        src={validationOptions[type].icon}
        size={ComponentSize.Small}
        color={validationOptions[type].color}
      />
      <FlexValidationMessage
        color={theme.colors.dark}
        lineHeight={1.4}
        fontSize={0.8}
        ref={ref}
      >
        {children}
      </FlexValidationMessage>
    </Group>
  )
);

export default ValidationErrorMessage;
