import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationStatus, FlowTypes } from '~/enums';
import { onboardingApplicationInitialState } from '../initialState';
import { IOnboardingAddressFields } from '~/interfaces/Onboarding';
import {
  ILoanOnboardingApplicationApplicant,
  ILoanOnboardingApplicationForm,
  ILoanOnboardingApplicationOverview,
  ILoanOnboardingApplicationState,
} from '~/interfaces/LoanOnboardingApplication';
import { listenerMiddleware } from '../middlewares/listener.middleware';

const onboardingApplicationSlice = createSlice({
  name: 'onboardingApplication',
  initialState: onboardingApplicationInitialState,
  reducers: {
    updateCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    goToNextStep: (state) => {
      state.currentStep += 1;
    },
    goToPreviousStep: (state) => {
      state.currentStep -= 1;
    },
    updateFlowType: (state, action: PayloadAction<FlowTypes>) => {
      state.flowType = action.payload;
    },
    setCompanyIsReal: (state, action: PayloadAction<boolean>) => {
      state.companyIsReal = action.payload;
    },
    setCompanyIsNew: (state, action: PayloadAction<boolean>) => {
      state.companyIsNew = action.payload;
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    updateForm: (
      state,
      action: PayloadAction<Partial<ILoanOnboardingApplicationForm>>
    ) => {
      state.form = { ...state.form, ...action.payload };
    },
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },
    setClientId: (state, action: PayloadAction<number>) => {
      state.clientId = action.payload;
    },
    updateOverview: (
      state,
      action: PayloadAction<ILoanOnboardingApplicationOverview>
    ) => {
      state.overview = { ...state.overview, ...action.payload };
    },
    setApiStatus: (
      state,
      action: PayloadAction<
        Partial<ILoanOnboardingApplicationState['apiStatus']>
      >
    ) => {
      state.apiStatus = {
        ...state.apiStatus,
        ...action.payload,
      };
    },
    setStepStartTimestamp: (state) => {
      state.stepStartTimestamp = new Date().toString();
    },
    updateApplicationStatus: (
      state,
      action: PayloadAction<ApplicationStatus>
    ) => {
      state.applicationStatus = action.payload;
    },
    updateCurrentOrgNumber: (state, action: PayloadAction<string>) => {
      state.currentApplicationOrgNumber = action.payload;
    },
    setBasicApplicationSent: (state, action: PayloadAction<boolean>) => {
      state.basicApplicationSent = action.payload;
    },
    setIsCompanyTypeCategoryRequested: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCompanyTypeCategoryRequested = action.payload;
    },
    clearOnboardingState: () => ({
      ...onboardingApplicationInitialState,
      cleared: true,
    }),
    removeClearedState: (state) => {
      state.cleared = false;
    },
    updateApplicantData: (
      state,
      action: PayloadAction<ILoanOnboardingApplicationApplicant>
    ) => {
      state.form.applicant = {
        ...state.form.applicant,
        ...action.payload,
      };
    },
    updateApplicantAddress: (
      state,
      action: PayloadAction<IOnboardingAddressFields>
    ) => {
      state.form.applicant.addressFields = {
        ...state.form.applicant.addressFields,
        ...action.payload,
      };
    },
  },
});

export const {
  updateCurrentStep,
  goToNextStep,
  goToPreviousStep,
  updateFlowType,
  setCompanyIsReal,
  setCompanyIsNew,
  setCompanyName,
  updateForm,
  setUserId,
  setClientId,
  updateOverview,
  setApiStatus,
  setStepStartTimestamp,
  updateApplicationStatus,
  updateCurrentOrgNumber,
  setBasicApplicationSent,
  setIsCompanyTypeCategoryRequested,
  clearOnboardingState,
  removeClearedState,
  updateApplicantData,
  updateApplicantAddress,
} = onboardingApplicationSlice.actions;

export default onboardingApplicationSlice.reducer;

listenerMiddleware.startListening({
  matcher: isAnyOf(updateCurrentStep, goToNextStep, goToPreviousStep),
  effect: async (_action, listenerApi) => {
    listenerApi.dispatch(setStepStartTimestamp());
  },
});
