import { useEffect, useState } from 'react';
import { calculateTimeLeft } from '~/helpers/utils';

const timerUpdateFrequencyInMs = 1000;

export const useCountdownTimer = (timestamp: number) => {
  const [countdownTimer, setCountdownTimer] = useState(
    calculateTimeLeft(timestamp)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = calculateTimeLeft(timestamp);
      setCountdownTimer(timeLeft);
    }, timerUpdateFrequencyInMs);
    return () => {
      clearInterval(interval);
    };
  }, [timestamp]);

  return { countdownTimer };
};
