import {
  CardBeneficialOwner,
  CardOffer,
  CardOfferUserData,
  CardSigner,
} from '~/Interfaces';

export const SET_CARD_OFFER_DATA = 'SET_CARD_OFFER_DATA';
export const SET_CARD_OFFER_USER_DATA = 'SET_CARD_OFFER_USER_DATA';
export const SET_HAS_CARD_OFFER = 'SET_HAS_CARD_OFFER';
export const UPDATE_CARD_OFFER_USER_DATA = 'UPDATE_CARD_OFFER_USER_DATA';
export const UPDATE_CARD_OFFER_USER_DATA_ADD_OWNER_PLACEHOLDER =
  'UPDATE_CARD_OFFER_USER_DATA_ADD_OWNER_PLACEHOLDER';
export const UPDATE_CARD_OFFER_USER_DATA_UPDATE_OWNER =
  'UPDATE_CARD_OFFER_USER_DATA_UPDATE_OWNER';
export const UPDATE_CARD_OFFER_USER_DATA_UPDATE_SIGNER =
  'UPDATE_CARD_OFFER_USER_DATA_UPDATE_SIGNER';
export const SET_CARD_OFFER_SUBMIT_STATUS = 'SET_CARD_OFFER_SUBMIT_STATUS';
export const UPDATE_CARD_OFFER_USER_DATA_REMOVE_OWNER_PLACEHOLDER =
  'UPDATE_CARD_OFFER_USER_DATA_REMOVE_OWNER_PLACEHOLDER';

interface SetCardOfferData {
  type: typeof SET_CARD_OFFER_DATA;
  payload: CardOffer[];
}

interface SetCardOfferUserData {
  type: typeof SET_CARD_OFFER_USER_DATA;
  payload: CardOffer;
}

interface SetHasCardOffer {
  type: typeof SET_HAS_CARD_OFFER;
  payload: boolean;
}

interface UpdateCardOfferUserData {
  type: typeof UPDATE_CARD_OFFER_USER_DATA;
  payload: Partial<CardOfferUserData>;
}

interface UpdateCardOfferUserData_AddOwnerPlaceholder {
  type: typeof UPDATE_CARD_OFFER_USER_DATA_ADD_OWNER_PLACEHOLDER;
}

interface UpdateCardOfferUserData_RemoveOwnerPlaceholder {
  type: typeof UPDATE_CARD_OFFER_USER_DATA_REMOVE_OWNER_PLACEHOLDER;
  payload: string;
}

interface UpdateCardOfferUserData_UpdateOwner {
  type: typeof UPDATE_CARD_OFFER_USER_DATA_UPDATE_OWNER;
  payload: Partial<CardBeneficialOwner>;
}

interface UpdateCardOfferUserData_UpdateSigner {
  type: typeof UPDATE_CARD_OFFER_USER_DATA_UPDATE_SIGNER;
  payload: Partial<CardSigner>;
}

interface SetCardOfferSubmitStatusAction {
  type: typeof SET_CARD_OFFER_SUBMIT_STATUS;
  payload: boolean;
}

export type CardOfferAction =
  | SetCardOfferData
  | SetCardOfferUserData
  | SetHasCardOffer
  | UpdateCardOfferUserData
  | UpdateCardOfferUserData_AddOwnerPlaceholder
  | UpdateCardOfferUserData_RemoveOwnerPlaceholder
  | UpdateCardOfferUserData_UpdateOwner
  | UpdateCardOfferUserData_UpdateSigner
  | SetCardOfferSubmitStatusAction;

export interface PostCardOfferResponse {
  signatureUrl: string;
  hash: string;
  message?: string;
}

export interface PostCardOfferPayload {
  applicationUuid: string;
  market: string;
  clientId: number;
  isPublic: boolean;
  personalGuaranteeRequired?: boolean;
  apiVersion?: string;
}

export interface PostCardOfferUserData extends PostCardOfferPayload {
  userData: CardOfferUserData;
  apiVersion?: string;
}

export interface PostSignersCardOfferV2Response {
  hash: string;
  message?: string;
}
