import React from 'react';
import styled from 'styled-components';
import Popup from '~/components/shared/Popup/Popup';
import Image from '~/components/shared/Image/Image';
import { Typography } from '~/components/shared/Typography/Typography';
import useTranslate from '~/hooks/useTranslate';

const ImageContainer = styled.div`
  width: 6em;
  margin: auto;
`;

const RedirectFromEskatModal: React.FC = () => {
  const translate = useTranslate();

  return (
    <Popup
      isOpen
      onOkButtonClick={() => window.location.assign('https://www.qred.com')}
      buttonText="Continue"
    >
      <ImageContainer>
        <Image src="rocket.png" />
      </ImageContainer>
      <Typography centerAlign lineHeight={1.5} element="p">
        {translate('RedirectFromEskatModal.Text1')}
      </Typography>
    </Popup>
  );
};

export default RedirectFromEskatModal;
