import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';

export const CheckboxWrapper = styled.div`
  padding: 1.4em 0;
  border-bottom: 0.125em solid #f2f3f0;
  position: relative;
`;

export const FooterContent = styled.div`
  width: 100%;
  padding: 0 2em;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 1em 0;
  box-shadow: 1px -5px 18px -5px rgba(102, 99, 99, 0.11);
  -webkit-box-shadow: 1px -5px 18px -5px rgba(102, 99, 99, 0.11);
  -moz-box-shadow: 1px -5px 18px -5px rgba(102, 99, 99, 0.11);

  @media ${MediaQueries.smallScreenPortrait} {
    position: fixed;
    height: 5em;
  }
`;
