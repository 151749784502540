import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { additionalSignerInitialState } from '../initialState';
import { TOnboardingDTO } from '~/types/DTO/onboarding';
import { IAdditionalSignerState } from '~/interfaces/AdditionalSigner';

const additionalSignerSlice = createSlice({
  name: 'additionalSigner',
  initialState: additionalSignerInitialState,
  reducers: {
    setOTPExpiryTimestamp: (state, action: PayloadAction<number>) => {
      state.otpExpiryTimestamp = action.payload;
    },
    setOTP: (state, action: PayloadAction<string>) => {
      state.otp = action.payload;
    },
    setOTPError: (
      state,
      action: PayloadAction<IAdditionalSignerState['otpError']>
    ) => {
      state.otpError = action.payload;
    },
    setTimerCompleted: (state, action: PayloadAction<boolean>) => {
      state.timerCompleted = action.payload;
    },
    setAdditionalSignerLoanApplicationData: (
      state,
      action: PayloadAction<
        TOnboardingDTO['getAdditionalSignerLoanApplicationDataRes']
      >
    ) => {
      state.additionalSignerLoanApplicationOffer = action.payload;
    },
    setAdditionalSignerCardApplicationData: (
      state,
      action: PayloadAction<
        TOnboardingDTO['getAdditionalSignerCardApplicationDataRes']
      >
    ) => {
      state.additionalSignerCardApplicationOffer = action.payload;
    },
    setApiStatus: (
      state,
      action: PayloadAction<Partial<IAdditionalSignerState['apiStatus']>>
    ) => {
      state.apiStatus = {
        ...state.apiStatus,
        ...action.payload,
      };
    },
  },
});

export const {
  setOTPExpiryTimestamp,
  setOTP,
  setOTPError,
  setTimerCompleted,
  setAdditionalSignerLoanApplicationData,
  setAdditionalSignerCardApplicationData,
  setApiStatus,
} = additionalSignerSlice.actions;

export default additionalSignerSlice.reducer;
