import React, { useEffect } from 'react';
import { Group, Stack, Typography } from '@qred/components-library';
import { theme } from '~/styles/themes';
import useTranslate from '~/hooks/useTranslate';
import { ClickableText } from '~/styles/BaseStyledComponents';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { getSupportUrl } from '~/constants/markets';
import { CountryCode } from '~/enums';

const AdditionalSignerError = () => {
  const {
    intl: { language, market },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();

  useEffect(() => {
    document.cookie = `additionalSignerId=; Max-Age=0`;
    document.cookie = `additionalSignerProductType=; Max-Age=0`;
    document.cookie = `additionalSignerHashCode=; Max-Age=0`;
    document.cookie = `additionalSignerAccessToken=; Max-Age=0`;
  }, []);

  const contactUsOnClick = () => {
    if (!language) return;
    const supportUrl = getSupportUrl(market as CountryCode, language);
    window.open(supportUrl, '_blank');
  };

  return (
    <Group
      spacing={0}
      h={mobile ? 320 : 266}
      w={mobile ? 332 : 580}
      p={mobile ? 'lg' : 'xxl'}
      style={{
        backgroundColor: `${theme.colors.neutral2}`,
        borderRadius: '4px',
      }}
    >
      <Stack>
        <Typography weight={600} size="xl" lineHeight={32}>
          {translate('LoanAdditionalSigner.LoanAdditionalSignerErrorTitle')}
        </Typography>
        <Typography lineHeight={24}>
          {translate('LoanAdditionalSigner.LoanAdditionalSignerErrorSubheader')}
        </Typography>
        <Typography lineHeight={24}>
          {translate(
            'LoanAdditionalSigner.LoanAdditionalSignerSuccessContactUs',
            {
              contactUs: (
                <ClickableText
                  typographyColor={theme.colors.midwayLinkDark}
                  onClick={contactUsOnClick}
                  noUnderline
                >
                  {translate('Onboarding.ContactUs').toString().toLowerCase()}
                </ClickableText>
              ),
            }
          )}
        </Typography>
      </Stack>
    </Group>
  );
};

export default AdditionalSignerError;
