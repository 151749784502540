import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { useHistory } from 'react-router-dom';

const gtmEventsMapper: any = {
  '': 'home_view',
  profile: 'profile',
  loans: 'loan',
  'p-offer': 'offer_loan',
  'p-cardoffer': 'offer_card',
  offer: 'offer_loan',
  cardoffer: 'offer_card',
  'previous-loans': 'loan_previous',
};

const withGtmEvent = (WrappedComponent: React.FC<any>) => {
  const Component: React.FC = () => {
    const { market, marketIsSet, language, languageIsSet } = useSelector(
      (state: RootState) => state.intl
    );
    const history = useHistory();
    const splitedLocation = history.location.pathname.split('/');

    useEffect(() => {
      if (marketIsSet) {
        window.dataLayer.push({ event: `q_user_v_${market.toLowerCase()}` });
      }
    }, [market, marketIsSet]);

    useEffect(() => {
      if (languageIsSet) {
        window.dataLayer.push({
          event: `q_user_v_lang_${language.toLowerCase()}`,
        });
      }
    }, [language, languageIsSet]);

    useEffect(() => {
      // TODO: Find a way to refactor this hacky solution for excluding certain routes in order to send correct events.
      if (
        !history.location.pathname.includes('thankyou') &&
        splitedLocation[2] !== 'setting'
      ) {
        if (history.location.pathname.includes('offer')) {
          window.dataLayer.push({
            event: 'q_user_v_offer',
          });
        }
        gtmEventsMapper[splitedLocation[1]] &&
          window.dataLayer.push({
            event: `q_user_v_${gtmEventsMapper[splitedLocation[1]]}`,
          });
      }

      if (
        history.location.pathname.includes('thankyou') &&
        history.location.pathname.includes('p-')
      ) {
        window.dataLayer.push({
          event: 'q_user_v_loan_offer_signed_confirmation_public',
        });
      }
      window.dataLayer.push({ event: 'q_user_v_my' });
    }, [history]);

    return <WrappedComponent />;
  };

  return Component;
};

export default withGtmEvent;
