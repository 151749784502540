import useTranslate from '~/hooks/useTranslate';
import React, { useCallback, useState } from 'react';
import { Menu, Navbar as CLNavbar } from '@qred/components-library';
import { CompanyItem } from './CompanyItem';
import { usePostHogEvents } from '~/hooks/usePostHogEvents';
import { posthogActionNames } from '~/constants/posthogActionNames';
import { useAccount } from '~/hooks/useAccount';

export const SwitchCompanyButton = () => {
  const t = useTranslate();
  const [companySelectorOpened, setCompanySelectorOpened] = useState(false);
  const { selectedCompany, companies, updateSelectedCompany } = useAccount();
  const { capture } = usePostHogEvents();
  const otherCompany =
    companies.length === 2
      ? companies.find((c) => c.id !== selectedCompany?.id)
      : undefined;

  const onSwitchCompany = useCallback(() => {
    if (!otherCompany) {
      return;
    }

    updateSelectedCompany(otherCompany);

    capture({
      action: posthogActionNames.sidebar.SWITCH_COMPANY,
      sidebar: true,
    });
  }, [otherCompany]);

  if (otherCompany) {
    return (
      <CLNavbar.Link rightIcon="repeat" onClick={onSwitchCompany}>
        {t('Dashboard.SwitchCompany')}
      </CLNavbar.Link>
    );
  }

  return (
    <Menu onChange={setCompanySelectorOpened}>
      <Menu.Target>
        <CLNavbar.Link
          rightIcon={companySelectorOpened ? 'expand_less' : 'expand_more'}
        >
          {t('Dashboard.SwitchCompany')}
        </CLNavbar.Link>
      </Menu.Target>
      <Menu.Dropdown>
        {companies?.map((c) => (
          <CompanyItem key={c.id} company={c} />
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
