import styled from 'styled-components';
import { theme } from '~/styles/themes';

export const HamburgerIcon = styled.div.attrs(() => ({
  'data-testid': 'main-menu',
}))`
  display: inline-block;
  border-radius: 0.5em 0.5em 0 0;
  cursor: pointer;
`;

export const HamburgerIconOpen = styled(HamburgerIcon)`
  background-color: ${theme.colors.white};
  z-index: 11;
  position: relative;
`;

export const MenuItemContainer = styled.div`
  width: 100%;
  padding: 1em;
  cursor: pointer;
  position: relative;
  @media (orientation: landscape) {
    padding: 1em 7em 1em 1em;
  }
`;
export const MenuWrapper = styled.div`
  background-color: white;
  position: absolute;
  z-index: 9999;
  width: max-content;
  box-shadow: 0px 0px 20px -10px #000000;
`;

export const MainMenuContainer = styled.nav<{
  alignMenuRight?: boolean;
  hasSiblings?: boolean;
}>`
  position: relative;
  pointer-events: all;
  margin-left: ${(props) =>
    props.alignMenuRight && !props.hasSiblings ? 'auto' : undefined};

  &:focus {
    outline: none;
  }
`;

export const TransitionedUnderline = styled.span`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -0.4em;
    width: 0px;
    height: 0.2em;
    background: ${theme.colors.primaryGreen};
    transition: all 0.45s;
  }
  ${MenuItemContainer}:hover &:after {
    width: 100%;
    left: 0;
  }
`;

export const Logout = styled(MenuItemContainer)`
  border-radius: 0 0 0.5em 0.5em;
  border-top: 1px solid ${theme.colors.primaryGray};
`;

export const LanguageContainer = styled.div`
  display: flex;
  padding-bottom: 1em;
  @media (max-width: 850px) {
    margin-top: 1em;
  }
`;

export const LanguageOption = styled.span<{ active?: boolean }>`
  padding: 0.1em 1em;
  cursor: pointer;
  text-decoration: underline;
  &:not(:first-of-type) {
    border-left: 1px solid ${theme.colors.neutral};
  }
  &:hover {
    color: ${theme.colors.hoverGreen};
  }
  color: ${(props) =>
    props.active ? theme.colors.active : theme.colors.neutral};
`;

export const OnboardingMenuWrapper = styled(MenuWrapper)`
  right: 0;
`;
