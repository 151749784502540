import React, { useEffect } from 'react';
import { Group, Typography } from '@qred/components-library';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { useCountdownTimer } from '~/hooks/useCountdownTimer';
import useTranslate from '~/hooks/useTranslate';
import { setTimerCompleted } from '~/store/slices/additionalSigner.slice';
import { timestampIsInThePast } from '~/helpers/date.helper';

interface IAdditionalSignerOTPTimer {
  otpExpiryTimestamp: number;
}

const AdditionalSignerOTPTimer: React.FC<IAdditionalSignerOTPTimer> = ({
  otpExpiryTimestamp,
}) => {
  const {
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const otpIsExpired = timestampIsInThePast(otpExpiryTimestamp);
  const dispatch = useDispatch();

  const { countdownTimer } = useCountdownTimer(otpExpiryTimestamp);
  const translate = useTranslate();

  useEffect(() => {
    if (otpIsExpired) {
      dispatch(setTimerCompleted(true));
    }
  }, [otpIsExpired]);

  return (
    <Group mt={mobile ? 32 : 40}>
      <Typography
        size="sm"
        weight={400}
        lineHeight={22}
        letterSpacing={0.25}
        mt={15}
      >
        {!otpIsExpired &&
          `${
            translate('AdditionalSigner.CodeExpirationText') as string
          }: ${countdownTimer}`}
      </Typography>
    </Group>
  );
};

export default AdditionalSignerOTPTimer;
