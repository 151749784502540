import { Loan, Payment } from '~/Interfaces';

/**
 * Extract and sort all overdue payments from all loans in on single array
 */
export function sortOverduePayments(loans: Array<Loan>): Array<Payment> {
  const overduePaymentsArray = [];

  for (const loan of loans) {
    if (loan.overduePayments && loan.overduePayments.length > 0) {
      for (const overduePayment of loan.overduePayments) {
        overduePaymentsArray.push(overduePayment);
      }
    }
  }

  overduePaymentsArray.sort((paymentA, paymentB) =>
    paymentA.dueDate && paymentB.dueDate
      ? paymentA.dueDate > paymentB.dueDate
        ? 1
        : -1
      : 0
  );

  return overduePaymentsArray;
}
