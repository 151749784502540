import React from 'react';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import Icon, { SvgSrc } from '../Icon/Icon';

const DownArrow = () => (
  <Icon
    size={ComponentSize.Medium}
    mt={1}
    mb={1}
    src={SvgSrc.AngleDoubleDown}
  />
);

export default DownArrow;
