import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@qred/components-library';
import { getPolicyTermsUrl } from '~/constants/markets';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { Link } from '~/styles/BaseStyledComponents';

interface Props {
  smallText?: boolean;
  centerAlign?: boolean;
}

const PolicyAgreement = ({ smallText, centerAlign }: Props) => {
  const t = useTranslate();
  const {
    intl: { market, language },
  } = useSelector((state: RootState) => state);

  const smallTextFontSize = smallText ? 'sm' : 'md';

  return (
    <Typography
      size={smallTextFontSize}
      align={centerAlign ? 'center' : undefined}
    >
      {t('TermsAndConfirmationCard.PolicyAgreementText')}{' '}
      <Link
        href={getPolicyTermsUrl(market, language)}
        target="_blank"
        rel="noreferrer"
      >
        {t('TermsAndConfirmationCard.PolicyAgreementLink')}
      </Link>
    </Typography>
  );
};

export default PolicyAgreement;
