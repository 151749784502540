import React from 'react';
import { scrollToTop } from '~/helpers/scrollPage';
import { Menu } from '@qred/components-library';
import { usePostHogEvents } from '~/hooks/usePostHogEvents';
import { posthogActionNames } from '~/constants/posthogActionNames';
import { Company } from '~/models/accounts/accounts';
import { useAccount } from '~/hooks/useAccount';

export const CompanyItem: React.FC<{
  company: Company;
}> = ({ company }) => {
  const { updateSelectedCompany } = useAccount();
  const { capture } = usePostHogEvents();

  const onSwitchCompany = () => {
    updateSelectedCompany(company);
    scrollToTop();

    capture({
      action: posthogActionNames.sidebar.COMPANY_ITEM,
      sidebar: true,
    });
  };

  return <Menu.Item onClick={onSwitchCompany}>{company.name}</Menu.Item>;
};
