import initialState from '~/store/initialState';
import { EskatAction, SET_ESKAT_API_STATUS } from '../types/eskatTypes';
import { ApiStatus } from '../types/sharedTypes';

interface EskatState {
  readonly apiStatus: ApiStatus;
}

const eskatReducer = (
  state = initialState.eskat,
  action: EskatAction
): EskatState => {
  switch (action.type) {
    case SET_ESKAT_API_STATUS:
      return { ...state, apiStatus: action.payload };

    default:
      return state;
  }
};

export default eskatReducer;
