import { createContext } from 'react';

export interface PostHogEvent {
  name: string;
  properties?: Record<string, any>;
}

interface PostHogContextProps {
  event: PostHogEvent;
  setEvent: (event: PostHogEvent) => void;
}

export const PostHogContext = createContext<PostHogContextProps>(null as any);
