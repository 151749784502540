import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalTransitionDuration } from '~/components/shared/Modal/Modal';
import { RootState } from '~/store/types/sharedTypes';

const getScrollWidth = () => {
  if (typeof window === 'undefined' || typeof document === 'undefined')
    return 0;

  const paddingRight = parseInt(
    window.getComputedStyle(document.body).paddingRight,
    10
  );
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth;

  return paddingRight + scrollbarWidth;
};

const getLockStyles = (disableBodyPadding: any, isMobile: boolean) => {
  const scrollWidth = disableBodyPadding ? null : getScrollWidth();

  const styles = `
  html {
    overflow: hidden;
  }
  body {
    --removed-scroll-width: ${scrollWidth}px;
    position: ${isMobile ? 'fixed' : 'static'};
        touch-action: none;
        overflow: hidden !important;
        ${
          scrollWidth
            ? 'padding-right: var(--removed-scroll-width) !important;'
            : ''
        }
      }
  `;

  return styles;
};

const injectStyles = (tag: any, css: any): void => {
  if (tag.styleSheet) {
    // eslint-disable-next-line no-param-reassign
    tag.styleSheet.cssText = css;
  } else {
    tag.appendChild(document.createTextNode(css));
  }
};

const makeStyleTag = () => {
  const tag = document.createElement('style');
  tag.type = 'text/css';
  tag.setAttribute('scroll-lock', '');

  return tag;
};

const insertStyleTag = (tag: any) => {
  const head = document.head || document.getElementsByTagName('head')[0];

  head.appendChild(tag);
};

const useScrollLock = (
  lock?: boolean,
  options = {
    disableBodyPadding: false,
  }
) => {
  const { matchedMedia } = useSelector((state: RootState) => state);

  const [scrollLocked, setScrollLocked] = useState(lock || false);

  const scrollTop = useRef(0);

  const [savedScrollPosition, setSavedScrollPosition] = useState(0);

  const { disableBodyPadding } = options;

  const stylesheet = useRef<CSSStyleSheet | any | null>(null);

  const lockScroll = () => {
    scrollTop.current = window.scrollY;

    const styles = getLockStyles(
      disableBodyPadding,
      matchedMedia.smallScreenPortrait
    );
    const sheet = makeStyleTag();

    injectStyles(sheet, styles);
    insertStyleTag(sheet);

    stylesheet.current = sheet;
  };

  const unlockScroll = () => {
    if (!stylesheet?.current) return;

    stylesheet.current.parentNode.removeChild(stylesheet.current);
    stylesheet.current = null;
  };

  useEffect(() => {
    if (scrollLocked) {
      setSavedScrollPosition(window.scrollY);
      setTimeout(lockScroll, ModalTransitionDuration);
    } else {
      window.scrollTo(0, savedScrollPosition);
      unlockScroll();
    }

    return unlockScroll;
  }, [scrollLocked]);

  useEffect(() => {
    if (lock !== undefined) {
      setScrollLocked(lock);
    }
  }, [lock]);

  useEffect(() => {
    if (lock === undefined && typeof window !== 'undefined') {
      window.document.body.style.overflow === 'hidden' && setScrollLocked(true);
    }
  }, [setScrollLocked]);

  return [scrollLocked, setScrollLocked] as const;
};

export default useScrollLock;
