import React from 'react';
import { Group, Loader, Typography } from '@qred/components-library';
import OTPVerificationInput from './AdditionalSignerOTPInput';
import { ClickableText } from '~/styles/BaseStyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { regenerateOTP } from '~/store/actions/additionalSignerActions';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { OTPErrorType } from '~/enums';
import useTranslate from '~/hooks/useTranslate';

const AdditionalSignerOTPInputSection = () => {
  const {
    intl: { market },
    matchedMedia: { mobile },
    additionalSigner: { apiStatus, otpError, timerCompleted },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const translate = useTranslate();

  const onClickResendCode = () => {
    dispatch(regenerateOTP(market));
  };

  return (
    <>
      <Group direction="column" mt={mobile ? 32 : 40} spacing={0}>
        <Typography size="sm" weight={500} lineHeight={18} letterSpacing={0.15}>
          {translate('AdditionalSigner.VerificationCode') as string}{' '}
        </Typography>
        <OTPVerificationInput />
        <Typography
          color="error.700"
          size="sm"
          weight={400}
          letterSpacing={0.25}
          mt={5}
        >
          {otpError === OTPErrorType.INVALID_OTP &&
            (translate('AdditionalSigner.InvalidCodeSent') as string)}
        </Typography>
      </Group>
      <Group align="center" spacing="xs" mt={4}>
        <Typography size="sm" weight={400} lineHeight={20} letterSpacing={0.25}>
          {timerCompleted
            ? translate('AdditionalSigner.CodeExpiredShort')
            : translate('AdditionalSigner.NoCodeText')}
        </Typography>

        <ClickableText onClick={onClickResendCode} noUnderline>
          <Typography
            color="textLink.dark"
            size="sm"
            lineHeight={20}
            weight={400}
            letterSpacing={0.25}
          >
            {apiStatus.regenerateOTP === ApiStatus.Started
              ? (translate('AdditionalSigner.CodeSentText') as string)
              : (translate('AdditionalSigner.Resend') as string)}
          </Typography>
        </ClickableText>
        {apiStatus.regenerateOTP === ApiStatus.Started && (
          <Loader size={'xs'} />
        )}
      </Group>
    </>
  );
};

export default AdditionalSignerOTPInputSection;
