import {
  LoanAction,
  SET_API_APPLICATIONS_STATUS,
  SET_API_LOANS_STATUS,
  TOGGLE_NOTIFICATIONS_STATUS,
  UPDATE_APPLICATIONS_DATA,
  UPDATE_LOANS_DATA,
  UPDATE_PREVIOUS_LOANS,
  UPDATE_TERMINATED_CONTRACT_DATA,
} from '~/store/types/loanTypes';
import {
  apiGetApplicationStatus,
  apiGetLoan,
  apiGetPreviousLoans,
  apiGetTerminatedContractById,
} from '~/services/api';
import {
  findNextPaymentObj,
  findOverduePayments,
} from '~/helpers/payments.helper';
import {
  applicationDataMapper,
  loanDataMapper,
  paymentObjectMapper,
  previousLoanDataMapper,
  terminatedContractMapper,
} from '~/helpers/mappers.helper';
import { ApiStatus, AppThunk } from '~/store/types/sharedTypes';
import {
  ApiListData,
  ApiObjectData,
  Application,
  Invoices,
  Loan,
  PreviousLoans,
  TerminatedContract,
} from '~/Interfaces';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import { updateActiveCompanyLoan } from '~/store/actions/userActions';
import {
  UPDATE_ACTIVE_COMPANY_APPLICATION,
  UPDATE_ACTIVE_COMPANY_PREVIOUS_LOANS,
  UserAction,
} from '~/store/types/userTypes';
import {
  fetchInvoices,
  getMappedInvoices,
} from '~/store/actions/invoicesActions';

// Action creators
export const updateLoansOverview = (loansData: Array<Loan>): LoanAction => ({
  type: UPDATE_LOANS_DATA,
  payload: loansData,
});

export const updateContract = (
  terminatedContract: TerminatedContract
): LoanAction => ({
  type: UPDATE_TERMINATED_CONTRACT_DATA,
  payload: terminatedContract,
});

export const updatePreviousContracts = (
  contracts: Array<PreviousLoans>
): LoanAction => ({
  type: UPDATE_PREVIOUS_LOANS,
  payload: contracts,
});

export const updateApplications = (
  applicationData: Array<Application>
): LoanAction => ({
  type: UPDATE_APPLICATIONS_DATA,
  payload: applicationData,
});

export const toggleNotificationsStatus = (status: boolean): LoanAction => ({
  type: TOGGLE_NOTIFICATIONS_STATUS,
  payload: status,
});

export const updateApiApplicationsStatus = (status: ApiStatus): LoanAction => ({
  type: SET_API_APPLICATIONS_STATUS,
  payload: status,
});

export const updateApiLoansStatus = (status: ApiStatus): LoanAction => ({
  type: SET_API_LOANS_STATUS,
  payload: status,
});

export const updateActiveCompanyPreviousLoan = (
  previousLoans: PreviousLoans[]
): UserAction => ({
  type: UPDATE_ACTIVE_COMPANY_PREVIOUS_LOANS,
  payload: previousLoans,
});

export const updateActiveCompanyApplication = (
  applications: Application[]
): UserAction => ({
  type: UPDATE_ACTIVE_COMPANY_APPLICATION,
  payload: applications,
});

// ========================== Thunks ============================
export const fetchLoansData = (): AppThunk => (dispatch, getState): void => {
  dispatch(updateApiLoansStatus(ApiStatus.Started));
  const state = getState();
  apiGetLoan()
    .then((res) => {
      const data = res.data && res.data.loan && res.data.loan.loans;

      // TODO: Temporary unused until we split response for each companies
      const { unpaid, paid, relatedLoanId } = getMappedInvoices(
        state.user.activeCompany?.id,
        res.data
      );

      dispatch(
        fetchInvoices({
          unpaid,
          paid,
          ...(relatedLoanId && { relatedLoanId }),
        } as Invoices)
      );
      ///

      if (data) {
        const loansData: Array<Loan> = data.map((l) => ({
          ...loanDataMapper(l),
          nextPaymentObj: findNextPaymentObj(l.loan_status),
          overduePayments: findOverduePayments(l.loan_status),
          details:
            l.loan_status?.map((ls: ApiObjectData) =>
              paymentObjectMapper(ls)
            ) || [],
        }));
        dispatch(updateLoansOverview(loansData));
        dispatch(updateActiveCompanyLoan(loansData));
      }
      dispatch(updateApiLoansStatus(ApiStatus.Completed));
    })
    .catch((err) => {
      dispatch(updateApiLoansStatus(ApiStatus.Failed));
      dispatch(pushToSentryAction(err, 'apiGetLoan'));
    });
};

export const fetchApplicationStatus = (): AppThunk => (dispatch): void => {
  dispatch(updateApiApplicationsStatus(ApiStatus.Started));
  apiGetApplicationStatus()
    .then((res) => {
      const data: ApiListData =
        res.data && res.data.client && res.data.client.companies;
      if (data) {
        const applications: Array<Application> = [];
        data.forEach((company) => {
          if (company.applications) {
            company.applications.forEach((application: any) => {
              applications.push(applicationDataMapper(application, company));
            });
          }
        });
        dispatch(updateApplications(applications));
        dispatch(updateActiveCompanyApplication(applications));
      }
      dispatch(updateApiApplicationsStatus(ApiStatus.Completed));
    })
    .catch((err) => {
      dispatch(updateApiApplicationsStatus(ApiStatus.Failed));
      dispatch(pushToSentryAction(err, 'apiGetApplicationStatus'));
    });
};

export const fetchTerminatedContractById = (
  contractId: number,
  clientId: number
): AppThunk => (dispatch): void => {
  apiGetTerminatedContractById(contractId, clientId)
    .then((res) => {
      if (res.data.show_termination) {
        dispatch(updateContract(terminatedContractMapper(res.data)));
      }
    })
    .catch((err) => {
      dispatch(
        pushToSentryAction(err, 'apiGetTerminatedContractById', {
          contractId,
        })
      );
    });
};

export const fetchPreviousLoans = (clientId: number): AppThunk => (
  dispatch
): void => {
  apiGetPreviousLoans(clientId)
    .then((res) => {
      if (res.data.contracts) {
        const previousLoans = res.data.contracts
          .filter(
            (previousLoan: PreviousLoans) =>
              previousLoan.status.split('_')[0] === 'Closed'
          )
          .reverse()
          .map((previousLoan: PreviousLoans) =>
            previousLoanDataMapper(previousLoan)
          );
        dispatch(updateActiveCompanyPreviousLoan(previousLoans));
        dispatch(updatePreviousContracts(previousLoans));
      }
    })
    .catch((err) => {
      dispatch(pushToSentryAction(err, 'apiGetPreviousLoans'));
    });
};
