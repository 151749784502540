import Month, {
  DayKeydownPayload,
  MonthProps,
} from '~/components/shared/DatePicker/Month';
import React from 'react';
import dayjs from 'dayjs';
import CalendarHeader from '~/components/shared/DatePicker/CalendarHeader';
import { formatMonthLabel, isMonthInRange } from '~/helpers/date.helper';

interface MonthsListProps
  extends Omit<
    MonthProps,
    'styles' | 'classNames' | 'daysRefs' | 'onDayKeyDown'
  > {
  amountOfMonths: number;
  paginateBy: number;
  month: Date;
  locale: string;
  allowLevelChange: boolean;
  daysRefs: React.RefObject<HTMLButtonElement[][][]>;

  onMonthChange(month: Date): void;

  onNextLevel(): void;

  onDayKeyDown(
    monthIndex: number,
    payload: DayKeydownPayload,
    event: React.KeyboardEvent<HTMLButtonElement>
  ): void;

  nextMonthLabel?: string;
  previousMonthLabel?: string;
  labelFormat: string;
  weekdayLabelFormat?: string;
}

const MonthsList: React.FC<MonthsListProps> = ({
  amountOfMonths,
  paginateBy,
  month,
  locale,
  minDate,
  maxDate,
  allowLevelChange,
  daysRefs,
  onMonthChange,
  onNextLevel,
  onDayKeyDown,
  weekdayLabelFormat,
  preventFocus,
  renderDay,
  nextMonthLabel,
  previousMonthLabel,
  labelFormat,
  ...others
}) => {
  const nextMonth = dayjs(month).add(amountOfMonths, 'months').toDate();
  const previousMonth = dayjs(month).subtract(1, 'months').toDate();

  const months = Array(amountOfMonths)
    .fill(0)
    .map((_, index) => {
      const monthDate = dayjs(month).add(index, 'months').toDate();
      return (
        <div key={index}>
          <CalendarHeader
            hasNext={
              index + 1 === amountOfMonths &&
              isMonthInRange({ date: nextMonth, minDate, maxDate })
            }
            hasPrevious={
              index === 0 &&
              isMonthInRange({ date: previousMonth, minDate, maxDate })
            }
            label={formatMonthLabel({
              month: monthDate,
              locale,
              format: labelFormat,
            })}
            onNext={() =>
              onMonthChange(dayjs(month).add(paginateBy, 'months').toDate())
            }
            onPrevious={() =>
              onMonthChange(
                dayjs(month).subtract(paginateBy, 'months').toDate()
              )
            }
            onNextLevel={onNextLevel}
            nextLevelDisabled={!allowLevelChange}
            nextLabel={nextMonthLabel}
            previousLabel={previousMonthLabel}
            preventLevelFocus={index > 0}
            preventFocus={preventFocus}
          />

          <Month
            month={monthDate}
            daysRefs={(daysRefs.current as HTMLButtonElement[][][])[index]}
            onDayKeyDown={(...args) => onDayKeyDown(index, ...args)}
            minDate={minDate}
            maxDate={maxDate}
            locale={locale}
            focusable={index === 0}
            preventFocus={preventFocus}
            renderDay={renderDay}
            weekdayLabelFormat={weekdayLabelFormat}
            {...others}
          />
        </div>
      );
    });

  return <>{months}</>;
};

export default MonthsList;
