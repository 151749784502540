import React from 'react';
import Card from '~/components/shared/Card/Card';
import styled from 'styled-components';
import Image from '~/components/shared/Image/Image';
import { Route } from 'react-router-dom';
import constVars from '~/constants/constVars';
import ThankYouCardContentSetPinCode from './ThankYouCardContentSetPinCode';
import ThankYouCardContentCardOffer from './ThankYouCardContentCardOffer';

const ImageContainer = styled.div`
  width: 4em;
  margin: 1em auto;
`;

const ThankYouCard = () => (
  <Card>
    <ImageContainer>
      <Image src="hero.png" />
    </ImageContainer>
    <Route
      path={[
        `/${constVars.ROUTE_THANK_YOU}/${constVars.ROUTE_CARD_OFFER}`,
        `/${constVars.ROUTE_THANK_YOU}/${constVars.ROUTE_CARD_SIGNED}`,
      ]}
    >
      <ThankYouCardContentCardOffer />
    </Route>
    <Route
      path={[
        `/${constVars.ROUTE_THANK_YOU}/${constVars.ROUTE_SET_PIN}`,
        `/${constVars.ROUTE_THANK_YOU}/${constVars.ROUTE_CARD_CREATED}`,
      ]}
    >
      <ThankYouCardContentSetPinCode />
    </Route>
  </Card>
);

export default ThankYouCard;
