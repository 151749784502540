import { Route, RouteProps } from 'react-router-dom';
import {
  useAuth0,
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import constVars from '~/constants/constVars';
import React, { useEffect } from 'react';
import { Center, Loader } from '@qred/components-library';
import { accessToken, TOKEN } from '~/store/apis/utils/accessToken';
import posthog from 'posthog-js';
import { useAccount } from '~/hooks/useAccount';

interface AuthenticatedRouteProps extends RouteProps {}

const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
  const { isAuthenticated } = useAuth0();
  const { setMarket, init, isInitialized } = useAccount();

  useEffect(() => {
    if (isAuthenticated) {
      accessToken.getDecoded().then((decodedToken: any) => {
        const _market = decodedToken[TOKEN.MARKET];
        if (_market) {
          setMarket(_market);
        }

        const userCode = decodedToken[TOKEN.USER_CODE];

        if (userCode) {
          posthog.identify(userCode);
          Sentry.setUser({ id: userCode });
        }
      });

      init();

      localStorage.setItem('isAuthenticated', '1');
    }
  }, [isAuthenticated]);

  if (isInitialized) return <Route {...props} />;

  return (
    <Center pt="xxl">
      <Loader size={'xxl'} />
    </Center>
  );
};

const options: WithAuthenticationRequiredOptions | undefined =
  import.meta.env.MODE !== 'internal-prod'
    ? {
        onRedirecting: () => {
          if (window.location.pathname !== constVars.ROUTE_DEFAULT) {
            sessionStorage.setItem(
              'returnTo',
              `${window.location.pathname}${window.location.search}`
            );
          }

          return (
            <Center pt="xxl">
              <Loader size={'xxl'} />
            </Center>
          );
        },
        loginOptions: {
          authorizationParams: {
            redirectUri: `${window.location.origin}/${constVars.ROUTE_LOGIN}`,
            prompt: 'none',
          },
        },
      }
    : undefined;

export default withAuthenticationRequired(AuthenticatedRoute, options);
