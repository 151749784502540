import React, { useEffect } from 'react';
import Button, { ButtonType } from '~/components/shared/Button/button';
import useTranslate from '~/hooks/useTranslate';
import { PageFooter } from '~/styles/BaseStyledComponents';

const UserSurvey = () => {
  const translate = useTranslate();
  useEffect(() => {
    const button = document.getElementById('survey-button');
    button?.setAttribute('data-tf-slider', 'UkkCrKD5');
    const script = document.createElement('script');
    script.src = 'https://embed.typeform.com/next/embed.js';
    document.body.appendChild(script);
  }, []);

  return (
    <PageFooter>
      <Button type={ButtonType.Outlined} id="survey-button">
        {translate('WeNeedYourHelp')}
      </Button>
    </PageFooter>
  );
};

export default UserSurvey;
