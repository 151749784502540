import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalErrorType } from '~/enums';
import { CustomGlobalError } from '~/Interfaces';
import { globalErrorInitialState } from '../initialState';

const globalErrorSlice = createSlice({
  name: 'globalError',
  initialState: globalErrorInitialState,
  reducers: {
    toggleGlobalErrorOn: (state, action: PayloadAction<GlobalErrorType>) => {
      state.hasError = true;
      state.errorType = action.payload;
    },
    toggleGlobalErrorOff: (state) => {
      state.hasError = false;
      state.globalErrorPersistent = false;
    },
    hideBanner: (state) => {
      state.showBanner = false;
    },
    hideCardOfferBanner: (state) => {
      state.showCardOfferBanner = false;
    },
    isTokenSet: (state, action: PayloadAction<boolean>) => {
      state.isTokenSet = action.payload;
    },
    setCustomGlobalError: (state, action: PayloadAction<CustomGlobalError>) => {
      state.customGlobalError = action.payload;
    },
    setGlobalErrorPersistent: (state, action: PayloadAction<boolean>) => {
      state.globalErrorPersistent = action.payload;
    },
  },
});

export const {
  toggleGlobalErrorOn,
  toggleGlobalErrorOff,
  isTokenSet,
  setCustomGlobalError,
  setGlobalErrorPersistent,
} = globalErrorSlice.actions;

export default globalErrorSlice.reducer;
