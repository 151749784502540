import {
  Group,
  Stack,
  Typography,
  Anchor,
  MaterialIcon,
} from '@qred/components-library';
import React, { Ref } from 'react';
import { useSelector } from 'react-redux';
import { formatSize } from '~/helpers/formatters.helper';
import useTranslate from '~/hooks/useTranslate';
import { IOnboardingFile } from '~/interfaces/Onboarding';
import { RootState } from '~/store';

interface IDocumentList {
  files: IOnboardingFile[];
  fileListRef: Ref<HTMLDivElement>;
  onDeleteAll: () => void;
  handleRemoveFile: (File: IOnboardingFile) => void;
}
const DocumentList: React.FC<IDocumentList> = ({
  files,
  fileListRef,
  onDeleteAll,
  handleRemoveFile,
}) => {
  const {
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);
  const t = useTranslate();
  return (
    <Stack>
      <Group justify="space-between">
        <Typography weight={500} size="sm">
          {`${t('Onboarding.UploadedFilesListTitle')} (${files.length})`}
        </Typography>
        {files.length > 1 && (
          <Anchor color="" onClick={onDeleteAll} size="sm">
            {t('Onboarding.RemoveAllFiles')}
          </Anchor>
        )}
      </Group>
      <Group spacing={10} ref={fileListRef} direction="column">
        {files.map((localFile: IOnboardingFile, i, arr) => (
          <Group
            key={localFile.objectURL}
            align="center"
            nowrap
            justify="space-between"
            display="flex"
          >
            <Typography
              weight={400}
              size="sm"
              maw={mobile ? '200px' : '350px'}
              style={{
                display: 'flex',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {localFile.name}
            </Typography>

            <Group direction="row" align="center">
              <Typography
                weight={400}
                size="sm"
                maw={mobile ? '65px' : '165px'}
                style={{
                  display: 'flex',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {formatSize(localFile.size)}
              </Typography>
              <MaterialIcon
                src="delete"
                size="24"
                onClick={() => handleRemoveFile(localFile)}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Group>
          </Group>
        ))}
      </Group>
    </Stack>
  );
};

export default DocumentList;
