export const SET_APPLY_LOAN = 'SET_APPLY_LOAN';
export const SET_IS_LOAN_APPLIED = 'SET_IS_LOAN_APPLIED';
export const SET_SUBMIT_STATUS = 'SET_SUBMIT_STATUS';
export const SET_IS_INITIATED = 'SET_IS_INITIATED';

export interface ApplyNewLoanAction {
  type: typeof SET_APPLY_LOAN;
  payload: Partial<NewLoanData>;
}

export interface SetIsLoanApplied {
  type: typeof SET_IS_LOAN_APPLIED;
  payload: boolean;
}

export interface SetSubmitStatusAction {
  type: typeof SET_SUBMIT_STATUS;
  payload: boolean;
}
export interface SetIsInitiatedAction {
  type: typeof SET_IS_INITIATED;
  payload: boolean;
}

export interface NewLoanData {
  selectedAmount: number;
  purposeOfLoan: string;
  loanPurposeManual: string;
}

export type ApplyLoanAction =
  | ApplyNewLoanAction
  | SetIsLoanApplied
  | SetSubmitStatusAction
  | SetIsInitiatedAction;
