import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import Icon, { SvgSrc } from '../Icon/Icon';
import { FontWeights, Typography } from '../Typography/Typography';

const ToolTipTriggerIcon = styled(Icon)`
  position: absolute;
  display: inline-flex;
  top: 0;
  right: 0;
  border: 0.125em solid ${theme.colors.secondary};
  border-radius: 50%;
  width: 0.75em;
  height: 0.75em;
`;

const ToolTipContainer = styled.div`
  position: absolute;
  right: -0.9em;
  bottom: 1.1em;
  padding: 0.8em;
  background-color: ${theme.colors.document};
  border: 0.125em solid ${theme.colors.document};
  z-index: 3;
  border-radius: 1em;
  color: ${theme.colors.secondary};
  box-shadow: 0 0.25em 1em -0.5em ${theme.colors.dark};
  word-break: break-word;
  max-width: 90%;

  &:after {
    content: '';
    border-style: solid;
    border-width: 1.6em 1em 0 1em;
    border-color: ${theme.colors.document} transparent transparent transparent;
    position: absolute;
    bottom: -1.2em;
    right: 0.55em;
  }
`;

const CloseButtonContainer = styled.div`
  float: right;
  margin-right: -0.9em;
  margin-top: -0.9em;
`;

const CloseButton = styled(Icon)`
  cursor: pointer;
`;

const ToolTipWrapper = styled.div`
  position: relative;
`;

interface Props {
  explanation: string;
  icon?: 'QuestionMark' | 'ExclamationMark';
}

const ToolTip: React.FC<Props> = ({ explanation, icon = 'QuestionMark' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onToolTipClicked = () => {
    setIsOpen(!isOpen);
    if (!isOpen && ref && ref.current) {
      ref.current.focus();
    }
  };

  const onBlur = () => {
    setIsOpen(false);
  };

  return (
    <ToolTipWrapper ref={ref} onBlur={onBlur} tabIndex={0}>
      <ToolTipTriggerIcon
        src={SvgSrc[icon]}
        color={theme.colors.secondary}
        padding={0.3}
        onClick={onToolTipClicked}
        clickable
      />
      {isOpen && (
        <ToolTipContainer>
          <CloseButtonContainer onClick={onToolTipClicked}>
            <CloseButton
              src={SvgSrc.Times}
              size={ComponentSize.Small}
              margin={0.5}
              color={theme.colors.secondary}
            />
          </CloseButtonContainer>
          <Typography
            element="p"
            fontSize={
              window.matchMedia('(max-width: 688px)').matches ? 0.7 : 0.8
            }
            color={
              window.matchMedia('(max-width: 688px)').matches
                ? theme.colors.dark
                : theme.colors.secondaryBlack
            }
            lineHeight={1.5}
            fontWeight={
              window.matchMedia('(max-width: 688px)').matches
                ? FontWeights.Thick
                : FontWeights.Thin
            }
          >
            {explanation}
          </Typography>
        </ToolTipContainer>
      )}
    </ToolTipWrapper>
  );
};

export default ToolTip;
