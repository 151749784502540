import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { Typography } from '~/components/shared/Typography/Typography';
import useTranslate from '~/hooks/useTranslate';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import { Notification, Placement } from './NotificationMenu';

const NotificationItemContainer = styled.div<{ placement: Placement }>`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.white};
  padding: 1em;
  cursor: pointer;
  border-top-left-radius: ${(props) =>
    props.placement === Placement.Top || props.placement === Placement.Single
      ? '0.5em'
      : 0};
  border-bottom-left-radius: ${(props) =>
    props.placement === Placement.Buttom || props.placement === Placement.Single
      ? '0.5em'
      : 0};
  border-bottom-right-radius: ${(props) =>
    props.placement === Placement.Buttom || props.placement === Placement.Single
      ? '0.5em'
      : 0};
  margin-top: ${(props) =>
    props.placement === Placement.Top || props.placement === Placement.Single
      ? '-0.8em'
      : 0};
  flex-shrink: 0;
  border-bottom: ${(props) =>
    props.placement === Placement.Top || props.placement === Placement.Middle
      ? `1px solid ${theme.colors.primaryGray}`
      : 'none'};

  @media (orientation: landscape) {
    // padding: 1em 4em;
  }
`;

const IconContainer = styled.div``;

const TextContainer = styled.div`
  flex-grow: 1;
`;

const TextRow = styled.div`
  padding: 0.2em 0.5em;
`;

const ArrowContainer = styled.div``;

const TransitionedUnderline = styled(Typography)`
  background-image: linear-gradient(
    transparent calc(100% - 0.3em),
    ${theme.colors.primaryGreen} 2px
  );
  padding-bottom: 0.3em;
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.45s;

  ${NotificationItemContainer}:hover & {
    background-size: 100% 100%;
  }
`;

interface Props {
  placement: Placement;
  notification: Notification;
}

const NotificationItem: React.FC<Props> = (props) => {
  const history = useHistory();
  const translate = useTranslate();

  const onClick = () => {
    props.notification.onClick?.();
    history.push(props.notification.redirect);
  };

  return (
    <NotificationItemContainer placement={props.placement} onClick={onClick}>
      <IconContainer>{props.notification.icon}</IconContainer>
      <TextContainer>
        <TextRow>
          <TransitionedUnderline fontSize={0.9} fontWeight={600}>
            {translate(
              props.notification.textKey,
              props.notification.textKeyOptions
            )}
          </TransitionedUnderline>
        </TextRow>
        <TextRow>
          <Typography fontSize={0.9} fontWeight={400}>
            {translate(
              props.notification.subTextKey,
              props.notification.subTextKeyOptions
            )}
          </Typography>
        </TextRow>
      </TextContainer>
      <ArrowContainer>
        <Icon src={SvgSrc.ChevronRight} size={ComponentSize.Small} />
      </ArrowContainer>
    </NotificationItemContainer>
  );
};

export default NotificationItem;
