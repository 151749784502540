import React from 'react';
import { Stack, Typography, Button, Group } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { ClickableText } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import { RootState } from '~/store';
import { useSelector } from 'react-redux';
import { getSupportUrl } from '~/constants/markets';
import { CountryCode } from '~/enums';

interface AdditionalSignerOTPErrorModalProps {
  onClose: () => void;
}

const AdditionalSignerOTPErrorModal = ({
  onClose,
}: AdditionalSignerOTPErrorModalProps) => {
  const translate = useTranslate();

  const {
    intl: { language, market },
  } = useSelector((state: RootState) => state);

  const contactUsOnClick = () => {
    if (!language) return;
    const supportUrl = getSupportUrl(market as CountryCode, language);
    window.open(supportUrl, '_blank');
  };

  return (
    <Stack>
      <Typography>
        {translate('AdditionalSigner.ErrorBody', {
          support: (
            <ClickableText
              typographyColor={theme.colors.midwayLinkDark}
              onClick={contactUsOnClick}
              noUnderline
            >
              {translate('AdditionalSigner.SupportTeam') as string}
            </ClickableText>
          ),
        })}
      </Typography>

      <Group justify="flex-end" mt={10}>
        <Button onClick={onClose}>
          {translate('AdditionalSigner.ErrorClose') as string}
        </Button>
      </Group>
    </Stack>
  );
};

export default AdditionalSignerOTPErrorModal;
