import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from '~/store/types/sharedTypes';
import { compose } from 'redux';
import Logo from '~/components/shared/LogoOld/LogoOld';
import {
  BodyContainer,
  MainContainer,
  PageFooter,
} from '~/styles/BaseStyledComponents';
import withValidation, {
  WithValidationProps,
} from '~/components/hoc/withValidation';
import withGtmEvent from '~/components/hoc/withGtmEvent';
import {
  fetchPublicCardOfferData,
  setCardOfferUserData,
} from '~/store/actions/cardOfferActions';
import useQueryParams from '~/hooks/useQueryParams';
import { defaultLanguageToMarket, setMarket } from '~/store/slices/intl.slice';
import DownArrow from '~/components/shared/DownArrow/DownArrow';
import pushToGtmHelper from '~/helpers/pushToGtm.helper';
import MeansOfSupport from '~/components/shared/MeansOfSupport/MeansOfSupport';
import CardSection from '~/components/shared/CardSection/CardSection';
import {
  FontWeights,
  Typography,
} from '~/components/shared/Typography/Typography';
import Card from '~/components/shared/Card/Card';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { parseMarket } from '~/helpers/market.helper';
import CardOfferErrorModal from './components/CardOfferErrorModal';
import CreditCardDetailsSimpleCard from './components/CreditCardDetailsSimpleCard';
import CreditCardPurposeCard from './components/CreditCardPurposeCard';
import CreditCardBeneficialOwnersCard from './components/CreditCardBeneficialOwnersCard';
import CreditCardInvoiceInfoCard from './components/CreditCardInvoiceInfoCard';
import CardAdditionalSignersCard from './components/CreditCardAdditionalSignersCard';
import CreditCardTermsAndConditionsCard from './components/CreditCardTermsAndConditionsCard';
import PageTitle from '../../components/shared/PageTitle/PageTitle';
import PageHeader from '../../components/shared/PageHeader/PageHeader';

const PublicCardOffer: React.FC<
  WithValidationProps & RouteComponentProps<any>
> = () => {
  /* Other hooks */
  const t = useTranslate();
  const queryParams = useQueryParams();
  const dispatch = useDispatch();

  const market = parseMarket(queryParams.get('market'));
  const companyId = Number(queryParams.get('clientId'));
  const identificationError = queryParams.get('identification_error');

  /* States */
  const [hasIdentificationError, setHasIdentificationError] = useState(false);

  /* Selectors */

  /** *********************************************************************************************

  ATTENTION: YOU MUST NOT ADD ANY OTHER SELECTOR TO THIS COMPONENT OR ANY OF ITS SUB-COMPONETS

  ********************************************************************************************** */

  const cardOffer = useSelector((state: RootState) =>
    state.cardOffer.overview.find((o) => o.companyId === companyId)
  );

  // We need this from userData not overview cause it will be changed by user actions
  const {
    hasAcceptedTerms,
    hasAcceptedPersonalGuarantee,
    isPersonalGuaranteeRequired,
  } = useSelector((state: RootState) => state.cardOffer.userData);

  const { isSubmitting } = useSelector((state: RootState) => state.cardOffer);
  const { languageIsSet } = useSelector((state: RootState) => state.intl);

  /* Effects */
  useEffect(() => {
    if (companyId && market) {
      dispatch(fetchPublicCardOfferData([companyId], market));
    }
    if (market) {
      localStorage.setItem('qredMarket', market);
      dispatch(setMarket(market));
      dispatch(defaultLanguageToMarket(market));
      pushToGtmHelper(`q_user_v_${market.toLowerCase()}`);
    }
  }, [companyId, market]);

  useEffect(() => {
    if (companyId && cardOffer) {
      dispatch(setCardOfferUserData(cardOffer));
    }
  }, [companyId, cardOffer]);

  useEffect(() => {
    if (identificationError) {
      setHasIdentificationError(true);
    }
  }, [identificationError]);

  const additionalSigners = cardOffer && cardOffer.additionalSigners;

  return languageIsSet ? (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
        <PageTitle mb={2}>
          {
            t('PublicCardOffer.PageTitle', {
              companyName: cardOffer ? cardOffer.companyName : '',
            }) as string
          }
        </PageTitle>
      </PageHeader>
      {cardOffer && market && companyId && (
        <BodyContainer>
          <CreditCardDetailsSimpleCard
            offeredCreditLimit={cardOffer.offeredCreditLimit}
            interest={cardOffer.interest}
          />
          <DownArrow />
          <CreditCardInvoiceInfoCard />
          <DownArrow />
          {additionalSigners && additionalSigners.length > 0 && (
            <>
              <CardAdditionalSignersCard />
              <DownArrow />
            </>
          )}
          <CreditCardBeneficialOwnersCard companyType={cardOffer.companyType} />
          {cardOffer.companyType !== 'EF' && <DownArrow />}
          <CreditCardPurposeCard />
          <DownArrow />
          <CreditCardTermsAndConditionsCard
            isPublic
            applicationUuid={cardOffer.applicationUuid}
            market={market}
            clientId={companyId}
            hasAcceptedTerms={hasAcceptedTerms}
            hasAcceptedPersonalGuarantee={hasAcceptedPersonalGuarantee}
            isPersonalGuaranteeRequired={isPersonalGuaranteeRequired}
            isSubmitting={isSubmitting}
          />
          {hasIdentificationError && (
            <Card mt={2}>
              <CardSection>
                <Typography
                  fontSize={1}
                  fontWeight={FontWeights.Normal}
                  lineHeight={1.5}
                >
                  {t('ContactNeedHelp')}
                </Typography>
                <MeansOfSupport />
              </CardSection>
            </Card>
          )}
        </BodyContainer>
      )}
      <PageFooter />
      {hasIdentificationError && <CardOfferErrorModal />}
    </MainContainer>
  ) : (
    <SpinnerCircular />
  );
};

export default compose(withGtmEvent, withValidation)(PublicCardOffer);
