import React from 'react';
import styled from 'styled-components';
import { theme } from '~/styles/themes';

interface SpinnerLinnearProps {
  filled?: boolean;
  smallSize?: boolean;
}

const SpinnerContainer = styled.div<SpinnerLinnearProps>`
  font-size: 0.5em;
  display: inline-block;
  position: relative;
  width: 8em;
  height: ${(props) => (props.smallSize ? '0.7em' : '1.3em')};
  & div {
    position: absolute;
    top: 0em;
    width: ${(props) => (props.smallSize ? '0.7em' : '1.3em')};
    height: ${(props) => (props.smallSize ? '0.7em' : '1.3em')};
    border-radius: 50%;
    background: ${(props) =>
      props.filled ? theme.colors.primaryGreen : theme.colors.white};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & div:nth-child(1) {
    left: 0.8em;
    animation: lds-ellipsis1 0.8s infinite;
  }

  & div:nth-child(2) {
    left: 0.8em;
    animation: lds-ellipsis2 0.8s infinite;
  }

  & div:nth-child(3) {
    left: 3.2em;
    animation: lds-ellipsis3 0.8s infinite;
  }

  & div:nth-child(4) {
    left: 5.6em;
    animation: lds-ellipsis4 0.8s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      left: 0.8em;
    }
    100% {
      left: 3.2em;
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      left: 3.2em;
    }
    100% {
      left: 5.6em;
    }
  }

  @keyframes lds-ellipsis4 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
`;

const SpinnerLinear: React.FC<SpinnerLinnearProps> = (props) => (
  <SpinnerContainer filled={props.filled} smallSize={props.smallSize}>
    <div />
    <div />
    <div />
    <div />
  </SpinnerContainer>
);

export default SpinnerLinear;
