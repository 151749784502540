import { z } from 'zod';

export const klarnaAccountSchema = z.strictObject({
  id: z.string().optional(),
  currency: z.string().optional(),
  alias: z.string().optional(),
  account_number: z.string().optional(),
  iban: z.string().optional(),
  holder_name: z.string().optional(),
  holder_address: z
    .object({
      street_address: z.string().optional(),
      street_address2: z.string().optional(),
      postalcode: z.string().optional(),
      city: z.string().optional(),
      region: z.string().optional(),
      country: z.string().optional(),
    })
    .optional(),
  bank_code: z.string().optional(),
  bic: z.string().optional(),
  national_branch_code: z.string().optional(),
  bank_name: z.string().optional(),
  bank_address: z
    .object({
      street_address: z.string().optional(),
      street_address2: z.string().optional(),
      postalcode: z.string().optional(),
      city: z.string().optional(),
      region: z.string().optional(),
      country: z.string().optional(),
    })
    .optional(),
  transfer_type: z
    .enum(['NONE', 'DOMESTIC', 'FULL', 'REFERENCE', 'RESTRICTED'])
    .optional(),
  account_type: z.enum(['DEFAULT', 'SAVING', 'CREDITCARD', 'DEPOT']).optional(),
  balance: z
    .object({
      amount: z.number(),
      currency: z.string(),
    })
    .optional(),
  capabilities: z
    .object({
      account_details: z
        .object({
          available: z.boolean().optional(),
        })
        .optional(),
      balances: z
        .object({
          available: z.boolean().optional(),
        })
        .optional(),
      transactions: z
        .object({
          available: z.boolean().optional(),
        })
        .optional(),
      transfer: z
        .object({
          available: z.boolean().optional(),
        })
        .optional(),
    })
    .optional(),
});
