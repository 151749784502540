import {
  ApiObjectData,
  Application,
  CardOffer,
  Company,
  LoanBase,
  Offer,
  Payment,
  PreviousLoans,
  ShippingDetails,
  TerminatedContract,
  User,
} from '~/Interfaces';
import { nordiskaBankId } from '~/constants/constVars';

export const paymentObjectMapper = (
  nextPaymentObj: ApiObjectData
): Payment => ({
  loanId: nextPaymentObj.contract_id,
  index: nextPaymentObj.line_no,
  datePaid: nextPaymentObj.date_paid,
  dueDate: nextPaymentObj.due_date,
  amortization:
    nextPaymentObj.invoice?.amortization ?? nextPaymentObj.amortization,
  invoiceLineId:
    nextPaymentObj.invoice?.r_contract_line_id ??
    nextPaymentObj.contract_line_id,
  interest: nextPaymentObj.interest,
  totalMonthAmount:
    nextPaymentObj.invoice?.total_month ?? nextPaymentObj.total_month,
  penalty: nextPaymentObj.invoice?.penalty ?? nextPaymentObj.penalty,
  amortizationPaid: nextPaymentObj.payments_amount_principal,
});

export const loanDataMapper = (loan: ApiObjectData): LoanBase => ({
  id: loan.loan_id,
  companyId: loan.company_id,
  orgNumber: loan.company_no,
  companyName: loan.company_name,
  total: loan.loan_amount,
  remaining: loan.amount_left,
  loanTerms: loan.loan_terms,
  fees: loan.fees,
  interestPercent: loan.interest_percent,
  penalty: loan.penalty_actual,
  status: loan.status,
  granter: loan.granter_bank_id === nordiskaBankId ? 'nordiska' : 'qred',
});

export const offerDataMapper = (offer: ApiObjectData): Offer => ({
  maxAllowedAmount: offer.max_allowed_amount,
  interest: offer.interest_percent,
  companyName: offer.lender_name,
  companyNumber: offer.company_no,
  representativeName: offer.lender_rep,
  maxAllowedTerm: offer.max_allowed_term,
  bindingTerm: offer.binding_term,
  invoiceEmail: offer.email,
  purposeOfLoan: offer.loan_purpose,
  // temporary fix, need to investigate where and why we recieve "null" in here.
  loanPurposeManual:
    offer.loan_purpose_manual === 'null' || !offer.loan_purpose_manual
      ? ''
      : offer.loan_purpose_manual,
  accountNumber: offer.bank_account,
  applicationId: offer.application_id,
  clientId: offer.client_id,
  personalNumber: offer.personal_id,
  dateOfBirth: offer.date_of_birth,
  clientName: offer.lender_rep,
  signers: offer.signers,
  language: offer.language,
  isLunarPartner: offer.isLunarPartner,
  campaignType: offer.campaign_type,
  campaignPrincipalFreeMonths: offer.campaign_principal_free_months || 0,
  campaignInterestRateDiscount: offer.campaign_interest_rate_discount,
  campaignDiscount: offer.campaign_discount,
  currentAmountLeft: offer.current_loan_amount_left || 0, // If there is no amount left assign zero to the property so the calculations become easier
  amount: offer.amount,
  amountOriginal: offer.amount_original,
  source: offer.source,
  uuid: offer.application_uuid,
});

// Commented properties are available in the api for future use
export const cardOfferDataMapper = (cardOffer: ApiObjectData): CardOffer => ({
  // creditLimit: cardOffer.creditLimit,
  // firstName: cardOffer.firstName,
  // lastName: cardOffer.lastName,
  // phoneNumber: cardOffer.phoneNumber,
  additionalSigners: cardOffer.additionalSigners || [],
  applicant: cardOffer.applicant || null,
  applicantOwnerShare: cardOffer.applicantOwnerShare || '',
  applicationReasons: cardOffer.applicationReasons || [],
  applicationUuid: cardOffer.applicationUuid || '',
  beneficialOwners: cardOffer.beneficialOwners || [],
  cardOfferAlreadySigned: cardOffer.cardOfferAlreadySigned || false,
  companyId: cardOffer.clientId || '',
  companyName: cardOffer.companyName || '',
  companyNo: cardOffer.companyNo || '',
  companyType: cardOffer.companyType || '',
  creditLimit: cardOffer.offeredCreditLimit || 0, // creditLimit is null for now, will be used in future
  hasAcceptedPersonalGuarantee: cardOffer.hasAcceptedPersonalGuarantee || false,
  hasAcceptedTerms: cardOffer.hasAcceptedTerms || false,
  interest: cardOffer.interest || 0,
  invoiceAddress: cardOffer.invoiceAddress || '',
  invoiceDay: cardOffer.invoiceDay || 'MID_OF_MONTH',
  invoiceEmail: cardOffer.invoiceEmail || '',
  isPaperInvoiceChosen: cardOffer.isPaperInvoiceChosen || false,
  isPersonalGuaranteeRequired: !!cardOffer.isPersonalGuaranteeRequired,
  offeredCreditLimit: cardOffer.offeredCreditLimit || 0,
  ownerStructure: cardOffer.ownerStructure,
  shippingAddress: cardOffer.shippingAddress || '',
});

export const applicationDataMapper = (
  application: ApiObjectData,
  company: ApiObjectData
): Application => ({
  amount: application.amount,
  applicationDate: application.applic_date,
  loanPurpose: application.loan_purpose,
  applicationId: application.r_application_id,
  contractId: application.r_contract_id,
  signers: application.signers,
  status: application.status,
  term: application.term,
  id: company.company_id,
  name: company.company_name,
  number: company.company_no,
});

export const terminatedContractMapper = (
  contract: ApiObjectData
): TerminatedContract => ({
  amountToPay: contract.amountToPay,
  clientId: contract.client_id,
  contractId: contract.contract_id,
  earlyRepaymentAmount: contract.earlyRepaymentAmount,
  granterBank: contract.granter_bank,
  ocr: contract.ocr,
  status: contract.status,
  terminatedDate: contract.termination_date,
  showTermination: contract.show_termination,
  sentTime: contract.sent_time,
});

export const companyDataMapper = (company: ApiObjectData): Company => ({
  id: company.company_id,
  number: company.company_no,
  name: company.company_name,
  street: company.company_street,
  zip: company.company_zip,
  city: company.company_city,
  email: company.company_email,
  phone: company.company_phone,
  loanId: company.loan_id,
  loanAmount: company.loan_amount,
  loanStatus: company.status,
  loanAmountLeft: company.amount_left,
});

export const userDataMapper = (
  user: ApiObjectData
): Omit<User, 'personalNumber'> => ({
  firstName: user.first_name || '',
  lastName: user.last_name || '',
  phone: user.phone || '',
  email: user.email || '',
  city: user.city || '',
  zip: user.zip || '',
  street: user.street || '',
  country: user.country || '',
  language: user.language || '',
});

export const previousLoanDataMapper = (loan: ApiObjectData): PreviousLoans => ({
  amountPaid: loan.amount_paid,
  status: loan.status,
  id: loan.contract_id,
  amountLeft: loan.amount_left,
  amountTotal: loan.amount,
  term: loan.term,
  companyId: loan.client_id,
  invoices: loan.contract_line,
  startDate: loan.start_date,
  endDate: loan.end_date,
  closedDate: loan.closed_date,
  monthsToPayout: loan.months_to_payout,
});

// Commented properties are available in the api for future use
export const cardShippingDetailsDataMapper = (
  data: ApiObjectData
): ShippingDetails => ({
  cardHolderFullName: data.cardHolderFullName,
  companyType: data.companyType,
  companyName: data.companyName,
  mobilePhone: data.mobilePhone,
  email: data.email,
  addresses: data.addresses,
  cardAlreadyCreated: data.cardAlreadyCreated,
});
