import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import InputField from '~/components/shared/Input/InputField';
import {
  getPersonalNumberPlaceholder,
  personalNumberIsOnlyNumbers,
  PhoneNumberPlaceholders,
} from '~/constants/markets';
import {
  Button,
  useWindowProperties,
  Typography,
  Stack,
} from '@qred/components-library';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import useTranslate from '~/hooks/useTranslate';
import withValidation, {
  FormStatus,
  ValidationContext,
} from '~/components/hoc/withValidation';
import useModals from '~/hooks/useModals';
import NumberInputField from '~/components/shared/Input/NumberInputField';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import { BaseArrangement } from '~/styles/BaseStyledComponents';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { onChangeInputEvent } from '~/types/types';
import moment from 'moment';
import useDateFormatter from '~/hooks/useDateFormatter';
import {
  personAlreadyExistsInList,
  personsAreTheSame,
} from '~/helpers/onboarding.helper';
import {
  fullNameValidator,
  personalNumberValidator,
} from '~/helpers/validators.helper';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { updateForm } from '~/store/slices/onboardingApplication.slice';
import { formatPersonalNumber } from '~/helpers/formatters.helper';
import { v4 as uuidv4 } from 'uuid';
import { Footer, FooterContent } from './SelectGuarantor.styled';
import Address from '../Address/Address';
import DateOfBirth from '../DateOfBirth/DateOfBirth';
import { ILoanOnboardingApplicationGuarantor } from '~/interfaces/LoanOnboardingApplication';
import { IOnboardingAddressFields } from '~/interfaces/Onboarding';

interface AddOrEditGuarantorModalBodyProps {
  initialGuarantor?: ILoanOnboardingApplicationGuarantor;
}

const AddOrEditGuarantorModalBody = ({
  initialGuarantor,
}: AddOrEditGuarantorModalBodyProps) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const modals = useModals();
  const { isMobile } = useWindowProperties();

  const [
    guarantor,
    setGuarantor,
  ] = useState<ILoanOnboardingApplicationGuarantor>(
    initialGuarantor || {
      guarantorId: uuidv4(),
    }
  );

  const validationContext = useContext(ValidationContext);

  const formatDate = useDateFormatter();

  const {
    marketHasPersonalNumber,
    marketHasAddress,
    marketHasAddGuarantorButtonCopy,
  } = useOnboardingMarketConfig();

  const { form } = useSelector(
    (state: RootState) => state.onboardingApplication
  );
  const { market, language } = useSelector((state: RootState) => state.intl);

  const isNewGuarantor = !initialGuarantor;
  const showValidationInfoWhenUntouched = !!initialGuarantor?.guarantorId;

  const validationErrorGuarantorAlreadyAddedShouldBeShown =
    validationContext.validationErrors.GuarantorAlreadyAdded;
  const validationErrorGuarantorSameAsApplicantShouldBeShown =
    validationContext.validationErrors.GuarantorSameAsApplicant;
  const validationErrorEmailAlreadyUsedShouldBeShown =
    validationContext.validationErrors.EmailAlreadyUsed;
  const validationErrorPhoneAlreadyUsedShouldBeShown =
    validationContext.validationErrors.PhoneAlreadyUsed;

  const onChange = (event: onChangeInputEvent) => {
    const { name, value } = event.target;
    setGuarantor({
      ...guarantor,
      [name]: value,
    });
  };

  const onBlur = (event: onChangeInputEvent) => {
    const { name, value } = event.target;
    dispatch(
      pushToGtmOnboardingAction({
        actionName: isNewGuarantor
          ? `modal_new_guarantor_${name}_change`
          : `modal_edit_guarantor_${name}_change`,
      })
    );

    setGuarantor({
      ...guarantor,
      [name]: value.trim(),
    });
  };

  const onAddressFieldChange = (partialAddress: IOnboardingAddressFields) => {
    setGuarantor({
      ...guarantor,
      addressFields: {
        ...guarantor.addressFields,
        ...partialAddress,
      },
    });
  };

  const dateOfBirthOnChangeHandler = useCallback(
    (date: Date | null) => {
      if (date instanceof Date) {
        setGuarantor({
          ...guarantor,
          dateOfBirth: moment(date).format('YYYY-MM-DD'),
        });
        dispatch(
          pushToGtmOnboardingAction({
            actionName: isNewGuarantor
              ? `modal_new_guarantor_date_of_birth_change`
              : `modal_edit_guarantor_date_of_birth_change`,
          })
        );
      }
    },
    [guarantor]
  );

  const onAddressFieldBlur = (name: string) => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: isNewGuarantor
          ? `modal_new_guarantor_${name}_change`
          : `modal_edit_guarantor_${name}_change`,
      })
    );
  };

  const guarantorErrors = useMemo(() => {
    const localGuarantorErrors = {
      guarantorAlreadyAdded: false,
      guarantorSameAsApplicant: false,
      emailAlreadyUsed: false,
      phoneAlreadyUsed: false,
    };
    const guarantorIdIsInvalid = guarantor.personalNumber
      ? personalNumberValidator(guarantor.personalNumber)
      : fullNameValidator(guarantor.fullName) || !guarantor.dateOfBirth;

    let existingGuarantors = form.guarantors;
    if (initialGuarantor?.guarantorId) {
      existingGuarantors = form.guarantors.filter(
        (g) => g.guarantorId !== initialGuarantor.guarantorId
      );
    }

    localGuarantorErrors.guarantorAlreadyAdded =
      !guarantorIdIsInvalid &&
      personAlreadyExistsInList(existingGuarantors, guarantor);
    localGuarantorErrors.guarantorSameAsApplicant =
      !guarantorIdIsInvalid && personsAreTheSame(guarantor, form.applicant);

    localGuarantorErrors.emailAlreadyUsed = [
      form.applicant,
      ...existingGuarantors,
    ].some((g) => guarantor.email && g.email === guarantor.email);

    localGuarantorErrors.phoneAlreadyUsed = [
      form.applicant,
      ...existingGuarantors,
    ].some((g) => guarantor.phone && g.phone === guarantor.phone);

    return localGuarantorErrors;
  }, [guarantor, form.guarantors, form.applicant, marketHasPersonalNumber]);

  useEffect(() => {
    if (guarantorErrors.guarantorAlreadyAdded) {
      validationContext.addPropertyToValidationErrors('GuarantorAlreadyAdded');
    }
    if (guarantorErrors.guarantorSameAsApplicant) {
      validationContext.addPropertyToValidationErrors(
        'GuarantorSameAsApplicant'
      );
    }
    if (guarantorErrors.emailAlreadyUsed) {
      validationContext.addPropertyToValidationErrors('EmailAlreadyUsed');
    }
    if (guarantorErrors.phoneAlreadyUsed) {
      validationContext.addPropertyToValidationErrors('PhoneAlreadyUsed');
    }

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'GuarantorAlreadyAdded'
      );
      validationContext.removePropertyFromValidationErrors(
        'GuarantorSameAsApplicant'
      );
      validationContext.removePropertyFromValidationErrors('EmailAlreadyUsed');
      validationContext.removePropertyFromValidationErrors('PhoneAlreadyUsed');
    };
  }, [guarantorErrors]);

  const saveEditedGuarantors = () => {
    validationContext.setFormStatus(FormStatus.SUBMITTING);

    if (validationContext.isFormValid) {
      if (isNewGuarantor) {
        dispatch(
          pushToGtmOnboardingAction({
            actionName: 'new_guarantor_save_pressed',
          })
        );

        dispatch(
          updateForm({
            guarantors: [
              ...form.guarantors,
              {
                ...guarantor,
                isChecked: true,
                manuallyAdded: true,
              },
            ],
          })
        );
      } else {
        dispatch(
          pushToGtmOnboardingAction({
            actionName: 'edited_guarantor_save_pressed',
          })
        );

        dispatch(
          updateForm({
            guarantors: form.guarantors.map(
              (formGuarantor: ILoanOnboardingApplicationGuarantor) => {
                if (
                  formGuarantor.guarantorId === initialGuarantor.guarantorId
                ) {
                  return {
                    ...formGuarantor,
                    ...guarantor,
                    isChecked: true,
                  };
                }
                return formGuarantor;
              }
            ),
          })
        );
      }

      modals.closeModal('addOrEditGuarantor-modal');
    } else {
      validationContext.setFormStatus(FormStatus.SUBMITTED);
    }
  };

  const shouldShowPersonalNumberField =
    marketHasPersonalNumber &&
    (isNewGuarantor ||
      guarantor.personalNumberManuallyAdded ||
      guarantor.manuallyAdded ||
      !guarantor.maskedPersonalNumber);

  const shouldShowDateOfBirthField =
    !marketHasPersonalNumber &&
    (isNewGuarantor ||
      guarantor.dateOfBirthManuallyAdded ||
      guarantor.manuallyAdded ||
      !guarantor.maskedDateOfBirth);

  return (
    <Stack spacing="sm">
      <Typography>
        {t(`Onboarding.AddAdditionalGuarantorsModalHeader`)}
      </Typography>
      {isNewGuarantor || guarantor.manuallyAdded ? (
        <InputField
          name="fullName"
          dataCy="add_or_edit_guarantor_full_name"
          label={t('FullName') as string}
          placeholder={t('FullName') as string}
          value={guarantor.fullName || ''}
          onChange={onChange}
          onBlur={onBlur}
          validationType="FullName"
        />
      ) : (
        <>
          <BaseArrangement mt={1}>
            <Typography weight={600}>{guarantor.fullName}</Typography>
          </BaseArrangement>
          {!guarantor.personalNumberManuallyAdded &&
            !guarantor.dateOfBirthManuallyAdded && (
              <BaseArrangement mb={0.5}>
                <Typography size="sm">
                  {marketHasPersonalNumber
                    ? guarantor.maskedPersonalNumber
                    : guarantor.maskedDateOfBirth}
                </Typography>
              </BaseArrangement>
            )}
        </>
      )}

      {shouldShowPersonalNumberField && (
        <InputField
          name="personalNumber"
          dataCy="add_or_edit_guarantor_personal_number"
          onChange={onChange}
          onBlur={onBlur}
          label={t('PersonalNumber') as string}
          placeholder={getPersonalNumberPlaceholder(market, language)}
          validationType="PersonalNumber"
          value={formatPersonalNumber(guarantor.personalNumber || '', market)}
          inputMode={personalNumberIsOnlyNumbers(market) ? 'numeric' : 'text'}
          showValidationInfoWhenUntouched={!isNewGuarantor}
        />
      )}

      {shouldShowDateOfBirthField && (
        <>
          <DateOfBirth
            useModal={false}
            dateOfBirthOnChangeHandler={dateOfBirthOnChangeHandler}
            value={
              (guarantor.dateOfBirth &&
                formatDate(guarantor.dateOfBirth, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })) ||
              ''
            }
            showValidationInfoWhenUntouched={!isNewGuarantor}
            openOnFocus
            dataCy="add_or_edit_guarantor_date_of_birth"
          />
        </>
      )}

      {validationErrorGuarantorAlreadyAddedShouldBeShown && (
        <ValidationErrorMessage>
          {t('ValidationErrors.GuarantorAlreadyExist')}
        </ValidationErrorMessage>
      )}
      {validationErrorGuarantorSameAsApplicantShouldBeShown && (
        <ValidationErrorMessage>
          {t('ValidationErrors.GuarantorSameAsApplicant')}
        </ValidationErrorMessage>
      )}

      <NumberInputField
        name="phone"
        tel
        dataCy="add_or_edit_guarantor_phone"
        label={t('Phone') as string}
        validationType="Phone"
        placeholder={PhoneNumberPlaceholders[market]}
        onChange={onChange}
        onBlur={onBlur}
        value={guarantor.phone || ''}
        showValidationInfoWhenUntouched={!isNewGuarantor}
      />
      {validationErrorPhoneAlreadyUsedShouldBeShown && (
        <ValidationErrorMessage>
          {t('ValidationErrors.PhoneAlreadyUsed')}
        </ValidationErrorMessage>
      )}
      <InputField
        name="email"
        type="email"
        dataCy="add_or_edit_guarantor_email"
        label={t('Email') as string}
        validationType="Email"
        placeholder={t('Onboarding.EmailPlaceholder') as string}
        onChange={onChange}
        onBlur={onBlur}
        value={guarantor.email || ''}
        showValidationInfoWhenUntouched={!isNewGuarantor}
      />
      {validationErrorEmailAlreadyUsedShouldBeShown && (
        <ValidationErrorMessage>
          {t('ValidationErrors.EmailAlreadyUsed')}
        </ValidationErrorMessage>
      )}

      {marketHasAddress && (
        <Address
          addressFields={guarantor.addressFields}
          onAddressFieldChange={onAddressFieldChange}
          onAddressFieldBlur={onAddressFieldBlur}
          applicantAuthenticated={form.applicantAuthenticated}
          showValidationInfoWhenUntouched={showValidationInfoWhenUntouched}
        />
      )}

      <Footer>
        <FooterContent>
          <Stack align="center">
            <Button
              dataCy="add_or_edit_guarantor_save_button"
              disabled={!validationContext.isFormValid}
              onClick={saveEditedGuarantors}
              size="md"
              fullWidth={isMobile}
            >
              {marketHasAddGuarantorButtonCopy ? t('Add') : t('Save')}
            </Button>
          </Stack>
        </FooterContent>
      </Footer>
    </Stack>
  );
};

export default withValidation(AddOrEditGuarantorModalBody);
