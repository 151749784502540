import React, { useCallback } from 'react';
import { IconKey, Menu as MenuQitUI } from '@qred/qit-ui';
import { Menu } from '@qred/components-library';
import { useDispatch } from 'react-redux';
import { saveLanguage } from '~/store/actions/userActions';
import { pushToGtmAction } from '~/store/actions/gtmActions';
import { setLanguage } from '~/store/slices/intl.slice';
import { Language, LanguageLabel } from '~/store/types/sharedTypes';

export const LanguageItem: React.FC<{
  label: LanguageLabel;
  value: Language;
  icon: IconKey;
  newStyle?: boolean;
  loggedOut?: boolean;
}> = ({ label, value, icon, newStyle = false, loggedOut = false }) => {
  const dispatch = useDispatch();

  const onLanguageChange = useCallback(() => {
    if (loggedOut) {
      dispatch(setLanguage(value));
    } else {
      dispatch(saveLanguage(value));
    }
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'language_changed',
      })
    );
  }, [value]);

  return (
    <>
      {newStyle ? (
        // TODO: (QIT-UI) ignored until we remove qit-ui as dependency
        // @ts-ignore
        <Menu.Item icon={icon} onClick={onLanguageChange}>
          {label.long}
        </Menu.Item>
      ) : (
        <MenuQitUI.Item icon={icon} onClick={onLanguageChange}>
          {label.long}
        </MenuQitUI.Item>
      )}
    </>
  );
};
