import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import {
  SlideshowContainer,
  SlideshowImageContainer,
} from './Slideshow.styled';

const images = [
  'torch',
  'mechanic',
  'construction',
  'business_owner',
  'hair_dresser',
];

const Slideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { matchedMedia } = useSelector((state: RootState) => state);

  const screenSizeSuffix = useMemo(() => {
    if (matchedMedia?.desktop) {
      return 'desktop';
    }
    if (matchedMedia?.tablet) {
      return 'tablet';
    }
    return 'mobile';
  }, [matchedMedia?.tablet, matchedMedia?.desktop]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevState) => {
        if (prevState === images.length - 1) {
          return 0;
        }
        return prevState + 1;
      });
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <SlideshowContainer>
      {images.map((imageName, index) => (
        <SlideshowImageContainer
          key={imageName}
          active={currentImageIndex === index}
          src={`${imageName}_${screenSizeSuffix}.webp`}
        />
      ))}
    </SlideshowContainer>
  );
};

export default Slideshow;
