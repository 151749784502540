import initialState from '~/store/initialState';
import {
  LoanAction,
  SET_AMOUNT_TO_PAY_BACK,
  SET_API_AMOUNT_LEFT_STATUS,
  SET_API_APPLICATIONS_STATUS,
  SET_API_LOANS_STATUS,
  TOGGLE_NOTIFICATIONS_STATUS,
  UPDATE_APPLICATIONS_DATA,
  UPDATE_LOANS_DATA,
  UPDATE_PREVIOUS_LOANS,
  UPDATE_TERMINATED_CONTRACT_DATA,
} from '~/store/types/loanTypes';
import {
  Application,
  Loan,
  PreviousLoans,
  TerminatedContract,
} from '~/Interfaces';
import { ApiStatus } from '../types/sharedTypes';

interface LoansState {
  readonly overview: Array<Loan>;
  readonly application: Array<Application>;
  readonly terminatedContract: Array<TerminatedContract>;
  readonly areNotificationsSeen: boolean;
  readonly apiApplicationsStatus: ApiStatus;
  readonly apiLoansStatus: ApiStatus;
  readonly apiAmountLeftStatus: ApiStatus;
  readonly amountToPayBack: number;
  readonly previousLoans: Array<PreviousLoans>;
}

const loansReducer = (
  state: LoansState = initialState.loans,
  action: LoanAction
): LoansState => {
  switch (action.type) {
    case UPDATE_LOANS_DATA:
      return { ...state, overview: action.payload };

    case UPDATE_APPLICATIONS_DATA:
      return { ...state, application: action.payload };

    case TOGGLE_NOTIFICATIONS_STATUS:
      return { ...state, areNotificationsSeen: action.payload };

    case UPDATE_TERMINATED_CONTRACT_DATA:
      return {
        ...state,
        terminatedContract: [...state.terminatedContract, action.payload],
      };

    case SET_API_APPLICATIONS_STATUS:
      return { ...state, apiApplicationsStatus: action.payload };

    case SET_API_AMOUNT_LEFT_STATUS:
      return { ...state, apiAmountLeftStatus: action.payload };

    case SET_API_LOANS_STATUS:
      return { ...state, apiLoansStatus: action.payload };

    case SET_AMOUNT_TO_PAY_BACK:
      return { ...state, amountToPayBack: action.payload };

    case UPDATE_PREVIOUS_LOANS:
      return { ...state, previousLoans: action.payload };

    default:
      return state;
  }
};

export default loansReducer;
