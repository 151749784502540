import { createSlice } from '@reduxjs/toolkit';
import { helpPopupInitialState } from '../initialState';

const helpPopupSlice = createSlice({
  name: 'helpPopup',
  initialState: helpPopupInitialState,
  reducers: {
    openHelpPopup: (state) => {
      state.isOpen = true;
    },
    closeHelpPopup: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openHelpPopup, closeHelpPopup } = helpPopupSlice.actions;

export default helpPopupSlice.reducer;
