import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FormStatus,
  SimpleEvent,
  ValidationContext,
  ValidationType,
} from '~/components/hoc/withValidation';
import pushToGtmHelper from '~/helpers/pushToGtm.helper';
import useTranslate from '~/hooks/useTranslate';
import { theme } from '~/styles/themes';
import Stack from '~/components/shared/Layout/Stack';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import SpinnerCircularSmall from '../Spinner/SpinnerCircularSmall';
import InputTooltip from './InputTooltip';
import {
  InputContainer,
  InputWrapper,
  LoadingSpinnerWrapper,
  RightText,
  InputSupportContainer,
  CharacterCounterContainer,
  Input,
  LeftText,
} from './InputField.styled';
import { Typography } from '@qred/components-library';

interface InputFieldProps extends React.ComponentPropsWithoutRef<'input'> {
  label?: string | React.ReactNode;
  labelColor?: string;
  validationType?: ValidationType;
  maxLength?: number;
  characterCounter?: boolean;
  disabled?: boolean;
  rightText?: string | JSX.Element;
  leftText?: string;
  dataCy?: string;
  isLoading?: boolean;
  showValidationInfoWhenUntouched?: boolean;
  name: string;
  explanation?: string | React.ReactNode;
  thin?: boolean;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref) => {
    const {
      name,
      label,
      labelColor,
      placeholder,
      defaultValue,
      value,
      type,
      validationType,
      maxLength,
      characterCounter,
      disabled = false,
      onChange,
      onBlur,
      rightText = '',
      isLoading = false,
      showValidationInfoWhenUntouched,
      explanation,
      leftText = '',
      ...rest
    } = props;

    const [touched, setTouched] = useState(false);
    const [validationMessageType, setValidationMessageType] = useState<
      'error' | 'info' | null
    >(null);
    const validationMessageRef = useRef<HTMLSpanElement>(null);
    const inputSupportContainerRef = useRef<HTMLDivElement>(null);
    const rightContainerRef = useRef<HTMLDivElement>(null);
    const leftContainerRef = useRef<HTMLDivElement>(null);
    const {
      formStatus,
      onChangeHOC,
      removePropertyFromValidationErrors,
      validationErrors,
    } = useContext(ValidationContext);
    const translate = useTranslate();

    const id = `${name}-input-id`;

    useEffect(() => {
      const fakeEvent: SimpleEvent = {
        target: {
          name,
          value: value || defaultValue,
          dataset: { validationType },
        },
      };

      onChangeHOC(fakeEvent);

      // This clean up is needed to remove the property from errors if input field is no longer shown
      return () => {
        removePropertyFromValidationErrors(name);
      };
    }, [value, defaultValue]);

    useEffect(() => {
      const shouldShow =
        (showValidationInfoWhenUntouched ||
          touched ||
          formStatus === FormStatus.SUBMITTED) &&
        validationType &&
        validationErrors[name];
      if (shouldShow) {
        setValidationMessageType(
          showValidationInfoWhenUntouched &&
            !touched &&
            formStatus === FormStatus.IDLE
            ? 'info'
            : 'error'
        );
      } else {
        setValidationMessageType(null);
      }
    }, [
      touched,
      formStatus,
      validationErrors,
      showValidationInfoWhenUntouched,
    ]);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setTouched(true);
      onBlur && onBlur(e);
      if (validationMessageType) {
        const gtmValue = value
          ? 'q_user_a_input_field_format_error'
          : 'q_user_a_input_field_empty_error';
        pushToGtmHelper(gtmValue);
      }
    };

    const rightContainerWidth = rightContainerRef?.current?.clientWidth;
    const leftContainerWidth = leftContainerRef?.current?.clientWidth;

    return (
      <InputContainer>
        {
          // eslint-disable-next-line no-nested-ternary
          explanation ? (
            <InputTooltip label={label} tooltipCopy={explanation} />
          ) : label && typeof label === 'string' ? (
            <Typography size="sm" weight={600}>
              {label}
            </Typography>
          ) : (
            label
          )
        }
        <Stack spacing="sm">
          <InputWrapper>
            <Input
              {...rest}
              data-cy={props.dataCy}
              type={type || 'text'}
              id={id}
              ref={ref}
              data-validation-type={validationType}
              name={name}
              placeholder={placeholder}
              defaultValue={defaultValue}
              value={value}
              onChange={onChange}
              onBlur={handleBlur}
              maxLength={maxLength}
              disabled={disabled}
              rightText={!!rightText}
              leftText={!!leftText}
              /* Getting round the problem of input elements not re-rendered when default value is async by force-updating  */
              /* https://github.com/facebook/react/issues/4101 */
              key={`input-key:${defaultValue}`}
              className={
                validationMessageType
                  ? `has-${validationMessageType}`
                  : props.className ?? ''
              }
              isLoading={isLoading}
              rightContainerWidth={rightContainerWidth}
              leftContainerWidth={leftContainerWidth}
            />
            {isLoading && (
              <LoadingSpinnerWrapper ref={rightContainerRef}>
                <SpinnerCircularSmall />
              </LoadingSpinnerWrapper>
            )}
            {rightText && (
              <RightText ref={rightContainerRef}>{rightText}</RightText>
            )}
            {leftText && (
              <LeftText ref={leftContainerRef} value={value}>
                {leftText}
              </LeftText>
            )}
          </InputWrapper>
          {(validationMessageType ||
            (maxLength && value && characterCounter)) && (
            <InputSupportContainer ref={inputSupportContainerRef}>
              {validationMessageType && (
                <ValidationErrorMessage
                  ref={validationMessageRef}
                  type={validationMessageType}
                >
                  {translate(
                    `${
                      validationMessageType === 'error'
                        ? 'ValidationErrors'
                        : 'ValidationInfo'
                    }.${validationType?.split('_')[0]}`
                  )}
                </ValidationErrorMessage>
              )}
              {maxLength && value && characterCounter && (
                <CharacterCounterContainer
                  color={theme.colors.newSecondaryGray}
                  lineHeight={1.2}
                  fontSize={0.8}
                >
                  {`${value?.toString().length}/${maxLength}`}
                </CharacterCounterContainer>
              )}
            </InputSupportContainer>
          )}
        </Stack>
      </InputContainer>
    );
  }
);

export default InputField;
