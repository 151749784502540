import React from 'react';
import styled from 'styled-components';
import { Typography } from '~/components/shared/Typography/Typography';
import useTranslate from '~/hooks/useTranslate';
import { theme } from '~/styles/themes';

const EmptyItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.white};
  padding: 1em;
  text-align: center;
  border-radius: 0.5em 0 0.5em 0.5em;
  margin-top: -0.8em;
  flex-shrink: 0;
`;

const EmptyNotificationItem = () => {
  const translate = useTranslate();

  return (
    <EmptyItemContainer>
      <Typography color={theme.colors.secondaryGray}>
        {translate('NoNotifications')}
      </Typography>
    </EmptyItemContainer>
  );
};

export default EmptyNotificationItem;
