import React from 'react';
import useModals from '~/hooks/useModals';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { logToSentry } from '~/helpers/loggers.helper';
import { termsAndConditionPdfSources } from '~/constants/markets';
import PDFViewer from '~/components/shared/PDFViewer/PDFViewer';
import { notifications } from '@qred/components-library';
import useTranslate from './useTranslate';
import { LoanGranter } from '~/types/types';

const useTermsAndConditionsModal = () => {
  const modals = useModals();
  const {
    intl: { market },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);
  const translate = useTranslate();

  const openTermsAndConditionsModal = (granter: LoanGranter) => {
    if (termsAndConditionPdfSources[market][granter]) {
      const fileSrc = termsAndConditionPdfSources[market][granter];
      modals.openModal({
        id: 'terms_and_conditions',
        size: 'xl',
        fullScreen: mobile,
        children: <PDFViewer file={fileSrc} />,
      });
    } else {
      notifications.show({
        state: 'critical',
        message: translate('SomethingWentWrong'),
        opened: true,
      });
      logToSentry(
        new Error(
          `Terms and conditions file not defined for granter "${granter}" in market "${market}"`
        ),
        'TermsAndConditionsFileNotDefined'
      );
    }
  };

  return {
    openTermsAndConditionsModal,
  };
};

export default useTermsAndConditionsModal;
