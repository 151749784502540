import sharedConstVars from '@qred/shared-constants';
import { MatchedMediaBreakPoints } from '~/Interfaces';

const constVars = {
  ...sharedConstVars,
  PARAMS_SIGNED: 'signed',
  PARAMS_SIGNER_SIGNED_SUCCESS: 'signersuccess',
  PARAMS_SIGNER_ALREADY_SIGNED: 'signeralreadysigned',
  ROUTE_LOAN_ADDITIONAL_SIGNER_OLD: 'signer', // Old route for loan additional signer, redirect to ROUTE_LOAN_ADDITIONAL_SIGNER
  ROUTE_LOAN_ADDITIONAL_SIGNER: 'loan-additional-signer', // New route for '/signer'
  ROUTE_SIGNER_ERROR: 'additional-signer-error',
  ROUTE_ADDITIONAL_SIGNER_AUTH_OLD: 'additional-signer', // Old route for OTP for loan and card, redirect to ROUTE_ADDITIONAL_SIGNER_AUTH
  ROUTE_ADDITIONAL_SIGNER_AUTH: 'additional-signer-auth', // New route for OTP for '/additional-signer
  ROUTE_CUSTOMER_LINK: 'customer-link',
  ROUTE_ESKAT: 'eskat',
  ROUTE_PUBLIC_CARD_OFFER: 'p-cardoffer',
  ROUTE_CARD_ADDITIONAL_SIGNER_OLD: 'p-cardoffer-signer', // Old route for card offer signer, redirect to ROUTE_CARD_ADDITIONAL_SIGNER
  ROUTE_CARD_ADDITIONAL_SIGNER: 'card-additional-signer', // New route for '/p-cardoffer-signer'
  ROUTE_THANK_YOU: 'thankyou',
  ROUTE_THANK_YOU_CONTINUE_IN_APP: 'thankyou-continue-in-app',
  ROUTE_THANK_YOU_WAITING_ADDITIONAL_SIGNER:
    'thankyou-card-waiting-additional-signer',
  ROUTE_CARD_OFFER: 'cardoffer',
  ROUTE_PUBLIC_SHIPPING_DETAILS: 'p-shipping-details',
  ROUTE_SET_PIN: 'set-pin-code',
  ROUTE_CREATING_CARD: 'create-card-progress',
  ROUTE_CARD_SIGNED: 'card-signed',
  ROUTE_CONTRACT_SIGNED: 'contract-signed',
  ROUTE_CARD_CREATED: 'card-created',
  ROUTE_CARD_CREDIT_LIMIT_INCREASED: 'credit-limit-increased',
  AUTH0_FI_FTN_CONNECTION_PREPROD: 'FI-FTN-Signicat',
  AUTH0_FI_FTN_CONNECTION_PROD: 'FI-FTN-Signicat',
  AUTH0_SE_BANKID_CONNECTION_PREPROD: 'SE-BankID-Signicat',
  AUTH0_SE_BANKID_CONNECTION_PROD: 'SE-BankId-Signicat',
  ROUTE_ONBOARDING: 'onboarding',
  ROUTE_ONBOARDING_OFFER: 'onboarding-offer',
  ROUTE_CARD_ONBOARDING_OFFER: 'card-onboarding-offer',
  AUTH_CLIENT_ID: 'https://onboarding/clientId',
  AUTH_USER_EMAIL: 'https://mypages/email',
  ROUTE_TERMS_AND_CONDITIONS: 'terms-and-conditions',
  OLD_HOME_PAGE: 'old-home',
  AUTH_USER_DATE_OF_BIRTH: 'https://onboarding/dateOfBirth',
  ROUTE_KOSMA_PUBLIC: 'bank-integration',
  ROUTE_MAINTENANCE: 'maintenance',
  ROUTE_DOCUMENTS: 'documents',
  ROUTE_INVOICES: 'invoices',
  ROUTE_HELP_AND_SUPPORT: 'help',
};

export default constVars;

export const PRODUCTION = 'production';

export const V2 = 'v2';

export const MediaQueries: Record<keyof MatchedMediaBreakPoints, string> = {
  mobile: '(max-width: 480px)',
  mobileLandscape: '(orientation: landscape) and (max-height: 480px)',
  smallScreen: '(max-width: 688px)',
  smallScreenPortrait: '(orientation: portrait) and (max-width: 688px)',
  mediumScreen: '(min-width: 689px)',
  bigScreen: '(min-width: 1024px)',
  portrait: '(orientation: portrait)',
  landscape: '(orientation: landscape)',
  tablet: '(min-width: 481px) and (min-height: 481px)',
  desktop: '(min-width: 1024px)',
};

export const nordiskaBankId = 123;
