import initialState from '~/store/initialState';
import {
  ApplyLoanAction,
  NewLoanData,
  SET_APPLY_LOAN,
  SET_IS_LOAN_APPLIED,
  SET_SUBMIT_STATUS,
  SET_IS_INITIATED,
} from '~/store/types/applyLoanTypes';

interface ApplyLoanState {
  readonly newLoanData: NewLoanData;
  readonly loanIsApplied: boolean;
  readonly isSubmitting: boolean;
  readonly isInitiated: boolean;
}

const applyNewLoanReducer = (
  state = initialState.applyLoan,
  action: ApplyLoanAction
): ApplyLoanState => {
  switch (action.type) {
    case SET_APPLY_LOAN:
      return {
        ...state,
        newLoanData: {
          ...state.newLoanData,
          ...action.payload,
        },
      };

    case SET_IS_LOAN_APPLIED:
      return { ...state, loanIsApplied: action.payload };

    case SET_SUBMIT_STATUS:
      return { ...state, isSubmitting: action.payload };

    case SET_IS_INITIATED:
      return { ...state, isInitiated: true };

    default:
      return state;
  }
};

export default applyNewLoanReducer;
