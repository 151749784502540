import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { getCurrency } from '@qred/shared-component-library/src/currency';
import { updateForm } from '~/store/slices/onboardingApplication.slice';
import {
  Button,
  Group,
  Stack,
  Typography,
  Select,
} from '@qred/components-library';
import { ValidationContext } from '~/components/hoc/withValidation';
import { numberFormatter } from '@qred/fe-cp-formatting-helpers';

const turnoverRangesNonEuro = [
  { min: 0, max: 10000 },
  { min: 10000, max: 25000 },
  { min: 25000, max: 50000 },
  { min: 50000, max: 75000 },
  { min: 75000, max: 100000 },
  { min: 100000, max: 250000 },
  { min: 250000, max: 2500000 },
  { min: 2500000, max: 50000000 },
  { min: 50000000 },
];

const turnoverRangesEuro = [
  { min: 0, max: 1000 },
  { min: 1000, max: 2500 },
  { min: 2500, max: 5000 },
  { min: 5000, max: 7500 },
  { min: 7500, max: 10000 },
  { min: 10000, max: 25000 },
  { min: 25000, max: 250000 },
  { min: 250000, max: 5000000 },
  { min: 5000000 },
];

const FinancialInformation = () => {
  const validationContext = useContext(ValidationContext);

  const dispatch = useDispatch();

  const {
    onboardingApplication: { form },
    intl: { market },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();

  const hasBankIntegrationOrFilesUploaded =
    form.bankIntegration || form.bankFilesUploaded;
  const formatter = numberFormatter(market);

  const formatTurnoverRange = ({ min, max }: { min: number; max?: number }) => {
    let label;
    if (max) {
      label = `${formatter(min)} - ${formatter(max)}`;
    } else {
      label = `> ${formatter(min)}`;
    }
    return { value: label, label };
  };

  const currencyOptions =
    getCurrency(market).unit === '€'
      ? turnoverRangesEuro
      : turnoverRangesNonEuro;

  const formattedTurnoverOptions = currencyOptions.map(formatTurnoverRange);

  useEffect(() => {
    const hasAnsweredForeignCurrenciesQuestion =
      typeof form.incomeReceivedInForeignCurrencies === 'boolean';
    if (!hasAnsweredForeignCurrenciesQuestion || !form.turnover) {
      validationContext.manuallyInvalidateForm(true);
    }

    return () => {
      validationContext.manuallyInvalidateForm(false);
    };
  }, [form.incomeReceivedInForeignCurrencies, form.turnover]);

  const onTurnoverChange = (selectedOption: { label: string }) => {
    if (selectedOption) {
      dispatch(
        updateForm({
          turnover: selectedOption.label,
        })
      );
    }
  };

  const onClick = (answer: boolean) => {
    dispatch(
      updateForm({
        incomeReceivedInForeignCurrencies: answer,
      })
    );

    dispatch(
      pushToGtmOnboardingAction({
        actionName: `incomeReceivedInForeignCurrencies: ${answer}`,
      })
    );

    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'financial_information_change',
        stepActionNumber: 0.1,
      })
    );
  };

  return (
    <Stack pt="sm">
      <Typography mt={mobile ? '1.3rem' : '0.75rem'}>
        {hasBankIntegrationOrFilesUploaded
          ? translate(
              'Onboarding.HasCompletedBankIntegrationTurnoverDescription'
            )
          : translate('Onboarding.TurnoverDescription')}
      </Typography>
      <Stack pt="sm">
        <Select
          className="turnover_step_amount"
          options={formattedTurnoverOptions}
          onChange={onTurnoverChange}
          value={formattedTurnoverOptions.find(
            (option) => option.label === form.turnover
          )}
          label={`${translate('Onboarding.TurnoverLabel')} (${
            getCurrency(market).unit
          })`}
          placeholder={translate('Onboarding.TurnoverSelectInterval') as string}
          isSearchable={false}
        />
      </Stack>
      <Typography mt="xs">
        {translate('Onboarding.TurnoverForeignCurrencyQuestion')}
      </Typography>
      <Group direction="row" mt="1rem">
        <Button
          data-cy="foreign_currency_question_button"
          size="md"
          onClick={() => onClick(true)}
          variant="secondary"
          style={{ flexGrow: mobile ? 1 : 0 }}
          data-active={form.incomeReceivedInForeignCurrencies === true}
        >
          {translate('Yes')}
        </Button>
        <Button
          data-cy="foreign_currency_question_button"
          size="md"
          onClick={() => onClick(false)}
          variant="secondary"
          style={{ flexGrow: mobile ? 1 : 0 }}
          data-active={form.incomeReceivedInForeignCurrencies === false}
        >
          {translate('No')}
        </Button>
      </Group>
    </Stack>
  );
};

export default FinancialInformation;
