import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '~/components/shared/Input/InputField';
import { Typography } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { FlowTypes } from '~/enums';
import {
  getPersonalNumberPlaceholder,
  personalNumberIsOnlyNumbers,
} from '~/constants/markets';
import Stack from '~/components/shared/Layout/Stack';
import { formatNameHelper } from '~/helpers/formatName.helper';
import { formatPersonalNumber } from '~/helpers/formatters.helper';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { onChangeInputEvent } from '~/types/types';
import moment from 'moment';
import useDateFormatter from '~/hooks/useDateFormatter';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import {
  updateApplicantAddress,
  updateApplicantData,
} from '~/store/slices/onboardingApplication.slice';
import ContactInfo from './components/ContactInfo';
import Address from '../Address/Address';
import DateOfBirth from '../DateOfBirth/DateOfBirth';
import { IOnboardingAddressFields } from '~/interfaces/Onboarding';

const PersonalInformation = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const formatDate = useDateFormatter();

  const {
    marketHasPersonalNumber,
    marketHasAddress,
    marketShouldAskApplicantForFullName,
    marketShouldShowCreditCheckCopyTooltip,
  } = useOnboardingMarketConfig();

  const { form, flowType } = useSelector(
    (state: RootState) => state.onboardingApplication
  );

  const { market, language } = useSelector((state: RootState) => state.intl);

  const onChange = (event: onChangeInputEvent) => {
    const { name, value } = event.target;

    dispatch(updateApplicantData({ [name]: value }));
  };

  const onBlur = (event: onChangeInputEvent) => {
    const { name, value } = event.target;

    dispatch(
      pushToGtmOnboardingAction({
        actionName: `${name}_change`,
      })
    );

    dispatch(updateApplicantData({ [name]: value.trim() }));
  };

  const onAddressFieldChange = (partialAddress: IOnboardingAddressFields) => {
    dispatch(updateApplicantAddress(partialAddress));
  };

  const onAddressFieldBlur = (name: string) => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: `${name}_change`,
      })
    );
  };

  const dateOfBirthOnChangeHandler = useCallback(
    (date: Date | null) => {
      if (date instanceof Date) {
        dispatch(
          updateApplicantData({
            dateOfBirth: moment(date).format('YYYY-MM-DD'),
          })
        );
        dispatch(
          pushToGtmOnboardingAction({
            actionName: `date_of_birth_change`,
          })
        );
      }
    },
    [dispatch]
  );

  const tooltipCopy = marketShouldShowCreditCheckCopyTooltip
    ? (translate('Onboarding.TooltipCreditCheckReportGenerated', {
        TooltipCreditCheckImpactWorthiness: (
          <Typography weight={600} size="sm">
            {translate('Onboarding.TooltipCreditCheckImpactWorthiness')}
          </Typography>
        ),
      }) as string)
    : '';

  return (
    <Stack>
      <Typography>
        {flowType === FlowTypes.Authenticated && form.applicant.fullName
          ? translate('Onboarding.PersonalInformationDescriptionWithName', {
              name: formatNameHelper(form.applicant.fullName),
            })
          : translate('Onboarding.PersonalInformationDescription')}
      </Typography>
      <Stack spacing="sm">
        {marketShouldAskApplicantForFullName &&
          flowType === FlowTypes.Manual && (
            <InputField
              name="fullName"
              dataCy="personal_information_step_full_name"
              label={translate('FullName') as string}
              placeholder={translate('FullName') as string}
              value={form.applicant.fullName || ''}
              onChange={onChange}
              onBlur={onBlur}
              validationType="FullName"
            />
          )}
        <ContactInfo onChange={onChange} onBlur={onBlur} />

        {flowType === FlowTypes.Manual && (
          <>
            {marketHasPersonalNumber ? (
              <>
                <InputField
                  name="personalNumber"
                  dataCy="personal_information_step_personal_number"
                  label={translate('PersonalNumber') as string}
                  explanation={tooltipCopy}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputMode={
                    personalNumberIsOnlyNumbers(market) ? 'numeric' : 'text'
                  }
                  placeholder={getPersonalNumberPlaceholder(market, language)}
                  value={formatPersonalNumber(
                    (form.applicant && form.applicant.personalNumber) || '',
                    market
                  )}
                  validationType="PersonalNumber"
                />
              </>
            ) : (
              <DateOfBirth
                dataCy="personal_information_step_date_of_birth"
                useModal
                dateOfBirthOnChangeHandler={dateOfBirthOnChangeHandler}
                value={
                  (form.applicant.dateOfBirth &&
                    formatDate(form.applicant.dateOfBirth, {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })) ||
                  ''
                }
                openOnFocus
              />
            )}
          </>
        )}

        {marketHasAddress && (
          <Address
            addressFields={form.applicant.addressFields}
            onAddressFieldChange={onAddressFieldChange}
            onAddressFieldBlur={onAddressFieldBlur}
            applicantAuthenticated={form.applicantAuthenticated}
            showTooltipHomeAddressExplanation
          />
        )}
      </Stack>
    </Stack>
  );
};

export default PersonalInformation;
