import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { trustPilotBusinessIdPerMarket } from '~/constants/markets';
import { generateLocale } from '~/helpers/generators.helper';
import { RootState } from '~/store/types/sharedTypes';
import { TrustPilotWidget } from './TrustpilotWidget.styled';

const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null);

  const { language, market } = useSelector((state: RootState) => state.intl);

  const locale = generateLocale(language, market);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [language]);

  return (
    <TrustPilotWidget
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      data-locale={locale}
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id={trustPilotBusinessIdPerMarket[market]}
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    />
  );
};
export default TrustBox;
