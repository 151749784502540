import React, { useRef, useState } from 'react';
import { Stack, Typography } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { updateForm } from '~/store/slices/loanOnboardingOffer.slice';
import { IOnboardingFile } from '~/interfaces/Onboarding';
import { validateFiles } from '~/helpers/validators.helper';
import DocumentList from '~/components/shared/DocumentUpload/DocumentList';
import DocumentUpload from '~/components/shared/DocumentUpload/DocumentUpload';

import DocumentErrorMessage, {
  FileUploadErrors,
} from '~/components/shared/DocumentUpload/DocumentErrorMessage';
import { filterOutIncorrectFiles } from '~/helpers/files.helper';

const maxFiles = 5;
const allowedFileExtensionsDisplayed = ['.pdf', '.jpg', '.jpeg', '.png'];
const allowedFileFormats = [
  'image/png',
  'application/pdf',
  'image/jpeg',
  ...allowedFileExtensionsDisplayed,
];
const maxSize = 15000000;

const OwnershipUploadDocumentationPanel = () => {
  const dispatch = useDispatch();
  const {
    onboardingOffer: {
      form: { ownershipFiles },
    },
  } = useSelector((state: RootState) => state);

  const t = useTranslate();

  const [documentsApiStatus, setDocumentsApiStatus] = useState(ApiStatus.Idle);
  const [
    localFileUploadErrors,
    setLocalFileUploadErrors,
  ] = useState<FileUploadErrors>({
    alreadyAdded: false,
    invalidType: false,
    tooLarge: false,
    maxLimit: false,
  });
  const [
    lastLocalUploadHadMultipleFiles,
    setLastLocalUploadHadMultipleFiles,
  ] = useState(false);
  const uploadedFilesListRef = useRef<HTMLDivElement>(null);

  const setErrors = (filesToCheck: IOnboardingFile[]) => {
    const { hasAnyFormatIssue, hasAnySizeIssue } = validateFiles(
      filesToCheck,
      allowedFileFormats,
      maxSize
    );

    const fileIsAlreadyAdded = filesToCheck.some(
      (file) => filesToCheck.filter((f) => f.name === file.name).length > 1
    );

    setLocalFileUploadErrors({
      ...localFileUploadErrors,
      alreadyAdded: fileIsAlreadyAdded,
      maxLimit: filesToCheck.length > maxFiles,
      invalidType: hasAnyFormatIssue,
      tooLarge: hasAnySizeIssue,
    });
  };

  const onLocalUploadFiles = (acceptedFiles: File[]) => {
    setLastLocalUploadHadMultipleFiles(acceptedFiles.length > 1);
    setDocumentsApiStatus(ApiStatus.Idle);

    const acceptedFilesToOnboardingFiles = acceptedFiles.map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      timestamp: file.lastModified,
      objectURL: URL.createObjectURL(file),
    }));

    const acceptedAndStoredFilesMerged = acceptedFilesToOnboardingFiles.concat(
      ownershipFiles
    );
    setErrors(acceptedAndStoredFilesMerged);
    dispatch(
      updateForm({
        ownershipFiles: [
          ...filterOutIncorrectFiles(
            acceptedAndStoredFilesMerged,
            allowedFileFormats,
            maxSize,
            maxFiles
          ),
        ],
      })
    );

    uploadedFilesListRef.current?.scrollIntoView();
  };

  const handleRemoveFile = (file: IOnboardingFile) => {
    setDocumentsApiStatus(ApiStatus.Idle);
    URL.revokeObjectURL(file.objectURL);
    const ownershipFilesManipulated = ownershipFiles.filter(
      (localFile) => localFile.objectURL !== file.objectURL
    );
    dispatch(
      updateForm({
        ownershipFiles: ownershipFilesManipulated,
      })
    );
    setErrors(ownershipFilesManipulated);
  };

  const onDeleteAll = () => {
    ownershipFiles.forEach((localFile) => {
      URL.revokeObjectURL(localFile.objectURL);
    });
    dispatch(updateForm({ ownershipFiles: [] }));
    setErrors([]);
  };

  const maxFilesUploaded = ownershipFiles.length >= maxFiles;

  return (
    <Stack mt={20}>
      <Typography>
        {t('OnboardingOffer.UploadDocumentationPanelDescription')}
      </Typography>

      {!maxFilesUploaded && (
        <DocumentUpload
          allowedFileExtensionsDisplayed={allowedFileExtensionsDisplayed}
          maxFiles={maxFiles}
          maxSize={maxSize}
          onUploadFiles={onLocalUploadFiles}
        />
      )}
      <DocumentErrorMessage
        allowedFileExtensionsDisplayed={allowedFileExtensionsDisplayed}
        documentsApiStatus={documentsApiStatus}
        fileUploadErrors={localFileUploadErrors}
        lastLocalUploadHadMultipleFiles={lastLocalUploadHadMultipleFiles}
        maxFiles={maxFiles}
        maxSize={maxFiles}
      />
      {!!ownershipFiles.length && (
        <DocumentList
          fileListRef={uploadedFilesListRef}
          files={ownershipFiles}
          handleRemoveFile={handleRemoveFile}
          onDeleteAll={onDeleteAll}
        ></DocumentList>
      )}
    </Stack>
  );
};

export default OwnershipUploadDocumentationPanel;
