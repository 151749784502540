import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';
import { theme } from '~/styles/themes';

export const BadgeContainer = styled.div`
  @media ${MediaQueries.mediumScreen} {
    display: flex;
    align-items: center;
  }
  @media ${MediaQueries.smallScreen} {
    display: flex;
  }
`;
export const Container = styled.div`
  width: 10em;
  margin: 0.875em 3.75em 0 0;
  cursor: pointer;

  @media ${MediaQueries.smallScreen} {
    display: flex;
  }
`;

export const HorizontalLine = styled.div`
  text-align: center;
  border-bottom: 0.0625em solid ${theme.colors.midwayNeutral};
  line-height: 0.1em;
  max-width: 10em;
  margin: 0 auto 0.625em;
  position: relative;
  justify-content: center;
  width: 80%;
  & span {
    background: ${theme.colors.white};
    padding: 0 1.125em;
    font-size: 0.64em;
    font-weight: 500;
  }
  @media ${MediaQueries.smallScreen} {
    display: flex;
    background-color: ${theme.colors.midwayNeutral};
    border-bottom: unset;
  }
`;
