import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { StepStatus } from '~/enums';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { goToNextStep } from '~/store/slices/onboardingApplication.slice';

const useSkip = () => {
  const dispatch = useDispatch();

  const onSkip = useCallback(() => {
    dispatch(pushToGtmOnboardingAction({ actionName: 'skip_button_pressed' }));
    dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Finished }));
    dispatch(goToNextStep());
  }, [dispatch]);

  return onSkip;
};

export default useSkip;
