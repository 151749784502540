import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import InputField from '~/components/shared/Input/InputField';
import { Typography } from '~/components/shared/Typography/Typography';
import useTranslate from '~/hooks/useTranslate';
import { updateCardOfferUserData_UpdateSigner } from '~/store/actions/cardOfferActions';
import { RootState } from '~/store/types/sharedTypes';

const CreditCardAdditionalSignersCard: React.FC = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { additionalSigners, cardOfferAlreadySigned } = useSelector(
    (state: RootState) => state.cardOffer.userData
  );

  const onSignerEmailChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    signerId: string | undefined
  ) => {
    if (signerId)
      dispatch(
        updateCardOfferUserData_UpdateSigner(signerId, 'email', e.target.value)
      );
  };

  const onSignerPhoneNumberChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    signerId: string | undefined
  ) => {
    if (signerId)
      dispatch(
        updateCardOfferUserData_UpdateSigner(
          signerId,
          'phoneNumber',
          e.target.value
        )
      );
  };

  return (
    <Card noPadding dataTestId="credit-card-additional-signers">
      <CardHeader
        value={translate('CreditCardAdditionalSignersCard.Title') as string}
      />
      {additionalSigners.map((signer, index) => (
        <Fragment key={`${signer}-${index}`}>
          <CardSection
            title={
              translate(
                'CreditCardAdditionalSignersCard.ThisAgreementMustBeSignedBy'
              ) as string
            }
            explanation={
              translate('CreditCardAdditionalSignersCard.HelpText') as string
            }
            borderTop
          >
            <Typography>{`${signer.firstName} ${signer.lastName}`}</Typography>
          </CardSection>

          <CardSection
            title={
              translate(
                'CreditCardAdditionalSignersCard.EmailOfSigner'
              ) as string
            }
            borderTop
          >
            <InputField
              name={`cardSignerEmail${index}`}
              placeholder={
                translate(
                  'CreditCardAdditionalSignersCard.AddEmailAddress'
                ) as string
              }
              value={signer.email}
              disabled={cardOfferAlreadySigned}
              onChange={(e) => onSignerEmailChanged(e, signer.signerId)}
              validationType="Email"
            />
          </CardSection>
          <CardSection
            title={
              translate(
                'CreditCardAdditionalSignersCard.PhoneNumberOfSigner'
              ) as string
            }
            borderTop
          >
            <InputField
              name={`cardSignerPhoneNumber${index}`}
              placeholder={
                translate(
                  'CreditCardAdditionalSignersCard.AddPhoneNumber'
                ) as string
              }
              value={signer.phoneNumber}
              disabled={cardOfferAlreadySigned}
              onChange={(e) => onSignerPhoneNumberChanged(e, signer.signerId)}
              validationType="Phone"
            />
          </CardSection>
        </Fragment>
      ))}
    </Card>
  );
};

export default CreditCardAdditionalSignersCard;
