import React from 'react';
import { LoginMethod } from '~/helpers/authentication.helper';
import Stack from '~/components/shared/Layout/Stack';
import { Box, Button, ButtonType, QitUiProvider } from '@qred/qit-ui';
import useTranslate from '~/hooks/useTranslate';
interface AuthPopupProps {
  loginMethods: LoginMethod[];
  onAuthMethodSelected: (connectionName: string) => Promise<void>;
  bottomCTA?: {
    action: () => void;
    text: string;
  };
}

const AuthPopup = ({
  loginMethods,
  onAuthMethodSelected,
  bottomCTA,
}: AuthPopupProps) => {
  const t = useTranslate();
  return (
    <QitUiProvider>
      <Stack align="center">
        <Box mb="2em">
          <Stack spacing="sm">
            {loginMethods.map((loginMethod: LoginMethod) => (
              <Button
                key={loginMethod.connectionName}
                type={ButtonType.SecondaryInverted}
                onClick={() => onAuthMethodSelected(loginMethod.connectionName)}
                fullWidthLandscape
                fullWidthPortrait
                wide
                dataCy="popup_authentication_button"
              >
                {loginMethod.hasTranslation
                  ? t(loginMethod.label)
                  : loginMethod.label}
              </Button>
            ))}
          </Stack>
        </Box>
        {bottomCTA && (
          <Button
            onClick={bottomCTA.action}
            type={ButtonType.NoBorder}
            fullWidthLandscape
            fullWidthPortrait
          >
            {bottomCTA.text}
          </Button>
        )}
      </Stack>
    </QitUiProvider>
  );
};

export default AuthPopup;
