import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { RootState } from '~/store/types/sharedTypes';
import { pushToGtmAction } from '~/store/actions/gtmActions';
import { StepStatus } from '~/enums';
import { useCallback } from 'react';
import constVars from '~/constants/constVars';
import posthog from 'posthog-js';

const useLogout = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const { language: selectedLanguage, market } = useSelector(
    (state: RootState) => state.intl
  );

  return useCallback(
    (returnTo = constVars.ROUTE_LOGIN) => {
      sessionStorage.removeItem('returnTo');
      localStorage.setItem('qredMarket', market);
      localStorage.setItem('qredLoginLanguage', selectedLanguage);
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('active_company');
      localStorage.removeItem('qredLanguage');

      dispatch(
        pushToGtmAction({
          eventName: 'main',
          actionName: 'user_logout',
          stepStatus: StepStatus.Finished,
          stepActionNumber: 1.2,
        })
      );

      logout({
        logoutParams: {
          returnTo: returnTo.includes('https://')
            ? returnTo
            : `${window.location.origin}/${returnTo}`,
        },
      }).then(() => {
        posthog.reset();
        Sentry.setUser(null);
      });
    },
    [selectedLanguage]
  );
};

export default useLogout;
