import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import React, { useCallback, useState } from 'react';
import { RootState } from '~/store/types/sharedTypes';
import { updateActiveCompany } from '~/store/actions/userActions';
import { Company } from '~/Interfaces';
import { Group, Icon, Menu, Navbar } from '@qred/qit-ui';
import { CompanyItem } from '~/components/shared/Sidebar/CompanyItem';
import { pushToGtmAction } from '~/store/actions/gtmActions';
import { sidebarSwitchCompanyButton } from '~/constants/cy/components/sidebar';
import {
  fetchApplicationStatus,
  fetchLoansData,
  fetchPreviousLoans,
} from '~/store/actions/loansActions';
import { fetchOfferData } from '~/store/actions/loanOfferActions';

export const SwitchCompanyButton = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const [companySelectorOpened, setCompanySelectorOpened] = useState(false);
  const { companies, activeCompany } = useSelector(
    (state: RootState) => state.user
  );
  const otherCompany =
    companies.length === 2 && companies.find((c) => c.id !== activeCompany?.id);

  const onSwitchCompany = useCallback(() => {
    dispatch(updateActiveCompany(otherCompany as Company));
    dispatch(fetchApplicationStatus());
    dispatch(fetchLoansData());
    dispatch(fetchPreviousLoans((otherCompany as Company)?.id as number));
    dispatch(fetchOfferData((otherCompany as Company)?.id as number));
    dispatch(
      pushToGtmAction({
        eventName: 'sidebar',
        actionName: 'company_changed',
      })
    );
  }, [otherCompany]);

  if (otherCompany) {
    return (
      <Navbar.Link
        icon="Repeat"
        onClick={onSwitchCompany}
        dataCy={sidebarSwitchCompanyButton}
      >
        {t('Dashboard.SwitchCompany')}
      </Navbar.Link>
    );
  }

  return (
    <Menu onChange={setCompanySelectorOpened}>
      <Menu.Target>
        <Navbar.Link icon="RepeatStraight" dataCy={sidebarSwitchCompanyButton}>
          <Group spacing="sm">
            {t('Dashboard.SwitchCompany')}
            <Icon
              src={companySelectorOpened ? 'ChevronUp' : 'ChevronDown'}
              size="sm"
            />
          </Group>
        </Navbar.Link>
      </Menu.Target>
      <Menu.Dropdown>
        {companies.map((c) => (
          <CompanyItem key={c.id} company={c} />
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
