import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { BaseArrangement, ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import { formatOrganizationNumber } from '~/helpers/formatters.helper';

import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import {
  ListItem,
  BoldSpan,
  UnorderedListContainer,
  BoldSpanNoLetterSpacing,
} from './CardOfferView.styled';

interface cardOfferListType {
  id: string;
  content: string | JSX.Element[];
}
const CardOfferView = () => {
  const translate = useTranslate();

  const {
    cardOnboardingOffer: { overview },
  } = useSelector((state: RootState) => state);

  const companyName = overview?.companyName;
  const companyNo = overview?.companyNo;
  const companyAddress = overview?.invoiceAddress;
  const interestRate = overview.interest || '';

  const listItems: cardOfferListType[] = [
    {
      id: 'cash-back-item',
      content: translate('OnboardingCardOffer.BenefitsCashback', {
        onePercentPlaceholder: <BoldSpan>1%</BoldSpan>,
      }),
    },
    {
      id: 'zero-interest-item',
      content: translate('OnboardingCardOffer.BenefitsInterest', {
        zeroPercentPlaceholder: <BoldSpan>0%</BoldSpan>,
      }),
    },
    {
      id: 'partial-payment-item',
      content: translate('OnboardingCardOffer.BenefitsPartialPayments', {
        interestRate: (
          /*
            TODO: FI has requested to have a space between the number and the percent sign.
            See the translation sheet for more info.
          */
          <BoldSpanNoLetterSpacing>{interestRate}%</BoldSpanNoLetterSpacing>
        ),
      }),
    },
    {
      id: 'collect-receipts-item',
      content: translate('OnboardingCardOffer.BenefitsCollectReceipts', {
        qredAppPlaceholder: (
          <BoldSpanNoLetterSpacing>
            {translate('OnboardingCardOffer.qredApp')}
          </BoldSpanNoLetterSpacing>
        ),
      }),
    },
  ];

  return (
    <>
      <BaseArrangement mb={2}>
        <Typography>
          {translate('OnboardingCardOffer.Text1', {
            companyName: companyName,
            organizationNumber: formatOrganizationNumber(companyNo || ''),
            companyAddress: companyAddress,
          })}
        </Typography>
      </BaseArrangement>
      <UnorderedListContainer>
        {listItems.map((item) => (
          <ListItem key={item.id}>
            <div>
              <Icon
                src={SvgSrc.CheckCircle}
                color={theme.colors.midwaySuccess}
                mr={0.6}
                size={ComponentSize.Small}
              />
            </div>
            <Typography>{item.content}</Typography>
          </ListItem>
        ))}
      </UnorderedListContainer>
    </>
  );
};

export default CardOfferView;
