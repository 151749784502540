import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  FormStatus,
  SimpleEvent,
  ValidationContext,
  ValidationType,
} from '~/components/hoc/withValidation';
import useTranslate from '~/hooks/useTranslate';
import { BaseArrangement } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';

const DropDownContainer = styled(BaseArrangement)`
  margin: 0.3em 0;
  color: ${theme.colors.primaryGray};
`;

const Label = styled.label<{ labelColor?: string }>`
  color: ${(props) => props.labelColor || theme.colors.secondaryGray};
  // text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: 0.3em;
  display: inline-block;
`;

const Select = styled.select<{ disabled?: boolean }>`
  background-color: ${(props) =>
    props.disabled ? theme.colors.backdrop : theme.colors.white};
  color: ${(props) =>
    props.disabled ? theme.colors.newSecondaryGray : theme.colors.dark};
  opacity: 1;
  line-height: 1.3;
  padding: 0.8em 0.7em;
  border-radius: 0.5em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='200' height='200' viewBox='0 0 200 200' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M92.2276 157.154L3.21959 68.1456C-1.0732 63.8529 -1.0732 56.8932 3.21959 52.6008L13.6009 42.2195C17.8864 37.934 24.8319 37.9258 29.1274 42.2012L100 112.742L170.873 42.2012C175.168 37.9258 182.114 37.934 186.399 42.2195L196.78 52.6008C201.073 56.8936 201.073 63.8533 196.78 68.1456L107.773 157.154C103.48 161.446 96.5204 161.446 92.2276 157.154Z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.8em auto, 100%;
  &:not(:disabled) {
    cursor: pointer;
  }
  margin-top: 0.2em;
  border: 0.1em solid ${theme.colors.neutral};

  &:not(:disabled) {
    &:hover {
      border-color: ${theme.colors.dark};
    }
    &:focus {
      border-color: ${theme.colors.active};
    }

    &.has-error {
      border-color: ${theme.colors.error};
    }

    &.has-info {
      border-color: ${theme.colors.accent};
      background-color: ${theme.colors.accent}0d; // 0d means 5% opacity
    }
  }

  /* Hide arrow icon in IE browsers */
  ::-ms-expand {
    display: none;
  }

  /* Disabled styles */
  :disabled {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  }

  outline: none;
`;

const Option = styled.option`
  // max-width: 100%;
`;

export interface DropDownOption {
  value: string | number;
  label: string;
}

interface Props {
  name: string;
  label?: string;
  labelColor?: string;
  value?: string | number;
  disabled?: boolean;
  defaultValue?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  dropDownValues: DropDownOption[];
  validationType?: ValidationType;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  showValidationInfoWhenUntouched?: boolean;
}

const DropDown: React.FC<Props> = (props) => {
  const id = `${props.name}dropdown-id`;

  const [touched, setTouched] = useState(false);
  const translate = useTranslate();
  const {
    formStatus,
    onChangeHOC,
    removePropertyFromValidationErrors,
    validationErrors,
  } = useContext(ValidationContext);
  const [validationMessageType, setValidationMessageType] = useState<
    'error' | 'info' | null
  >(null);

  useEffect(() => {
    const fakeEvent: SimpleEvent = {
      target: {
        name: props.name,
        value: props.value || props.defaultValue,
        dataset: { validationType: props.validationType },
      },
    };
    onChangeHOC(fakeEvent);

    // This clean up is needed to remove the property from errors if input field is no longer shown
    return () => {
      removePropertyFromValidationErrors(props.name);
    };
  }, [props.value, props.defaultValue]);

  const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
    props.onBlur && props.onBlur(e);
    setTouched(true);
  };

  useEffect(() => {
    const shouldShow =
      (props.showValidationInfoWhenUntouched ||
        touched ||
        formStatus === FormStatus.SUBMITTED) &&
      props.validationType &&
      validationErrors[props.name];
    if (shouldShow) {
      setValidationMessageType(
        props.showValidationInfoWhenUntouched &&
          !touched &&
          formStatus === FormStatus.IDLE
          ? 'info'
          : 'error'
      );
    } else {
      setValidationMessageType(null);
    }
  }, [
    touched,
    formStatus,
    validationErrors,
    props.showValidationInfoWhenUntouched,
  ]);

  return (
    <DropDownContainer>
      {props.label && (
        <Label htmlFor={id} labelColor={props.labelColor}>
          {props.label}
        </Label>
      )}
      <Select
        id={id}
        name={props.name}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        onBlur={handleBlur}
        data-testid={`select-${props.name}`}
        key={`drop-down-key:${props.defaultValue}`}
        data-validation-type={props.validationType}
        className={validationMessageType ? `has-${validationMessageType}` : ''}
        disabled={props.disabled}
      >
        {props.dropDownValues.map((option) => (
          <Option
            key={`${option.value}-${option.label}`}
            value={option.value}
            data-testid={`select-option-${props.name}`}
          >
            {option.label}
          </Option>
        ))}
      </Select>
      {validationMessageType && (
        <ValidationErrorMessage type={validationMessageType}>
          {translate(
            `${
              validationMessageType === 'error'
                ? 'ValidationErrors'
                : 'ValidationInfo'
            }.${props.validationType?.split('_')[0]}`
          )}
        </ValidationErrorMessage>
      )}
    </DropDownContainer>
  );
};

export default DropDown;
