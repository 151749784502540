import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';
import { BodyContainer, MainContainer } from './BaseStyledComponents';
import { theme } from './themes';

export const MainOnboardingContainer = styled(MainContainer)`
  background-color: ${theme.colors.neutral12};
  font-size: 16px;
  @media ${MediaQueries.smallScreenPortrait} {
    width: 100%;
    background-color: ${theme.colors.white};
  }
`;

export const OnboardingStepsContainer = styled(BodyContainer)`
  @media ${MediaQueries.mediumScreen} {
    height: calc(var(--client-height) * 0.8);
  }
  @media ${MediaQueries.smallScreenPortrait} {
    margin-top: 64px;
    padding: 0;
    margin-top: 5em;
  }
`;

export const SubmissionIdentificationExplanation = styled.div`
  margin-bottom: 2em;
  text-align: center;
`;
