import { CountryCode } from '~/enums';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { RootState } from '~/store/types/sharedTypes';
import {
  ArrangementProps,
  BaseArrangement,
  cssLenght,
  SizeProps,
} from '~/styles/BaseStyledComponents';
import { logoContainer } from '~/constants/cy/components/logo';

const LogoContainer = styled(BaseArrangement)<SizeProps>`
  height: ${(props) => cssLenght(props, 3)};
  width: ${(props) => cssLenght(props, 3)};
`;

const Img = styled.img`
  width: 100%;
`;

interface LogoProps {
  noLink?: boolean;
  onClick?: () => void;
  style?: object;
}

const Logo: React.FC<ArrangementProps & SizeProps & LogoProps> = (props) => {
  const { market } = useSelector((state: RootState) => state.intl);
  const logoSrc =
    market === CountryCode.NL
      ? '/logo_qeld_text_primary.svg'
      : '/logo_qred_text_primary.svg';

  return (
    <LogoContainer
      mt={props.mt}
      mb={props.mb}
      ml={props.ml}
      size={props.size}
      data-cy={logoContainer}
      style={props.style}
    >
      {props.noLink ? (
        <Img src={logoSrc} alt="Qred logo" />
      ) : (
        <Link
          to="/"
          onClick={() => {
            props?.onClick && props.onClick();
          }}
        >
          <Img src={logoSrc} alt="Qred logo" />
        </Link>
      )}
    </LogoContainer>
  );
};

export default Logo;
