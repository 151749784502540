import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Icon from '~/components/shared/Icon/Icon';
import { Typography, Colors } from '@qred/components-library';
import ToolTip from '../ToolTip/ToolTip';

const borderStyle = '1px solid #dddddd';

const SectionContainer = styled.div<{
  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  noPadding?: boolean;
  isOnboarding?: boolean;
}>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  border-left: ${(props) => (props.borderLeft ? borderStyle : 'none')};
  border-right: ${(props) => (props.borderRight ? borderStyle : 'none')};
  border-bottom: ${(props) => (props.borderBottom ? borderStyle : 'none')};
  border-top: ${(props) => (props.borderTop ? borderStyle : 'none')};
  position: relative;
`;

const SectionContainer2 = styled(SectionContainer)<{
  topSection?: boolean;
  bottomSection?: boolean;
}>`
  padding-left: 1em;
  padding-right: 1em;
  padding-top: ${(props) => (props.topSection ? '1em' : '0.5em')};
  padding-bottom: ${(props) => (props.bottomSection ? '1em' : '0.5em')};
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  padding: 0.1em 0;
`;

const IconContainer = styled.div<{ iconPadding?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  padding: ${(props) =>
    props.iconPadding ? `${props.iconPadding}em` : undefined};
`;

interface Props {
  title?: string;
  value?: string | number | JSX.Element;
  iconSrc?: IconProp;
  valueAlt?: string;
  iconSrcAlt?: IconProp;
  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  style?: object;
  iconColor?: string;
  iconPadding?: number; // Some icons are a bit bigger and we need to decrese icon size to make it look consistent
  boldValue?: boolean;
  largeValue?: boolean;
  noPadding?: boolean;
  valueColor?: Colors;
  explanation?: string;
  isOnboarding?: boolean;
}

const CardSection: React.FC<Props> = (props) => (
  <SectionContainer
    style={props.style}
    borderTop={props.borderTop}
    borderRight={props.borderRight}
    borderBottom={props.borderBottom}
    borderLeft={props.borderLeft}
    noPadding={props.noPadding}
    isOnboarding={props.isOnboarding}
  >
    {renderContent(props)}
  </SectionContainer>
);

interface CardSection2Props {
  topSection?: boolean;
  bottomSection?: boolean;
}

// Use a separate component in order to introduce the new design gradually to all pages
export const CardSection2: React.FC<Props & CardSection2Props> = (props) => (
  <SectionContainer2
    style={props.style}
    borderTop={props.borderTop}
    borderRight={props.borderRight}
    borderBottom={props.borderBottom}
    borderLeft={props.borderLeft}
    topSection={props.topSection}
    bottomSection={props.bottomSection}
  >
    {renderContent(props)}
  </SectionContainer2>
);

const renderContent = (props: PropsWithChildren<Props>) => (
  <>
    {/* Render title only if one is provided */}
    {props.title && (
      <>
        {props.explanation && <ToolTip explanation={props.explanation} />}
        <Typography weight={900} size="sm">
          {props.title}
        </Typography>
        {/* OBS! Probplem with this solutions is that if CardSection has no title, then ToolTip won't render either */}
      </>
    )}

    {/* Render icon & value only if one is provided */}
    {(props.iconSrc || props.value) && (
      <InfoContainer>
        {props.iconSrc && (
          <IconContainer iconPadding={props.iconPadding}>
            <Icon size={0.5} src={props.iconSrc} color={props.iconColor} />
          </IconContainer>
        )}
        {props.value && (
          <Typography
            size={props.largeValue ? 'lg' : 'md'}
            color={props.valueColor}
            weight={props.boldValue ? 600 : undefined}
            lineHeight="xl"
          >
            {props.value}
          </Typography>
        )}
      </InfoContainer>
    )}

    {/* Render alternative icon & value only if one is provided */}
    {(props.iconSrcAlt || props.valueAlt) && (
      <InfoContainer>
        {props.iconSrcAlt && (
          <IconContainer iconPadding={props.iconPadding}>
            <Icon src={props.iconSrcAlt} color={props.iconColor} />
          </IconContainer>
        )}
        {props.valueAlt && (
          <Typography
            color={props.valueColor}
            weight={props.boldValue ? 600 : undefined}
            lineHeight="xl"
          >
            {props.valueAlt}
          </Typography>
        )}
      </InfoContainer>
    )}

    {props.children}
  </>
);

export default CardSection;
