import { createContext, ReactNode } from 'react';
import { ModalProps } from '~/components/shared/Modal/Modal';
import { ConfirmModalProps } from '~/components/shared/Modal/ConfirmModal';

export type ModalSettings = Omit<ModalProps, 'opened'>;

export type ConfirmLabels = Record<'confirm' | 'cancel', ReactNode>;

export interface OpenConfirmModal extends ModalSettings, ConfirmModalProps {}

export type ModalState =
  | { id: string; props: ModalSettings; type: 'content' }
  | { id: string; props: OpenConfirmModal; type: 'confirm' };

export interface ModalsContextProps {
  modals: ModalState[];
  openModal: (props: ModalSettings) => string;
  openConfirmModal: (props: OpenConfirmModal) => string;
  closeModal: (id: string, canceled?: boolean) => void;
  closeAll: () => void;
}

export const ModalsContext = createContext<ModalsContextProps>({} as any);
