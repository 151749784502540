import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';
import { theme } from '~/styles/themes';

export const CompanyNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;

  @media ${MediaQueries.smallScreenPortrait} {
    padding: 1em;
    background-color: ${theme.colors.document};
    border-radius: 0.2em;
  }
`;

export const CompanyNameResult = styled.div`
  position: relative;
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  right: 3.5em;
`;
