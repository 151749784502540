import { CountryCode } from '~/enums';
import { Language } from '~/store/types/sharedTypes';

// This simple id generator is more than enough for our limited use of random ids in My Qred
export const generateId = () =>
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  `_${Math.random().toString(36).substr(2, 9)}`;

// Split language to ignore market in case langauge contains both variables. (For example NL_BE).
// Format of locale: language_MARKET
export const generateLocale = (language: Language, market: CountryCode) =>
  `${language.split('_')[0].toLowerCase()}-${market.toUpperCase()}`;
