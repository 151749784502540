import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { ModalTransitionDuration } from '~/components/shared/Modal/Modal';
import { globalModalInitialState } from '../initialState';
import { listenerMiddleware } from '../middlewares/listener.middleware';

const globalModalSlice = createSlice({
  name: 'globalModal',
  initialState: globalModalInitialState,
  reducers: {
    openModal: (state, action) => {
      state.current = action.payload;
      state.modals = [...state.modals, action.payload];
    },
    closeModal: (state, action: PayloadAction<string>) => {
      state.modals = state.modals.filter((m) => m.id !== action.payload);
    },
    closeAllModals: (state) => {
      state.modals = [];
    },
    recalculateCurrentModal: (state) => {
      state.current = state.modals[state.modals.length - 2] || null;
    },
  },
});

export const {
  openModal,
  closeModal,
  closeAllModals,
  recalculateCurrentModal,
} = globalModalSlice.actions;

export default globalModalSlice.reducer;

listenerMiddleware.startListening({
  matcher: isAnyOf(closeModal, closeAllModals),
  effect: async (_action, listenerApi) => {
    setTimeout(() => {
      listenerApi.dispatch(recalculateCurrentModal());
    }, ModalTransitionDuration);
  },
});
