import constVars from '~/constants/constVars';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import * as userActions from '~/store/actions/userActions';
import * as loansActions from '~/store/actions/loansActions';
import {
  useAuth0,
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react';
import { Route, RouteProps } from 'react-router';
import Spinner from '~/components/shared/Spinner/SpinnerCircular';
import { pushToGtmAction } from '~/store/actions/gtmActions';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import {
  defaultLanguageToMarket,
  setLanguage,
  setMarket,
} from './store/slices/intl.slice';
import { formatPersonalNumber } from './helpers/formatters.helper';
import { RootState } from './store/types/sharedTypes';
import { theme } from './styles/themes';
import { getAuth0Audience } from './helpers/utils';
import { CountryCode, GlobalErrorType, StepStatus } from '~/enums';
import { isTokenSet } from './store/slices/globalError.slice';
import posthog from 'posthog-js';
import { TOKEN, accessToken } from '~/store/apis/utils/accessToken';

const ProtectedRoute = ({ ...props }: RouteProps): ReactElement => {
  /* Selectors */
  const { overview } = useSelector((state: RootState) => state.loans);
  const location = useLocation();
  const { info: userInfo, userIsSet, companies } = useSelector(
    (state: RootState) => state.user
  );
  const { errorType } = useSelector((state: RootState) => state.globalError);

  /* Other hooks */
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  /* Gets access token and saves it in local storage */
  const setAccessToken = async () => {
    const _accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: getAuth0Audience(),
      },
    });
    if (!_accessToken) {
      throw new Error(`accessToken is invalid, it is ${_accessToken}`);
    }

    dispatch(isTokenSet(true));
  };

  let personalNumber =
    user && (user[constVars.AUTH_USER_PERSONAL_NUMBER] as string);
  const market = user && user[constVars.AUTH_MARKET];

  const email = user?.[constVars.AUTH_USER_EMAIL];

  // If market is DK format Personal ID (xxxxxx-xxxx)
  if (personalNumber && market && market.toUpperCase() === CountryCode.DK) {
    personalNumber = formatPersonalNumber(personalNumber, market);
  }

  useEffect(() => {
    if (market) {
      window.__QRED_MARKET__ = market;
      dispatch(setMarket(market));
      if (userIsSet) {
        userInfo.language
          ? dispatch(setLanguage(userInfo.language))
          : dispatch(defaultLanguageToMarket(market));
      }
    }
  }, [market, userIsSet]);

  /* Effects */
  useEffect(() => {
    if (personalNumber || (market === CountryCode.NL && email)) {
      setAccessToken()
        .then(() => {
          if (!userInfo.personalNumber && personalNumber) {
            dispatch(userActions.updateUserPersonalNumber(personalNumber));
          }
          if (!location.pathname.includes(constVars.ROUTE_ONBOARDING_OFFER)) {
            dispatch(userActions.fetchUserData());
          }
        })
        .catch((err) => {
          dispatch(pushToSentryAction(err, 'Problem getting accessToken'));
        });
    }
  }, [personalNumber]);

  useEffect(() => {
    if (companies.length > 0) {
      dispatch(
        pushToGtmAction({
          eventName: 'main',
          actionName: 'user_login',
          stepStatus: StepStatus.Started,
          stepActionNumber: 1.1,
        })
      );
    }
  }, [companies]);

  useEffect(() => {
    if (isAuthenticated) {
      accessToken.getDecoded().then((decodedToken: any) => {
        const userCode = decodedToken[TOKEN.USER_CODE];

        if (userCode) {
          posthog.identify(userCode);
          Sentry.setUser({ id: userCode });
        }
      });
      localStorage.setItem('isAuthenticated', '1');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (overview.length) {
      overview.map((loan) =>
        dispatch(
          loansActions.fetchTerminatedContractById(loan.id, loan.companyId)
        )
      );
    }
  }, [overview.length]);

  if (
    userInfo.language ||
    errorType === GlobalErrorType.USER_HAS_NO_ACCOUNT ||
    errorType === GlobalErrorType.API_FAILURE ||
    errorType === GlobalErrorType.USER_SIGNED_OFFER_AND_NO_ACCOUNT ||
    location.pathname.includes(constVars.ROUTE_ONBOARDING_OFFER)
  ) {
    return <Route {...props} />;
  }
  return <Spinner color={theme.colors.secondaryGray} />;
};

let options: WithAuthenticationRequiredOptions = {
  onRedirecting: () => {
    if (window.location.pathname !== constVars.ROUTE_DEFAULT) {
      sessionStorage.setItem(
        'returnTo',
        `${window.location.pathname}${window.location.search}`
      );
    }

    return <Spinner color={theme.colors.secondaryGray} />;
  },
  loginOptions: {
    authorizationParams: {
      redirectUri: `${window.location.origin}/${constVars.ROUTE_LOGIN}`,
      prompt: 'none',
    },
  },
};

if (import.meta.env.MODE === 'internal-prod') {
  options = {};
}

export default withAuthenticationRequired(ProtectedRoute, options);
