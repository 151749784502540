import React, { useEffect } from 'react';
import { Typography, Group, Box } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { theme } from '~/styles/themes';
import { formatAmount } from '~/helpers/formatters.helper';
import { useDispatch, useSelector } from 'react-redux';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import useDateFormatter from '~/hooks/useDateFormatter';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { getFullName } from '~/helpers/onboarding.helper';
import LoanAdditionalSignerFooter from './LoanAdditionalSignerFooter';
import { fetchAdditionalSignerLoanApplication } from '~/store/actions/additionalSignerActions';
import { parseLanguage, parseMarket } from '~/helpers/market.helper';
import useQueryParams from '~/hooks/useQueryParams';
import { setPublicPagesIntl } from '~/store/slices/intl.slice';
import { CountryCode } from '~/enums';
import { useHistory } from 'react-router-dom';
import { setAdditionalSignerLoanApplicationData } from '~/store/slices/additionalSigner.slice';
import { TOnboardingDTO } from '~/types/DTO/onboarding';
import { getMarketBasedOnHostname } from '~/helpers/utils';
import constVars from '~/constants/constVars';

const LoanAdditionalSigner = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    intl: { market, languageIsSet },
    matchedMedia: { mobile },
    additionalSigner: { additionalSignerLoanApplicationOffer, apiStatus },
  } = useSelector((state: RootState) => state);

  const formatDate = useDateFormatter();
  const translate = useTranslate();
  const queryParams = useQueryParams();

  useEffect(() => {
    // ============= REMOVE ONCE WE STOP SUPPORTING OLD SIGNER SOLUTION =============
    const base64ApplicationData = queryParams.get('data');
    if (base64ApplicationData) {
      const parsedMarket =
        parseMarket(
          queryParams.get('market') ?? localStorage.getItem('qredMarket')
        ) ??
        getMarketBasedOnHostname() ??
        CountryCode.SE;
      const token = queryParams.get('token');
      const iSig = queryParams.get('iSig');

      if (parsedMarket === CountryCode.NL && token && iSig) {
        document.cookie = `publicToken=${token}; Path=/; Max-Age=86400`;
        document.cookie = `iSig=${iSig}; Path=/;`;
        const url = new URL(window.location.href);
        url.searchParams.delete('token');
        url.searchParams.delete('iSig');
        history.replace({ search: url.search });
      }
      const loanOfferResponseData = JSON.parse(
        window.atob(base64ApplicationData)
      ) as TOnboardingDTO['getAdditionalSignerLoanApplicationDataRes'];
      const parsedLanguage = parseLanguage(loanOfferResponseData.language);
      dispatch(setPublicPagesIntl(parsedMarket, parsedLanguage));
      dispatch(setAdditionalSignerLoanApplicationData(loanOfferResponseData));
      // ==============================================================================
    } else {
      dispatch(fetchAdditionalSignerLoanApplication(market));
    }
  }, []);

  useEffect(() => {
    if (apiStatus.fetchAdditionalSignerLoanApplication === ApiStatus.Failed) {
      history.push(constVars.ROUTE_SIGNER_ERROR);
    }
  }, [apiStatus.fetchAdditionalSignerLoanApplication]);

  const loanDetailsTypographyAndValue = [
    {
      typography: translate('LoanAmount') as string,
      value: formatAmount(Number(additionalSignerLoanApplicationOffer.amount)),
    },
    {
      typography: translate('MonthlyFee') as string,
      value: formatAmount(
        Number(additionalSignerLoanApplicationOffer.monthly_fee)
      ),
    },
    {
      typography: translate('LoanPeriod') as string,
      value: `${additionalSignerLoanApplicationOffer.term}  ${translate(
        'Months'
      )}`,
    },
    {
      typography: translate('DueDate') as string,
      value: additionalSignerLoanApplicationOffer
        ? formatDate(additionalSignerLoanApplicationOffer.payment_due, {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })
        : '',
    },
  ];

  const loanBorrowerDetailsTypographyAndValue = [
    {
      typography: translate('LoanRecipient') as string,
      value: additionalSignerLoanApplicationOffer.company_name,
    },
    {
      typography: translate('OrganisationNumber') as string,
      value: additionalSignerLoanApplicationOffer.company_no,
    },
    {
      typography: translate('CompanyRepresentative') as string,
      value: getFullName(
        additionalSignerLoanApplicationOffer.first_name || '',
        additionalSignerLoanApplicationOffer.last_name || ''
      ),
    },
    {
      typography: translate('RecipientBankAccount') as string,
      value: additionalSignerLoanApplicationOffer.bank_account,
    },
  ];

  return apiStatus.fetchAdditionalSignerLoanApplication !== ApiStatus.Started &&
    languageIsSet ? (
    <Box>
      <Group
        spacing={0}
        w={mobile ? 396 : 580}
        direction="column"
        p={mobile ? 'md' : 'xxl'}
        style={{
          borderRadius: '4px 4px 0px 0px',
          backgroundColor: `${theme.colors.neutral2}`,
        }}
      >
        <Typography weight={600} size="xl" lineHeight={32} mb={8}>
          {translate('VerifyCreditAgreementsCard.Title')}
        </Typography>
        <Typography lineHeight={24}>
          {translate('VerifyCreditAgreementsCard.Text1', {
            companyName: additionalSignerLoanApplicationOffer.company_name,
            amount: (
              <Typography weight={500}>
                {formatAmount(
                  Number(additionalSignerLoanApplicationOffer.amount)
                )}
              </Typography>
            ),
          })}{' '}
          {translate('VerifyCreditAgreementsCard.Text2')}
        </Typography>

        <Typography
          weight={600}
          size="xl"
          lineHeight={32}
          mt={mobile ? 43 : 58}
          mb={8}
        >
          {translate('LoanDetailsCard_signer.Title')}
        </Typography>
        {loanDetailsTypographyAndValue.map((loanDetail) => (
          <Group
            key={loanDetail.typography}
            spacing={2}
            justify="space-between"
            mb={2}
            direction={mobile ? 'column' : 'row'}
          >
            <Typography lineHeight={24}>{loanDetail.typography}</Typography>
            <Typography weight={500} lineHeight={24}>
              {loanDetail.value}
            </Typography>
          </Group>
        ))}
        <Typography
          weight={600}
          size="xl"
          lineHeight={32}
          mt={mobile ? 43 : 58}
          mb={8}
        >
          {translate('RecipientDetailsCard.Title')}
        </Typography>
        {loanBorrowerDetailsTypographyAndValue.map((loanBorrowerDetail) => (
          <Group
            key={loanBorrowerDetail.typography}
            spacing={2}
            justify="space-between"
            mb={2}
            direction={mobile ? 'column' : 'row'}
          >
            <Typography weight={400} lineHeight={24}>
              {loanBorrowerDetail.typography}
            </Typography>
            <Typography weight={500} lineHeight={24}>
              {loanBorrowerDetail.value}
            </Typography>
          </Group>
        ))}
        <Typography
          weight={600}
          size="xl"
          lineHeight={32}
          mt={mobile ? 43 : 58}
          mb={8}
        >
          {translate('LoanSummeryCard.Title')}
        </Typography>
        <Typography lineHeight={24} mb={40}>
          {
            translate('LoanSummeryCard.Text', {
              loanPeriod: (
                <Typography weight={500}>
                  {additionalSignerLoanApplicationOffer.term}
                </Typography>
              ),
            }) as string
          }
        </Typography>
      </Group>
      <LoanAdditionalSignerFooter />
    </Box>
  ) : (
    <SpinnerCircular />
  );
};

export default LoanAdditionalSigner;
