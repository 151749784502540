import styled from 'styled-components';
import Stack from '~/components/shared/Layout/Stack';
import { theme } from '~/styles/themes';
import { MediaQueries } from '~/constants/constVars';
import Center from '~/components/shared/Center/Center';

export const BottomContent = styled(Stack)`
  margin-top: auto;
`;

export const SummaryHeader = styled(Center)`
  background-color: ${theme.colors.document};
  padding: 2em;
  margin: -1.5em -3em 0;
  box-sizing: border-box;
  text-align: center;

  @media ${MediaQueries.smallScreenPortrait} {
    padding: 3.5em;
    margin: -2em -2em 0;
  }
`;
