import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import MainMenu from '~/components/shared/Menu/MainMenu';
import NotificationMenu from '~/components/shared/Notifications/NotificationMenu';
import { MediaQueries } from '~/constants/constVars';
import { theme } from '~/styles/themes';
import Group from '../Layout/Group';

export const HeaderContainer = styled.div<{
  filledBackground?: boolean;
  hasSiblings?: boolean;
  singleChild?: boolean;
  alignMenuRight?: boolean;
}>`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-items: flex-start;
  padding: 1.5em;
  justify-content: ${(props) =>
    props.alignMenuRight ? 'flex-end' : 'space-between'};
  ${(props) =>
    props.filledBackground &&
    css`
      @media ${MediaQueries.smallScreenPortrait} {
        box-shadow: 1px 4px 18px 5px rgba(102, 99, 99, 0.11);
        -webkit-box-shadow: 1px 4px 18px 5px rgba(102, 99, 99, 0.11);
        -moz-box-shadow: 1px 4px 18px 5px rgba(102, 99, 99, 0.11);
        background-color: ${theme.colors.white};
      }
    `};
`;

const ChildrenContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (orientation: landscape) {
    max-width: 40em;
  }
`;

interface Props {
  hideAllMenus?: boolean;
  alignMenuRight?: boolean;
  hideMainMenu?: boolean;
  hideNotificationMenu?: boolean;
  extraButtons?: ReactElement;
}

const PageHeader: React.FC<Props> = ({
  children,
  extraButtons,
  hideMainMenu,
  hideNotificationMenu,
  hideAllMenus,
  alignMenuRight,
}) => (
  <HeaderContainer alignMenuRight={alignMenuRight} hasSiblings={!!children}>
    {!(hideAllMenus || hideMainMenu) && !alignMenuRight && (
      <MainMenu hasSiblings={!!children} />
    )}
    {children && <ChildrenContainer>{children}</ChildrenContainer>}
    {(extraButtons || !(hideAllMenus || hideNotificationMenu)) && (
      <Group>
        {extraButtons}
        {!(hideAllMenus || hideNotificationMenu) && <NotificationMenu />}
      </Group>
    )}
    {!(hideAllMenus || hideMainMenu) && alignMenuRight && (
      <MainMenu alignMenuRight hasSiblings={!!children} />
    )}
  </HeaderContainer>
);

export default PageHeader;
