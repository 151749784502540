import styled from 'styled-components';
import { theme } from './themes';

export enum ComponentSize {
  ExtraSmall,
  Small,
  SmallMedium,
  Medium,
  Large,
  MenuIcon, // Quick fix to get 1,2em for menu icons.
}

export interface ArrangementProps {
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  pt?: number;
  pb?: number;
  pr?: number;
  pl?: number;
}

export interface SizeProps {
  size?: ComponentSize;
}

export const BaseArrangement = styled.div<ArrangementProps>`
  margin-top: ${(props) => (props.mt ? `${props.mt}em` : undefined)};
  margin-bottom: ${(props) => (props.mb ? `${props.mb}em` : undefined)};
  margin-right: ${(props) => (props.mr ? `${props.mr}em` : undefined)};
  margin-left: ${(props) => (props.ml ? `${props.ml}em` : undefined)};
  padding-top: ${(props) => (props.pt ? `${props.pt}em` : undefined)};
  padding-bottom: ${(props) => (props.pb ? `${props.pb}em` : undefined)};
  padding-right: ${(props) => (props.pr ? `${props.pr}em` : undefined)};
  padding-left: ${(props) => (props.pl ? `${props.pl}em` : undefined)};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.document};
  min-height: var(--client-height);
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  padding: 0 2em;
  flex: 1 0;
  width: 100%;
  @media (orientation: landscape) {
    max-width: 40em;
  }
`;

export const PageFooter = styled.div`
  width: 100%;
  padding: 0 2em;
  margin: 2em auto;
  @media (orientation: landscape) {
    max-width: 40em;
  }
`;
export const boxShadow = '0px 0.02em 0.02em 0.04em rgba(0, 0, 0, .18)';

/**
 * General function to generate css lenght properties based on the ComponentSize and a base length value
 */
export const cssLenght = (props: any, baseLength: number) => {
  if (props.size === ComponentSize.Large) return `${baseLength * 3}em`;
  if (props.size === ComponentSize.SmallMedium) return `${baseLength * 1.5}em`;
  if (props.size === ComponentSize.Small) return `${baseLength}em`;
  if (props.size === ComponentSize.ExtraSmall) return `${baseLength * 0.5}em`;
  // This is a quick fix to get menu icons right, find a better solution!
  if (props.size === ComponentSize.MenuIcon) return `${baseLength * 1.2}em`;
  return `${baseLength * 2}em`; // If no ComponetSize is given, Medium size is returned
};

export const Link = styled.a`
  color: ${theme.colors.primaryGreen};
  text-decoration: underline;
  cursor: pointer;
`;

export const ClickableText = styled.span<{
  typographyColor?: string;
  noUnderline?: boolean;
}>`
  color: ${({ typographyColor }) =>
    typographyColor || theme.colors.primaryGreen};
  text-decoration: ${({ noUnderline }) => (noUnderline ? 'none' : 'underline')};
  cursor: pointer;
`;
