import { ContentType, HeaderKeys } from '@qred/shared-enums';
import { getCookieValue } from '~/helpers/utils';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { normalizeOrgNumberHelper } from '~/helpers/normalizeOrgNumber.helper';
import { IFileInformation } from '~/Interfaces';
import { logToSentry } from '~/helpers/loggers.helper';
import { buildPostOfferFormData } from '~/helpers/onboarding.helper';
import { onboardingDTOSchemas } from '~/schemas/DTO/Onboarding';
import { loanOnboardingOfferDTOSchemas } from '~/schemas/DTO/LoanOnboardingOffer';
import { TLoanOnboardingApplicationDTO } from '~/types/DTO/loanOnboardingApplication';
import { TLoanOnboardingOfferDTO } from '~/types/DTO/loanOnboardingOffer';
import { TOnboardingDTO } from '~/types/DTO/onboarding';
import { TCardOnboardingOfferDTO } from '~/types/DTO/cardOnboardingOffer';
import { cardOnboardingOfferDTOSchemas } from '~/schemas/DTO/CardOnboardingOffer';
import { loanOnboardingApplicationDTOSchemas } from '~/schemas/DTO/LoanOnboardingApplication';
import { axiosWithRetry, getApiVersionPathParam } from '../api';
import { IOnboardingKYCQuestionnaireAnswer } from '~/interfaces/Onboarding';
import { ILoanOnboardingOfferForm } from '~/interfaces/LoanOnboardingoffer';
import { CountryCode } from '~/enums';
import { accessToken } from '~/store/apis/utils/accessToken';

// Onboarding offer
export const apiGetOnboardingOffer = async (
  uuid: string,
  countryCode: CountryCode
) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const response = await axios.get<TLoanOnboardingOfferDTO['getOfferRes']>(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${countryCode}/offer/uuid/${uuid}`,
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = loanOnboardingOfferDTOSchemas.getOfferRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetOnboardingOffer parseResponseResult'
    );
  }
  return response.data;
};

export const apiPostOffer = async (
  data: Omit<ILoanOnboardingOfferForm, 'payoutAmount'> & { amount: number }
) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }
  const formData = buildPostOfferFormData(data, 'private');

  const response = await axios.post<TLoanOnboardingOfferDTO['postOfferRes']>(
    `/loans/offer/application/${data.applicationId}/${data.clientId}/`,
    formData,
    {
      withCredentials: false, // TODO ONBOARDING: keep or remove?
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
        [HeaderKeys.ContentTypeKey]: 'application/x-www-form-urlencoded',
      },
    }
  );

  const parseResponseResult = loanOnboardingOfferDTOSchemas.postOfferRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(parseResponseResult.error, 'apiPostOffer parseResponseResult');
  }
  return response.data;
};

export const apiGetOnboardingKYCQuestionsAndAnswers = async (
  countryCode: CountryCode
) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const response = await axios.get<
    TOnboardingDTO['getKYCQuestionsAndAnswersRes']
  >(
    `${
      import.meta.env.VITE_API_BASE_URL
    }/client-assessment/${countryCode.toLowerCase()}/questions?typeId=7`,
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = onboardingDTOSchemas.getKYCQuestionsAndAnswersRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetOnboardingKYCQuestionsAndAnswers parseResponseResult'
    );
  }
  return response.data;
};

export const apiPostOnboardingKYCAnswers = async (
  countryCode: CountryCode,
  clientId: number,
  kycQuestionnaireAnswers: IOnboardingKYCQuestionnaireAnswer[]
) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const data: TOnboardingDTO['postKYCAnswersReq'] = {
    questionsAndAnswers: kycQuestionnaireAnswers,
  };

  const parseRequestDataResult = onboardingDTOSchemas.postKYCAnswersReq.safeParse(
    data
  );

  if (!parseRequestDataResult.success) {
    logToSentry(
      parseRequestDataResult.error,
      'apiPostOnboardingKYCAnswers parseRequestDataResult'
    );
  }

  const response = await axios.post<TOnboardingDTO['postKYCAnswersRes']>(
    `${
      import.meta.env.VITE_API_BASE_URL
    }/client-assessment/${countryCode.toLowerCase()}/assessment/${clientId}`,
    { questionsAndAnswers: kycQuestionnaireAnswers },
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = onboardingDTOSchemas.postKYCAnswersRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiPostOnboardingKYCAnswers parseResponseResult'
    );
  }
  return response.data;
};

// Onboarding
export const apiGetOnboardingApplication = async (
  applicantAuthenticated: boolean,
  uuid: string,
  countryCode: CountryCode,
  sendPublicToken: boolean
) => {
  const publicToken = getCookieValue('publicToken');
  let ACCESSS_TOKEN;
  if (applicantAuthenticated) {
    ACCESSS_TOKEN = await accessToken.get();
  } else {
    ACCESSS_TOKEN = publicToken;
  }
  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const response = await axios.get<
    TLoanOnboardingApplicationDTO['getApplicationRes']
  >(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${countryCode}/application/${uuid}`,
    {
      params: {
        publicToken: sendPublicToken ? publicToken : undefined,
      },
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = loanOnboardingApplicationDTOSchemas.getApplicationRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetOnboardingApplication parseResponseResult'
    );
  }
  return response.data;
};

export const apiGetOnboardingClientCompanies = async (
  clientId: number,
  countryCode: CountryCode
) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const response = await axios.get<
    TLoanOnboardingApplicationDTO['getClientsRes']
  >(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${countryCode}/client/${clientId}`,
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = loanOnboardingApplicationDTOSchemas.getClientsRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetOnboardingClientCompanies parseResponseResult'
    );
  }
  return response.data;
};

export const apiGetOnboardingGuarantorsAndBoardMembers = async (
  orgNumber: string,
  applicationUuid: string,
  countryCode: CountryCode,
  applicantAuthenticated: boolean,
  withRetry: boolean
) => {
  let ACCESSS_TOKEN;
  if (applicantAuthenticated) {
    ACCESSS_TOKEN = await accessToken.get();
  } else {
    ACCESSS_TOKEN = getCookieValue('publicToken');
  }
  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const axiosInstance = withRetry ? axiosWithRetry : axios;

  const options: AxiosRequestConfig = {
    headers: {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    },
  };
  if (withRetry) {
    options.retry = {
      retryCount: 3,
      retryDelay: 3000,
      retryUntil: (data: any) => Array.isArray(data) && !!data.length,
    };
  }

  const response = await axiosInstance.get<
    TLoanOnboardingApplicationDTO['getGuarantorsAndBoardMembersRes'][]
  >(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${countryCode.toLowerCase()}/guarantors/companyNumber/${orgNumber}/applicationUuid/${applicationUuid}`,
    options
  );

  const parseResponseResult = loanOnboardingApplicationDTOSchemas.getGuarantorsAndBoardMembersRes
    .array()
    .safeParse(response.data);

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetOnboardingGuarantorsAndBoardMembers parseResponseResult'
    );
  }
  return response;
};

export const apiGetOnboardingBeneficialOwners = async (
  orgNumber: string,
  countryCode: CountryCode
) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const getOnboardingBeneficialOwnersResponse = await axios.get<
    TLoanOnboardingOfferDTO['getBeneficialOwnersRes']
  >(
    `${
      import.meta.env.VITE_API_BASE_URL
    }/int-roaring/country/${countryCode.toLowerCase()}/company/${orgNumber}/beneficial-owners`,
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = loanOnboardingOfferDTOSchemas.getBeneficialOwnersRes.safeParse(
    getOnboardingBeneficialOwnersResponse.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetOnboardingBeneficialOwners parseResponseResult'
    );
  }
  return getOnboardingBeneficialOwnersResponse.data;
};

export const apiGetOnboardingCompanyLookup = async (
  orgNumber: string,
  countryCode: CountryCode
) => {
  const formattedOrgNumber = normalizeOrgNumberHelper(orgNumber);

  const getOnboardingCompanyResponse = await axios.get<
    TLoanOnboardingApplicationDTO['getCompanyLookupRes']
  >(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${countryCode.toLowerCase()}/companyNumber/${formattedOrgNumber}`
  );

  const parseResponseResult = loanOnboardingApplicationDTOSchemas.getCompanyLookupRes.safeParse(
    getOnboardingCompanyResponse.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetOnboardingCompanyLookup parseResponseResult'
    );
  }
  return getOnboardingCompanyResponse.data;
};

export const apiPostApplication = async (
  data: TLoanOnboardingApplicationDTO['postPatchApplicationReq']
) => {
  const config = { headers: {} };
  let ACCESSS_TOKEN;
  if (data.applicantAuthenticated) {
    ACCESSS_TOKEN = await accessToken.get();
  } else {
    ACCESSS_TOKEN = getCookieValue('publicToken');
  }
  if (ACCESSS_TOKEN) {
    config.headers = {
      [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
    };
  }

  const parseRequestDataResult = loanOnboardingApplicationDTOSchemas.postPatchApplicationReq.safeParse(
    data
  );

  if (!parseRequestDataResult.success) {
    logToSentry(
      parseRequestDataResult.error,
      'apiPostApplication parseRequestDataResult'
    );
  }

  const response = await axios.post<
    TLoanOnboardingApplicationDTO['postApplicationRes']
  >(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${data.market.toLowerCase()}/application/`,
    data,
    config
  );

  const parseResponseResult = loanOnboardingApplicationDTOSchemas.postApplicationRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiPostApplication parseResponseResult'
    );
  }
  return response.data;
};

export const apiPatchApplication = async (
  data: TLoanOnboardingApplicationDTO['postPatchApplicationReq'],
  sendPublicToken: boolean,
  getCompanyTypeCategory: boolean
) => {
  const publicToken = getCookieValue('publicToken');

  let ACCESSS_TOKEN;
  if (data.applicantAuthenticated) {
    ACCESSS_TOKEN = await accessToken.get();
  } else {
    ACCESSS_TOKEN = publicToken;
  }
  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const parseRequestDataResult = loanOnboardingApplicationDTOSchemas.postPatchApplicationReq.safeParse(
    data
  );

  if (!parseRequestDataResult.success) {
    logToSentry(
      parseRequestDataResult.error,
      'apiPatchApplication parseRequestDataResult'
    );
  }

  const response = await axios.patch<
    TLoanOnboardingApplicationDTO['patchApplicationRes']
  >(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${data.market.toLowerCase()}/application/${data.applicationUuid}`,
    data,
    {
      params: {
        publicToken: sendPublicToken ? publicToken : undefined,
        getCompanyTypeCategory: getCompanyTypeCategory || undefined,
      },
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = loanOnboardingApplicationDTOSchemas.patchApplicationRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiPatchApplication parseResponseResult'
    );
  }
  return response.data;
};

export const apiGetAddress = async (
  zipCode: string | undefined,
  houseNumber: string | undefined,
  applicantAuthenticated: boolean,
  market: CountryCode
) => {
  let ACCESSS_TOKEN;
  if (applicantAuthenticated) {
    ACCESSS_TOKEN = await accessToken.get();
  } else {
    ACCESSS_TOKEN = getCookieValue('publicToken');
  }
  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }
  const response = await axios.get<TOnboardingDTO['getAddressRes']>(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${market}/postcode/${zipCode}/houseNumber/${houseNumber}/address`,
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
    }
  );

  const parseResponseResult = onboardingDTOSchemas.getAddressRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(parseResponseResult.error, 'apiGetAddress parseResponseResult');
  }
  return response.data;
};

export const getSignedUrl = async (
  urlList: string[],
  clientId: string,
  market: CountryCode,
  applicantAuthenticated: boolean,
  documentType: string
): Promise<AxiosResponse<IFileInformation[]>> => {
  let ACCESSS_TOKEN;
  if (applicantAuthenticated) {
    ACCESSS_TOKEN = await accessToken.get();
  } else {
    ACCESSS_TOKEN = getCookieValue('publicToken');
  }
  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }
  const getSignedUrlsPayload = {
    fileNames: urlList,
    documentType,
  };

  return axios.post(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${market}/upload/${clientId}`,
    JSON.stringify(getSignedUrlsPayload),
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
        [HeaderKeys.ContentTypeKey]: ContentType.ApplicationJson,
      },
    }
  );
};

export const postFile = (
  url: string,
  form: FormData
): Promise<AxiosResponse<IFileInformation[]>> => axios.post(url, form);

export const apiPostCardOnboardingOffer = async ({
  data,
  applicationUuid,
  market,
  clientId,
  apiVersion,
}: {
  data: TCardOnboardingOfferDTO['postOfferReq'];
  applicationUuid: string;
  market: CountryCode;
  clientId: number;
  apiVersion?: string;
}) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const version = getApiVersionPathParam(apiVersion);

  const parseRequestDataResult = cardOnboardingOfferDTOSchemas.postOfferReq.safeParse(
    data
  );

  if (!parseRequestDataResult.success) {
    logToSentry(
      parseRequestDataResult.error,
      'apiPostCardOnboardingOffer parseRequestDataResult'
    );
  }

  const response = await axios.post<TCardOnboardingOfferDTO['postOfferRes']>(
    `/cards/${version}application/offer/`,
    data,
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
      params: {
        application_uuid: applicationUuid,
        market,
        client_id: clientId,
      },
    }
  );

  // something went wrong
  if (response?.data?.message) {
    throw new Error(response.data.message);
  }

  const parseResponseResult = cardOnboardingOfferDTOSchemas.postOfferRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiPostCardOnboardingOffer parseResponseResult'
    );
  }
  return response.data;
};

export const apiGetCardOnboardingOffer = async (
  clientId: number,
  market: CountryCode
) => {
  const ACCESSS_TOKEN = await accessToken.get();

  if (!ACCESSS_TOKEN) {
    return Promise.reject(new Error('No ACCESSS_TOKEN found!'));
  }

  const response = await axios.get<TCardOnboardingOfferDTO['getOfferRes']>(
    '/cards/application/offer',
    {
      headers: {
        [HeaderKeys.Authorization]: `${HeaderKeys.Bearer} ${ACCESSS_TOKEN}`,
      },
      params: {
        clientId,
        market,
        environment: process.env.NODE_ENV,
      },
    }
  );

  // something went wrong
  if (response?.data?.message) {
    return response.data;
  }

  const parseResponseResult = cardOnboardingOfferDTOSchemas.getOfferRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetCardOnboardingOffer parseResponseResult'
    );
  }
  return response.data;
};

export const apiGetCompanyInfoBySearch = async (
  searchInput: string,
  market: CountryCode
) => {
  const response = await axios.get<
    TLoanOnboardingApplicationDTO['getCompanySearchRes']
  >(
    `${
      import.meta.env.VITE_API_ONBOARDING_BASE_URL
    }/country/${market}/company-search/${encodeURIComponent(searchInput)}`
  );

  const parseResponseResult = loanOnboardingApplicationDTOSchemas.getCompanySearchRes.safeParse(
    response.data
  );

  if (!parseResponseResult.success) {
    logToSentry(
      parseResponseResult.error,
      'apiGetCompanyInfoBySearch parseResponseResult'
    );
  }

  return response.data;
};
