import {
  Button,
  Container,
  Group,
  useWindowProperties,
} from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import Logo from '~/components/shared/LogoOld/LogoOld';
import MainMenu from '~/components/shared/Menu/MainMenu';
import ProgressStepperDots from '~/components/shared/ProgressStepperDots/ProgressStepperDots';
import { RootState } from '~/store/types/sharedTypes';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import {
  BackButton,
  CenterGroup,
  LeftGroup,
  OnboardingNavbarContainer,
  RightGroup,
} from './OnboardingNavbar.styled';
import useTranslate from '~/hooks/useTranslate';

interface OnboardingNavbarProps {
  absolutePosition?: boolean;
  fixedPosition?: boolean;
  showBackButton?: boolean;
  onBackClick?: () => void;
  progressStepperDots?: {
    steps: any;
    currentStep: number;
  };
  filledBackground?: boolean;
  showLogo?: boolean;
  style?: React.CSSProperties | undefined;
  shouldDisplayTestVariant?: boolean;
}

const OnboardingNavbar = ({
  absolutePosition,
  fixedPosition,
  filledBackground,
  showLogo,
  showBackButton,
  onBackClick,
  progressStepperDots,
  shouldDisplayTestVariant = false,
  style,
}: OnboardingNavbarProps) => {
  const { matchedMedia } = useSelector((state: RootState) => state);

  const { isMobile } = useWindowProperties();
  const t = useTranslate();

  return shouldDisplayTestVariant ? (
    <Container
      pos="relative"
      display="flex"
      style={{ alignItems: 'center', justifyContent: 'center', ...style }}
      p="2.1em"
      w="100%"
      bg={matchedMedia.smallScreenPortrait ? 'textLight.1' : 'transparent'}
    >
      <Group pos="absolute" left="8px">
        {showLogo && (
          <Logo
            noLink
            size={
              matchedMedia.desktop
                ? ComponentSize.Medium
                : ComponentSize.MenuIcon
            }
          />
        )}
        {showBackButton && onBackClick && (
          <Button
            leftIcon="arrow_back_ios"
            data-cy="navbar_back_button"
            onClick={onBackClick}
            variant="tertiary"
          >
            {!isMobile && t('Back')}
          </Button>
        )}
      </Group>
      {progressStepperDots && (
        <ProgressStepperDots
          steps={progressStepperDots.steps}
          currentStep={progressStepperDots.currentStep}
        />
      )}
    </Container>
  ) : (
    <OnboardingNavbarContainer
      absolutePosition={absolutePosition}
      fixedPosition={fixedPosition}
      filledBackground={filledBackground}
    >
      <LeftGroup>
        {showLogo && (
          <Logo
            // onClick={() => {
            //   window.location.assign(getHomePageByMarket(market, language));
            // }}
            noLink
            size={
              matchedMedia.desktop
                ? ComponentSize.Medium
                : ComponentSize.MenuIcon
            }
          />
        )}

        {showBackButton && onBackClick && (
          <BackButton data-cy="navbar_back_button" onClick={onBackClick}>
            <Icon
              color={theme.colors.secondaryBlack}
              src={SvgSrc.ChevronLeft}
              size={ComponentSize.Medium}
            />
          </BackButton>
        )}
      </LeftGroup>
      <CenterGroup>
        {progressStepperDots && (
          <ProgressStepperDots
            steps={progressStepperDots.steps}
            currentStep={progressStepperDots.currentStep}
          />
        )}
      </CenterGroup>
      <RightGroup>
        <MainMenu />
      </RightGroup>
    </OnboardingNavbarContainer>
  );
};

export default OnboardingNavbar;
