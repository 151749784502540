import styled, { css } from 'styled-components';

export const TooltipContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isMobile ? ' space-between' : '')};

  div[role='tooltip'] {
    span {
      font-size: 14px;
      line-height: 21px;
    }
  }

  ${(props) =>
    !props.isMobile &&
    css`
      svg {
        margin-left: 0.3em;
        position: relative;
        top: -3px;
      }

      div > div {
        margin-left: 0.3em;
      }
    `}
`;
