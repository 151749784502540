import { Dispatch } from 'redux';
import { ApiStatus, AppThunk, GetStateFunc } from '~/store/types/sharedTypes';
import { logToSentry } from '~/helpers/loggers.helper';
import {
  ApplicationStatus,
  ClientType,
  FlowTypes,
  GlobalErrorType,
  StepStatus,
} from '~/enums';
import {
  apiGetOnboardingApplication,
  apiGetOnboardingClientCompanies,
  apiGetOnboardingCompanyLookup,
  apiGetOnboardingGuarantorsAndBoardMembers,
  apiPatchApplication,
  apiPostApplication,
} from '~/services/api/onboarding';
import { companyIsSoleTrader } from '~/constants/markets';
import { hasBasicApplicationDataPerMarket } from '~/helpers/onboarding.helper';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import { normalizeOrgNumberHelper } from '~/helpers/normalizeOrgNumber.helper';
import { loanOnboardingApplicationDTOMappers } from '~/mappers/onboarding/DTO/loanOnboardingApplication';
import { pushToGtmOnboardingAction } from './gtmActions';
import {
  clearOnboardingState,
  setApiStatus,
  setBasicApplicationSent,
  setClientId,
  setCompanyIsNew,
  setCompanyIsReal,
  setCompanyName,
  setIsCompanyTypeCategoryRequested,
  updateApplicationStatus,
  updateCurrentOrgNumber,
  updateCurrentStep,
  updateForm,
  updateOverview,
} from '../slices/onboardingApplication.slice';
import {
  setCustomGlobalError,
  toggleGlobalErrorOn,
} from '../slices/globalError.slice';
import {
  ILoanOnboardingApplicationClient,
  ILoanOnboardingApplicationGuarantor,
} from '~/interfaces/LoanOnboardingApplication';
import { CompanyTypeCategory } from '~/interfaces/Onboarding';
import { selectStepConfig } from '../selectors/loanOnboardingApplication.selector';

export const fetchCompanyLookupData = (lookupOrgNr: string): AppThunk => async (
  dispatch: Dispatch<any>,
  getState: GetStateFunc
) => {
  const { onboardingApplication, intl } = getState();

  dispatch(setApiStatus({ companyLookupData: ApiStatus.Started }));
  try {
    const companyLookUpData = await apiGetOnboardingCompanyLookup(
      lookupOrgNr,
      intl.market
    );
    const {
      organizationNumber: currentReduxOrgNr,
    } = getState().onboardingApplication.form;
    // If user has changed org number in the input while lookup api was in progress, ignore response
    if (companyLookUpData && currentReduxOrgNr === lookupOrgNr) {
      const clientType =
        onboardingApplication.flowType === FlowTypes.Manual
          ? ClientType.Unknown
          : ClientType.NLC;
      dispatch(setCompanyIsReal(true));
      dispatch(setCompanyName(companyLookUpData.companyName));
      dispatch(
        updateForm({
          companyType: companyLookUpData.companyType,
          clientType,
          organizationNumber: companyLookUpData.companyNumber,
        })
      );
    }
    dispatch(setApiStatus({ companyLookupData: ApiStatus.Completed }));
  } catch (err) {
    dispatch(setApiStatus({ companyLookupData: ApiStatus.Failed }));
    if (err?.response?.status === 404) {
      dispatch(setCompanyIsReal(false));
      dispatch(updateForm({ companyType: '' }));
      dispatch(
        pushToGtmOnboardingAction({
          actionName: 'fetch_company_not_found',
          errorMessage: err.response?.data?.message,
          stepStatus: StepStatus.Error,
        })
      );
    } else {
      dispatch(
        pushToGtmOnboardingAction({
          actionName: 'fetch_company',
          errorMessage: err.response?.data?.message,
          stepStatus: StepStatus.Error,
        })
      );
      dispatch(
        pushToSentryAction(err, 'apiGetOnboardingCompanyLookup', {
          lookupOrgNr,
          product: 'onboarding',
        })
      );
    }
  }
};

export const fetchClientApplication = (uuid: string): AppThunk => async (
  dispatch,
  getState: GetStateFunc
) => {
  dispatch(setApiStatus({ application: ApiStatus.Started }));
  const {
    intl,
    onboardingApplication: { form, flowType },
  } = getState();
  const sendPublicToken =
    flowType === FlowTypes.Manual && form.applicantAuthenticated;
  try {
    const clientApplicationData = await apiGetOnboardingApplication(
      form.applicantAuthenticated,
      uuid,
      intl.market,
      sendPublicToken
    );
    if (
      typeof clientApplicationData === 'object' &&
      !clientApplicationData.finalized
    ) {
      const mappedFormData = loanOnboardingApplicationDTOMappers.applicationFromDTO(
        clientApplicationData,
        form
      );

      // completedStep will not exist on existing applications once we deploy this
      // so we need to use currentStep as default for a while
      // TODO: instead of having 'completedStep', create 'stepCompleted' prop instead
      dispatch(
        updateCurrentStep(
          clientApplicationData.completedStep
            ? clientApplicationData.completedStep + 1
            : clientApplicationData.currentStep + 1
        )
      );
      dispatch(
        setIsCompanyTypeCategoryRequested(
          !!clientApplicationData.isCompanyTypeCategoryRequested
        )
      );
      dispatch(
        setBasicApplicationSent(!!clientApplicationData.basicApplicationSent)
      );
      dispatch(updateForm(mappedFormData));
      dispatch(setApiStatus({ application: ApiStatus.Completed }));
      dispatch(updateCurrentOrgNumber(mappedFormData.organizationNumber));
      if (clientApplicationData.clientId) {
        dispatch(setClientId(clientApplicationData.clientId));
      }

      // TODO: this should actually be checking companyIsNew state, but that is not set yet. We can move that to form to have it
      // stored in the database to know when company is new or from the clients list.
      if (flowType === FlowTypes.Manual) {
        dispatch(fetchCompanyLookupData(mappedFormData.organizationNumber));
      }
    } else {
      // TODO: decide what to do if no application was found OR if application is already finalized
      dispatch(clearOnboardingState());
    }
  } catch (err) {
    dispatch(
      pushToSentryAction(err, 'apiGetOnboardingApplication', {
        applicationUuid: uuid,
        product: 'onboarding',
      })
    );
    dispatch(setApiStatus({ application: ApiStatus.Failed }));
    if (
      err.message === 'No ACCESSS_TOKEN found!' ||
      err?.response?.status === 401
    ) {
      dispatch(toggleGlobalErrorOn(GlobalErrorType.ONBOARDING_TOKEN_EXPIRED));
    } else {
      dispatch(clearOnboardingState());
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    }
  }
};

export const fetchClientCompanies = (): AppThunk => async (
  dispatch,
  getState: GetStateFunc
) => {
  dispatch(setApiStatus({ clients: ApiStatus.Started }));
  const { intl, onboardingApplication } = getState();
  if (!onboardingApplication.userId) return;
  try {
    const clientCompaniesData = await apiGetOnboardingClientCompanies(
      onboardingApplication.userId,
      intl.market
    );
    if (clientCompaniesData?.clients?.length) {
      const validClients = clientCompaniesData.clients.filter(
        (client: ILoanOnboardingApplicationClient) => client.company_no
      );
      dispatch(
        updateForm({
          clientType: validClients.some(
            (client: ILoanOnboardingApplicationClient) =>
              client.company_no ===
                onboardingApplication.form.organizationNumber && client.active
          )
            ? ClientType.RLC
            : ClientType.NLC,
        })
      );
      if (onboardingApplication.flowType === FlowTypes.Authenticated) {
        dispatch(updateOverview({ clients: validClients }));
      }
    } else {
      dispatch(setCompanyIsNew(true));
    }
    dispatch(setApiStatus({ clients: ApiStatus.Completed }));
  } catch (err) {
    dispatch(
      pushToSentryAction(err, 'apiGetOnboardingClientCompanies', {
        clientId: onboardingApplication.userId,
        product: 'onboarding',
      })
    );
    dispatch(setApiStatus({ clients: ApiStatus.Failed }));
  }
};

/**
 * API for fetching board members, and existing guarantors from current loan in case of RLC.
 * Retries are needed because backend might not be done with fetching board members
 * from the credit report.
 * Retries are not needed for sole traders though, because they dont have board members.
 */
export const fetchGuarantorsAndBoardMembers = (): AppThunk => async (
  dispatch,
  getState: GetStateFunc
) => {
  const { market } = getState().intl;
  const {
    organizationNumber,
    applicantAuthenticated,
    companyType,
    guarantors: applicationGuarantors,
    applicationUuid,
  } = getState().onboardingApplication.form;

  if (!organizationNumber || !applicationUuid) return;

  dispatch(setApiStatus({ guarantors: ApiStatus.Started }));
  const withRetry = !companyIsSoleTrader(market, companyType);
  try {
    const guarantorAndBoardMembers = await apiGetOnboardingGuarantorsAndBoardMembers(
      normalizeOrgNumberHelper(organizationNumber),
      applicationUuid,
      market,
      applicantAuthenticated,
      withRetry
    );

    if (guarantorAndBoardMembers.maxRetriesReached) {
      logToSentry(
        new Error('Max api call retries reached'),
        'apiGetOnboardingGuarantorsAndBoardMembers',
        {
          product: 'onboarding',
        }
      );
    }

    if (guarantorAndBoardMembers.data) {
      let guarantors = guarantorAndBoardMembers.data.map(
        (apiGuarantor): ILoanOnboardingApplicationGuarantor => {
          const commonProperties = {
            isChecked: !!apiGuarantor.latestGuarantor,
          };

          if (apiGuarantor.address?.postCode) {
            const { postCode, street, city, houseNo } = apiGuarantor.address;

            return {
              ...apiGuarantor,
              addressFields: {
                zipCode: postCode,
                streetName: street || '',
                city: city || '',
                houseNumber: houseNo || '',
              },
              ...commonProperties,
            };
          }

          return { ...apiGuarantor, ...commonProperties };
        }
      );

      // filter out the guarantors from API that also exists in application guarantors list
      if (applicationGuarantors?.length) {
        guarantors = guarantors.filter(
          (apiGuarantor: ILoanOnboardingApplicationGuarantor) =>
            !applicationGuarantors.some(
              (applicationGuarantor) =>
                applicationGuarantor.guarantorId === apiGuarantor.guarantorId
            )
        );
        guarantors.push(...applicationGuarantors);
      }
      const sortedGuarantor = guarantors.sort(
        (guarantor: ILoanOnboardingApplicationGuarantor) =>
          guarantor.latestGuarantor ? -1 : 1
      );
      dispatch(
        updateForm({
          guarantors: sortedGuarantor,
        })
      );
    }
    dispatch(setApiStatus({ guarantors: ApiStatus.Completed }));
  } catch (err) {
    dispatch(
      pushToSentryAction(err, 'apiGetOnboardingGuarantorsAndBoardMembers', {
        organizationNumber,
        product: 'onboarding',
      })
    );
    dispatch(setApiStatus({ guarantors: ApiStatus.Failed }));
  }
};

export const postApplication = () => async (
  dispatch: Dispatch<any>,
  getState: GetStateFunc
) => {
  const { intl } = getState();
  const storedLandingUrl = sessionStorage.getItem('landingUrl') || '';
  const landingUrl = new URL(storedLandingUrl);
  landingUrl.searchParams.set('languageCode', intl.language);
  const referenceUrl = sessionStorage.getItem('referrerUrl') || '';
  dispatch(
    updateForm({
      guarantors: [],
      bankIntegration: false,
      turnover: '',
      purposeOfLoan: '',
      reason: '',
      applicationUuid: '',
      landingUrl: landingUrl.href,
      referenceUrl,
      eskatConnected: false,
      qCode: '',
    })
  );

  const {
    onboardingApplication: { form, currentStep },
  } = getState();

  const shouldSendBasicApplicationGtmEvent = hasBasicApplicationDataPerMarket(
    form,
    intl.market
  );

  const requestData = loanOnboardingApplicationDTOMappers.applicationToDTO({
    form,
    currentStep,
    market: intl.market,
    shouldPostApplication: true,
    finalized: false,
    currentStepCompleted: true,
    basicApplicationSent: shouldSendBasicApplicationGtmEvent,
  });

  dispatch(setApiStatus({ post: ApiStatus.Started }));
  try {
    const result = await apiPostApplication(requestData);

    dispatch(
      updateCurrentOrgNumber(requestData.organization.organizationNumber)
    );
    dispatch(updateForm({ applicationUuid: result.applicationUuid }));

    if (result.applicationId) {
      dispatch(updateForm({ applicationId: result.applicationId }));
    }
    if (result.qCode) {
      dispatch(updateForm({ qCode: result.qCode }));
    }
    if (result.clientId) {
      dispatch(setClientId(result.clientId));
    }
    if (result.userCode) {
      dispatch(updateForm({ userCode: result.userCode }));
    }
    if (result.accessToken) {
      document.cookie = `publicToken=${result.accessToken}; Max-Age=36000`;
    }

    if (shouldSendBasicApplicationGtmEvent) {
      dispatch(setBasicApplicationSent(true));
      dispatch(
        pushToGtmOnboardingAction({ actionName: 'basic_application_sent' })
      );
    } else {
      dispatch(setBasicApplicationSent(false));
    }

    dispatch(setApiStatus({ guarantors: ApiStatus.Idle }));

    dispatch(setApiStatus({ post: ApiStatus.Completed }));
  } catch (err) {
    dispatch(
      pushToSentryAction(err, 'apiPostApplication', { product: 'onboarding' })
    );
    dispatch(setApiStatus({ post: ApiStatus.Failed }));
    dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    throw err;
  }
};

export const patchApplication = (options?: {
  finalized?: boolean;
  currentStepCompleted?: boolean;
}): AppThunk => async (dispatch, getState: GetStateFunc) => {
  const {
    onboardingApplication: {
      form,
      currentStep,
      basicApplicationSent,
      isCompanyTypeCategoryRequested,
      clientId,
      flowType,
    },
    intl,
  } = getState();
  const finalized = options?.finalized ?? false;
  const currentStepCompleted = options?.currentStepCompleted ?? true;

  const nextStepName = selectStepConfig(getState())[currentStep]?.name;
  const nextStepIsGuarantorsStep = nextStepName === 'guarantor_selector_step';
  const getCompanyTypeCategory =
    !isCompanyTypeCategoryRequested && nextStepIsGuarantorsStep;
  const shouldSendBasicApplicationGtmEvent =
    !basicApplicationSent &&
    hasBasicApplicationDataPerMarket(form, intl.market);

  const requestData = loanOnboardingApplicationDTOMappers.applicationToDTO({
    form,
    currentStep,
    market: intl.market,
    shouldPostApplication: false,
    finalized,
    currentStepCompleted,
    clientId,
    isCompanyTypeCategoryRequested:
      isCompanyTypeCategoryRequested || getCompanyTypeCategory,
    basicApplicationSent:
      shouldSendBasicApplicationGtmEvent || basicApplicationSent,
  });

  dispatch(setApiStatus({ patch: ApiStatus.Started }));
  if (finalized) {
    dispatch(updateApplicationStatus(ApplicationStatus.ApplicationFinished));
  }
  try {
    const sendPublicToken =
      flowType === FlowTypes.Manual && form.applicantAuthenticated;
    const patchApplicationData = await apiPatchApplication(
      requestData,
      sendPublicToken,
      getCompanyTypeCategory
    );

    if (getCompanyTypeCategory) {
      dispatch(setIsCompanyTypeCategoryRequested(true));
    }

    if (patchApplicationData.companyTypeCategory) {
      dispatch(
        updateForm({
          companyTypeCategory: patchApplicationData.companyTypeCategory as CompanyTypeCategory,
        })
      );
    }

    if (patchApplicationData.userCode) {
      dispatch(updateForm({ userCode: patchApplicationData.userCode }));
    }

    if (shouldSendBasicApplicationGtmEvent) {
      dispatch(setBasicApplicationSent(true));
      dispatch(
        pushToGtmOnboardingAction({ actionName: 'basic_application_sent' })
      );
    }
    if (finalized) {
      dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Submitted }));
    }
    dispatch(setApiStatus({ patch: ApiStatus.Completed }));
  } catch (err) {
    dispatch(
      pushToSentryAction(err, 'apiPatchApplication', { product: 'onboarding' })
    );
    if (
      finalized &&
      err?.response?.data?.message === 'Endpoint request timed out'
    ) {
      // If endpoint times out, we will assume this request updated the application in BO but we didn't get a response in time.
      dispatch(setApiStatus({ patch: ApiStatus.Completed }));
    } else {
      if (
        err.message === 'No ACCESSS_TOKEN found!' ||
        err?.response?.status === 401
      ) {
        dispatch(setApiStatus({ patch: ApiStatus.Failed }));
        dispatch(toggleGlobalErrorOn(GlobalErrorType.ONBOARDING_TOKEN_EXPIRED));
      } else if (
        err?.response?.data?.errorCode === 'DuplicateGuarantorsDetected'
      ) {
        dispatch(setApiStatus({ patch: ApiStatus.Failed }));
        dispatch(toggleGlobalErrorOn(GlobalErrorType.CUSTOM_ERROR));
        dispatch(
          setCustomGlobalError({
            title: 'Onboarding.DuplicateGuarantorsDetectedTitle',
            description: 'Onboarding.DuplicateGuarantorsDetectedDescription',
            buttonText: 'Onboarding.DuplicateGuarantorsDetectedButtonText',
          })
        );
      } else {
        dispatch(updateApplicationStatus(ApplicationStatus.None));
        dispatch(setApiStatus({ patch: ApiStatus.Failed }));
        dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
      }
      throw err;
    }
  }
};
