import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import rootReducer from '../reducers/rootReducer';
import { store } from '~/App';

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export type GetStateFunc = () => RootState;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export enum Language {
  English = 'EN',
  Swedish = 'SV',
  Danish = 'DA',
  Norwegian = 'NO',
  Finnish = 'FI',
  French = 'FR',
  DutchBE = 'NL_BE',
  Dutch = 'NL',
  German = 'DE',
}

export interface LanguageLabel {
  long: string;
  short: string;
}

export enum ApiStatus {
  Idle = 10,
  Started,
  Completed,
  Failed,
}

export type InvoiceState = 'overdue' | 'due-in' | 'new' | 'inProcess';
