import { createSafeContext } from '@qred/components-library';

interface AppShellContext {
  headerTitle: string;
  setHeaderTitle(title: string): void;
}

export const [
  AppShellContextProvider,
  useAppShellContext,
] = createSafeContext<AppShellContext>(
  'AppShell component was not found in the tree'
);
