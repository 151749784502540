import React, { useContext } from 'react';
import {
  Banner,
  Button,
  Group,
  Icon,
  Stack,
  Typography,
} from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import useModals from '~/hooks/useModals';
import { updateForm } from '~/store/slices/cardOnboardingOffer.slice';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';
import { useCardOnboardingOfferSelectors } from '~/store/selectors/cardOnboardingOffer.selector';
import OwnersList from './OwnersList';
import OwnerManagementModal from './OwnerManagementModal';
import { ValidationContext } from '~/components/hoc/withValidation';

const OwnershipAdditionalOwnersPanel = () => {
  const dispatch = useDispatch();
  const {
    cardOnboardingOffer: {
      form: { companyHasOtherBeneficialOwners, owners },
    },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);
  const validationContext = useContext(ValidationContext);

  const t = useTranslate();
  const modals = useModals();
  const {
    applicantHasAnsweredBeneficialOwnersQuestion,
    totalAmountOfShares,
    applicantIsABeneficialOwner,
    alternativeOwnerManuallyAdded,
  } = useCardOnboardingOfferSelectors();

  const { marketHasPersonalNumber } = useOnboardingMarketConfig();

  const onChange = (otherBeneficialOwnersExist: boolean) => {
    dispatch(
      updateForm({
        companyHasOtherBeneficialOwners: otherBeneficialOwnersExist,
      })
    );
  };

  const openOwnerManagementModal = () => {
    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: 'card_offer_add_owner',
      })
    );

    modals.openModal({
      header: <Typography>{t('OnboardingOffer.AddNewOwner')}</Typography>,
      children: (
        <OwnerManagementModal
          isAlternativeBeneficialOwner={!companyHasOtherBeneficialOwners}
          askForPersonalNumber={marketHasPersonalNumber}
          askForDateOfBirth={!marketHasPersonalNumber}
        />
      ),
      size: 'md',
      fullScreen: mobile,
      filledBackground: true,
      hasOwnFooter: true,
    });
  };

  const applicantCanAddAdditionalBeneficialOwners =
    companyHasOtherBeneficialOwners && totalAmountOfShares < 75;

  const applicantCanAddAlternativeOwners =
    !companyHasOtherBeneficialOwners && !applicantIsABeneficialOwner;

  return (
    <Stack mt={20}>
      <Typography>
        {t('OnboardingOffer.AdditionalOwnersPanelDescription')}
      </Typography>
      <Group direction="row">
        <Button
          onClick={() => onChange(true)}
          variant="secondary"
          data-active={companyHasOtherBeneficialOwners}
          style={{ flexGrow: 1 }}
        >
          {t('Yes')}
        </Button>
        <Button
          onClick={() => onChange(false)}
          variant="secondary"
          data-active={
            applicantHasAnsweredBeneficialOwnersQuestion &&
            !companyHasOtherBeneficialOwners
          }
          style={{ flexGrow: 1 }}
        >
          {t('No')}
        </Button>
      </Group>
      {applicantHasAnsweredBeneficialOwnersQuestion && (
        <>
          {applicantCanAddAdditionalBeneficialOwners && (
            <Typography>
              {t('OnboardingOffer.AdditionalOwnersPanelAddOwnerLabel')}
            </Typography>
          )}

          {applicantCanAddAlternativeOwners && (
            <Typography>
              {t(
                'OnboardingOffer.AdditionalOwnersPanelAddAlternativeBeneficialOwner'
              )}
            </Typography>
          )}

          {!!owners.length && <OwnersList />}

          <Banner
            state="critical"
            withCloseButton={false}
            message={t('OnboardingOffer.TotalOwnershipSharesMoreThan100')}
            opened={
              validationContext.validationErrors.totalOwnershipSharesMoreThan100
            }
          />

          {(applicantCanAddAdditionalBeneficialOwners ||
            applicantCanAddAlternativeOwners) && (
            <Stack align="center">
              <Button
                onClick={openOwnerManagementModal}
                variant="secondary"
                fullWidth={mobile}
                disabled={alternativeOwnerManuallyAdded}
              >
                <Icon src="Plus" mr="0.5em" />
                {t('OnboardingOffer.AdditionalOwnersPanelAddOwnerButtonLabel')}
              </Button>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export default OwnershipAdditionalOwnersPanel;
