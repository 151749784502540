import React from 'react';
import { useSelector } from 'react-redux';
import InputField from '~/components/shared/Input/InputField';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { PhoneNumberPlaceholders } from '~/constants/markets';
import NumberInputField from '~/components/shared/Input/NumberInputField';

interface ContactInfoProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContactInfo = ({ onChange, onBlur }: ContactInfoProps) => {
  const translate = useTranslate();

  const { form } = useSelector(
    (state: RootState) => state.onboardingApplication
  );
  const { market } = useSelector((state: RootState) => state.intl);

  return (
    <>
      <InputField
        name="email"
        dataCy="personal_information_step_email"
        type="email"
        label={translate('Email') as string}
        onChange={onChange}
        onBlur={onBlur}
        value={(form.applicant && form.applicant.email) || ''}
        validationType="Email"
        placeholder={translate('Onboarding.EmailPlaceholder') as string}
      />
      <NumberInputField
        tel
        name="phone"
        dataCy="personal_information_step_phone"
        label={translate('Phone') as string}
        onChange={onChange}
        onBlur={onBlur}
        value={(form.applicant && form.applicant.phone) || ''}
        validationType="Phone"
        placeholder={PhoneNumberPlaceholders[market]}
      />
    </>
  );
};

export default ContactInfo;
