import { AppliedLoanStatus } from '@qred/shared-enums';

export interface Application {
  status: AppliedLoanStatus;
  applicationId: number;
  contractId: number;
  signers: Signer[];
  amount: number;
  term: number;
  applicationDate: string;
  loanPurpose: string;
}

export interface Signer {
  signer_id: string | number;
  type: string;
  name: string;
  email: string | null;
  phone: string | null;
  signed_date?: Date;
}

export enum CustomerApplicationStep {
  Review,
  Offer,
  Confirmation,
  None,
}
