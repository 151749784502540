import React from 'react';
import { useSelector } from 'react-redux';
import Button, { ButtonType } from '~/components/shared/Button/button';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import { Typography } from '@qred/components-library';
import { hasManuallyAddedAGuarantor } from '~/helpers/onboarding.helper';
import useModals from '~/hooks/useModals';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import AddOrEditGuarantorModalBody from '../AddOrEditGuarantorModalBody';
import useGoToNextStep from '../../Steps/useGoToNextStep';

const SelectGuarantorNetherlandsFooter = () => {
  const t = useTranslate();
  const modals = useModals();
  const onNextStepClick = useGoToNextStep();

  const {
    matchedMedia,
    onboardingApplication: { form, apiStatus },
  } = useSelector((state: RootState) => state);

  const isManuallyAddingAGuarantor =
    hasManuallyAddedAGuarantor(form) || form.guarantors.length === 0;

  const handleOpenAddGuarantorClick = () => {
    modals.openModal({
      id: 'addOrEditGuarantorNetherlands-modal',
      header: (
        <Typography align="center" size="lg">
          {t('Onboarding.AddNewBoardMember')}
        </Typography>
      ),
      children: <AddOrEditGuarantorModalBody />,
      size: 'lg',
      fullScreen: matchedMedia.smallScreenPortrait,
      fixedHeaderAndFooter: true,
      hasOwnFooter: true,
      filledBackground: true,
    });
  };

  return (
    <>
      {apiStatus.guarantors !== ApiStatus.Started &&
        isManuallyAddingAGuarantor && (
          <Button
            type={ButtonType.NoBorder}
            onClick={handleOpenAddGuarantorClick}
          >
            {t('Onboarding.AddNewGuarantor')}
          </Button>
        )}

      <NextButton
        label="Onboarding.Next"
        showLoadingSpinner={apiStatus.patch === ApiStatus.Started}
        action={onNextStepClick}
      />
    </>
  );
};

export default SelectGuarantorNetherlandsFooter;
