import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import InputField from '~/components/shared/Input/InputField';
import useTranslate from '~/hooks/useTranslate';
import { ShippingDetails } from '~/Interfaces';
import { updateShippingDetailsUserData } from '~/store/actions/shippingDetailsActions';
import { RootState } from '~/store/types/sharedTypes';

interface Props {
  shippingDetails: ShippingDetails;
}

const ContactInformationCard: React.FC<Props> = (props) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { email, mobilePhone } = useSelector(
    (state: RootState) => state.shippingDetails.userData
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateShippingDetailsUserData({
        [e.target.name]: e.target.value,
      })
    );
  };

  return (
    <Card noPadding dataTestId="shipping-details-contact-information-card">
      <CardHeader value={translate('ContactInformationCard.Title') as string} />

      <CardSection
        title={translate('Email') as string}
        explanation={translate('ContactInformationCard.HelpText1') as string}
        borderTop
      >
        <InputField
          name="email"
          placeholder={
            translate('ContactInformationCard.AddEmailAddress') as string
          }
          value={email}
          onChange={onChange}
          validationType="Email"
          disabled={props.shippingDetails.cardAlreadyCreated}
        />
      </CardSection>

      <CardSection
        title={translate('MobilePhone') as string}
        explanation={translate('ContactInformationCard.HelpText2') as string}
        borderTop
      >
        <InputField
          name="mobilePhone"
          placeholder={
            translate('ContactInformationCard.AddPhoneNumber') as string
          }
          value={mobilePhone}
          onChange={onChange}
          validationType="Phone"
          disabled={props.shippingDetails.cardAlreadyCreated}
        />
      </CardSection>
    </Card>
  );
};

export default ContactInformationCard;
