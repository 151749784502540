import { CountryCode } from '~/enums';
import { Language, RootState } from '~/store/types/sharedTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLanguage as _setLanguage,
  setMarket as _setMarket,
} from '~/store/slices/intl.slice';
import { useUpdateCompanyLanguageMutation } from '~/store/apis/endpoints/myQredApi/companies/companies.api';
import { setSelectedCompany } from '~/store/slices/accounts.slice';
import { Company, User } from '~/models/accounts/accounts';
import { useLazyGetAccountQuery } from '~/store/apis/endpoints/myQredApi/accounts/accounts.api';
import useLogout from '~/hooks/useLogout';

interface UseAccountReturn {
  init(): void;
  isInitialized: boolean;
  updateSelectedCompany(company: Company): void;
  setMarket(market: CountryCode): void;
  setLanguage(language: Language): void;
  selectedCompany: Company;
  user: User;
  companies: Company[];
  market: CountryCode;
  language: Language;
}

export const useAccount = (): UseAccountReturn => {
  const dispatch = useDispatch();
  const logout = useLogout();

  const { market, language } = useSelector((state: RootState) => state.intl);
  const { user, companies, selectedCompany } = useSelector(
    (state: RootState) => state.account
  );

  const isSelectedCompanyInitialized = !!selectedCompany.id;

  const [
    getAccountTrigger,
    { isSuccess: getAccountIsSuccess },
  ] = useLazyGetAccountQuery();
  const [updateCompanyLanguage] = useUpdateCompanyLanguageMutation();

  const setMarket = (_market: CountryCode) => {
    dispatch(_setMarket(_market));
  };

  const setLanguage = (_language: Language) => {
    dispatch(_setLanguage(_language));
    updateCompanyLanguage({
      id: selectedCompany.id,
      languageCode: _language,
    });
  };

  const setCompany = (company: Company) => {
    localStorage.setItem('@qred/selectedCompanyId', company.id.toString());
    dispatch(setSelectedCompany(company));
  };

  const init = () => {
    getAccountTrigger()
      .unwrap()
      .catch((err) => {
        if (err.status === 401) {
          logout();
        }
      });
  };

  return {
    market,
    language,
    setMarket,
    setLanguage,
    init,
    updateSelectedCompany: setCompany,
    selectedCompany,
    user,
    companies,
    isInitialized: getAccountIsSuccess && isSelectedCompanyInitialized,
  };
};
