import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import useTranslate from '~/hooks/useTranslate';
import { useHistory } from 'react-router';
import { CountryCode, GlobalErrorType } from '~/enums';
import useLogout from '~/hooks/useLogout';
import { clearOnboardingState } from '~/store/slices/onboardingApplication.slice';
import { toggleGlobalErrorOff } from '~/store/slices/globalError.slice';
import Popup from '../Popup/Popup';
import ApiFailureError from './components/ApiFailureError';
import NoAccountError from './components/NoAccountError';
import SignedOfferNoAccountError from './components/SignedOfferNoAccountError';
import OnboardingTokenExpiredError from './components/OnboardingTokenExpiredError';
import CustomGlobalError from './components/CustomGlobalError';

const GlobalErrorPopup = () => {
  const {
    hasError,
    errorType,
    customGlobalError,
    globalErrorPersistent,
  } = useSelector((state: RootState) => state.globalError);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const logout = useLogout();
  const history = useHistory();
  const { market } = useSelector((state: RootState) => state.intl);

  const shouldShowOnboardingLink = market === CountryCode.SE;

  const onOkButtonClick = () => {
    if (errorType === GlobalErrorType.API_FAILURE) {
      dispatch(toggleGlobalErrorOff());
    } else if (errorType === GlobalErrorType.ONBOARDING_TOKEN_EXPIRED) {
      dispatch(clearOnboardingState());
      dispatch(toggleGlobalErrorOff());
    } else if (
      errorType === GlobalErrorType.USER_HAS_NO_ACCOUNT &&
      shouldShowOnboardingLink
    ) {
      dispatch(toggleGlobalErrorOff());
      history.push('/onboarding');
    } else if (errorType === GlobalErrorType.CUSTOM_ERROR) {
      dispatch(toggleGlobalErrorOff());
    } else {
      logout();
    }
  };

  const buttonTextHandler = () => {
    switch (errorType) {
      case GlobalErrorType.API_FAILURE:
        return translate('Ok');

      case GlobalErrorType.USER_HAS_NO_ACCOUNT:
        if (shouldShowOnboardingLink) {
          return translate('NoAccountError.ButtonTextOnboarding');
        }
        return translate('NoAccountError.ButtonText');

      case GlobalErrorType.USER_SIGNED_OFFER_AND_NO_ACCOUNT:
        if (shouldShowOnboardingLink) {
          return translate('SignedOfferNoAccountError.ButtonTextOnboarding');
        }
        return translate('SignedOfferNoAccountError.ButtonText');

      case GlobalErrorType.ONBOARDING_TOKEN_EXPIRED:
        return translate('Onboarding.SessionExpiredButtonText');

      case GlobalErrorType.CUSTOM_ERROR:
        return translate(customGlobalError?.buttonText || 'Ok');

      default:
        return '';
    }
  };

  return (
    <Popup
      buttonText={buttonTextHandler() as string}
      isOpen={hasError}
      onOkButtonClick={onOkButtonClick}
      closeOnClickOutside={!globalErrorPersistent}
      persistent={globalErrorPersistent}
    >
      {errorType === GlobalErrorType.API_FAILURE && <ApiFailureError />}
      {errorType === GlobalErrorType.ONBOARDING_TOKEN_EXPIRED && (
        <OnboardingTokenExpiredError />
      )}
      {errorType === GlobalErrorType.USER_HAS_NO_ACCOUNT && (
        <NoAccountError shouldShowOnboardingLink={shouldShowOnboardingLink} />
      )}
      {errorType === GlobalErrorType.USER_SIGNED_OFFER_AND_NO_ACCOUNT && (
        <SignedOfferNoAccountError />
      )}
      {errorType === GlobalErrorType.CUSTOM_ERROR && customGlobalError && (
        <CustomGlobalError
          title={customGlobalError.title}
          description={customGlobalError?.description}
        />
      )}
    </Popup>
  );
};

export default GlobalErrorPopup;
