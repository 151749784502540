import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import se_SV from '~/languages/se_SV.json';
import se_EN from '~/languages/se_EN.json';
import dk_DA from '~/languages/dk_DA.json';
import dk_EN from '~/languages/dk_EN.json';
import fi_FI from '~/languages/fi_FI.json';
import fi_EN from '~/languages/fi_EN.json';
import nl_NL from '~/languages/nl_NL.json';
import be_NL from '~/languages/be_NL.json';
import be_FR from '~/languages/be_FR.json';
import be_EN from '~/languages/be_EN.json';
import no_NO from '~/languages/no_NO.json';
import no_EN from '~/languages/no_EN.json';
import de_EN from '~/languages/de_EN.json';
import de_DE from '~/languages/de_DE.json';
import flatten from 'flat';
import { shouldPolyfill as shouldPolyfillGetCannonialLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { MarketLanguagePartial } from '~/constants/markets';
import { Language, RootState } from '~/store/types/sharedTypes';
import { generateLocale } from '~/helpers/generators.helper';
import { pushToSentryAction } from '~/store/actions/sentryActions';

const IntlProviderHOC = (IntlProvider: any) => {
  const polyfill = async () => {
    if (shouldPolyfillGetCannonialLocales()) {
      await import('@formatjs/intl-getcanonicallocales/polyfill');
    }

    if (shouldPolyfillLocale()) {
      await import('@formatjs/intl-locale/polyfill');
    }

    if (shouldPolyfillPluralRules()) {
      await import('@formatjs/intl-pluralrules/polyfill');
    }

    if ((Intl.PluralRules as any).polyfilled) {
      await import('@formatjs/intl-pluralrules/locale-data/sv' as any);
      await import('@formatjs/intl-pluralrules/locale-data/da' as any);
      await import('@formatjs/intl-pluralrules/locale-data/nb' as any);
      await import('@formatjs/intl-pluralrules/locale-data/no' as any);
      await import('@formatjs/intl-pluralrules/locale-data/nl' as any);
      await import('@formatjs/intl-pluralrules/locale-data/fr' as any);
      await import('@formatjs/intl-pluralrules/locale-data/fi' as any);
      await import('@formatjs/intl-pluralrules/locale-data/de' as any);
    }

    if (shouldPolyfillRelativeTimeFormat()) {
      await import('@formatjs/intl-relativetimeformat/polyfill');
    }

    if ((Intl as any).RelativeTimeFormat.polyfilled) {
      await import('@formatjs/intl-relativetimeformat/locale-data/sv' as any);
      await import('@formatjs/intl-relativetimeformat/locale-data/da' as any);
      await import('@formatjs/intl-relativetimeformat/locale-data/nb' as any);
      await import('@formatjs/intl-relativetimeformat/locale-data/no' as any);
      await import('@formatjs/intl-relativetimeformat/locale-data/nl' as any);
      await import('@formatjs/intl-relativetimeformat/locale-data/fr' as any);
      await import('@formatjs/intl-relativetimeformat/locale-data/fi' as any);
      await import('@formatjs/intl-relativetimeformat/locale-data/de' as any);
    }
  };

  polyfill();

  const i18nConfig1: MarketLanguagePartial = {
    SE: {
      SV: flatten(se_SV),
      EN: flatten(se_EN),
    },
    NO: {
      NO: flatten(no_NO),
      EN: flatten(no_EN),
    },
    DK: {
      DA: flatten(dk_DA),
      EN: flatten(dk_EN),
    },
    FI: {
      FI: flatten(fi_FI),
      EN: flatten(fi_EN),
    },
    NL: {
      NL: flatten(nl_NL),
    },
    BE: {
      NL_BE: flatten(be_NL),
      FR: flatten(be_FR),
      EN: flatten(be_EN),
    },
    DE: {
      DE: flatten(de_DE),
      EN: flatten(de_EN),
    },
  };

  const Component: React.FC = (props) => {
    const { market, language } = useSelector((state: RootState) => state.intl);
    const dispatch = useDispatch();
    const locale = useMemo(() => generateLocale(language, market), [
      language,
      market,
    ]);

    const onTranslationError = (err: any) => {
      if (
        language !== Language.English ||
        err.message.includes('using id as fallback')
      ) {
        dispatch(pushToSentryAction(err, 'onTranslationError'));
      }
    };

    return (
      <IntlProvider
        locale={locale}
        messages={i18nConfig1[market][language]}
        textComponent={React.Fragment}
        onError={onTranslationError}
      >
        {props.children}
      </IntlProvider>
    );
  };

  return Component;
};

export default IntlProviderHOC;
