import React from 'react';
import Card from '~/components/shared/Card/Card';
import CardSection from '~/components/shared/CardSection/CardSection';
import { SvgSrc } from '~/components/shared/Icon/Icon';
import { formatAmount } from '~/helpers/formatters.helper';
import useTranslate from '~/hooks/useTranslate';

interface Props {
  offeredCreditLimit: number;
  interest: number;
}

const CreditCardDetailsSimpleCard: React.FC<Props> = (props) => {
  const translate = useTranslate();
  return (
    <Card noPadding dataTestId="credit-card-details-simple-card">
      <CardSection
        title={translate('CreditCardDetailsCard.ApprovedCredit') as string}
        value={formatAmount(props.offeredCreditLimit)}
        iconSrc={SvgSrc.HandHoldingUsd}
      />
      <CardSection
        title={translate('CreditCardUsageCard.InterestAfter45') as string}
        explanation={
          translate('CreditCardUsageCard.InterestAfter45Explanation') as string
        }
        value={`${props.interest}%`}
        iconSrc={SvgSrc.ChartArea}
        borderTop
      />
    </Card>
  );
};

export default CreditCardDetailsSimpleCard;
