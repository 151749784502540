import {
  ActionIcon,
  Box,
  Group,
  Tooltip,
  Typography,
} from '@qred/components-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import { ClickableText } from '~/styles/BaseStyledComponents';
import { openQredCardPricesPage } from '~/services/links';
import pushToGtmHelper from '~/helpers/pushToGtm.helper';
import { formatAmount } from '~/helpers/formatters.helper';
import { fetchAdditionalSignerCardApplication } from '~/store/actions/additionalSignerActions';
import CardAdditionalSignerFooter from './CardAdditionalSignerFooter';
import useQueryParams from '~/hooks/useQueryParams';
import { TOnboardingDTO } from '~/types/DTO/onboarding';
import { setAdditionalSignerCardApplicationData } from '~/store/slices/additionalSigner.slice';
import { defaultLanguageToMarket, setMarket } from '~/store/slices/intl.slice';
import { useHistory } from 'react-router';
import constVars from '~/constants/constVars';

const CardAdditionalSigner = () => {
  const dispatch = useDispatch();
  const {
    intl: { market, language },
    matchedMedia: { mobile },
    additionalSigner: { additionalSignerCardApplicationOffer, apiStatus },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();
  const queryParams = useQueryParams();
  const history = useHistory();

  useEffect(() => {
    // ============= REMOVE ONCE WE STOP SUPPORTING OLD SIGNER SOLUTION =============
    const base64ApplicationData = queryParams.get('data');
    if (base64ApplicationData) {
      if (market) {
        dispatch(setMarket(market));
        dispatch(defaultLanguageToMarket(market));
        pushToGtmHelper(`q_user_v_${market.toLowerCase()}`);
      }
      const cardOfferResponseData = JSON.parse(
        window.atob(base64ApplicationData)
      ) as TOnboardingDTO['getAdditionalSignerCardApplicationDataRes'];
      dispatch(setAdditionalSignerCardApplicationData(cardOfferResponseData));
      // ==============================================================================
    } else {
      dispatch(fetchAdditionalSignerCardApplication(market));
    }
  }, []);

  useEffect(() => {
    if (apiStatus.fetchAdditionalSignerCardApplication === ApiStatus.Failed) {
      history.push(constVars.ROUTE_SIGNER_ERROR);
    }
  }, [apiStatus.fetchAdditionalSignerCardApplication]);

  const cardDetailsTypographyAndValue = [
    {
      typography: translate('CreditCardDetailsCard.CompanyName') as string,
      value: additionalSignerCardApplicationOffer.companyName,
    },
    {
      typography: translate('CreditCardDetailsCard.ApprovedCredit') as string,
      value: formatAmount(
        additionalSignerCardApplicationOffer.offeredCreditLimit
      ),
    },
    {
      typography: translate('CreditCardDetailsCard.YearlyMonthlyFee') as string,
      value: formatAmount(0),
    },
    {
      typography: translate('CreditCardDetailsCard.PlasticCardFee') as string,
      value: formatAmount(0),
    },
  ];

  const cardUsageDetailsTypographyAndValue = [
    {
      typography: translate('CreditCardUsageCard.InterestFirst45') as string,
      value: `0% (${translate('CreditCardUsageCard.InterestFreePurchase')})`,
      tooltipTypography: translate(
        'CreditCardUsageCard.InterestFirst45Explanation'
      ),
    },
    {
      typography: translate('CreditCardUsageCard.InterestAfter45') as string,
      value: `${additionalSignerCardApplicationOffer.interest}%`,
      tooltipTypography: translate(
        'CreditCardUsageCard.InterestAfter45Explanation'
      ),
    },
    {
      typography: translate('CreditCardUsageCard.MinimumToPay') as string,
      value: translate('CreditCardUsageCard.NoLessThan500'),
      tooltipTypography: translate(
        'CreditCardUsageCard.MinimumToPayExplanation'
      ),
    },
    {
      typography: translate(
        'CreditCardUsageCard.ForeignTransactionFee'
      ) as string,
      value: translate('CreditCardUsageCard.AddedFeeByQred'),
      tooltipTypography: translate(
        'CreditCardUsageCard.PurchaseInForeignCurrencyExplanation',
        {
          link: (
            <ClickableText
              typographyColor={theme.colors.midwayLinkDark}
              noUnderline
              onClick={() => openQredCardPricesPage(market, language)}
            >
              {translate('ClickHere')}
            </ClickableText>
          ),
        }
      ),
    },
  ];

  return additionalSignerCardApplicationOffer.cardApplicationUuid ? (
    <Box>
      <Group
        spacing={0}
        w={mobile ? 396 : 580}
        direction="column"
        p={mobile ? 'md' : 'xxl'}
        style={{
          borderRadius: '4px 4px 0px 0px',
          backgroundColor: `${theme.colors.neutral2}`,
        }}
      >
        <Typography weight={600} size="xl" lineHeight={32} mb={8}>
          {translate('ConfirmOffer')}
        </Typography>
        <Typography lineHeight={24}>
          {translate('CreditCardIntroductionCard.Title', {
            companyName: additionalSignerCardApplicationOffer.companyName,
          })}
        </Typography>
        <Typography weight={600} size="xl" lineHeight={32} mt={38} mb={8}>
          {translate('CreditCardDetailsCard.Title')}
        </Typography>
        {cardDetailsTypographyAndValue.map((cardDetail) => (
          <Group
            key={cardDetail.typography}
            spacing={2}
            justify="space-between"
            mb={2}
            direction={mobile ? 'column' : 'row'}
          >
            <Typography lineHeight={24}>{cardDetail.typography}</Typography>
            <Typography weight={500} lineHeight={24}>
              {cardDetail.value}
            </Typography>
          </Group>
        ))}
        <Typography weight={600} size="xl" lineHeight={32} mt={38} mb={8}>
          {translate('CreditCardUsageCard.Title')}
        </Typography>

        {cardUsageDetailsTypographyAndValue.map((cardUsageDetail) => (
          <Group
            key={cardUsageDetail.typography}
            justify="space-between"
            direction="column"
            spacing={0}
            mb={12}
          >
            <Group justify="space-between">
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{cardUsageDetail.typography}</Typography>
              </Box>
              <Tooltip
                label={cardUsageDetail.tooltipTypography}
                width={mobile ? '311px' : '422px'}
                events={{
                  hover: true,
                  focus: true,
                  touch: true,
                }}
              >
                <ActionIcon icon="help" />
              </Tooltip>
            </Group>
            <Typography weight={500} lineHeight={24}>
              {cardUsageDetail.value}
            </Typography>
          </Group>
        ))}
      </Group>
      <CardAdditionalSignerFooter />
    </Box>
  ) : (
    <SpinnerCircular />
  );
};

export default CardAdditionalSigner;
