import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { useKlarnaFlow } from '~/hooks/useKlarnaFlow/useKlarnaFlow';
import useTranslate from '~/hooks/useTranslate';
import { Typography } from '@qred/components-library';
import { KlarnaStatus } from '~/enums';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { ValidationContext } from '~/components/hoc/withValidation';
import BankAccountList from './BankAccountList';
import KlarnaKosmaButton from './KlarnaKosmaButton';
import AccountNumberHeader from './AccountNumberHeader';
import AccountNumberInput from './AccountNumberInput';

const AccountNumber = () => {
  const [klarnaStatus, setKlarnaStatus] = useState(KlarnaStatus.NOT_STARTED);
  const [showKlarna, setShowKlarna] = useState(false);
  const validationContext = useContext(ValidationContext);

  const {
    onboardingOffer: { overview, form },
  } = useSelector((state: RootState) => state);

  useKlarnaFlow();
  const translate = useTranslate();
  const {
    marketShouldShowAccountNumberInputFieldByDefault,
  } = useOnboardingMarketConfig();

  const hasBankAccounts =
    overview.bankAccounts && overview.bankAccounts.length > 0;

  useEffect(() => {
    if (Object.keys(form.account).length === 0 && !form.accountNumber) {
      validationContext.addPropertyToValidationErrors(
        'MissingAccountAndAccountNumber'
      );
    }

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'MissingAccountAndAccountNumber'
      );
    };
  }, [form.account, form.accountNumber]);

  return (
    <>
      <AccountNumberHeader
        klarnaStatus={klarnaStatus}
        hasBankAccounts={hasBankAccounts || false}
      />

      {marketShouldShowAccountNumberInputFieldByDefault && !hasBankAccounts ? (
        <>
          <AccountNumberInput />
          <Typography mt="4em" weight={400}>
            {translate('OnboardingOffer.TextAboveKlarnaKosmaButton')}
          </Typography>
          <KlarnaKosmaButton setKlarnaStatus={setKlarnaStatus} />
        </>
      ) : (
        <>
          {!form.accountNumber &&
          hasBankAccounts &&
          klarnaStatus !== KlarnaStatus.ERROR ? (
            <>
              <BankAccountList setShowKlarna={setShowKlarna} />
              {showKlarna && (
                <KlarnaKosmaButton setKlarnaStatus={setKlarnaStatus} />
              )}
            </>
          ) : (
            <>
              {(klarnaStatus === KlarnaStatus.ERROR || form.accountNumber) && (
                <>
                  <AccountNumberInput />
                  <Typography mt="4em" weight={400}>
                    {translate('OnboardingOffer.TextAboveKlarnaKosmaButton')}
                  </Typography>
                </>
              )}
              <KlarnaKosmaButton setKlarnaStatus={setKlarnaStatus} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AccountNumber;
