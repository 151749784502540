import React from 'react';
import {
  Typography,
  Group,
  Button,
  notifications,
} from '@qred/components-library';
import { ClickableText } from '~/styles/BaseStyledComponents';
import { confirmContractAction } from '~/store/actions/additionalSignerActions';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '~/styles/themes';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { logToSentry } from '~/helpers/loggers.helper';
import useTermsAndConditionsModal from '~/hooks/useTermsAndConditionsModal';

const LoanAdditionalSignerFooter = () => {
  const {
    additionalSigner: {
      additionalSignerLoanApplicationOffer: { granter },
      apiStatus: { postAdditionalSignerLoanApplication },
    },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const translate = useTranslate();
  const { openTermsAndConditionsModal } = useTermsAndConditionsModal();

  const onConfirmLoanClick = () => {
    dispatch(confirmContractAction());
  };

  const openModal = () => {
    if (granter) {
      openTermsAndConditionsModal(granter);
    } else {
      notifications.show({
        state: 'critical',
        message: translate('SomethingWentWrong'),
        opened: true,
      });
      logToSentry(
        new Error('Granter is missing for application'),
        'GranterIsMissingForApplication'
      );
    }
  };

  return (
    <Group
      spacing={0}
      w={mobile ? 396 : 580}
      direction="column"
      p={mobile ? 'md' : 'xxl'}
      align="center"
      mb={mobile ? 36 : 62}
      style={{
        backgroundColor: `${theme.colors.neutral2}`,
        borderTop: mobile ? '' : `1px solid ${theme.colors.neutral16}`,
      }}
    >
      <Typography size="sm" lineHeight={20}>
        {translate('ConfirmTermsCard.UserAgreementText')}{' '}
        <ClickableText
          noUnderline
          typographyColor={theme.colors.midwayLinkDark}
          onClick={openModal}
        >
          {translate('ConfirmTermsCard.UserAgreementLink')}
        </ClickableText>
      </Typography>
      <Button
        fullWidth={mobile}
        mt={mobile ? 12 : 18}
        mb={mobile ? 24 : 0}
        onClick={onConfirmLoanClick}
        variant="primary"
        size="md"
        isLoading={postAdditionalSignerLoanApplication === ApiStatus.Started}
      >
        <Typography color="textLight.1" weight={500}>
          {translate('ConfirmTermsCard.ConfirmAgreement')}
        </Typography>
      </Button>
    </Group>
  );
};

export default LoanAdditionalSignerFooter;
