import React from 'react';
import Button, { ButtonType } from '~/components/shared/Button/button';
import Card from '~/components/shared/Card/Card';
import useTranslate from '~/hooks/useTranslate';
import { useHistory } from 'react-router-dom';
import CardSection from '~/components/shared/CardSection/CardSection';
import CardHeader from '~/components/shared/CardHeader/CardHeader';

const SetCreditCardPinCard = () => {
  const translate = useTranslate();
  const history = useHistory();

  const handleSubmit = () => {};

  return (
    <Card noPadding dataTestId="set-pincode-card">
      <CardHeader value={translate('PinCodeCard.Title') as string} />
      <CardSection borderTop>
        <Button type={ButtonType.Filled} onClick={handleSubmit}>
          {translate('PinCodeCard.SetPinCode')}
        </Button>
      </CardSection>
    </Card>
  );
};

export default SetCreditCardPinCard;
