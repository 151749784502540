import {
  getDatePlaceholder,
  getPersonalNumberPlaceholder,
  personalNumberIsOnlyNumbers,
} from '~/constants/markets';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import useTranslate from '~/hooks/useTranslate';
import { onChangeInputEvent, onFocusSelectEvent } from '~/types/types';
import useModals from '~/hooks/useModals';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { personAlreadyExistsInList } from '~/helpers/onboarding.helper';
import {
  Button,
  DatePicker,
  Stack,
  TextInput,
  Typography,
} from '@qred/components-library';
import { v4 as uuidv4 } from 'uuid';
import {
  beneficialOwnerPercentageValidator,
  fullNameValidator,
  personalNumberValidator,
  validateOwnershipPercentage,
  validateYearOfBirth,
} from '~/helpers/validators.helper';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';
import OwnershipPercentageSelect from '../OwnershipPercentageSelect';
import AMLFields from '../../../AMLFields/AMLFields';
import { IOnboardingBeneficialOwner } from '~/interfaces/Onboarding';
import {
  removeAdditionalOwnerById,
  updateAdditionalOwner,
} from '~/store/slices/cardOnboardingOffer.slice';

interface OwnerManagementModalProps {
  beneficialOwner: IOnboardingBeneficialOwner;
  isAlternativeBeneficialOwner?: boolean;
  onBlur: (name: onChangeInputEvent | onFocusSelectEvent | string) => void;
}

const BeneficialOwner = ({
  beneficialOwner,
  isAlternativeBeneficialOwner,
  onBlur,
}: OwnerManagementModalProps) => {
  const dispatch = useDispatch();
  const {
    cardOnboardingOffer: { form },
    matchedMedia: { mobile },
    intl: { market, language },
  } = useSelector((state: RootState) => state);
  const translate = useTranslate();

  const formMethods = useForm<IOnboardingBeneficialOwner>({
    defaultValues: {
      ...(beneficialOwner || {
        id: uuidv4(),
        isAlternativeBeneficialOwner,
      }),
    },
    mode: 'onTouched',
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = formMethods;
  const modals = useModals();
  const {
    marketHasPersonalNumberFromRoaring,
    marketHasDateOfBirthFromRoaring,
    marketHasYearOfBirthFromRoaring,
    marketHasCountryOfResidenceFromRoaring,
    marketHasFetchedOwnershipSharesAsInteger,
    marketHasFetchedOwnershipSharesAsRanges,
  } = useOnboardingMarketConfig();

  useEffect(() => {
    trigger('percentage');
  }, []);

  const checkForDuplicates = (data: IOnboardingBeneficialOwner) => {
    let error = false;

    const existingOwners = form.owners.filter(
      (o) => o.id !== beneficialOwner.id
    );

    if (personAlreadyExistsInList(existingOwners, data)) {
      setError('root.SubmitError', {
        type: 'manual',
        message: translate('ValidationErrors.OwnerAlreadyExists') as string,
      });
      error = true;
    }
    return error;
  };

  const saveOwner: SubmitHandler<IOnboardingBeneficialOwner> = (formData) => {
    const hasSubmissionError = checkForDuplicates(formData);

    if (!hasSubmissionError) {
      dispatch(updateAdditionalOwner(formData));

      dispatch(
        pushToGtmCardOnboardingAction({
          actionName: 'edit_owner_save_button_pressed',
        })
      );
    }
  };

  const removeOwner = () => {
    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: 'edit_owner_delete_button_pressed',
      })
    );
    const id = getValues('id');
    dispatch(removeAdditionalOwnerById(id));
    modals.closeAll();
  };

  const onRemoveOwner = () => {
    modals.openConfirmModal({
      title: translate(
        'OnboardingOffer.RemoveOwnerConfirmationPopupTitle'
      ) as string,
      children: (
        <Typography weight={600} my="1em">
          {translate('OnboardingOffer.RemoveOwnerConfirmationPopupDescription')}
        </Typography>
      ),
      labels: { confirm: translate('Confirm'), cancel: translate('Cancel') },
      onConfirm: () => removeOwner(),
      heightAuto: true,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(saveOwner)}>
        <Stack ml="2em" mr="2em" mt="0.5em">
          <TextInput
            label={translate('OnboardingOffer.AdditionalOwnerFullName')}
            dataCy="onboarding_offer_add_new_owner_name"
            placeholder={translate('FullName') as string}
            {...register('fullName', {
              required: translate('ValidationErrors.Required') as string,
              validate: (value) => {
                if (fullNameValidator(value)) {
                  return translate('ValidationErrors.FullName') as string;
                }

                return true;
              },
              onBlur: (e: onChangeInputEvent) => {
                clearErrors('root.SubmitError');
                onBlur(e);
              },
            })}
            error={errors.fullName?.message}
          />

          {marketHasPersonalNumberFromRoaring && (
            <TextInput
              label={translate('PersonalNumber')}
              dataCy="onboarding_offer_add_new_owner_personal_number"
              placeholder={getPersonalNumberPlaceholder(market, language)}
              inputMode={
                personalNumberIsOnlyNumbers(market) ? 'numeric' : 'text'
              }
              {...register('personalNumber', {
                required: translate('ValidationErrors.Required') as string,
                validate: (value) => {
                  if (personalNumberValidator(value)) {
                    return translate(
                      'ValidationErrors.PersonalNumber'
                    ) as string;
                  }
                  return true;
                },
                onBlur: (e: onChangeInputEvent) => {
                  clearErrors('root.SubmitError');
                  onBlur(e);
                },
              })}
              error={errors.personalNumber?.message}
            />
          )}

          {marketHasDateOfBirthFromRoaring && (
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: translate('ValidationErrors.Required') as string,
              }}
              render={({ field, fieldState }) => (
                <DatePicker
                  label={translate('DateOfBirth')}
                  maxDate={new Date()}
                  placeholder={getDatePlaceholder(market, language)}
                  onChange={(value) => {
                    onBlur(field.name);
                    field.onChange(value);
                    clearErrors('root.SubmitError');
                  }}
                  valueFormat="DD-MM-YYYY"
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}

          {marketHasYearOfBirthFromRoaring && (
            <TextInput
              label={translate('YearOfBirth')}
              inputMode="numeric"
              {...register('yearOfBirth', {
                required: translate('ValidationErrors.Required') as string,
                validate: (value) => {
                  if (value && validateYearOfBirth(value)) {
                    return translate('ValidationErrors.YearOfBirth') as string;
                  }
                  return true;
                },
                onBlur: (e: onChangeInputEvent) => {
                  clearErrors('root.SubmitError');
                  onBlur(e);
                },
              })}
              error={errors.yearOfBirth?.message}
            />
          )}

          {!isAlternativeBeneficialOwner && (
            <>
              {marketHasFetchedOwnershipSharesAsInteger && (
                <TextInput
                  label={translate('OnboardingOffer.SharesPercentages')}
                  dataCy="onboarding_offer_add_new_owner_name"
                  rightSection="%"
                  placeholder={
                    translate('OnboardingOffer.moreThan25%') as string
                  }
                  {...register('percentage', {
                    required: translate('ValidationErrors.Required') as string,
                    validate: (value) =>
                      validateOwnershipPercentage(value, translate),
                    onBlur,
                  })}
                  error={errors.percentage?.message}
                />
              )}

              {marketHasFetchedOwnershipSharesAsRanges && (
                <Controller
                  name="percentage"
                  control={control}
                  rules={{
                    required: translate('ValidationErrors.Required') as string,
                    validate: (value) =>
                      validateOwnershipPercentage(value, translate),
                  }}
                  render={({ field, fieldState }) => (
                    <OwnershipPercentageSelect
                      onChange={(value) => {
                        onBlur(field.name);
                        field.onChange(value?.value || '');
                        trigger('percentage');
                      }}
                      error={fieldState.error?.message}
                      value={field.value}
                    />
                  )}
                />
              )}
            </>
          )}

          <AMLFields
            trackFieldChange={onBlur}
            hideFields={
              marketHasCountryOfResidenceFromRoaring
                ? []
                : ['countryOfResidence']
            }
          />
          <Stack spacing="sm" mt="2em">
            <Button
              variant="secondary"
              onClick={onRemoveOwner}
              fullWidth={mobile}
              size="md"
            >
              {translate('OnboardingOffer.DeleteAdditionalOwner')}
            </Button>

            <Stack>
              {errors.root?.SubmitError && (
                <Typography color="error.700" size="sm">
                  {errors.root?.SubmitError?.message}
                </Typography>
              )}

              <Button
                variant="primary"
                dataCy="onboarding_offer_add_new_owner_modal_button"
                fullWidth={mobile}
                size="md"
                type="submit"
                disabled={isSubmitting || isSubmitSuccessful}
              >
                {translate('Save')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default BeneficialOwner;
