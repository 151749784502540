import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '~/store/apis/baseQuery/baseQuery';

export const tagTypes = [
  'Client',
  'Loan',
  'SingleInvoice',
  'LoanOffer',
  'Applications',
  'Contracts',
  'DownloadFinalInvoice',
  'Cards',
];
export const myPagesBaseApi = createApi({
  reducerPath: 'myPagesBaseApi',
  tagTypes: tagTypes,
  endpoints: () => ({}),
  baseQuery: baseQuery(import.meta.env.VITE_API_MYPAGES_URL),
});
