import React, { useEffect, useState } from 'react';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { Typography } from '@qred/components-library';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import useTranslate from '~/hooks/useTranslate';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem } from '~/Interfaces';
import { Language, RootState } from '~/store/types/sharedTypes';
import { setLanguage } from '~/store/slices/intl.slice';
import { logToSentry } from '~/helpers/loggers.helper';
import { getMarketLanguageLabels } from '~/constants/markets';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import useLogout from '~/hooks/useLogout';
import { useLocation } from 'react-router-dom';
import constVars from '~/constants/constVars';
import { openHelpPopup } from '~/store/slices/helpPopup.slice';
import {
  LanguageContainer,
  LanguageOption,
  MenuItemContainer,
  OnboardingMenuWrapper,
  TransitionedUnderline,
} from './StyledComponentsMenu';

interface Props {
  onMenuItemClick: () => void;
}

const OnboardingMenuItems: React.FC<Props> = ({ onMenuItemClick }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { intl } = useSelector((state: RootState) => state);
  const { language, market } = intl;
  const { pathname } = useLocation();
  const logout = useLogout();
  const { isAuthenticated } = useAuth0();
  const [logsHaveBeenSent, setLogsHaveBeenSent] = useState(false);
  const isOnboardingOffer = pathname.includes(
    `/${constVars.ROUTE_ONBOARDING_OFFER}`
  );

  useEffect(() => {
    dispatch(pushToGtmOnboardingAction({ actionName: 'onboarding_menu_open' }));
  }, []);

  const onHelpClicked = () => {
    dispatch(openHelpPopup());
    dispatch(pushToGtmOnboardingAction({ actionName: 'help_button_pressed' }));
  };

  const handleLanguageChanged = (newLanguage: Language) => {
    dispatch(setLanguage(newLanguage));
    localStorage.setItem('onboardingLanguage', newLanguage);
  };

  const onLogoutClicked = () => {
    dispatch(
      pushToGtmOnboardingAction({ actionName: 'logout_button_pressed' })
    );

    logout(
      !isOnboardingOffer
        ? `${constVars.ROUTE_ONBOARDING}?authType=logout`
        : undefined
    );
  };

  const sendLogs = () => {
    if (!logsHaveBeenSent) {
      logToSentry(
        Error('Error manually sent from onboarding menu'),
        'Manually triggered error',
        {
          product: 'onboarding',
        }
      );
      setLogsHaveBeenSent(true);
      setTimeout(() => {
        setLogsHaveBeenSent(false);
      }, 5000);
    }
  };

  const MenuItemOptions: Array<MenuItem> = [
    {
      label: translate('Help') as string,
      icon: SvgSrc.Comments,
      onClick: onHelpClicked,
    },
    {
      label: translate('Onboarding.Exit') as string,
      icon: SvgSrc.SignOut,
      onClick: onLogoutClicked,
      hide: !isAuthenticated,
    },
    {
      label: logsHaveBeenSent ? 'Thank you!' : 'Send logs',
      icon: logsHaveBeenSent ? SvgSrc.CheckCircle : SvgSrc.Rocket,
      onClick: sendLogs,
      hide: import.meta.env.MODE !== 'internal-prod',
    },
  ];

  const marketLanguages = getMarketLanguageLabels(market);

  return (
    <>
      <OnboardingMenuWrapper onClick={onMenuItemClick}>
        {MenuItemOptions.filter((item) => !item.hide).map((item: MenuItem) => (
          <MenuItemContainer key={item.label} onClick={() => item.onClick()}>
            <TransitionedUnderline>
              <Icon
                mr={0.5}
                src={item.icon}
                color={
                  item.active ? theme.colors.dark : theme.colors.secondaryGray
                }
                size={ComponentSize.MenuIcon}
              />
              <Typography weight={item.active ? 600 : undefined}>
                {item.label}
              </Typography>
            </TransitionedUnderline>
          </MenuItemContainer>
        ))}
        {marketLanguages?.length > 1 && (
          <LanguageContainer>
            {marketLanguages.map((languageOption) => (
              <LanguageOption
                key={languageOption.value}
                active={languageOption.value === language}
                onClick={() => handleLanguageChanged(languageOption.value)}
              >
                {languageOption.label.short}
              </LanguageOption>
            ))}
          </LanguageContainer>
        )}
      </OnboardingMenuWrapper>
    </>
  );
};

export default OnboardingMenuItems;
