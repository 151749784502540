import { IOnboardingFile } from '~/interfaces/Onboarding';
import { validateFile } from './validators.helper';

export const filterOutIncorrectFiles = (
  files: IOnboardingFile[],
  allowedFileFormats: string[],
  maxSize: number,
  maxFiles: number
) => {
  const validFiles = files
    .filter((file) => validateFile(file, allowedFileFormats, maxSize).isValid)
    .filter(removeDuplicates);

  return validFiles.slice(0, maxFiles);
};

const removeDuplicates = (
  item: IOnboardingFile,
  i: number,
  arr: Array<IOnboardingFile>
) => {
  return i === arr.findIndex((t) => t.name === item.name);
};

export const getFileExtension = (filename: string) => filename.split('.').pop();
