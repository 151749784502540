import React from 'react';
import { useSelector } from 'react-redux';
import { DataView, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { useOnboardingOfferSelectors } from '~/store/selectors/onboardingOffer.selector';
import { useCurrencyFormatter } from '~/hooks/formatter/useCurrencyFormatter';

const PaymentDetails = () => {
  const translate = useTranslate();
  const formatCurrency = useCurrencyFormatter();

  const {
    onboardingOffer: { form, overview },
  } = useSelector((state: RootState) => state);

  const {
    monthlyAmortization,
    monthlyFee,
    monthlyPayment,
    loanAmount,
  } = useOnboardingOfferSelectors();

  return (
    <Stack spacing={'xxl'}>
      {overview.type === 'CREDIT_LIMIT_CHANGE' && (
        <DataView spacing={0} data-testid="existing-loan-info-section">
          <DataView.Section mih={0} mb={'sm'}>
            <DataView.Label
              weight={600}
              size={'lg'}
              data-testid="total-loan-label"
            >
              {translate('TotalLoan')}
            </DataView.Label>
            <DataView.Value
              align="end"
              weight={600}
              size={'lg'}
              data-testid="total-loan-value"
            >
              {formatCurrency.format(loanAmount)}
            </DataView.Value>
          </DataView.Section>
          <DataView.Section mih={0}>
            <DataView.Label data-testid="payout-amount-label">
              {translate('OnboardingOffer.PayoutAmount')}
            </DataView.Label>
            <DataView.Value align="end" data-testid="payout-amount-value">
              {formatCurrency.format(form.payoutAmount)}
            </DataView.Value>
          </DataView.Section>
          <DataView.Section mih={0}>
            <DataView.Label data-testid="existing-loan-label">
              {translate('ExistingLoan')}
            </DataView.Label>
            <DataView.Value align="end" data-testid="existing-loan-value">
              {formatCurrency.format(overview.currentLoanAmountLeft ?? 0)}
            </DataView.Value>
          </DataView.Section>
        </DataView>
      )}
      <DataView spacing={0}>
        <DataView.Section mih={0} mb={'sm'}>
          <DataView.Label
            weight={600}
            size={'lg'}
            data-testid="total-per-month-label"
          >
            {overview.type === 'CREDIT_LIMIT_CHANGE'
              ? translate('OnboardingOffer.NewTotalPerMonth')
              : translate('OnboardingOffer.TotalPerMonth')}
          </DataView.Label>
          <DataView.Value
            align="end"
            weight={600}
            size={'lg'}
            data-testid="total-per-month-value"
          >
            {formatCurrency.format(monthlyPayment)}
          </DataView.Value>
        </DataView.Section>
        <DataView.Section mih={0}>
          <DataView.Label data-testid="monthly-fee-label">
            {translate('MonthlyFee')}
          </DataView.Label>
          <DataView.Value align="end" data-testid="monthly-fee-value">
            {formatCurrency.format(monthlyFee)}
          </DataView.Value>
        </DataView.Section>
        <DataView.Section mih={0}>
          <DataView.Label data-testid="monthly-amortization-label">
            {translate('MonthlyAmortization')}
          </DataView.Label>
          <DataView.Value align="end" data-testid="monthly-amortization-value">
            {formatCurrency.format(monthlyAmortization)}
          </DataView.Value>
        </DataView.Section>
      </DataView>
    </Stack>
  );
};

export default PaymentDetails;
