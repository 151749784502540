import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';

export const LaunchViewTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: var(--client-height);
  @media ${MediaQueries.desktop} {
    flex-direction: row;
    height: calc(var(--client-height) - 60px);
  }
`;
