import React, { useContext } from 'react';
import { ValidationContext } from '~/components/hoc/withValidation';
import { Button, useWindowProperties } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';

interface SkipButtonProps {
  label: string;
  action: () => void;
  disabled?: boolean;
}

const SkipButton = ({ label, action, disabled = false }: SkipButtonProps) => {
  const translate = useTranslate();
  const validationContext = useContext(ValidationContext);
  const { isMobile } = useWindowProperties();

  return (
    <Button
      dataCy="step_footer_skip_button"
      variant="tertiary"
      onClick={action}
      disabled={disabled || !validationContext.isFormValid}
      size="md"
      fullWidth={isMobile}
    >
      {translate(label)}
    </Button>
  );
};

export default SkipButton;
