import { Invoices } from '~/Interfaces';
import { ApiStatus } from '~/store/types/sharedTypes';

export const FETCH_INVOICES = 'FETCH_INVOICES';
export const DOWNLOAD_FINAL_INVOICE_STATUS = 'DOWNLOAD_FINAL_INVOICE_STATUS';

interface FetchInvoicesAction {
  type: typeof FETCH_INVOICES;
  payload: Invoices;
}

interface DownloadFinalInvoiceStatus {
  type: typeof DOWNLOAD_FINAL_INVOICE_STATUS;
  payload: ApiStatus;
}

export type InvoicesAction = FetchInvoicesAction | DownloadFinalInvoiceStatus;
