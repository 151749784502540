import { da, de, enGB, fi, fr, nb, nl, nlBE, sv } from 'date-fns/locale';
import { capitalizeFirstLetter } from '~/helpers/formatters.helper';
import { format as formatFns } from 'date-fns';
import { Language } from '~/store/types/sharedTypes';
import dayjs from 'dayjs';
import { DayModifiers } from '~/helpers/utils';
import moment, { MomentInput } from 'moment';

export const locales = {
  NL_BE: {
    name: 'nlBE',
    locale: nlBE,
  },
  DA: {
    name: 'da',
    locale: da,
  },
  FI: {
    name: 'fi',
    locale: fi,
  },
  NL: {
    name: 'nl',
    locale: nl,
  },
  SV: {
    name: 'sv',
    locale: sv,
  },
  NO: {
    name: 'nb',
    locale: nb,
  },
  EN: {
    name: 'en-GB',
    locale: enGB,
  },
  FR: {
    name: 'fr',
    locale: fr,
  },
  DE: {
    name: 'de',
    locale: de,
  },
};

export const getDateWithMonth = (month: number) => {
  const actualDate = new Date();
  actualDate.setMonth(month);
  return actualDate;
};

export const months = (language: Language) =>
  Array.from({ length: 12 }, (v, k) => k).map((i) =>
    capitalizeFirstLetter(
      formatFns(getDateWithMonth(i), 'LLLL', {
        locale: locales[language].locale,
      })
    )
  );

interface GetDayProps {
  date: Date;
  month: Date;
  hasValue: boolean;
  maxDate?: Date;
  minDate?: Date;
  value?: Date;

  excludeDate?: (date: Date) => boolean;

  disableOutsideEvents?: boolean;
  weekendDays?: number[];
}

interface IsDisabled {
  date: Date;
  minDate?: Date;
  maxDate?: Date;

  excludeDate?(date: Date): boolean;

  disableOutsideEvents?: boolean;
  outside?: boolean;
}

export function isSameMonth(date: Date, comparison: Date) {
  return (
    date.getFullYear() === comparison.getFullYear() &&
    date.getMonth() === comparison.getMonth()
  );
}

export function isSameDate(date: Date, comparison: Date) {
  return (
    isSameMonth(date, comparison) && date.getDate() === comparison.getDate()
  );
}

export function isDisabled({
  minDate,
  maxDate,
  excludeDate,
  disableOutsideEvents,
  date,
  outside,
}: IsDisabled) {
  const isAfterMax =
    maxDate instanceof Date && dayjs(maxDate).isBefore(date, 'day');
  const isBeforeMin =
    minDate instanceof Date && dayjs(minDate).isAfter(date, 'day');
  const shouldExclude = typeof excludeDate === 'function' && excludeDate(date);
  const disabledOutside = !!disableOutsideEvents && !!outside;
  return isAfterMax || isBeforeMin || shouldExclude || disabledOutside;
}

export function isOutside(date: Date, month: Date) {
  return !isSameMonth(date, month);
}

export function isWeekend(date: Date, weekendDays = [0, 6]) {
  return weekendDays.includes(date.getDay());
}

export function getDayProps({
  date,
  month,
  hasValue,
  minDate,
  maxDate,
  value,
  excludeDate,
  disableOutsideEvents,
  weekendDays,
}: GetDayProps): DayModifiers {
  const outside = isOutside(date, month);
  const selected =
    hasValue &&
    (Array.isArray(value)
      ? value.some((val) => isSameDate(val, date))
      : isSameDate(date, value!));

  return {
    disabled: isDisabled({
      minDate,
      maxDate,
      excludeDate,
      disableOutsideEvents,
      date,
      outside,
    }),
    weekend: isWeekend(date, weekendDays),
    selected,
    outside,
  };
}

interface FormatMonthLabel {
  month: Date;
  locale: string;
  format: string;
}

export const formatMonthLabel = ({ month, locale, format }: FormatMonthLabel) =>
  capitalizeFirstLetter(dayjs(month).locale(locale).format(format));

interface IsMonthInRange {
  date: Date;
  minDate?: Date;
  maxDate?: Date;
}

export const isMonthInRange = ({ date, minDate, maxDate }: IsMonthInRange) => {
  const hasMinDate = minDate instanceof Date;
  const hasMaxDate = maxDate instanceof Date;

  if (!hasMaxDate && !hasMinDate) {
    return true;
  }

  const endOfMonth = dayjs(date).endOf('month');
  const startOfMonth = dayjs(date).startOf('month');
  const maxInRange = hasMaxDate ? startOfMonth.isBefore(maxDate) : true;
  const minInRange = hasMinDate ? endOfMonth.isAfter(minDate) : true;
  return maxInRange && minInRange;
};

export const formatDate = (date?: string | Date, format = 'YYYY-MM-DD') =>
  dayjs(date).format(format);

export const isValidDate = (d: Date | string) => {
  const date = new Date(d);
  return !Number.isNaN(date.getTime());
};

export const timestampIsInThePast = (timestamp: MomentInput) =>
  moment().isAfter(timestamp);
