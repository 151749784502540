import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import DropDown from '~/components/shared/DropDown/DropDown';
import useTranslate from '~/hooks/useTranslate';
import { ShippingDetails } from '~/Interfaces';
import { updateShippingDetailsUserData } from '~/store/actions/shippingDetailsActions';

interface Props {
  shippingDetails: ShippingDetails;
}

const DeliveryDetailsCard: React.FC<Props> = (props) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const addressSuggestions = useMemo(
    () =>
      props.shippingDetails.addresses.map((address, index) => ({
        label: `${address.street} ${address.zipCode} ${address.city}`,
        value: index,
      })),
    [props.shippingDetails]
  );

  const onDropDownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const index = parseInt(e.target.value);
    const selectedAddress = props.shippingDetails.addresses[index];

    dispatch(
      updateShippingDetailsUserData({
        address: selectedAddress,
      })
    );
  };

  return (
    <Card noPadding dataTestId="shipping-details-delivery-card">
      <CardHeader value={translate('DeliveryDetailsCard.Title') as string} />
      <CardSection
        title={translate('DeliveryDetailsCard.SelectDeliveryAddress') as string}
        explanation={translate('DeliveryDetailsCard.HelpText') as string}
        borderTop
      >
        <DropDown
          name="adress"
          defaultValue={0}
          dropDownValues={addressSuggestions}
          onChange={onDropDownChange}
          disabled={props.shippingDetails.cardAlreadyCreated}
        />
      </CardSection>
    </Card>
  );
};

export default DeliveryDetailsCard;
