import axios from 'axios';
import { myQredApi } from '../../_baseApi/myQredApi.base.api';
import { downloadFile } from '~/helpers/generatePdf.helper';
import { accessToken } from '~/store/apis/utils/accessToken';
import { generateParams } from '~/store/apis/utils/generateParams';
import {
  GetCompanyDocumentDownloadRequestDto,
  GetCompanyLoanApplicationActiveStageRequestDto,
  GetCompanyLoanApplicationActiveStageResponseDto,
  GetCompanyLoansRequestDto,
  GetCompanyLoansResponseDto,
  PatchCompanyLanguageRequestDto,
  PostCompanyUploadRequestDto,
  PostCompanyUploadResponseDto,
} from '~/store/apis/dtos/companies/companies.dto';
import {
  getCompanyLoanApplicationActiveStageMapped,
  getCompanyLoansMapped,
} from '~/store/apis/endpoints/myQredApi/companies/companies.mapper';
import { LoanOverview } from '~/models/companies/loans';
import { ApplicationStage } from '~/models/companies/application';
import { notifications } from '@qred/components-library';

export const companiesApi = myQredApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyDocument: builder.query<
      any,
      GetCompanyDocumentDownloadRequestDto
    >({
      async queryFn({ companyId, documentType, ...rest }) {
        const token = await accessToken.get();
        try {
          const res = await axios.get(
            `${
              import.meta.env.VITE_MYQRED_API_URL
            }/company/v1/companies/${companyId}/download/${documentType}?${generateParams(
              rest
            )}`,
            {
              responseType: 'blob',
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          downloadFile(`${documentType}.pdf`, res.data, 'application/pdf');
          return { data: res.data };
        } catch (error) {
          return { error: error };
        }
      },
    }),
    updateCompanyLanguage: builder.mutation<
      unknown,
      PatchCompanyLanguageRequestDto
    >({
      query: ({ id, ...rest }) => ({
        url: `/company/v1/companies/${id}/language`,
        method: 'PATCH',
        body: rest,
      }),
    }),
    getCompanyLoans: builder.query<LoanOverview[], GetCompanyLoansRequestDto>({
      query: ({ companyId }) => `/company/v1/companies/${companyId}/loans`,
      providesTags: (_result, _error, arg) => [
        { type: 'CompanyLoans', id: arg.companyId },
      ],
      transformResponse: (response: GetCompanyLoansResponseDto[], _meta, arg) =>
        getCompanyLoansMapped(response, arg.market),
    }),
    getCompanyLoanApplicationActiveStage: builder.query<
      ApplicationStage,
      GetCompanyLoanApplicationActiveStageRequestDto
    >({
      query: ({ companyId }) =>
        `/company/v1/companies/${companyId}/loan-applications/active/stage`,
      providesTags: (_result, _error, arg) => [
        { type: 'CompanyLoanApplicationActiveStage', id: arg.companyId },
      ],
      transformResponse: (
        response: GetCompanyLoanApplicationActiveStageResponseDto,
        _meta,
        arg
      ) => getCompanyLoanApplicationActiveStageMapped(response, arg.market),
      transformErrorResponse: (error, _meta, _arg) => {
        if (error.status !== 404) {
          notifications.show({
            state: 'critical',
            autoClose: false,
            message: 'Something went wrong',
          });
        }

        return { error: error.data, data: undefined };
      },
    }),
    postCompanyUpload: builder.mutation<unknown, PostCompanyUploadRequestDto>({
      async queryFn({ companyId, files }, _api, _extraOptions, baseQuery) {
        const { data, error } = await baseQuery({
          url: `/company/v1/companies/${companyId}/upload`,
          method: 'POST',
          body: files,
        });

        const _data = data as PostCompanyUploadResponseDto[];

        if (error) {
          return { error };
        }

        try {
          await Promise.all(
            _data.map(({ url, fileName }) => {
              const file = files.find((_file) => _file.fileName === fileName);

              if (!file) {
                return;
              }

              return axios.put(url, file.binary, {
                headers: { 'Content-Type': file.binary.type },
              });
            })
          );

          return { data: undefined };
        } catch (err) {
          return { error: err };
        }
      },
    }),
  }),
});

export const {
  useLazyGetCompanyDocumentQuery,
  useUpdateCompanyLanguageMutation,
  useGetCompanyLoansQuery,
  usePostCompanyUploadMutation,
  useGetCompanyLoanApplicationActiveStageQuery,
} = companiesApi;
