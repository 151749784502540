import { Stack } from '@qred/components-library';
import React from 'react';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import { formatSize } from '~/helpers/formatters.helper';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus } from '~/store/types/sharedTypes';

export interface FileUploadErrors {
  alreadyAdded: boolean;
  invalidType: boolean;
  tooLarge: boolean;
  maxLimit: boolean;
}

interface IDocumentErrorMessage {
  fileUploadErrors: FileUploadErrors;
  maxFiles: number;
  maxSize: number;
  documentsApiStatus: ApiStatus;
  lastLocalUploadHadMultipleFiles: boolean;
  allowedFileExtensionsDisplayed: string[];
}

const DocumentErrorMessage: React.FC<IDocumentErrorMessage> = ({
  fileUploadErrors,
  maxFiles,
  maxSize,
  documentsApiStatus,
  lastLocalUploadHadMultipleFiles,
  allowedFileExtensionsDisplayed,
}) => {
  const t = useTranslate();
  return (
    <Stack>
      {fileUploadErrors.maxLimit && (
        <ValidationErrorMessage>
          {t('Onboarding.TooManyFilesUploadedText', { maxFiles })}
        </ValidationErrorMessage>
      )}
      {documentsApiStatus === ApiStatus.Failed && (
        <ValidationErrorMessage>
          {t('Onboarding.FileUploadApiError')}
        </ValidationErrorMessage>
      )}
      {fileUploadErrors.alreadyAdded && (
        <ValidationErrorMessage>
          {lastLocalUploadHadMultipleFiles
            ? t('Onboarding.FileAlreadyAddedErrorPlural')
            : t('Onboarding.FileAlreadyAddedErrorSingle')}
        </ValidationErrorMessage>
      )}
      {fileUploadErrors.tooLarge && (
        <ValidationErrorMessage>
          {lastLocalUploadHadMultipleFiles
            ? t('Onboarding.FileIsTooLargeErrorPlural', {
                maxSize: formatSize(maxSize),
              })
            : t('Onboarding.FileIsTooLargeErrorSingle', {
                maxSize: formatSize(maxSize),
              })}
        </ValidationErrorMessage>
      )}
      {fileUploadErrors.invalidType && (
        <ValidationErrorMessage>
          {lastLocalUploadHadMultipleFiles
            ? t('Onboarding.FileInvalidTypeErrorPlural', {
                acceptedFormats: allowedFileExtensionsDisplayed.join(', '),
              })
            : t('Onboarding.FileInvalidTypeErrorSingle', {
                acceptedFormats: allowedFileExtensionsDisplayed.join(', '),
              })}
        </ValidationErrorMessage>
      )}
    </Stack>
  );
};

export default DocumentErrorMessage;
