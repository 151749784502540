import { Typography } from '@qred/components-library';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormStatus, ValidationContext } from '~/components/hoc/withValidation';
import Card from '~/components/shared/Card/Card';
import Stepper from '~/components/shared/Stepper/Stepper';
import { FlexContentPlacement, FlowTypes, StepStatus } from '~/enums';
import { getCookieValue, orgNrAreTheSame } from '~/helpers/utils';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { useShouldDisplayTestVariant } from '~/hooks/useShouldDisplayTestVariant';
import useTranslate from '~/hooks/useTranslate';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { useLoanOnboardingApplicationSelectors } from '~/store/selectors/loanOnboardingApplication.selector';
import {
  clearOnboardingState,
  goToPreviousStep,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import {
  OnboardingStepsContainer,
  SubmissionIdentificationExplanation,
} from '~/styles/OnboardingBaseStyledComponents';
import { theme } from '~/styles/themes';
import OnboardingNavbar from '../OnboardingNavbar/OnboardingNavbar';
import OnboardingAppShell from './OnboardingAppShell';
import { TopFixedContainer } from './TopFixedContainer';
import useGoToNextStep from './useGoToNextStep';
import useSubmit from './useSubmit';

const Steps = () => {
  const dispatch = useDispatch();
  const t = useTranslate();

  const [selectedCompanyHasOffer, setSelectedCompanyHasOffer] = useState(false);

  const validationContext = useContext(ValidationContext);

  const publicToken = getCookieValue('publicToken');
  const onSubmit = useSubmit();
  const onNextStepClick = useGoToNextStep();
  const {
    marketRequiresLoginBeforeSubmittingApplication,
  } = useOnboardingMarketConfig();

  const {
    onboardingApplication: {
      form,
      flowType,
      currentStep,
      overview,
      apiStatus: applicationApiStatus,
    },
    matchedMedia,
    klarnaKosma: { transactionFlow },
  } = useSelector((state: RootState) => state);

  const { stepConfig } = useLoanOnboardingApplicationSelectors();

  const isLastStep = stepConfig.length === currentStep;

  const applicationApiRequestInProgress = [
    applicationApiStatus.application,
    applicationApiStatus.patch,
    applicationApiStatus.post,
  ].some((apiStatus) => apiStatus === ApiStatus.Started);

  const currentStepConfig = stepConfig[currentStep - 1];

  useEffect(() => {
    if (!form.sessionId) {
      const sessionId = `${Math.random()}${Date.now()}`.replace('0.', '');
      dispatch(updateForm({ sessionId }));
    }
  }, [dispatch, form.sessionId]);

  useEffect(() => {
    dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Started }));
    validationContext.setFormStatus(FormStatus.IDLE);
  }, [currentStepConfig.name, dispatch]);

  useEffect(() => {
    const selectedCompany = overview.clients.find((client) =>
      orgNrAreTheSame(form.organizationNumber, client.company_no)
    );
    setSelectedCompanyHasOffer(!!selectedCompany?.has_offer_to_sign);
  }, [form.organizationNumber, overview.clients]);

  const onBackButtonClick = () => {
    if (
      transactionFlow.status === 'inProgress' ||
      applicationApiRequestInProgress
    )
      return;
    dispatch(pushToGtmOnboardingAction({ actionName: 'back_button_pressed' }));
    dispatch(goToPreviousStep());
    dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Edit }));
  };

  useEffect(() => {
    const userIsPastFirstStep = currentStep > 1;
    if (flowType === FlowTypes.Manual && !publicToken && userIsPastFirstStep) {
      dispatch(clearOnboardingState());
    }
  }, [currentStep, dispatch, flowType, publicToken]);

  const extraFooterContent = useMemo(() => {
    if (
      isLastStep &&
      marketRequiresLoginBeforeSubmittingApplication &&
      flowType === FlowTypes.Manual
    ) {
      return (
        <SubmissionIdentificationExplanation>
          <Typography weight={500} size="sm">
            {t('Onboarding.SubmissionIdentificationExplanation')}
          </Typography>
        </SubmissionIdentificationExplanation>
      );
    }
    return undefined;
  }, [isLastStep, marketRequiresLoginBeforeSubmittingApplication]);

  const shouldDisplayNavbarTestVariant = useShouldDisplayTestVariant([
    'AB_LoanOnboarding_NavigationBar_NoNegativeImpactOnConversion_NL',
  ]);

  return shouldDisplayNavbarTestVariant ? (
    <OnboardingAppShell>
      <TopFixedContainer
        style={{ maxWidth: '800px', width: '100%' }}
        topFixedDisabled={!matchedMedia.smallScreenPortrait}
      >
        <OnboardingNavbar
          onBackClick={onBackButtonClick}
          showBackButton
          progressStepperDots={
            !selectedCompanyHasOffer
              ? { steps: stepConfig, currentStep }
              : undefined
          }
          shouldDisplayTestVariant={shouldDisplayNavbarTestVariant}
          style={{
            borderBottom: matchedMedia.smallScreenPortrait
              ? `2px solid ${theme.colors.neutral12}`
              : 'none',
          }}
        />
      </TopFixedContainer>
      <OnboardingStepsContainer style={{ marginTop: 0 }}>
        <Card
          noPadding
          isOnboarding
          justifyContent={FlexContentPlacement.SpaceBetween}
        >
          {stepConfig.length && (
            <Stepper
              currentStepConfig={currentStepConfig}
              stepIsLoading={applicationApiRequestInProgress}
              currentStep={currentStep}
              footerCTALabel={
                isLastStep ? 'Onboarding.Submit' : 'Onboarding.Next'
              }
              footerCTAAction={isLastStep ? onSubmit : onNextStepClick}
              extraFooterContent={extraFooterContent}
            />
          )}
        </Card>
      </OnboardingStepsContainer>
    </OnboardingAppShell>
  ) : (
    <>
      <OnboardingNavbar
        filledBackground
        fixedPosition={matchedMedia.smallScreenPortrait}
        onBackClick={onBackButtonClick}
        showBackButton
        progressStepperDots={
          !selectedCompanyHasOffer
            ? { steps: stepConfig, currentStep }
            : undefined
        }
      />
      <OnboardingStepsContainer>
        <Card
          noPadding
          isOnboarding
          justifyContent={FlexContentPlacement.SpaceBetween}
        >
          {stepConfig.length ? (
            <Stepper
              currentStepConfig={currentStepConfig}
              stepIsLoading={applicationApiRequestInProgress}
              currentStep={currentStep}
              footerCTALabel={
                isLastStep ? 'Onboarding.Submit' : 'Onboarding.Next'
              }
              footerCTAAction={isLastStep ? onSubmit : onNextStepClick}
              extraFooterContent={extraFooterContent}
            />
          ) : (
            <></>
          )}
        </Card>
      </OnboardingStepsContainer>
    </>
  );
};

export default Steps;
