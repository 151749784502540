import { Button, Group, Stack, Typography } from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSupportUrl } from '~/constants/markets';
import { CountryCode } from '~/enums';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import useTranslate from '~/hooks/useTranslate';
import { openQredHomePage } from '~/services/links';
import { RootState } from '~/store/types/sharedTypes';
import { ClickableText } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';

const LoanAdditionalSignerSuccess = () => {
  const {
    intl: { language, market },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const translate = useTranslate();
  const { marketHasMultipleProducts } = useOnboardingMarketConfig();

  const contactUsOnClick = () => {
    if (!language) return;
    const supportUrl = getSupportUrl(market as CountryCode, language);
    window.open(supportUrl, '_blank');
  };

  return (
    <Group
      spacing={0}
      h={mobile ? 396 : 308}
      w={mobile ? 332 : 580}
      p={mobile ? 'lg' : 'xxl'}
      style={{
        backgroundColor: `${theme.colors.neutral2}`,
        borderRadius: '4px',
      }}
    >
      <Stack>
        <Typography weight={600} size="xl" lineHeight={32}>
          {translate('LoanAdditionalSigner.LoanAdditionalSignerSuccessTitle')}
        </Typography>
        <Typography lineHeight={24}>
          {translate(
            'LoanAdditionalSigner.LoanAdditionalSignerSuccessSubheader'
          )}
        </Typography>
        <Typography lineHeight={24}>
          {marketHasMultipleProducts ? (
            <>
              {translate(
                'LoanAdditionalSigner.LoanAdditionalSignerSuccessDiscoverOtherProducts'
              )}
            </>
          ) : (
            <>
              {translate(
                'LoanAdditionalSigner.LoanAdditionalSignerSuccessContactUs',
                {
                  contactUs: (
                    <ClickableText
                      typographyColor={theme.colors.midwayLinkDark}
                      onClick={contactUsOnClick}
                      noUnderline
                    >
                      {translate('Onboarding.ContactUs')
                        .toString()
                        .toLowerCase()}
                    </ClickableText>
                  ),
                }
              )}
            </>
          )}
        </Typography>
      </Stack>
      {marketHasMultipleProducts && (
        <Button
          mt={24}
          fullWidth={mobile}
          onClick={() => openQredHomePage(market, language)}
          variant="primary"
          size="md"
        >
          <Typography color="textLight.1" weight={500}>
            {translate(
              'LoanAdditionalSigner.LoanAdditionalSignerSuccessButtonText'
            )}
          </Typography>
        </Button>
      )}
    </Group>
  );
};

export default LoanAdditionalSignerSuccess;
