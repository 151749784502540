import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  @media ${MediaQueries.smallScreenPortrait} {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
  }
`;
