import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';

export const ApplicationDescriptionItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
  min-width: 220px;
  @media ${MediaQueries.desktop} {
    max-width: 320px;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  max-width: 400px;
  margin: 64px 40px 52px 40px;
  align-self: center;

  @media ${MediaQueries.desktop} {
    width: 80%;
    max-width: 1200px;
    flex-direction: row;
    justify-content: space-between;
    margin: 64px 0 52px 0;
  }
`;
