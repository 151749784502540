import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Card from '~/components/shared/Card/Card';
import CardSection from '~/components/shared/CardSection/CardSection';
import MeansOfSupport from '~/components/shared/MeansOfSupport/MeansOfSupport';
import {
  FontWeights,
  Typography,
} from '~/components/shared/Typography/Typography';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import CreateCardProgressBar from './CreateCardProgressBar';

const TextContainer = styled.div`
  padding: 1em;
  margin: auto;
`;

const ErrorMessageContainer = styled(TextContainer)`
  padding-left: 0;
  padding-right: 0;
`;

const Separator = styled.div`
  height: 1em;
`;

const CreateCardProgressCard: React.FC = () => {
  const { isCardCreationFailed } = useSelector(
    (state: RootState) => state.shippingDetails
  );
  const translate = useTranslate();

  return (
    <Card noPadding>
      <CardSection>
        <TextContainer>
          <Typography
            fontSize={1}
            fontWeight={FontWeights.Normal}
            lineHeight={1.5}
            centerAlign
          >
            {isCardCreationFailed
              ? translate('CreateCardProgressBarCard.Text2')
              : translate('CreateCardProgressBarCard.Text')}
          </Typography>
        </TextContainer>

        <CreateCardProgressBar mt={1} />

        {isCardCreationFailed ? (
          <ErrorMessageContainer>
            <Typography
              fontSize={0.8}
              fontWeight={600}
              color={theme.colors.error}
              centerAlign
            >
              {translate('CreateCardProgressBarCard.ErrorMessage')}
            </Typography>
          </ErrorMessageContainer>
        ) : (
          <TextContainer>
            <Typography
              fontSize={0.8}
              fontWeight={FontWeights.Normal}
              color={theme.colors.secondaryLightGray}
              centerAlign
            >
              {translate('CreateCardProgressBarCard.CreatingCard')}
            </Typography>
          </TextContainer>
        )}
      </CardSection>
      {isCardCreationFailed && (
        <CardSection borderTop>
          <Typography
            fontSize={1}
            fontWeight={FontWeights.Normal}
            lineHeight={1.5}
          >
            {translate('CreateCardProgressBarCard.SupportText')}
          </Typography>
          <Separator />
          <MeansOfSupport />
        </CardSection>
      )}
    </Card>
  );
};

export default CreateCardProgressCard;
