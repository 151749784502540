import React from 'react';
import {
  ActionIcon,
  Group,
  Header as CLHeader,
  Typography,
  useWindowProperties,
} from '@qred/components-library';
import { useHistory } from 'react-router';
import { LanguageItemHeader } from './LanguageItemHeader';
import constVars from '~/constants/constVars';
import { CountryCode } from '~/enums';
import { useAppShellContext } from '~/components/shared/Navigation/AppShell.context';
import { useAccount } from '~/hooks/useAccount';

interface HeaderProps {
  keepDesktopStyle?: boolean;
}

const Header = ({ keepDesktopStyle }: HeaderProps) => {
  const { market } = useAccount();
  const history = useHistory();
  const { isMobile } = useWindowProperties();
  const isQeld = market === CountryCode.NL;
  const ctx = useAppShellContext();

  const handleProfile = () => {
    history.push(`/${constVars.ROUTE_PROFILE}`);
  };

  return (
    <CLHeader
      keepDesktopStyle={keepDesktopStyle}
      mt={keepDesktopStyle ? -16 : undefined}
      mb={keepDesktopStyle ? 16 : undefined}
      mx={keepDesktopStyle ? -16 : undefined}
      style={keepDesktopStyle ? { zIndex: 0 } : { zIndex: 1 }}
      qeld={isQeld}
    >
      <Group justify="space-between" align="center" mx={40} h="100%">
        <Typography
          key={ctx.headerTitle}
          size="xxl"
          weight={500}
          lineHeight={24}
        >
          {ctx.headerTitle}
        </Typography>
        {!isMobile && (
          <Group align="center">
            {!isQeld && <LanguageItemHeader />}
            <ActionIcon
              icon="person"
              onClick={handleProfile}
              color="textDark.500"
            />
          </Group>
        )}
      </Group>
    </CLHeader>
  );
};

export default Header;
