import { CountryCode } from '~/enums';
import { capitalizeFirstLetter } from './formatters.helper';
import dayjs from 'dayjs';

const convertDataUriToBinary = (base64: string, fileType?: string) => {
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i += 1) {
    array[i] = raw.charCodeAt(i);
  }
  const mimeType = fileType === '.zip' ? 'application/zip' : 'application/pdf';
  const blob = new Blob([array.buffer], {
    type: mimeType,
  });

  return blob;
};

export const generatePdfHelper = (
  data: string,
  documentType: string,
  companyName?: string,
  fileFormat?: string
) => {
  const market = window.__QRED_MARKET__ || 'se';
  const today = dayjs(new Date()).toDate();
  const formattedDate = today.toLocaleDateString();
  const formattedType = capitalizeFirstLetter(documentType);
  const _companyName = `-${companyName}`;
  const formattedCompanyName = _companyName?.replace(/ /g, '-');
  const fileName = `${formattedDate}-${
    market === CountryCode.NL ? 'Qeld-' : 'Qred-'
  }${formattedType}${`_${companyName}` ? formattedCompanyName : ''}`;

  const downloadLink = document.createElement('a');
  const pdf = convertDataUriToBinary(data, fileFormat);
  if (pdf) {
    const link = URL.createObjectURL(pdf);
    downloadLink.href = link;
    downloadLink.download =
      fileFormat === '.zip' ? `${fileName}.zip` : `${fileName}.pdf`;
    downloadLink.click();
    URL.revokeObjectURL(link);
  }
};
export const downloadFile = (
  filename: string,
  data: any,
  contentType: string
) => {
  const blob = new Blob([data], { type: contentType });

  const link = document.createElement('a');
  if (typeof link.download !== 'undefined') {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};
