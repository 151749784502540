import * as Sentry from '@sentry/react';
import { Dispatch } from 'redux';
import { GlobalErrorType } from '~/enums';
import { apiGetEskatIntegrationUrl } from '~/services/api';
import { EskatAction, SET_ESKAT_API_STATUS } from '../types/eskatTypes';
import { ApiStatus } from '../types/sharedTypes';
import { toggleGlobalErrorOn } from '../slices/globalError.slice';

const updateEskatApiStatus = (status: ApiStatus): EskatAction => ({
  type: SET_ESKAT_API_STATUS,
  payload: status,
});

export const getEskatIntegrationUrl = (qcode: string) => (
  dispatch: Dispatch
) => {
  dispatch(updateEskatApiStatus(ApiStatus.Started));
  apiGetEskatIntegrationUrl(qcode)
    .then((res) => {
      const base64ResponseString = res.data;
      const testFlag =
        import.meta.env.MODE === 'production' ||
        import.meta.env.MODE === 'internal-prod'
          ? 'nej'
          : 'ja';

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = import.meta.env.VITE_API_ESKAT_BASE_URL;
      form.style.display = 'none';

      const input1 = document.createElement('input');
      input1.setAttribute('type', 'hidden');
      input1.setAttribute('name', 'SAMTYKKEPARM');
      input1.value = base64ResponseString;

      const input2 = document.createElement('input');
      input2.setAttribute('type', 'hidden');
      input2.setAttribute('name', 'TESTMODE');
      // input2.value = testFlag;
      input2.value = 'nej';

      form.appendChild(input1);
      form.appendChild(input2);

      document.body.appendChild(form);
      form.submit();
    })
    .catch((err) => {
      Sentry.withScope((scope) => {
        // Group all eskat connection errors together
        scope.setFingerprint(['apiGetEskatIntegrationUrl']);
        Sentry.captureException(err, {
          tags: { qcode },
        });
      });
      dispatch(updateEskatApiStatus(ApiStatus.Failed));
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    });
};
