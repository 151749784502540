import { StepConfigItem } from '~/Interfaces';
import { CountryCode, FlowTypes } from '~/enums';
import SelectGuarantor from './SelectGuarantor/SelectGuarantor';
import CompanySelector from './CompanySelector/CompanySelector';
import LoanPurpose from './LoanPurpose/LoanPurpose';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import FinancialInformation from './FinancialInformation/FinancialInformation';
import ConnectToBankOrUploadDocuments from './ConnectToBankOrUploadDocuments/ConnectToBankOrUploadDocuments';
import ConnectToBank from './ConnectToBank/ConnectToBank';
import ConnectToBankOrUploadDocumentsFooter from './ConnectToBankOrUploadDocuments/components/ConnectToBankOrUploadDocumentsFooter';
import ConnectToBankOrUploadDocumentsHeader from './ConnectToBankOrUploadDocuments/components/ConnectToBankOrUploadDocumentsHeader';
import ConnectToBankFooter from './ConnectToBank/components/ConnectToBankFooter';
import CompanySelectorFooter from './CompanySelector/components/CompanySelectorFooter';
import SelectGuarantorNetherlandsFooter from './SelectGuarantor/components/SelectGuarantorNetherlandsFooter';
import ConnectToEskat from './ConnectToEskat/ConnectToEskat';
import ConnectToEskatFooter from './ConnectToEskat/components/ConnectToEskatFooter';
import CompanySelectorHeader from './CompanySelector/components/CompanySelectorHeader';

const CompanySelectorStep: StepConfigItem = {
  stepContent: [CompanySelector],
  customHeader: CompanySelectorHeader,
  customFooter: CompanySelectorFooter,
  name: 'company_selector_step',
};

const PersonalInformationStep: StepConfigItem = {
  stepContent: [PersonalInformation],
  header: {
    title: 'Onboarding.PersonalInformationTitle',
  },
  name: 'personal_information_step',
};

const LoanPurposeStep: StepConfigItem = {
  stepContent: [LoanPurpose],
  header: {
    title: 'Onboarding.SelectPurposeTitle',
    description: 'Onboarding.SelectPurposeDescription',
  },
  name: 'loan_purpose_step',
};

const SelectGuarantorStep: StepConfigItem = {
  stepContent: [SelectGuarantor],
  header: {
    title: 'Onboarding.AddAdditionalGuarantors',
  },
  name: 'guarantor_selector_step',
};

const SelectGuarantorNetherlandsStep: StepConfigItem = {
  stepContent: [SelectGuarantor],
  header: {
    title: 'Onboarding.AddAdditionalGuarantors',
  },
  customFooter: SelectGuarantorNetherlandsFooter,
  name: 'guarantor_selector_step',
};

const KlarnaKosmaBankIntegrationStep: StepConfigItem = {
  stepContent: [ConnectToBank],
  customFooter: ConnectToBankFooter,
  name: 'connect_to_bank_step',
};

const FinancialInformationStep: StepConfigItem = {
  stepContent: [FinancialInformation],
  header: {
    title: 'Onboarding.FinancialDetailsTitle',
  },
  name: 'financial_information_step',
};

const ConnectToBankOrUploadDocumentsStep: StepConfigItem = {
  stepContent: [ConnectToBankOrUploadDocuments],
  customHeader: ConnectToBankOrUploadDocumentsHeader,
  customFooter: ConnectToBankOrUploadDocumentsFooter,
  name: 'connect_to_bank_step',
};

const EskatStep: StepConfigItem = {
  stepContent: [ConnectToEskat],
  customFooter: ConnectToEskatFooter,
  name: 'connect_to_eskat_step',
};

export const stepConfigPerMarketFlowType: Record<
  CountryCode,
  Record<FlowTypes, StepConfigItem[]>
> = {
  [CountryCode.SE]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
  },
  [CountryCode.FI]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
  },
  [CountryCode.NO]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
  },
  [CountryCode.DK]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      EskatStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      EskatStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
  },
  [CountryCode.BE]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      KlarnaKosmaBankIntegrationStep,
      FinancialInformationStep,
    ],
  },
  [CountryCode.NL]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorNetherlandsStep,
      ConnectToBankOrUploadDocumentsStep,
      FinancialInformationStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorNetherlandsStep,
      ConnectToBankOrUploadDocumentsStep,
      FinancialInformationStep,
    ],
  },
  [CountryCode.DE]: {
    [FlowTypes.Authenticated]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      FinancialInformationStep,
    ],
    [FlowTypes.Manual]: [
      CompanySelectorStep,
      LoanPurposeStep,
      PersonalInformationStep,
      SelectGuarantorStep,
      FinancialInformationStep,
    ],
  },
};
