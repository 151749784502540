import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import withGtmEvent from '~/components/hoc/withGtmEvent';
import Logo from '~/components/shared/LogoOld/LogoOld';
import PageHeader from '~/components/shared/PageHeader/PageHeader';
import PageTitle from '~/components/shared/PageTitle/PageTitle';
import useQueryParams from '~/hooks/useQueryParams';
import useTranslate from '~/hooks/useTranslate';
import { getEskatIntegrationUrl } from '~/store/actions/eskatActions';
import { defaultLanguageToMarket, setMarket } from '~/store/slices/intl.slice';
import { BodyContainer, MainContainer } from '~/styles/BaseStyledComponents';
import { Redirect, Route, Switch } from 'react-router-dom';
import constVars from '~/constants/constVars';
import { parseMarket } from '~/helpers/market.helper';
import EskatCard from './components/EskatCard';
import RedirectFromEskatModal from './components/RedirectFromEskatModal';

const EmptyFooter = styled.div`
  height: 2em;
`;

const Eskat = () => {
  const translate = useTranslate();
  const queryParams = useQueryParams();

  const dispatch = useDispatch();

  /* Effects */
  useEffect(() => {
    const market = parseMarket(queryParams.get('market'));
    if (market) {
      dispatch(setMarket(market));
      dispatch(defaultLanguageToMarket(market));
    }
  }, [queryParams.get('market')]);

  useEffect(() => {
    localStorage.removeItem('eskatQcode');
    const qcode = queryParams.get('qcode');
    if (qcode) {
      localStorage.setItem('eskatQcode', qcode);
    }
  }, [queryParams]);

  const onConsentButtonClicked = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const eskatQcode = localStorage.getItem('eskatQcode');
    // if qcode is not found we do not want to start the aiia process
    if (eskatQcode) {
      dispatch(getEskatIntegrationUrl(eskatQcode));
    }
  };

  const onboardingApplicationUuid = sessionStorage.getItem(
    'eskat-flow-onboarding'
  );

  return (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
        <PageTitle mb={2}>{translate('AddInformation')}</PageTitle>
      </PageHeader>
      <BodyContainer>
        <Switch>
          <Route path={[`/${constVars.ROUTE_ESKAT}/redirected`]}>
            {onboardingApplicationUuid ? (
              <Redirect
                to={`/${constVars.ROUTE_ONBOARDING}?applicationUuid=${onboardingApplicationUuid}&eskat=connected`}
              />
            ) : (
              <RedirectFromEskatModal />
            )}
          </Route>
          <Route path={[`/${constVars.ROUTE_ESKAT}`]}>
            {onboardingApplicationUuid ? (
              <Redirect
                to={`/${constVars.ROUTE_ONBOARDING}?applicationUuid=${onboardingApplicationUuid}&eskat=cancelled`}
              />
            ) : (
              <EskatCard onClick={onConsentButtonClicked} />
            )}
          </Route>
        </Switch>
      </BodyContainer>
      <EmptyFooter />
    </MainContainer>
  );
};

export default withGtmEvent(Eskat);
