import React from 'react';
import styled from 'styled-components';
import { theme } from '~/styles/themes';
import { Typography } from '../Typography/Typography';

const CardHeaderContainer = styled.div<{ noPadding?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.noPadding ? '0 0 1em 0' : '1em')};
`;

interface Props {
  value: string;
  noPadding?: boolean;
  color?: string;
}

const CardHeader: React.FC<Props> = (props) => (
  <CardHeaderContainer noPadding={props.noPadding}>
    <Typography
      fontSize={1}
      color={props.color ? props.color : theme.colors.dark}
      fontWeight={600}
      centerAlign
    >
      {props.value}
    </Typography>
  </CardHeaderContainer>
);

export default CardHeader;
