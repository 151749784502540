import React from 'react';
import { useSelector } from 'react-redux';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import SkipButton from '~/components/shared/Stepper/components/SkipButton';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import useSkip from '../../Steps/useSkip';
import useGoToNextStep from '../../Steps/useGoToNextStep';

const ConnectToBankOrUploadDocumentsFooter = () => {
  const {
    onboardingApplication: { form, apiStatus },
    klarnaKosma: { transactionFlow },
  } = useSelector((state: RootState) => state);

  const onNextStepClick = useGoToNextStep();
  const onSkip = useSkip();

  const showSkipButton = !form.bankIntegration && !form.bankFilesUploaded;

  return (
    <>
      {showSkipButton ? (
        <SkipButton
          label="Onboarding.Skip"
          action={onSkip}
          disabled={transactionFlow.status === 'inProgress'}
        />
      ) : (
        <NextButton
          label="Onboarding.Next"
          showLoadingSpinner={apiStatus.patch === ApiStatus.Started}
          action={onNextStepClick}
        />
      )}
    </>
  );
};

export default ConnectToBankOrUploadDocumentsFooter;
