import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { patchApplication } from '~/store/actions/onboardingActions';
import { useAuth0 } from '@auth0/auth0-react';
import { OnboardingAuthType, StepStatus } from '~/enums';
import LoanAmount from '~/components/shared/LoanAmount/LoanAmount';
import { RootState } from '~/store/types/sharedTypes';
import { loanAmountRounderHelper } from '~/helpers/loanAmountRounder.helper';
import {
  formatAmount,
  formatAmountNumbersOnly,
  formatAmountWithoutCurrency,
  removeNonDigits,
} from '~/helpers/formatters.helper';
import { FormStatus, ValidationContext } from '~/components/hoc/withValidation';
import SpinnerLinear from '~/components/shared/Spinner/SpinnerLinear';
import PolicyAgreement from '~/components/shared/PolicyAgreement/PolicyAgreement';
import { onboardingLoginMethods } from '~/helpers/authentication.helper';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { onChangeInputEvent } from '~/types/types';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { LoanAmountDefaultValues } from '~/constants/markets';
import {
  updateCurrentStep,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import {
  ClickableTextLogin,
  FormBottomContent,
  FormBottomContentButtons,
  FormTopContent,
  LaunchViewFormContainer,
  LaunchViewFormContent,
  LoanAmountInputWrapper,
} from './LaunchViewForm.styled';

const LaunchViewForm = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const [chosenLoginMethod, setChosenLoginMethod] = useState('');
  const [localAmount, setLocalAmount] = useState('');

  const validationContext = useContext(ValidationContext);

  const { marketOnlyHasEmailLogin } = useOnboardingMarketConfig();

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const {
    onboardingApplication: { form },
    intl: { market },
    matchedMedia,
  } = useSelector((state: RootState) => state);

  const { amount } = form;

  useEffect(() => {
    if (amount) {
      setLocalAmount(amount);
    }
  }, [amount]);

  const handleOnBlur = (event: onChangeInputEvent) => {
    const { value } = event.target;
    if (value) {
      validationContext.removePropertyFromValidationErrors('LoanAmount');
      const roundedAmount = loanAmountRounderHelper(value, market);
      const formattedValue = formatAmountWithoutCurrency(
        removeNonDigits(roundedAmount)
      );
      setLocalAmount(formattedValue);
    }
    dispatch(
      pushToGtmOnboardingAction({
        stepActionNumber: 0.1,
        actionName: 'loan_amount_change',
      })
    );
  };

  const handleOnChange = (event: onChangeInputEvent) => {
    const { value } = event.target;
    setLocalAmount(value ? formatAmountNumbersOnly(value) : value);
  };

  const handleAuthClick = async (connectionName: string) => {
    validationContext.setFormStatus(FormStatus.SUBMITTING);
    if (!validationContext.isFormValid) {
      validationContext.setFormStatus(FormStatus.SUBMITTED);
      return;
    }

    setChosenLoginMethod(connectionName);

    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'auth_button_pressed',
        stepActionNumber: 0.3,
      })
    );

    const url = new URL(window.location.href);
    url.searchParams.set('authType', OnboardingAuthType.AuthenticatedFlow);
    url.searchParams.set('loan_amount', removeNonDigits(localAmount));

    if (form.applicationUuid) {
      await dispatch(patchApplication());
    }

    loginWithRedirect({
      authorizationParams: {
        connection: connectionName,
        redirectUri: url.toString(),
      },
    });
  };

  const handleStartFlowClick = () => {
    validationContext.setFormStatus(FormStatus.SUBMITTING);
    if (!validationContext.isFormValid) {
      validationContext.setFormStatus(FormStatus.SUBMITTED);
      return;
    }

    dispatch(updateForm({ amount: localAmount }));

    dispatch(
      pushToGtmOnboardingAction({
        stepActionNumber: 0.2,
        actionName: isAuthenticated
          ? 'next_button_pressed'
          : 'start_manual_flow_pressed',
      })
    );
    dispatch(
      pushToGtmOnboardingAction({
        stepStatus: StepStatus.Finished,
      })
    );
    dispatch(updateCurrentStep(1));
  };

  const marketHasNoLoginMethod = onboardingLoginMethods[market].length === 0;

  return (
    <>
      <LaunchViewFormContainer>
        <LaunchViewFormContent justify="center" align="center">
          <FormTopContent align="center">
            <Typography
              dataCy="launchview_page_header"
              size="xxl"
              letterSpacing={0}
              align="center"
              lineHeight={50}
            >
              {t('Onboarding.LaunchViewLoanTitle')}
            </Typography>
            <LoanAmountInputWrapper>
              <LoanAmount
                value={localAmount}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                name="LoanAmount"
                dataCy="launchview_loan_amount"
              />
              <Typography size="sm" align="center">
                {t('Onboarding.LaunchViewLoanApplyMax', {
                  min: formatAmount(LoanAmountDefaultValues[market].min),
                  max: formatAmount(LoanAmountDefaultValues[market].max),
                })}
              </Typography>
            </LoanAmountInputWrapper>
          </FormTopContent>
          <FormBottomContent>
            <FormBottomContentButtons spacing="sm">
              {marketOnlyHasEmailLogin &&
                !isAuthenticated &&
                onboardingLoginMethods[market].map((loginMethod) => (
                  <Typography
                    key={loginMethod.connectionName}
                    lineHeight={matchedMedia.tablet ? 'xl' : 'xxl'}
                  >
                    {t('Onboarding.LaunchViewDoYouHaveAnAccount')}
                    <ClickableTextLogin
                      onClick={() =>
                        handleAuthClick(loginMethod.connectionName)
                      }
                    >
                      {t('Onboarding.LaunchViewLoginEmail')}
                    </ClickableTextLogin>
                  </Typography>
                ))}

              {isAuthenticated ? (
                <Button
                  variant="primary"
                  onClick={handleStartFlowClick}
                  dataCy="launchview_authenticated_next_button"
                  size="md"
                >
                  {t('Onboarding.Next')}
                </Button>
              ) : (
                <>
                  <Button
                    variant={
                      marketOnlyHasEmailLogin || marketHasNoLoginMethod
                        ? 'primary'
                        : 'tertiary'
                    }
                    fullWidth
                    onClick={handleStartFlowClick}
                    dataCy="launchview_apply_without_auth"
                    size="md"
                  >
                    {t('Onboarding.LaunchViewLoanManualFlow')}
                  </Button>

                  {!marketOnlyHasEmailLogin &&
                    onboardingLoginMethods[market].map(
                      (loginMethod) =>
                        loginMethod.showOnLaunchViewPage && (
                          <Button
                            key={loginMethod.connectionName}
                            onClick={() =>
                              handleAuthClick(loginMethod.connectionName)
                            }
                            dataCy="launchview_start_with_auth_button"
                            fullWidth
                            size="md"
                          >
                            {chosenLoginMethod ===
                            loginMethod.connectionName ? (
                              <SpinnerLinear />
                            ) : (
                              t('Onboarding.LaunchViewLoanAuthButton', {
                                connection: loginMethod.label,
                              })
                            )}
                          </Button>
                        )
                    )}
                </>
              )}
            </FormBottomContentButtons>
            <PolicyAgreement centerAlign smallText />
          </FormBottomContent>
        </LaunchViewFormContent>
      </LaunchViewFormContainer>
    </>
  );
};

export default LaunchViewForm;
