import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILegacyOnboardingOwner, OwnerType } from '~/Interfaces';
import {
  ValidationContext,
  ValidationType,
} from '~/components/hoc/withValidation';
import Button, { ButtonType } from '~/components/shared/Button/button';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import DropDown from '~/components/shared/DropDown/DropDown';
import InputField from '~/components/shared/Input/InputField';
import { getPersonalNumberPlaceholder } from '~/constants/markets';
import { OwnerTypeEnum } from '~/enums';
import useTranslate from '~/hooks/useTranslate';
import {
  updateCardOfferUserData,
  UpdateCardOfferUserData_AddOwnerPlaceholder,
  UpdateCardOfferUserData_RemoveOwnerPlaceholder,
  updateCardOfferUserData_UpdateOwner,
} from '~/store/actions/cardOfferActions';
import { RootState } from '~/store/types/sharedTypes';

interface Props {
  companyType: string;
}

const CreditCardBeneficialOwnersCard: React.FC<Props> = (props) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const validationContext = useContext(ValidationContext);
  const {
    ownerStructure,
    applicantOwnerShare,
    beneficialOwners,
    cardOfferAlreadySigned,
  } = useSelector((state: RootState) => state.cardOffer.userData);
  const { market, language } = useSelector((state: RootState) => state.intl);

  const onOwnershipPercentageChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    dispatch(
      updateCardOfferUserData({
        applicantOwnerShare: e.target.value.trim(),
      })
    );
  };

  const onOwnerTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // upon change of the ownerType value, we want "yourOwnShares" input field to get revalidated, hence the following:
    validationContext.onChangeHOC({
      target: {
        name: 'ownershipPercentage',
        value: applicantOwnerShare,
        dataset: {
          validationType: `${'PercentageShare'}_${
            event.currentTarget.value
          }` as ValidationType,
        },
      },
    });
    dispatch(
      updateCardOfferUserData({
        ownerStructure: event.target.value as OwnerType,
      })
    );
  };

  const onAddOwnerClicked = () => {
    dispatch(UpdateCardOfferUserData_AddOwnerPlaceholder());
  };

  const onRemoveOwnerClicked = (id: string) => {
    if (beneficialOwners.length > 0) {
      dispatch(UpdateCardOfferUserData_RemoveOwnerPlaceholder(id));
    }
  };

  const onOwnnerNameChanged = (
    ownerId: string | undefined,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (ownerId)
      dispatch(
        updateCardOfferUserData_UpdateOwner(ownerId, 'fullName', e.target.value)
      );
  };

  const onOwnnerShareChanged = (
    ownerId: string | undefined,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (ownerId)
      dispatch(
        updateCardOfferUserData_UpdateOwner(
          ownerId,
          'ownerShare',
          e.target.value.trim()
        )
      );
  };

  const onOwnnerPersonalNumberChanged = (
    ownerId: string | undefined,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (ownerId)
      dispatch(
        updateCardOfferUserData_UpdateOwner(
          ownerId,
          'personalNumber',
          e.target.value
        )
      );
  };

  useEffect(() => {
    if (props.companyType === 'EF') {
      dispatch(
        updateCardOfferUserData({
          ownerStructure: 'OWN_MORE_THAN_75',
          applicantOwnerShare: '100',
        })
      );
    }
  }, []);

  if (props.companyType === 'EF') return null;

  return (
    <Card noPadding dataTestId="credit-card-beneficial-owners-card">
      <CardHeader
        value={translate('CreditCardCompanyOwnersCard.Title') as string}
      />
      <CardSection borderBottom borderTop>
        <DropDown
          name="ownerType"
          value={ownerStructure}
          disabled={cardOfferAlreadySigned}
          dropDownValues={[
            { label: `- ${translate('OwnerType')} - `, value: '' },
            {
              label: translate(
                'CreditCardCompanyOwnersCard.OwnerType1'
              ) as string,
              value: OwnerTypeEnum.MoreThan75,
            },
            {
              label: translate(
                'CreditCardCompanyOwnersCard.OwnerType2'
              ) as string,
              value: OwnerTypeEnum.MorePersonsOwn25,
            },
            {
              label: translate(
                'CreditCardCompanyOwnersCard.OwnerType3'
              ) as string,
              value: OwnerTypeEnum.NonOneOwn25,
            },
          ]}
          onChange={onOwnerTypeChange}
          validationType="OwnerType"
        />
        <InputField
          name="ownershipPercentage"
          placeholder={
            translate(
              'CreditCardCompanyOwnersCard.YourOwnSharesPlaceHolder'
            ) as string
          }
          value={applicantOwnerShare}
          disabled={cardOfferAlreadySigned}
          onChange={onOwnershipPercentageChanged}
          validationType={
            `${'PercentageShare'}_${ownerStructure}` as ValidationType
          }
        />
      </CardSection>
      {beneficialOwners.map((owner: ILegacyOnboardingOwner, index) => (
        <CardSection
          key={owner.id}
          title={`${translate('CompanyOwnersCard.AdditionalOwner')} #${Number(
            index + 1
          )}`}
          borderBottom
        >
          <InputField
            name={`otherOwnersName${index}`}
            value={owner.fullName}
            disabled={!owner.isAddedByUser || cardOfferAlreadySigned}
            onChange={(e) => onOwnnerNameChanged(owner.id, e)}
            validationType="FirstAndLastNameOwner"
            placeholder={translate('FullName') as string}
          />
          <InputField
            name={`otherOwnersShare${index}`}
            value={owner.ownerShare}
            disabled={!owner.isAddedByUser || cardOfferAlreadySigned}
            onChange={(e) => onOwnnerShareChanged(owner.id, e)}
            validationType={
              `${'PercentageShare'}_${ownerStructure}` as ValidationType
            }
            placeholder={
              translate(
                'CompanyOwnersCard.PercentageSharePlaceholder'
              ) as string
            }
          />
          <InputField
            name={`otherOwnersPersonalNumber${index}`}
            value={owner.personalNumber || ''}
            disabled={!owner.isAddedByUser || cardOfferAlreadySigned}
            onChange={(e) => onOwnnerPersonalNumberChanged(owner.id, e)}
            validationType="PersonalNumber"
            placeholder={getPersonalNumberPlaceholder(market, language)}
          />
          {!cardOfferAlreadySigned && (
            <Button
              mt={1}
              type={ButtonType.Outlined}
              disabled={cardOfferAlreadySigned}
              onClick={() => onRemoveOwnerClicked(String(owner.id))}
            >
              {translate('CreditCardCompanyOwnersCard.RemoveOwner') as string}
            </Button>
          )}
        </CardSection>
      ))}
      {!cardOfferAlreadySigned && (
        <CardSection>
          <Button
            type={ButtonType.Outlined}
            disabled={
              ownerStructure !== OwnerTypeEnum.MorePersonsOwn25 &&
              ownerStructure !== OwnerTypeEnum.NonOneOwn25
            }
            onClick={onAddOwnerClicked}
          >
            {translate('CreditCardCompanyOwnersCard.AddOwner') as string}
          </Button>
        </CardSection>
      )}
    </Card>
  );
};

export default CreditCardBeneficialOwnersCard;
