import { combineReducers } from '@reduxjs/toolkit';
import invoicesReducer from './invoicesReducer';
import userReducer from './userReducer';
import loansReducer from './loansReducer';
import loanOfferReducer from './loanOfferReducer';
import applyLoanReducer from './applyLoanReducer';
import aiiaReducer from './aiiaReducer';
import cardOfferReducer from './cardOfferReducer';
import eskatReducer from './eskatReducer';
import shippingDetailsReducer from './shippingDetailsReducer';
import loanOnboardingOfferSliceReducer from '../slices/loanOnboardingOffer.slice';
import klarnaKosmaReducer from './klarnaKosmaReducer';
import onboardingApplicationSliceReducer from '../slices/onboardingApplication.slice';
import globalErrorSliceReducer from '../slices/globalError.slice';
import helpPopupSliceReducer from '../slices/helpPopup.slice';
import matchedMediaSliceReducer from '../slices/matchedMedia.slice';
import intlSliceReducer from '../slices/intl.slice';
import globalModalSliceReducer from '../slices/globalModal.slice';
import cardOnboardingOfferSliceReducer from '../slices/cardOnboardingOffer.slice';
import { apis } from '~/store/apis/endpoints/apis';
import dashboardSlice from '~/store/slices/dashboard.slice';
import additionalSignerSliceReducer from '../slices/additionalSigner.slice';
import accountSlice from '~/store/slices/accounts.slice';

const rootReducer = combineReducers({
  ...apis,
  aiia: aiiaReducer,
  klarnaKosma: klarnaKosmaReducer,
  eskat: eskatReducer,
  user: userReducer,
  onboardingOffer: loanOnboardingOfferSliceReducer,
  invoices: invoicesReducer,
  loans: loansReducer,
  applyLoan: applyLoanReducer,
  loanOffer: loanOfferReducer,
  cardOffer: cardOfferReducer,
  shippingDetails: shippingDetailsReducer,
  globalModals: globalModalSliceReducer,
  onboardingApplication: onboardingApplicationSliceReducer,
  globalError: globalErrorSliceReducer,
  helpPopup: helpPopupSliceReducer,
  matchedMedia: matchedMediaSliceReducer,
  intl: intlSliceReducer,
  cardOnboardingOffer: cardOnboardingOfferSliceReducer,
  dashboard: dashboardSlice,
  additionalSigner: additionalSignerSliceReducer,
  account: accountSlice,
});

export default rootReducer;
