import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { theme } from '~/styles/themes';

interface Props
  extends Omit<
    React.ComponentPropsWithoutRef<'button'>,
    'value' | 'onMouseEnter'
  > {
  value: Date;
  selected: boolean;
  weekend: boolean;
  outside: boolean;

  onMouseEnter(date: Date, event: React.MouseEvent): void;

  disabled: boolean;
}

const StyledDay = styled.button`
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 2.5em;
  width: 2.5em;
  margin: 0.4em;
  border-radius: 0.2em;
  line-height: 2.5em;
  font-size: 0.7em;
  color: ${theme.colors.dark};
  user-select: none;
  font-family: 'Poppins', sans-serif;

  &:hover {
    background-color: ${theme.colors.backdrop};
  }

  &:disabled {
    pointer-events: none;
    color: ${theme.colors.secondaryGray};
  }

  &[data-selected] {
    background-color: ${theme.colors.secondary};
    color: white;
  }

  &[data-outside] {
    color: ${theme.colors.neutral};
  }
`;

const Day = forwardRef<HTMLButtonElement, Props>(
  ({ value, onMouseEnter, selected, outside, disabled, ...rest }, ref) => (
    <StyledDay
      {...rest}
      data-cy="date_picker_day"
      type="button"
      ref={ref}
      disabled={disabled}
      data-selected={(selected && !disabled) || undefined}
      data-outside={(outside && !disabled) || undefined}
      onMouseEnter={(event) => onMouseEnter(value, event)}
    >
      {value.getDate()}
    </StyledDay>
  )
);

export default Day;
