import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { StepConfigItem } from '~/Interfaces';
import { ClientType, CountryCode, FlowTypes } from '~/enums';
import { CompanyTypeCategory } from '~/interfaces/Onboarding';
import { stepConfigPerMarketFlowType } from '~/pages/Onboarding/components/stepConfigs';
import { RootState } from '../types/sharedTypes';

export const selectStepConfig = createSelector(
  [
    (state: RootState) => state.onboardingApplication.flowType,
    (state: RootState) => state.onboardingApplication.form.clientType,
    (state: RootState) => state.onboardingApplication.form.bankIntegration,
    (state: RootState) => state.onboardingApplication.form.bankFilesUploaded,
    (state: RootState) => state.intl.market,
    (state: RootState) => state.onboardingApplication.form.companyTypeCategory,
  ],
  (
    flowType,
    clientType,
    bankIntegration,
    bankFilesUploaded,
    market,
    companyTypeCategory
  ) => {
    const stepConfig = stepConfigPerMarketFlowType[market]?.[flowType]?.filter(
      (step: StepConfigItem) => {
        let hideStep = false;

        switch (step.name) {
          case 'personal_information_step':
            hideStep =
              clientType === ClientType.RLC &&
              flowType === FlowTypes.Authenticated &&
              market !== CountryCode.NL;
            break;
          case 'connect_to_bank_step':
            hideStep = !!(bankIntegration || bankFilesUploaded);
            break;
          case 'guarantor_selector_step':
            hideStep =
              companyTypeCategory === CompanyTypeCategory.SOLE_TRADER &&
              market !== CountryCode.BE;
            break;
          default:
            break;
        }

        return !hideStep;
      }
    );
    return stepConfig || [];
  }
);

export const useLoanOnboardingApplicationSelectors = () => ({
  stepConfig: useSelector(selectStepConfig),
});
