import React, { forwardRef, useContext, useEffect, useState } from 'react';
import InputField from '~/components/shared/Input/InputField';
import useModals from '~/hooks/useModals';
import Calendar, {
  CalendarProps,
} from '~/components/shared/DatePicker/Calendar';
import { FirstDayOfWeek } from '~/helpers/utils';
import useUncontrolled from '~/hooks/useUncontrolled';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '~/helpers/formatters.helper';
import 'dayjs/locale/sv';
import 'dayjs/locale/fi';
import 'dayjs/locale/fr';
import 'dayjs/locale/nb';
import 'dayjs/locale/nl-be';
import {
  FormStatus,
  SimpleEvent,
  ValidationContext,
  ValidationType,
} from '~/components/hoc/withValidation';
import useTranslate from '~/hooks/useTranslate';
import Stack from '~/components/shared/Layout/Stack';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';

dayjs.extend(customParseFormat);

interface DatePickerProps
  extends Omit<
      React.ComponentPropsWithoutRef<'input'>,
      'value' | 'defaultValue' | 'onChange'
    >,
    Omit<CalendarProps, 'onMonthChange' | 'onChange'> {
  value?: any;

  onChange?(value: Date | null): void;

  defaultValue?: Date;
  closeCalendarOnChange?: boolean;
  openDropdownOnClear?: boolean;
  validationType?: ValidationType;
  inputFormat?: string;
  initiallyOpened?: boolean;
  dateParser?: (value: string) => Date;
  name: string;
  firstDayOfWeek?: FirstDayOfWeek;
  allowFreeInput?: boolean;
  label?: string;
  rightText?: string | JSX.Element;
  useModal?: boolean;
  openOnFocus?: boolean;
  showValidationInfoWhenUntouched?: boolean;
  dataCy?: string;

  renderDay?(date: Date): React.ReactNode;
}

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      value,
      onChange,
      defaultValue,
      locale,
      inputFormat,
      nextMonthLabel,
      previousMonthLabel,
      closeCalendarOnChange,
      labelFormat = 'MMMM YYYY',
      dayClassName,
      dayStyle,
      disableOutsideEvents,
      minDate,
      maxDate,
      excludeDate,
      initialMonth,
      initiallyOpened,
      validationType,
      name,
      disabled,
      allowFreeInput,
      dateParser,
      firstDayOfWeek,
      onFocus,
      onBlur,
      amountOfMonths,
      allowLevelChange,
      initialLevel,
      hideOutsideDates,
      hideWeekdays,
      renderDay,
      type,
      weekendDays,
      yearLabelFormat,
      nextDecadeLabel,
      nextYearLabel,
      previousDecadeLabel,
      previousYearLabel,
      label,
      rightText,
      useModal = true,
      openOnFocus,
      showValidationInfoWhenUntouched,
      dataCy,
      ...others
    },
    ref
  ) => {
    const modals = useModals();
    const t = useTranslate();
    const validationContext = useContext(ValidationContext);
    const { validationErrors } = validationContext;
    const finalLocale = locale || 'en';
    const dateFormat = type === 'date' ? 'YYYY-MM-DD' : inputFormat;
    const [_value, setValue] = useUncontrolled<Date>({
      value,
      defaultValue,
      finalValue: undefined,
      onChange,
    });
    const [calendarMonth, setCalendarMonth] = useState(
      _value || initialMonth || new Date()
    );
    const [focused, setFocused] = useState(false);
    const [touched, setTouched] = useState(false);
    const [openCalendarDropdown, setOpenCalendarDropdown] = useState(false);
    const [lastValidValue, setLastValidValue] = useState(defaultValue ?? null);
    const [inputState, setInputState] = useState(
      _value instanceof Date
        ? capitalizeFirstLetter(
            dayjs(_value).locale(finalLocale).format(dateFormat)
          )
        : ''
    );

    const validationErrorShouldBeShown =
      ((!openCalendarDropdown && touched && value) ||
        validationContext.formStatus === FormStatus.SUBMITTED) &&
      validationType &&
      validationErrors[name];

    const validationInfoShouldBeShown =
      showValidationInfoWhenUntouched && !value;

    let validationMessageType: 'error' | 'info' | null = null;
    if (validationErrorShouldBeShown) {
      validationMessageType = 'error';
    } else if (validationInfoShouldBeShown) {
      validationMessageType = 'info';
    }

    useEffect(() => {
      if (value === null && !focused) {
        setInputState('');
      } else {
        setInputState(value);
      }

      if (value instanceof Date && !focused) {
        setInputState(
          capitalizeFirstLetter(
            dayjs(value).locale(finalLocale).format(dateFormat)
          )
        );
      }
    }, [value, focused]);

    const handleValueChange = (date: Date) => {
      setValue(date);
      setInputState(
        capitalizeFirstLetter(
          dayjs(date).locale(finalLocale).format(dateFormat)
        )
      );
      useModal
        ? closeCalendarOnChange && modals.closeModal('datePicker-modal')
        : setOpenCalendarDropdown(false);
    };

    const parseDate = (date: string) =>
      dateParser
        ? dateParser(date)
        : dayjs(date, dateFormat, finalLocale).toDate();

    const setDateFromInput = () => {
      let date = typeof _value === 'string' ? parseDate(_value) : _value;

      if (maxDate && dayjs(date).isAfter(maxDate)) {
        date = maxDate;
      }

      if (minDate && dayjs(date).isBefore(minDate)) {
        date = minDate;
      }

      if (dayjs(date).isValid()) {
        setValue(date);
        setLastValidValue(date);
        setInputState(
          capitalizeFirstLetter(
            dayjs(date).locale(finalLocale).format(dateFormat)
          )
        );
        setCalendarMonth(date);
      } else {
        setValue(lastValidValue!);
      }
    };

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      typeof onBlur === 'function' && onBlur(event);
      setFocused(false);
      setTouched(true);

      if (allowFreeInput) {
        setDateFromInput();
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && allowFreeInput) {
        modals.closeAll();
        setDateFromInput();
      }
    };

    const handleOpen = () => {
      if (useModal) {
        modals.openModal({
          id: 'datePicker-modal',
          children: (
            <Calendar
              locale={finalLocale}
              nextMonthLabel={nextMonthLabel}
              previousMonthLabel={previousMonthLabel}
              month={allowFreeInput ? calendarMonth : undefined}
              initialMonth={
                initialMonth || (_value instanceof Date ? _value : new Date())
              }
              onMonthChange={setCalendarMonth}
              value={_value instanceof Date ? _value : dayjs(_value).toDate()}
              onChange={handleValueChange}
              labelFormat={labelFormat}
              dayClassName={dayClassName}
              dayStyle={dayStyle}
              disableOutsideEvents={disableOutsideEvents}
              minDate={minDate}
              maxDate={maxDate}
              excludeDate={excludeDate}
              fullWidth
              firstDayOfWeek={firstDayOfWeek}
              preventFocus={allowFreeInput}
              amountOfMonths={amountOfMonths}
              allowLevelChange={allowLevelChange}
              initialLevel={initialLevel}
              hideOutsideDates={hideOutsideDates}
              hideWeekdays={hideWeekdays}
              renderDay={renderDay}
              weekendDays={weekendDays}
              yearLabelFormat={yearLabelFormat}
              nextDecadeLabel={nextDecadeLabel}
              nextYearLabel={nextYearLabel}
              previousDecadeLabel={previousDecadeLabel}
              previousYearLabel={previousYearLabel}
            />
          ),
          heightAuto: true,
        });
      } else {
        setOpenCalendarDropdown(true);
      }
    };

    const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      typeof onFocus === 'function' && onFocus(event);
      if (openOnFocus) {
        handleOpen();
      }
      setFocused(true);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const date = parseDate(event.target.value);
      if (dayjs(date).isValid()) {
        setValue(date);
        setLastValidValue(date);
        setInputState(event.target.value);
        setCalendarMonth(date);
      } else {
        setInputState(event.target.value);
      }
    };

    useEffect(() => {
      const fakeEvent: SimpleEvent = {
        target: {
          name,
          value: _value || defaultValue,
          dataset: { validationType },
        },
      };
      validationContext.onChangeHOC(fakeEvent);

      return () => {
        validationContext.removePropertyFromValidationErrors(name);
      };
    }, [_value, defaultValue]);

    return (
      <Stack spacing="xs">
        <InputField
          dataCy={dataCy}
          name="date-readonly"
          ref={ref}
          onClick={handleOpen}
          onChange={handleChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          data-validation-type={validationType}
          value={inputState}
          readOnly
          label={label}
          rightText={rightText}
          className={
            validationMessageType ? `has-${validationMessageType}` : ''
          }
          {...others}
        />

        {validationMessageType && (
          <ValidationErrorMessage type={validationMessageType}>
            {t(
              `${
                validationMessageType === 'error'
                  ? 'ValidationErrors'
                  : 'ValidationInfo'
              }.${validationType?.split('_')[0]}`
            )}
          </ValidationErrorMessage>
        )}
        {openCalendarDropdown && (
          <Calendar
            locale={finalLocale}
            nextMonthLabel={nextMonthLabel}
            previousMonthLabel={previousMonthLabel}
            month={allowFreeInput ? calendarMonth : undefined}
            initialMonth={
              initialMonth || (_value instanceof Date ? _value : new Date())
            }
            onMonthChange={setCalendarMonth}
            value={_value instanceof Date ? _value : dayjs(_value).toDate()}
            onChange={handleValueChange}
            labelFormat={labelFormat}
            dayClassName={dayClassName}
            dayStyle={dayStyle}
            disableOutsideEvents={disableOutsideEvents}
            minDate={minDate}
            maxDate={maxDate}
            excludeDate={excludeDate}
            fullWidth
            firstDayOfWeek={firstDayOfWeek}
            preventFocus={allowFreeInput}
            amountOfMonths={amountOfMonths}
            allowLevelChange={allowLevelChange}
            initialLevel={initialLevel}
            hideOutsideDates={hideOutsideDates}
            hideWeekdays={hideWeekdays}
            renderDay={renderDay}
            weekendDays={weekendDays}
            yearLabelFormat={yearLabelFormat}
            nextDecadeLabel={nextDecadeLabel}
            nextYearLabel={nextYearLabel}
            previousDecadeLabel={previousDecadeLabel}
            previousYearLabel={previousYearLabel}
          />
        )}
      </Stack>
    );
  }
);

DatePicker.defaultProps = {
  closeCalendarOnChange: true,
  inputFormat: 'DD/MM/YYYY',
  initiallyOpened: false,
  firstDayOfWeek: 'monday',
  openDropdownOnClear: false,
};

export default DatePicker;
