import { z } from 'zod';
import { SignerType } from '~/enums';
import { klarnaAccountSchema } from './KlarnaKosma';

export const LoanApplicationType = z.union([
  z.literal('REPEATED_LOAN'),
  z.literal('FIRST_LOAN'),
  z.literal('CREDIT_LIMIT_CHANGE'),
]);

const loanOnboardingOfferGetOfferResDTOSchema = z.strictObject({
  offer: z.strictObject({
    min_payout_amount: z.number(),
    default_payout_amount: z.number(),
    max_payout_amount: z.number(),
    min_term: z.number(),
    default_term: z.number(),
    max_term: z.number(),
    amount_cannot_be_changed: z.boolean(),
    current_loan_amount_left: z.number().nullable(),
    type: LoanApplicationType,
    answered_date: z.string(),
    applicant_personal_id: z.string().nullable(),
    application_id: z.number(),
    bank_account: z.string().nullable(),
    bank_clearing: z.string().nullable(),
    campaign_discount: z.number().nullable(),
    campaign_principal_free_months: z.number().nullable(),
    campaign_interest_rate_discount: z.number().nullable(),
    campaign_type: z.number().nullable(),
    client_id: z.number(),
    company_no: z.string(),
    company_type: z.string(),
    date_of_birth: z.string().nullable(),
    email: z.string().email(),
    phone: z.string(),
    interest_percent: z.number(),
    landing_url: z.string().url().nullable().optional(),
    language: z.string().nullable(),
    lender_name: z.string(),
    lender_rep: z.string(),
    loan_purpose_manual: z.string().nullable(),
    loan_purpose: z.string().nullable(),
    owners: z.array(z.unknown()).nullable(), // we dont care about these owners, so we can just use z.unknown()
    personal_id: z.string().nullable(),
    r_partner_id: z.number().optional(),
    reason: z.string().nullable(),
    signers: z
      .array(
        z.strictObject({
          name: z.string(),
          email: z.string().email().nullable().optional(),
          phone: z.string().nullable().optional(),
          signer_id: z.number(),
          type: z.nativeEnum(SignerType),
        })
      )
      .nullable(),
    granter: z.enum(['qred', 'nordiska']),
    granter_bank_id: z.number(),
  }),
  accounts: z.array(klarnaAccountSchema).optional(),
});

const loanOnboardingOfferGetBeneficialOwnersResDTOSchema = z.strictObject({
  beneficialOwners: z.array(
    z.strictObject({
      fullName: z.string(),
      personalNumber: z.string().optional().nullable(),
      dateOfBirth: z.string().optional(),
      residenceCountry: z.string().optional(),
      ownership: z
        .string()
        .refine((ownership) => {
          return Number(ownership);
        }, 'ownership must be a valid number')
        .optional(),
    })
  ),
  alternativeBeneficialOwners: z.array(
    z.strictObject({
      fullName: z.string(),
      personalNumber: z.string().optional(),
      dateOfBirth: z.string().optional(),
      residenceCountry: z.string().optional(),
    })
  ),
});

const loanOnboardingOfferPostOfferResDTOSchema = z.strictObject({
  isThankYouPage: z.boolean(),
  responseForLogs: z.string(),
  signURL: z.string(),
  thankYouPage: z.boolean(),
  message: z.string().optional(), // this is only sent back in case of an error
});

export const loanOnboardingOfferDTOSchemas = {
  getOfferRes: loanOnboardingOfferGetOfferResDTOSchema,
  getBeneficialOwnersRes: loanOnboardingOfferGetBeneficialOwnersResDTOSchema,
  postOfferRes: loanOnboardingOfferPostOfferResDTOSchema,
};
