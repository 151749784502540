import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlowTypes, OnboardingAuthType } from '~/enums';
import { onboardingLoginMethods } from '~/helpers/authentication.helper';
import useModals from '~/hooks/useModals';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import useTranslate from '~/hooks/useTranslate';
import AuthPopup from '~/pages/Onboarding/components/AuthPopup/AuthPopup';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { patchApplication } from '~/store/actions/onboardingActions';
import { updateForm } from '~/store/slices/onboardingApplication.slice';
import { RootState } from '~/store/types/sharedTypes';

const useSubmit = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const modals = useModals();

  const { loginWithRedirect } = useAuth0();

  const {
    marketRequiresLoginBeforeSubmittingApplication,
  } = useOnboardingMarketConfig();

  const {
    onboardingApplication: { flowType },
    intl: { market },
  } = useSelector((state: RootState) => state);

  const onAuthMethodSelected = useCallback(
    async (connectionName: string) => {
      const url = new URL(window.location.href);
      url.searchParams.set('authType', OnboardingAuthType.ManualSubmit);
      await dispatch(patchApplication({ currentStepCompleted: false }));

      loginWithRedirect({
        authorizationParams: {
          connection: connectionName,
          redirectUri: url.toString(),
        },
      });
    },
    [dispatch, flowType, loginWithRedirect, market]
  );

  const openAuthPopup = () => {
    modals.openModal({
      id: 'authPopup-modal',
      header: t('Onboarding.AuthPopupTitle') as string,
      children: (
        <AuthPopup
          loginMethods={onboardingLoginMethods[market]}
          onAuthMethodSelected={onAuthMethodSelected}
          bottomCTA={{
            text: t('Cancel') as string,
            action: () => {
              modals.closeModal('authPopup-modal');
            },
          }}
        />
      ),
      size: 'xs',
      heightAuto: true,
      hideCloseButton: true,
    });
  };

  const onSubmit = useCallback(async () => {
    dispatch(
      pushToGtmOnboardingAction({ actionName: 'submit_button_pressed' })
    );

    if (
      !marketRequiresLoginBeforeSubmittingApplication &&
      flowType === FlowTypes.Manual
    ) {
      dispatch(updateForm({ applicantIdentified: true }));
    }

    if (
      flowType === FlowTypes.Manual &&
      marketRequiresLoginBeforeSubmittingApplication
    ) {
      if (onboardingLoginMethods[market].length > 1) {
        openAuthPopup();
      } else {
        onAuthMethodSelected(onboardingLoginMethods[market][0].connectionName);
      }
    } else {
      dispatch(patchApplication({ finalized: true }));
    }
  }, [dispatch, flowType, loginWithRedirect, market]);

  return onSubmit;
};

export default useSubmit;
