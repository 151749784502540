import {
  Dispatch,
  isRejected,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { RejectedRTKAction } from '~/store/middlewares/sentryNotifier/sentryNotifier.type';
import { notifications } from '@qred/components-library';
import {
  BaseQueryError,
  LambdaQueryError,
} from '~/store/apis/baseQuery/baseQuery.types';
import { isWhitelistedEndpoint } from '~/store/middlewares/middleware.helper';
import { RootState } from '~/store';

const notifyExtendedErrorOnDevelopment = (
  rejectedRTKAction: RejectedRTKAction
) => {
  if (import.meta.env.MODE !== 'development') {
    return;
  }

  const payload = (rejectedRTKAction.payload as BaseQueryError<LambdaQueryError>)
    ?.data;

  if (!payload) {
    return;
  }

  notifications.show({
    state: 'critical',
    message: `(DEV) ${payload?.message || payload}`,
  });
};

const notifySimpleError = (
  rejectedRTKAction: RejectedRTKAction,
  state: RootState
) => {
  if (isWhitelistedEndpoint(rejectedRTKAction.meta.arg.endpointName)) {
    return;
  }

  notifications.show({
    state: 'critical',
    autoClose: false,
    message: 'Something went wrong',
  });
};

export const rtkErrorNotifierMiddleware: Middleware = (
  api: MiddlewareAPI<Dispatch, RootState>
) => (next) => (action) => {
  if (!isRejected(action)) {
    return next(action);
  }

  const state = api.getState();

  const rejectedRTKAction = action as RejectedRTKAction;

  if (!rejectedRTKAction.meta.arg.endpointName) {
    return next(action);
  }

  notifyExtendedErrorOnDevelopment(rejectedRTKAction);

  notifySimpleError(rejectedRTKAction, state);

  return next(action);
};
