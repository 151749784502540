import { Typography } from '@qred/components-library';
import React from 'react';
import { ApplicationDescriptionItemWrapper } from './ApplicationDescriptionItem.styled';

interface ApplicationDescriptionItemProps {
  title: string;
  text: string;
}

const ApplicationDescriptionItem = ({
  title,
  text,
}: ApplicationDescriptionItemProps) => (
  <ApplicationDescriptionItemWrapper>
    <Typography size="lg" weight={600}>
      {title}
    </Typography>
    <Typography>{text}</Typography>
  </ApplicationDescriptionItemWrapper>
);

export default ApplicationDescriptionItem;
