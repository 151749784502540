import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import constVars from '~/constants/constVars';
import { getCookieValue } from '~/helpers/utils';
import useHubspotChat from '~/hooks/useHubspotChat';
import { RootState } from '~/store/types/sharedTypes';

// which subpages should not have chat loaded on page load unless user has interacted with it?
const excludedPages = [constVars.ROUTE_LOGIN, constVars.ROUTE_ONBOARDING];

const HubspotChat = () => {
  const { language, languageIsSet } = useSelector(
    (state: RootState) => state.intl
  );
  const { pathname } = useLocation();
  const [isChatScriptLoaded, setIsChatScriptLoaded] = useState(false);
  const { setHubspotLocaleParam, refreshChat } = useHubspotChat();

  // if hs-messages-is-open cookie exists, regardless of value, it means that user interacted with chat
  // less than 30 minutes ago, which means we should load chat regardless of pathname.
  // NOTE: This cookie is not set in localhost, so testing chat should be done in test environments
  const hasInteractedWithChat = !!getCookieValue('hs-messages-is-open');
  const chatShouldBeActive = useMemo(() => {
    const pageIsExcluded = excludedPages.some((excludedPage) =>
      pathname.includes(excludedPage)
    );

    return hasInteractedWithChat || !pageIsExcluded;
  }, [pathname, hasInteractedWithChat]);

  const onConversationsAPIReady = () => {
    setIsChatScriptLoaded(true);
  };

  useEffect(() => {
    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  }, []);

  useEffect(() => {
    if (languageIsSet) {
      window.hsConversationsSettings = {
        enableWidgetCookieBanner: true,
        disableAttachment: true,
        loadImmediately: chatShouldBeActive,
      };
      if (chatShouldBeActive) {
        setHubspotLocaleParam();
      }
      const script = document.createElement('script');
      document.body.appendChild(script);
      script.src = '//js.hs-scripts.com/3462817.js';
      script.async = true;
    }
  }, [languageIsSet]);

  useEffect(() => {
    if (isChatScriptLoaded && languageIsSet && chatShouldBeActive) {
      refreshChat();
    }
  }, [languageIsSet, language, isChatScriptLoaded]);

  return <></>;
};

export default HubspotChat;
