import {
  getDatePlaceholder,
  getMarketHasAddress,
  getPersonalNumberPlaceholder,
  personalNumberIsOnlyNumbers,
} from '~/constants/markets';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import useTranslate from '~/hooks/useTranslate';
import { onChangeInputEvent } from '~/types/types';
import useModals from '~/hooks/useModals';
import {
  Button,
  DatePicker,
  Stack,
  TextInput,
  Typography,
} from '@qred/components-library';
import { v4 as uuidv4 } from 'uuid';
import {
  beneficialOwnerPercentageValidator,
  fullNameValidator,
  personalNumberValidator,
  validateOwnershipPercentage,
  validateYearOfBirth,
} from '~/helpers/validators.helper';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';
import { useCardOnboardingOfferSelectors } from '~/store/selectors/cardOnboardingOffer.selector';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {
  personAlreadyExistsInList,
  personsAreTheSame,
} from '~/helpers/onboarding.helper';
import AMLFields from '../../../AMLFields/AMLFields';
import OwnershipPercentageSelect from '../OwnershipPercentageSelect';
import { IOnboardingBeneficialOwner } from '~/interfaces/Onboarding';
import {
  addAdditionalOwner,
  removeAdditionalOwnerById,
  updateAdditionalOwner,
} from '~/store/slices/cardOnboardingOffer.slice';

interface OwnerManagementModalProps {
  initialOwner?: IOnboardingBeneficialOwner;
  isAlternativeBeneficialOwner?: boolean;
  ownershipPercentageAsRanges?: boolean;
  askForPersonalNumber?: boolean;
  askForDateOfBirth?: boolean;
  askForYearOfBirth?: boolean;
}

const OwnerManagementModal = ({
  initialOwner,
  isAlternativeBeneficialOwner,
  ownershipPercentageAsRanges,
  askForPersonalNumber,
  askForDateOfBirth,
  askForYearOfBirth,
}: OwnerManagementModalProps) => {
  const dispatch = useDispatch();
  const {
    cardOnboardingOffer: { form, overview },
    matchedMedia: { mobile },
    intl: { market, language },
  } = useSelector((state: RootState) => state);
  const translate = useTranslate();

  const formMethods = useForm<IOnboardingBeneficialOwner>({
    defaultValues: {
      ...(initialOwner || {
        id: uuidv4(),
        isAlternativeBeneficialOwner,
      }),
    },
    mode: 'onTouched',
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = formMethods;
  const modals = useModals();
  const { totalAmountOfShares } = useCardOnboardingOfferSelectors();

  const isNewOwner = !initialOwner;
  const showAddressFields = getMarketHasAddress(market);

  const trackFieldChange = (event: string | onChangeInputEvent) => {
    let name;
    if (typeof event === 'string') {
      name = event;
    } else {
      name = event.target.name;
    }

    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: isNewOwner
          ? `modal_new_owner_${name}_change`
          : `modal_edit_owner_${name}_change`,
      })
    );
  };

  const checkForDuplicates = (formData: IOnboardingBeneficialOwner) => {
    let error = false;
    if (!isAlternativeBeneficialOwner && !overview.beneficialOwners.length) {
      if (personsAreTheSame(formData, form.applicant)) {
        setError('root.SubmitError', {
          type: 'manual',
          message: translate('ValidationErrors.OwnerSameAsApplicant') as string,
        });
        error = true;
      }
    }

    let existingOwners = form.owners;
    if (initialOwner?.id) {
      existingOwners = form.owners.filter((o) => o.id !== initialOwner.id);
    }

    if (personAlreadyExistsInList(existingOwners, formData)) {
      setError('root.SubmitError', {
        type: 'manual',
        message: translate('ValidationErrors.OwnerAlreadyExists') as string,
      });
      error = true;
    }
    return error;
  };

  const saveOwner: SubmitHandler<IOnboardingBeneficialOwner> = (formData) => {
    const hasSubmissionError = checkForDuplicates(formData);

    if (!hasSubmissionError) {
      dispatch(
        isNewOwner
          ? addAdditionalOwner(formData)
          : updateAdditionalOwner(formData)
      );

      dispatch(
        pushToGtmCardOnboardingAction({
          actionName: isNewOwner
            ? 'modal_new_owner_save_pressed'
            : 'modal_edit_owner_save_pressed',
        })
      );
      modals.closeAll();
    }
  };

  const removeOwner = () => {
    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: 'modal_card_offer_delete_owner_pressed',
      })
    );
    const id = getValues('id');
    dispatch(removeAdditionalOwnerById(id));
    modals.closeAll();
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(saveOwner)}>
        <Stack>
          <TextInput
            label={translate('OnboardingOffer.AdditionalOwnerFullName')}
            dataCy="onboarding_offer_add_new_owner_name"
            placeholder={translate('FullName') as string}
            {...register('fullName', {
              required: translate('ValidationErrors.Required') as string,
              validate: (value) => {
                if (fullNameValidator(value)) {
                  return translate('ValidationErrors.FullName') as string;
                }

                return true;
              },
              onBlur: (e: onChangeInputEvent) => {
                clearErrors('root.SubmitError');
                trackFieldChange(e);
              },
            })}
            error={errors.fullName?.message}
          />
          {askForPersonalNumber && (
            <TextInput
              label={translate('PersonalNumber')}
              dataCy="onboarding_offer_add_new_owner_personal_number"
              placeholder={getPersonalNumberPlaceholder(market, language)}
              inputMode={
                personalNumberIsOnlyNumbers(market) ? 'numeric' : 'text'
              }
              {...register('personalNumber', {
                required: translate('ValidationErrors.Required') as string,
                validate: (value) => {
                  if (personalNumberValidator(value)) {
                    return translate(
                      'ValidationErrors.PersonalNumber'
                    ) as string;
                  }
                  return true;
                },
                onBlur: (e: onChangeInputEvent) => {
                  clearErrors('root.SubmitError');
                  trackFieldChange(e);
                },
              })}
              error={errors.personalNumber?.message}
            />
          )}

          {askForDateOfBirth && (
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: translate('ValidationErrors.Required') as string,
              }}
              render={({ field, fieldState }) => (
                <DatePicker
                  label={translate('DateOfBirth')}
                  maxDate={new Date()}
                  placeholder={getDatePlaceholder(market, language)}
                  onChange={(value) => {
                    trackFieldChange(field.name);
                    field.onChange(value);
                    clearErrors('root.SubmitError');
                  }}
                  valueFormat="DD-MM-YYYY"
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}

          {askForYearOfBirth && (
            <TextInput
              label={translate('YearOfBirth')}
              inputMode="numeric"
              {...register('yearOfBirth', {
                required: translate('ValidationErrors.Required') as string,
                validate: (value) => {
                  if (value && validateYearOfBirth(value)) {
                    return translate('ValidationErrors.YearOfBirth') as string;
                  }
                  return true;
                },
                onBlur: (e: onChangeInputEvent) => {
                  clearErrors('root.SubmitError');
                  trackFieldChange(e);
                },
              })}
              error={errors.yearOfBirth?.message}
            />
          )}

          {!isAlternativeBeneficialOwner && (
            <>
              {ownershipPercentageAsRanges ? (
                <Controller
                  name="percentage"
                  control={control}
                  rules={{
                    required: translate('ValidationErrors.Required') as string,
                    validate: (value) =>
                      validateOwnershipPercentage(value, translate),
                  }}
                  render={({ field, fieldState }) => (
                    <OwnershipPercentageSelect
                      onChange={(value) => {
                        trackFieldChange(field.name);
                        field.onChange(value?.value || '');
                        trigger('percentage');
                      }}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              ) : (
                <TextInput
                  label={translate('OnboardingOffer.SharesPercentages')}
                  dataCy="onboarding_offer_add_new_owner_name"
                  rightSection="%"
                  placeholder={
                    translate('OnboardingOffer.moreThan25%') as string
                  }
                  {...register('percentage', {
                    required: translate('ValidationErrors.Required') as string,
                    validate: (value) =>
                      validateOwnershipPercentage(value, translate),
                    onBlur: trackFieldChange,
                  })}
                  error={errors.percentage?.message}
                />
              )}
            </>
          )}

          <AMLFields
            trackFieldChange={trackFieldChange}
            showAddressFields={showAddressFields}
          />

          <Stack spacing="sm" mt="2em">
            {!isNewOwner && (
              <Button
                variant="secondary"
                onClick={removeOwner}
                fullWidth={mobile}
                size="md"
              >
                {translate('OnboardingOffer.DeleteAdditionalOwner')}
              </Button>
            )}

            <Stack>
              {errors.root?.SubmitError && (
                <Typography color="error.700" size="sm">
                  {errors.root?.SubmitError?.message}
                </Typography>
              )}

              <Button
                variant="primary"
                dataCy="onboarding_offer_add_new_owner_modal_button"
                fullWidth={mobile}
                size="md"
                type="submit"
                disabled={isSubmitting || isSubmitSuccessful}
              >
                {translate('Save')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default OwnerManagementModal;
