import React from 'react';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import { SvgSrc } from '~/components/shared/Icon/Icon';
import { formatAmount } from '~/helpers/formatters.helper';
import useTranslate from '~/hooks/useTranslate';

interface Props {
  isSigner: boolean;
  companyName?: string;
  offeredCreditLimit: number;
}

const CreditCardDetailsCard: React.FC<Props> = (props) => {
  const translate = useTranslate();
  return (
    <Card noPadding dataTestId="credit-card-details-card">
      <CardHeader value={translate('CreditCardDetailsCard.Title') as string} />
      {props.isSigner && (
        <CardSection
          title={translate('CreditCardDetailsCard.CompanyName') as string}
          value={props.companyName}
          iconSrc={SvgSrc.Building}
          borderTop
        />
      )}
      <CardSection
        title={translate('CreditCardDetailsCard.ApprovedCredit') as string}
        value={formatAmount(props.offeredCreditLimit)}
        iconSrc={SvgSrc.HandHoldingUsd}
        borderTop
      />

      <CardSection
        title={translate('CreditCardDetailsCard.YearlyMonthlyFee') as string}
        value={formatAmount(0)}
        iconSrc={SvgSrc.Coins}
        borderTop
      />

      <CardSection
        title={translate('CreditCardDetailsCard.PlasticCardFee') as string}
        value={formatAmount(0)}
        iconSrc={SvgSrc.Coins}
        borderTop
      />
    </Card>
  );
};

export default CreditCardDetailsCard;
