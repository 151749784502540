import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CountryCode } from '~/enums';
import {
  defaultLanguagePerMarket,
  languageIsValidForMarket,
} from '~/helpers/market.helper';
import { intlInitialState } from '../initialState';
import { Language } from '../types/sharedTypes';

const intlSlice = createSlice({
  name: 'intl',
  initialState: intlInitialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
      state.languageIsSet = true;
      localStorage.setItem('qredLanguage', action.payload);
    },
    setMarket: (state, action: PayloadAction<CountryCode>) => {
      state.market = action.payload;
      state.marketIsSet = true;
      localStorage.setItem('qredMarket', action.payload);
      window.__QRED_MARKET__ = action.payload.toUpperCase();
    },
  },
});

export const { setLanguage, setMarket } = intlSlice.actions;

export default intlSlice.reducer;

export const defaultLanguageToMarket = (market: CountryCode) => {
  const language = defaultLanguagePerMarket[market];

  if (!language) {
    throw new Error('Invalid market');
  }

  return setLanguage(language);
};

export const setPublicPagesIntl = (
  market: CountryCode,
  language: Language | null
) => (dispatch: Dispatch) => {
  if (market) {
    dispatch(setMarket(market));
    if (language) {
      if (market === CountryCode.BE && language === Language.Dutch) {
        dispatch(setLanguage(Language.DutchBE));
      } else {
        languageIsValidForMarket(language, market)
          ? dispatch(setLanguage(language))
          : dispatch(defaultLanguageToMarket(market));
      }
    } else {
      dispatch(defaultLanguageToMarket(market));
    }
  }
};
