import React from 'react';
import { Typography, Stack } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import { BaseArrangement } from '~/styles/BaseStyledComponents';

interface StepHeaderProps {
  title?: string;
  description?: string;
  shouldTranslate?: boolean;
}

const StepHeader = ({
  title,
  description,
  shouldTranslate = true,
}: StepHeaderProps) => {
  const translate = useTranslate();

  return (
    <>
      {(title || description) && (
        <Stack>
          {!!title && (
            <Typography
              weight={600}
              size="xl"
              lineHeight="xxl"
              mb={!description ? 10 : undefined}
              mt="xs"
              data-testid="title"
            >
              {shouldTranslate ? translate(title) : title}
            </Typography>
          )}
          {!!description && (
            <BaseArrangement>
              <Typography data-testid="description">
                {shouldTranslate ? translate(description) : description}
              </Typography>
            </BaseArrangement>
          )}
        </Stack>
      )}
    </>
  );
};

export default StepHeader;
