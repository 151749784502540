import { captureException, SeverityLevel, withScope } from '@sentry/react';
import constVars from '~/constants/constVars';

type Product = 'onboarding' | 'loan' | 'card' | 'HubspotChat';

export interface Tags {
  product?: Product;
  contractId?: number;
  clientId?: number;
  referrerUrl?: string;
  organizationNumber?: string;
  pn?: string;
  applicationUuid?: string;
  public?: boolean;
  lookupOrgNr?: string;
  companyIds?: string;
}

const getProductFromUrl = (): Product => {
  const path = window.location.pathname;

  switch (true) {
    case path.includes(constVars.ROUTE_ONBOARDING):
    case path.includes(constVars.ROUTE_ONBOARDING_OFFER):
    case path.includes(constVars.ROUTE_CARD_ONBOARDING_OFFER):
    case path.includes(constVars.ROUTE_ADDITIONAL_SIGNER_AUTH):
    case path.includes(constVars.ROUTE_LOAN_ADDITIONAL_SIGNER):
    case path.includes(constVars.ROUTE_CARD_ADDITIONAL_SIGNER):
    case path.includes(constVars.ROUTE_KOSMA_PUBLIC):
      return 'onboarding';
    case path.includes(constVars.ROUTE_CREATING_CARD):
    case path.includes(constVars.ROUTE_CARD_SIGNED):
    case path.includes(constVars.ROUTE_CARD_OFFER):
    case path.includes(constVars.ROUTE_CARD_CREATED):
    case path.includes(constVars.ROUTE_CARD_CREDIT_LIMIT_INCREASED):
    case path.includes(constVars.ROUTE_PUBLIC_CARD_OFFER):
      return 'card';
    default:
      return 'loan';
  }
};

export function logToSentry(
  error: any,
  category: string,
  tags?: Tags,
  level: SeverityLevel = 'error'
) {
  let extraMessage = 'undefined';

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    extraMessage = JSON.stringify(error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    extraMessage = JSON.stringify(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error: ', error);
    console.log('Error message: ', error.message);
  }

  withScope((scope) => {
    const productFromUrl = getProductFromUrl();

    scope
      .setTransactionName(category)
      .setFingerprint([String(error.message), ...(category ? [category] : [])])
      .setExtra('ExtraMessage', extraMessage)
      .setLevel(level)
      .setTag('product', !tags?.product && productFromUrl)
      .setTags({ ...tags, identifier: category });

    captureException(error);
  });
}
