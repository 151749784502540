import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageTitle from '~/components/shared/PageTitle/PageTitle';
import constVars from '~/constants/constVars';
import useTranslate from '~/hooks/useTranslate';

const ThankYouPageTitle = () => {
  const translate = useTranslate();

  return (
    <Switch>
      <Route
        path={[
          `/${constVars.ROUTE_THANK_YOU}/cardoffer`,
          `/${constVars.ROUTE_THANK_YOU}/${constVars.ROUTE_CARD_SIGNED}`,
        ]}
      >
        <PageTitle mb={2}>{translate('ThankYouPageTitle.CardOffer')}</PageTitle>
      </Route>

      <Route
        path={[
          `/${constVars.ROUTE_THANK_YOU}/set-pin-code`,
          `/${constVars.ROUTE_THANK_YOU}/${constVars.ROUTE_CARD_CREATED}`,
        ]}
      >
        <PageTitle mb={2}>
          {translate('ThankYouPageTitle.SetPinCode')}
        </PageTitle>
      </Route>
    </Switch>
  );
};

export default ThankYouPageTitle;
