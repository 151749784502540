import { AppThunk, GetStateFunc } from '~/store/types/sharedTypes';
import { Dispatch } from 'redux';
import { logToSentry, Tags } from '~/helpers/loggers.helper';
import { SeverityLevel } from '@sentry/react';

export const pushToSentryAction = (
  err: any,
  category: string,
  tags?: Tags,
  level?: SeverityLevel
): AppThunk => (_dispatch: Dispatch, getState: GetStateFunc) => {
  const state = getState();

  logToSentry(
    err,
    category,
    {
      ...tags,
      ...(state.user.activeCompany &&
        !tags?.clientId && {
          clientId: state.user.activeCompany.id,
        }),
    },
    level
  );
};
