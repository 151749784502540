import { CountryCode } from '~/enums';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import { SvgSrc } from '~/components/shared/Icon/Icon';
import useTranslate from '~/hooks/useTranslate';
import { openQredCardPricesPage } from '~/services/links';
import { RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';

const Link = styled.a`
  color: ${theme.colors.primaryGreen};
  border-bottom: 0.1em solid ${theme.colors.primaryGreen};
`;

interface Props {
  interest: number;
  market: CountryCode;
}

const CreditCardUsageCard: React.FC<Props> = (props) => {
  const translate = useTranslate();
  const language = useSelector((state: RootState) => state.intl.language);

  const ClickHereTag = (
    <Link onClick={() => openQredCardPricesPage(props.market, language)}>
      {translate('ClickHere')}
    </Link>
  );

  return (
    <Card noPadding dataTestId="credit-card-usage-card">
      <CardHeader value={translate('CreditCardUsageCard.Title') as string} />

      <CardSection
        title={translate('CreditCardUsageCard.InterestFirst45') as string}
        explanation={
          translate('CreditCardUsageCard.InterestFirst45Explanation') as string
        }
        value={`0% (${translate('CreditCardUsageCard.InterestFreePurchase')})`}
        iconSrc={SvgSrc.ChartArea}
        borderTop
      />
      <CardSection
        title={translate('CreditCardUsageCard.MinimumToPay') as string}
        explanation={
          translate('CreditCardUsageCard.MinimumToPayExplanation') as string
        }
        value={translate('CreditCardUsageCard.NoLessThan500') as string}
        iconSrc={SvgSrc.ChartArea}
        borderTop
      />
      <CardSection
        title={translate('CreditCardUsageCard.InterestAfter45') as string}
        explanation={
          translate('CreditCardUsageCard.InterestAfter45Explanation') as string
        }
        value={`${props.interest}%`}
        iconSrc={SvgSrc.ChartArea}
        borderTop
      />
      <CardSection
        title={translate('CreditCardUsageCard.ForeignTransactionFee') as string}
        explanation={
          translate(
            'CreditCardUsageCard.PurchaseInForeignCurrencyExplanation',
            {
              link: ClickHereTag,
            }
          ) as string
        }
        value={translate('CreditCardUsageCard.AddedFeeByQred') as string}
        iconSrc={SvgSrc.ChartArea}
        borderTop
      />
    </Card>
  );
};

export default CreditCardUsageCard;
