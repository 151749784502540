import { CountryCode } from '~/enums';
import { ContactEmails, getHomePageByMarket } from '~/constants/markets';
import { Language } from '~/store/types/sharedTypes';

export const openBecomeTesterPage = (
  market: CountryCode,
  language: Language
) => {
  switch (market.toUpperCase()) {
    case CountryCode.SE:
      window.open('https://www.qred.se/testpanel', 'becomeTester', 'noopener');
      break;
    case CountryCode.NL:
      window.open('https://www.qeld.com/testpanel', 'becomeTester', 'noopener');
      break;
    case CountryCode.BE:
      if (language === Language.French) {
        window.open(
          'https://www.qred.be/testpanel-fr',
          'becomeTester',
          'noopener'
        );
      } else {
        window.open(
          'https://www.qred.be/testpanel',
          'becomeTester',
          'noopener'
        );
      }
      break;
    case CountryCode.FI:
      window.open('https://www.qred.fi/testpanel', 'becomeTester', 'noopener');
      break;
    case CountryCode.NO:
      window.open('https://www.qred.no/testpanel', 'becomeTester', 'noopener');
      break;
    case CountryCode.DK:
      window.open('https://www.qred.dk/testpanel', 'becomeTester', 'noopener');
      break;
    default:
      window.open('https://www.qred.se/testpanel', 'becomeTester', 'noopener');
  }
};

export const openQredCardPage = (market: CountryCode, language: Language) => {
  switch (market.toUpperCase()) {
    case CountryCode.SE:
      if (language === Language.Swedish) {
        window.open('https://www.qred.se/foretagskort', 'qredcard', 'noopener');
      } else {
        window.open('https://en.qred.se/businesscard', 'qredcard', 'noopener');
      }
      break;
    case CountryCode.FI:
      if (language === Language.Finnish) {
        window.open('https://www.qred.fi/luottokortti', 'qredcard', 'noopener');
      } else {
        window.open('https://en.qred.fi/luottokortti', 'qredcard', 'noopener');
      }
      break;
    default:
      console.error(`Unsupported market ${market} when opening Qred Card Page`);
      break;
  }
};

export const openQredCardGeneralTermsPage = (
  market: CountryCode,
  language: Language
) => {
  switch (market.toUpperCase()) {
    case CountryCode.FI:
      if (language === Language.Finnish) {
        window.open(
          'https://www.qred.fi/info/luottokortti-ehdot',
          'generalTerms',
          'noopener'
        );
      } else {
        window.open(
          'https://en.qred.fi/info/luottokortti-ehdot',
          'generalTerms',
          'noopener'
        );
      }
      break;
    case CountryCode.SE:
      if (language === Language.Swedish) {
        window.open(
          'https://www.qred.se/info/villkor-foretagskreditkort',
          'generalTerms',
          'noopener'
        );
      } else {
        window.open(
          'https://en.qred.se/info/villkor-foretagskreditkort',
          'generalTerms',
          'noopener'
        );
      }
      break;
    default:
      console.error(`Unsupported market ${market} when opening card prices`);
      break;
  }
};

export const openQredAppPage = (market: CountryCode, language: Language) => {
  switch (market.toUpperCase()) {
    case CountryCode.FI:
      if (language === Language.Finnish) {
        window.open('https://www.qred.fi/app', 'qredApp', 'noopener');
      } else {
        window.open('https://en.qred.fi/app', 'qredApp', 'noopener');
      }
      break;
    case CountryCode.SE:
      if (language === Language.Swedish) {
        window.open('https://www.qred.se/app', 'qredApp', 'noopener');
      } else {
        window.open('https://en.qred.se/app', 'qredApp', 'noopener');
      }
      break;
    default:
      break;
  }
};

export const openQredCardPricesPage = (
  market: CountryCode,
  language: Language
) => {
  switch (market.toUpperCase()) {
    case CountryCode.FI:
      if (language === Language.Finnish) {
        window.open(
          'https://www.qred.fi/luottokortti-hinnasto',
          'cardPrices',
          'noopener'
        );
      } else {
        window.open(
          'https://en.qred.fi/luottokortti-hinnasto',
          'cardPrices',
          'noopener'
        );
      }
      break;
    case CountryCode.SE:
      if (language === Language.Swedish) {
        window.open('https://qred.se/kort-prislista', 'cardPrices', 'noopener');
      } else {
        window.open(
          'https://en.qred.se/kort-prislista',
          'cardPrices',
          'noopener'
        );
      }
      break;
    default:
      console.error(`Unsupported market ${market} when opening card prices`);
      break;
  }
};

export const openQredHomePage = (market: CountryCode, language: Language) => {
  const url = getHomePageByMarket(market, language);
  window.open(
    url,
    market === CountryCode.NL ? 'qeldHome' : 'qredHome',
    'noopener'
  );
};

export const openMailToQredSupport = (market: CountryCode) => {
  window.open(`mailto:${ContactEmails[market]}`, 'qredSupport');
};

export const openAppStore = (language: Language) => {
  switch (language) {
    case Language.Swedish:
      window.open(
        'https://apps.apple.com/se/app/qred/id1528985194',
        'appStore',
        'noopener'
      );
      break;
    case Language.Finnish:
      window.open(
        'https://apps.apple.com/se/app/qred/id1528985194?l=fi',
        'appStore',
        'noopener'
      );
      break;
    default:
      window.open(
        'https://apps.apple.com/se/app/qred/id1528985194?l=en',
        'appStore',
        'noopener'
      );
      break;
  }
};

export const openGooglePlay = () => {
  window.open(
    'https://play.google.com/store/apps/details?id=com.qred',
    'googlePlay',
    'noopener'
  );
};
