import InputField from '~/components/shared/Input/InputField';
import React, { forwardRef } from 'react';

interface Props extends React.ComponentPropsWithoutRef<typeof InputField> {
  tel?: boolean;
}

const NumberInputField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { tel } = props;

  return (
    <InputField {...props} ref={ref} inputMode={tel ? 'tel' : 'numeric'} />
  );
});

export default NumberInputField;
