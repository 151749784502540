import React from 'react';
import { Typography } from '@qred/components-library';
import useTranslate from '~/hooks/useTranslate';
import {
  openQredCardGeneralTermsPage,
  openQredCardPricesPage,
} from '~/services/links';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import {
  ConfirmTermsAndConditionsPricelistTextStyle,
  Link,
} from './TermsAndConditions.styled';

const TermsAndConditions = () => {
  const t = useTranslate();
  const {
    intl: { market, language },
  } = useSelector((state: RootState) => state);

  const GeneralTermLink = () => (
    <Link onClick={() => openQredCardGeneralTermsPage(market, language)}>
      {t('CreditCardTermsAndConditionsCard.Link1') as string}
    </Link>
  );

  const QredCardPricesLink = () => (
    <Link onClick={() => openQredCardPricesPage(market, language)}>
      {t('CreditCardTermsAndConditionsCard.Link2') as string}
    </Link>
  );

  return (
    <ConfirmTermsAndConditionsPricelistTextStyle>
      <Typography weight={500} size="sm">
        {
          t('OnboardingCardOffer.ConfirmTermsAndConditionsPricelistText', {
            generalTermLink: <GeneralTermLink />,
            qredCardPricesLink: <QredCardPricesLink />,
          }) as string
        }
      </Typography>
    </ConfirmTermsAndConditionsPricelistTextStyle>
  );
};

export default TermsAndConditions;
