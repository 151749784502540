import { Typography } from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  ContactEmails,
  ContactNumbers,
  ContactNumbersFormatted,
} from '~/constants/markets';
import useHubspotChat from '~/hooks/useHubspotChat';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import HelpItem from './HelpItem';
import {
  Top,
  TopInner,
  TopInnerContent,
  Bottom,
  HackyOverlay,
  BottomInner,
  HelpItemsContainer,
} from './HelpSection.styled';

const HelpSection = () => {
  const translate = useTranslate();

  const { openChat } = useHubspotChat();

  const { market } = useSelector((state: RootState) => state.intl);

  return (
    <>
      <Top>
        <TopInner>
          <TopInnerContent>
            <Typography size="xxl" mb="xs">
              {translate('Onboarding.ContactUs')}
            </Typography>
            <Typography>{translate('Onboarding.ContactUsText')}</Typography>
          </TopInnerContent>
        </TopInner>
      </Top>
      <Bottom>
        <HackyOverlay />
        <BottomInner>
          <HelpItemsContainer>
            <HelpItem
              element="a"
              href={`tel:${ContactNumbers[market]}`}
              icon="Phone"
              text={ContactNumbersFormatted[market]}
            />
            <HelpItem
              element="a"
              href={`mailto:${ContactEmails[market]}`}
              icon="Mail"
              text={ContactEmails[market]}
            />
            <HelpItem
              onClick={openChat}
              icon="Comments"
              text={translate('ContactChat') as string}
            />
          </HelpItemsContainer>
        </BottomInner>
      </Bottom>
    </>
  );
};

export default HelpSection;
