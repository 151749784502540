import { CountryCode } from '~/enums';
import { StepConfigItem } from '~/Interfaces';
import OwnershipV2 from './OwnershipV2/Ownership';
import Signers from './Signers/Signers';
import OwnershipFooter from './OwnershipV2/OwnershipFooter';
import CardOfferView from './CardOffer/CardOfferView';
import CardPurpose from './CardPurpose/CardPurpose';
import KYCQuestionnaire from './KYCQuestionnaire/KYCQuestionnaire';

const CardPurposeStep: StepConfigItem = {
  stepContent: [CardPurpose],
  header: {
    title: 'OnboardingCardOffer.Title',
    description: 'OnboardingCardOffer.CardPurposeDescription',
  },
  name: 'card_purpose_step',
};
const CardOfferStep: StepConfigItem = {
  stepContent: [CardOfferView],
  header: {
    title: 'OnboardingCardOffer.BenefitsVisaTitle',
  },
  name: 'card_offer_step',
};
const KYCQuestionnaireStep: StepConfigItem = {
  header: {
    title: 'OnboardingOffer.KYCQuestionnaire',
    description: 'OnboardingOffer.KYCQuestionnaireDescription',
  },
  stepContent: [KYCQuestionnaire],
  name: 'kyc_questionnaire_step',
};

const SignersStep: StepConfigItem = {
  stepContent: [Signers],
  header: {
    title: 'Signers.Title',
    description: 'OnboardingCardOffer.SignersDescription',
  },
  name: 'additional_signers_and_guarantors_step',
};
const OwnershipStep: StepConfigItem = {
  stepContent: [OwnershipV2],
  header: {
    title: 'OnboardingOffer.Ownership',
  },
  customFooter: OwnershipFooter,
  name: 'ownership_step',
};

export type SupportedCountryCodes = CountryCode.SE | CountryCode.FI;

export const stepConfigPerMarket: Record<
  SupportedCountryCodes,
  StepConfigItem[]
> = {
  [CountryCode.SE]: [
    CardPurposeStep,
    OwnershipStep,
    SignersStep,
    KYCQuestionnaireStep,
    CardOfferStep,
  ],
  [CountryCode.FI]: [
    CardPurposeStep,
    OwnershipStep,
    SignersStep,
    KYCQuestionnaireStep,
    CardOfferStep,
  ],
};
