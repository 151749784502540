import React from 'react';
import { ConfirmLabels } from '~/contexts/modals.context';
import styled from 'styled-components';
import Button, {
  ButtonProps,
  ButtonType,
} from '~/components/shared/Button/button';
import useModals from '~/hooks/useModals';
import Group from '~/components/shared/Layout/Group';
import Stack from '~/components/shared/Layout/Stack';
import Divider from '~/components/shared/Divider/Divider';

const ButtonContainer = styled.div``;

export interface ConfirmModalProps {
  id?: string;
  children?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
  closeOnConfirm?: boolean;
  closeOnCancel?: boolean;
  cancelProps?: ButtonProps;
  confirmProps?: ButtonProps;
  labels?: ConfirmLabels;
}

const ActionSection = styled.div`
  margin-left: -1em;
  margin-right: -1em;
`;

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const {
    id,
    children,
    onConfirm,
    onCancel,
    closeOnCancel,
    closeOnConfirm,
    cancelProps,
    confirmProps,
    labels,
  } = props;
  const {
    confirm: confirmLabel,
    cancel: cancelLabel,
  } = labels as ConfirmLabels;
  const modals = useModals();

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    typeof cancelProps?.onClick === 'function' && cancelProps?.onClick(event);
    typeof onCancel === 'function' && onCancel();
    closeOnCancel && modals.closeModal(id as string);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    typeof confirmProps?.onClick === 'function' && confirmProps?.onClick(event);
    typeof onConfirm === 'function' && onConfirm();
    closeOnConfirm && modals.closeModal(id as string);
  };

  return (
    <Stack>
      {children}
      <ActionSection>
        <Stack>
          <Divider />
          <Group justify="center">
            <ButtonContainer>
              <Button type={ButtonType.NoBorder} onClick={handleCancel}>
                {cancelLabel}
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button type={ButtonType.Secondary} onClick={handleConfirm}>
                {confirmLabel}
              </Button>
            </ButtonContainer>
          </Group>
        </Stack>
      </ActionSection>
    </Stack>
  );
};

ConfirmModal.defaultProps = {
  closeOnCancel: true,
  closeOnConfirm: true,
  labels: { cancel: '', confirm: '' },
};

export default ConfirmModal;
