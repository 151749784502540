import React from 'react';
import { useSelector } from 'react-redux';
import NextButton from '~/components/shared/Stepper/components/NextButton';
import SkipButton from '~/components/shared/Stepper/components/SkipButton';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import KlarnaKosmaBankIntegration from '../../KlarnaKosmaBankIntegration/KlarnaKosmaBankIntegration';
import useSkip from '../../Steps/useSkip';
import useGoToNextStep from '../../Steps/useGoToNextStep';

const ConnectToBankFooter = () => {
  const {
    onboardingApplication: { form, clientId, apiStatus },
    klarnaKosma: { transactionFlow },
  } = useSelector((state: RootState) => state);

  const onNextStepClick = useGoToNextStep();
  const onSkip = useSkip();

  const hideDefaultCta = !form.bankIntegration;

  return (
    <>
      {hideDefaultCta ? (
        <>
          <SkipButton
            label="Onboarding.Skip"
            action={onSkip}
            disabled={transactionFlow.status === 'inProgress'}
          />
          {clientId && (
            <KlarnaKosmaBankIntegration
              buttonText="Onboarding.ConnectToBank"
              organizationNumber={form.organizationNumber}
              clientId={clientId}
            />
          )}
        </>
      ) : (
        <NextButton
          label="Onboarding.Next"
          showLoadingSpinner={apiStatus.patch === ApiStatus.Started}
          action={onNextStepClick}
        />
      )}
    </>
  );
};

export default ConnectToBankFooter;
