import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import {
  ComponentsLibraryProvider,
  Stack,
  Typography,
} from '@qred/components-library';

const OnboardingTokenExpiredError = () => {
  const translate = useTranslate();

  return (
    <ComponentsLibraryProvider>
      <Stack>
        <Typography align="center" weight={600}>
          {translate('Onboarding.SessionExpiredTitle')}
        </Typography>

        <Typography align="center">
          {translate('Onboarding.SessionExpiredText')}
        </Typography>
      </Stack>
    </ComponentsLibraryProvider>
  );
};

export default OnboardingTokenExpiredError;
