import { z } from 'zod';
import { CardPurposeType, OwnerTypeEnum } from '~/enums';

const cardOnboardingOfferGetOfferResDTOSignerSchema = z.strictObject({
  signerId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  residenceCountryIsoCode: z.string(),
  taxResidenceCountryIsoCode: z.string(),
  citizenshipCountryIsoCode: z.string(),
  politicallyExposed: z.boolean(),
});

const cardOnboardingOfferGetOfferResDTOBeneficialOwnerSchema = z.strictObject({
  fullName: z.string(),
  personalNumber: z.string().optional(),
  dateOfBirth: z.string().optional(),
  ownership: z
    .string()
    .refine((ownership) => {
      return Number(ownership);
    }, 'ownership must be a valid number')
    .optional(),
  residenceCountry: z.string(),
});

const cardOnboardingOfferGetOfferResDTOSchema = z.strictObject({
  applicationUuid: z.string(),
  creditLimit: z.null(),
  interest: z.number(),
  offeredCreditLimit: z.number().nullable().optional(),
  shippingAddress: z.string().nullable().optional(),
  invoiceAddress: z.string(),
  invoiceEmail: z.string(),
  additionalSigners: z.array(cardOnboardingOfferGetOfferResDTOSignerSchema),
  beneficialOwners: z.array(
    cardOnboardingOfferGetOfferResDTOBeneficialOwnerSchema
  ),
  clientId: z.number(),
  companyName: z.string(),
  companyNo: z.string(),
  companyType: z.string(),
  invoiceDay: z.union([z.literal('START_OF_MONTH'), z.literal('MID_OF_MONTH')]),
  applicantOwnerShare: z.number().nullable(),
  ownerStructure: z.nativeEnum(OwnerTypeEnum).nullable(),
  applicationReasons: z.array(
    z.strictObject({
      reason: z.nativeEnum(CardPurposeType),
      reasonText: z.string().nullable(),
    })
  ),
  applicant: z.strictObject({
    signerId: z.number(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
    personalNumber: z.string(),
    dateOfBirth: z.string().nullable(),
    residenceCountryIsoCode: z.string().nullable(), // not used, should be removed in BE
    taxResidenceCountryIsoCode: z.string().nullable(), // not used, should be removed in BE
    citizenshipCountryIsoCode: z.string().nullable(), // not used, should be removed in BE
    politicallyExposed: z.boolean(), // not used, should be removed in BE
  }),
  hasAcceptedTerms: z.boolean(),
  hasAcceptedPersonalGuarantee: z.boolean(),
  cardOfferAlreadySigned: z.boolean(),
  isPaperInvoiceChosen: z.boolean(),
  isBeneficialOwnersUpdated: z.null(),
  isPersonalGuaranteeRequired: z.boolean(),
  alternativeBeneficialOwners: z.array(z.unknown()),
  message: z.string().optional(), // this is only sent back in case of an error
});

const cardOnboardingOfferPostOfferReqDTOBeneficialOwnerSchema = z.strictObject({
  fullName: z.string(),
  id: z.string(),
  isAlternativeOwner: z.boolean(),
  personalNumber: z.string().optional(),
  dateOfBirth: z.string().optional(),
  ownerShare: z.string(), // is added because expected by svc-card-java
  ownership: z.string(), // is used in the Roaring API comparison
  residenceCountryIsoCode: z.string().min(2).max(2),
  taxResidenceCountryIsoCode: z.string().min(2).max(2),
  citizenshipCountryIsoCode: z.string().min(2).max(2),
  residenceCountry: z.string(), // is used in the Roaring API comparison
  politicallyExposed: z.boolean(),
});

const cardOnboardingOfferPostOfferReqDTOApplicantSchema = z.strictObject({
  signerId: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  residenceCountryIsoCode: z.string().min(2).max(2),
  taxResidenceCountryIsoCode: z.string().min(2).max(2),
  citizenshipCountryIsoCode: z.string().min(2).max(2),
  politicallyExposed: z.boolean(),
  personalNumber: z.string(),
  dateOfBirth: z.string().nullable(),
  fullName: z.string(),
  id: z.string(),
});

const cardOnboardingOfferPostOfferReqDTOSignerSchema = z.strictObject({
  name: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  signerId: z.number(),
  email: z.string(),
  phoneNumber: z.string(),
  type: z.string(),
  residenceCountryIsoCode: z.string().min(2).max(2),
  taxResidenceCountryIsoCode: z.string().min(2).max(2),
  citizenshipCountryIsoCode: z.string().min(2).max(2),
  politicallyExposed: z.boolean(),
});

const cardOnboardingOfferPostOfferReqDTOSchema = z.strictObject({
  applicant: cardOnboardingOfferPostOfferReqDTOApplicantSchema,
  additionalSigners: z.array(cardOnboardingOfferPostOfferReqDTOSignerSchema),
  applicationUuid: z.string(),
  beneficialOwners: z.array(
    cardOnboardingOfferPostOfferReqDTOBeneficialOwnerSchema
  ),
  companyType: z.string(),
  creditLimit: z.number(),
  offeredCreditLimit: z.number(),
  hasAcceptedTerms: z.boolean(),
  hasAcceptedPersonalGuarantee: z.boolean(),
  isPersonalGuaranteeRequired: z.boolean(),
  interest: z.number(),
  invoiceAddress: z.string(),
  invoiceDay: z.string(),
  invoiceEmail: z.string(),
  isPaperInvoiceChosen: z.boolean(),
  shippingAddress: z.null(),
  clientId: z.number(),
  companyName: z.string(),
  applicationReasons: z.array(
    z.strictObject({
      reason: z.nativeEnum(CardPurposeType),
      reasonText: z.string().nullable(),
    })
  ),
  applicantOwnerShare: z.string(),
  ownerStructure: z.nativeEnum(OwnerTypeEnum),
  companyNo: z.string(),
});

const cardOnboardingOfferPostOfferResDTOSchema = z.strictObject({
  signatureUrl: z.string(),
  hash: z.string().optional(),
  message: z.string().optional(), // this is only sent back in case of an error
});

export const cardOnboardingOfferDTOSchemas = {
  getOfferRes: cardOnboardingOfferGetOfferResDTOSchema,
  postOfferReqBeneficialOwner: cardOnboardingOfferPostOfferReqDTOBeneficialOwnerSchema,
  postOfferReqSigner: cardOnboardingOfferPostOfferReqDTOSignerSchema,
  postOfferReq: cardOnboardingOfferPostOfferReqDTOSchema,
  postOfferRes: cardOnboardingOfferPostOfferResDTOSchema,
};
