import styled from 'styled-components';
import { MediaQueries } from '~/constants/constVars';
import { theme } from '~/styles/themes';

export const DropZoneContainer = styled.div`
  text-align: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  gap: 20px;
  background: ${theme.colors.white};
  border: 2px dashed ${theme.colors.secondary};
  border-radius: 20px;
  flex: none;
  flex-grow: 0;
  width: 100%;
  min-height: 200px;
  @media ${MediaQueries.mediumScreen} {
  }

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.tertiaryGray};
  }
`;
