import { ApiStatus } from './sharedTypes';

export const SET_API_STATUS = 'SET_API_STATUS';

export interface UpdateApiStatusAction {
  type: typeof SET_API_STATUS;
  payload: ApiStatus;
}

export type AiiaAction = UpdateApiStatusAction;
