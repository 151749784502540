import { Box, Group } from '@qred/components-library';
import { theme } from '~/styles/themes';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import Logo from '../Logo/Logo';

interface SecondaryLayoutProps {
  children: React.ReactNode;
}

export const SecondaryLayout = ({ children }: SecondaryLayoutProps) => {
  const { mobile } = useSelector((state: RootState) => state.matchedMedia);
  return (
    <Box
      style={{
        backgroundColor: `${theme.colors.neutral12}`,
        height: 'var(--client-height)',
        overflow: 'auto',
      }}
    >
      <Group justify={mobile ? 'center' : ''}>
        <Logo noLink mt={1.6} ml={mobile ? 0 : 1.5} />
      </Group>
      <Group justify="center" spacing={0}>
        {children}
      </Group>
    </Box>
  );
};

export default SecondaryLayout;
