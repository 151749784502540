import React, { useContext, useEffect, useState } from 'react';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import CheckBox from '~/components/shared/CheckBox/CheckBox';
import useTranslate from '~/hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { updateCardOfferUserData } from '~/store/actions/cardOfferActions';
import InputField from '~/components/shared/Input/InputField';
import { RootState } from '~/store/types/sharedTypes';
import {
  FormStatus,
  SimpleEvent,
  ValidationContext,
  ValidationType,
} from '~/components/hoc/withValidation';
import styled from 'styled-components';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import { CardPurposeType } from '~/enums';

const CheckboxWrapper = styled.div`
  margin: 0.2em 0;
`;

const CreditCardPurposeCard: React.FC = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const validationContext = useContext(ValidationContext);
  const { validationErrors } = validationContext;
  const [otherPurposeReasonText, setOtherPurposeReasonText] = useState('');
  const otherPurposeReasonTextMaxCharLimit = 200;

  const { applicationReasons, cardOfferAlreadySigned } = useSelector(
    (state: RootState) => state.cardOffer.userData
  );

  const validationErrorShouldBeShown =
    (validationContext.formStatus === FormStatus.SUBMITTED &&
      validationErrors.applicationReasons) ||
    validationErrors.ApplicationReasonOther;

  useEffect(() => {
    const fakeEvent: SimpleEvent = {
      target: {
        name: 'applicationReasons',
        value: applicationReasons,
        dataset: { validationType: 'CardPurpose' },
      },
    };
    validationContext.onChangeHOC(fakeEvent);

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'applicationReasons'
      );
    };
  }, [applicationReasons]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (cardOfferAlreadySigned) return;

    if (e.target.type === 'checkbox') {
      validationContext.onChangeHOC({
        target: {
          name: 'applicationReasons',
          value: applicationReasons,
          dataset: {
            validationType: 'CardPurpose' as ValidationType,
          },
        },
      });
      if (applicationReasons.find((r) => r.reason === e.target.name)) {
        dispatch(
          updateCardOfferUserData({
            applicationReasons: applicationReasons.filter(
              (r) => r.reason !== e.target.name
            ),
          })
        );
      } else {
        dispatch(
          updateCardOfferUserData({
            applicationReasons: [
              ...applicationReasons,
              {
                reason: e.target.name as CardPurposeType,
                reasonText: null,
              },
            ],
          })
        );
      }
    } else {
      setOtherPurposeReasonText(e.target.value);
      validationContext.onChangeHOC({
        target: {
          name: 'specifyCardPurpose',
          value: e.target.value,
          dataset: {
            validationType: 'ApplicationReasonOther' as ValidationType,
          },
        },
      });
      dispatch(
        updateCardOfferUserData({
          applicationReasons: [
            ...applicationReasons.map((r) => {
              if (r.reason === CardPurposeType.OtherPurpose) {
                return { ...r, reasonText: otherPurposeReasonText };
              }
              return r;
            }),
          ],
        })
      );
    }
  };

  return (
    <Card noPadding dataTestId="credit-card-purpose-card">
      <CardHeader value={translate('CreditCardPurposeCard.Title') as string} />

      <CardSection
        title={translate('CreditCardPurposeCard.OptionsTitle') as string}
        explanation={
          translate('CreditCardPurposeCard.QuestionMarkExplanation') as string
        }
        borderTop
      >
        {Object.values(CardPurposeType).map((option: string, index: number) => (
          <CheckboxWrapper key={option}>
            <CheckBox
              key={option}
              checked={applicationReasons.some((r) => r.reason === option)}
              disabled={
                applicationReasons.some((r) => r.reason === option) &&
                cardOfferAlreadySigned
              }
              onChange={handleOnChange}
              name={option}
              className={validationErrorShouldBeShown ? 'has-error' : ''}
            >
              {translate(`CreditCardPurposeCard.Option${index + 1}` as string)}
            </CheckBox>
          </CheckboxWrapper>
        ))}

        {applicationReasons &&
          applicationReasons.some(
            (r) => r.reason === CardPurposeType.OtherPurpose
          ) && (
            <InputField
              name="specifyCardPurpose"
              onChange={handleOnChange}
              disabled={cardOfferAlreadySigned}
              validationType="ApplicationReasonOther"
              placeholder={
                translate('CreditCardPurposeCard.Option9Specify') as string
              }
              maxLength={otherPurposeReasonTextMaxCharLimit}
              characterCounter
              value={
                cardOfferAlreadySigned
                  ? (applicationReasons.find(
                      (r) => r.reason === CardPurposeType.OtherPurpose
                    )?.reasonText as string)
                  : otherPurposeReasonText
              }
            />
          )}
        {validationErrorShouldBeShown && (
          <ValidationErrorMessage>
            {translate('ValidationErrors.CardPurpose')}
          </ValidationErrorMessage>
        )}
      </CardSection>
    </Card>
  );
};

export default CreditCardPurposeCard;
