import { AppShell as CLAppShell } from '@qred/components-library';
import React, { FC } from 'react';
import OnboardingHeader from './OnboardingHeader';

interface AppShellProps {
  children: React.ReactNode;
}

const AppShell: FC<AppShellProps> = ({ children }) => {
  return (
    <CLAppShell
      header={<OnboardingHeader />}
      mainProps={{
        display: 'flex',
        p: 0,
        miw: '320px',
        style: {
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: 'unset',
        },
      }}
    >
      {children}
    </CLAppShell>
  );
};

export default AppShell;
