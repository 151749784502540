import { Dispatch } from 'redux';
import { cardOfferDataMapper } from '~/helpers/mappers.helper';
import { ApiListData, CardOffer, CardOfferUserData } from '~/Interfaces';
import {
  apiGetCardOfferData,
  apiGetPublicCardOfferData,
  apiPostCardOfferUserData,
} from '~/services/api';
import { AxiosResponse } from 'axios';
import { V2 } from '~/constants/constVars';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import { PostCardOfferCallback } from '~/types/types';
import { AppThunk, GetStateFunc } from '../types/sharedTypes';
import {
  CardOfferAction,
  PostCardOfferPayload,
  PostCardOfferResponse,
  SET_CARD_OFFER_DATA,
  SET_CARD_OFFER_SUBMIT_STATUS,
  SET_CARD_OFFER_USER_DATA,
  SET_HAS_CARD_OFFER,
  UPDATE_CARD_OFFER_USER_DATA,
  UPDATE_CARD_OFFER_USER_DATA_ADD_OWNER_PLACEHOLDER,
  UPDATE_CARD_OFFER_USER_DATA_REMOVE_OWNER_PLACEHOLDER,
  UPDATE_CARD_OFFER_USER_DATA_UPDATE_OWNER,
  UPDATE_CARD_OFFER_USER_DATA_UPDATE_SIGNER,
} from '../types/cardOfferTypes';

// ===========================  Action creators ===============================
export const setCardOfferData = (
  cardOfferData: CardOffer[]
): CardOfferAction => ({
  type: SET_CARD_OFFER_DATA,
  payload: cardOfferData,
});

export const setCardOfferUserData = (
  // Here the type is CardOffer, merged with inital state in the reducer its final type will be CardOfferUserData
  cardOfferUserData: CardOffer
): CardOfferAction => ({
  type: SET_CARD_OFFER_USER_DATA,
  payload: cardOfferUserData,
});

export const setHasCardOffer = (status: boolean): CardOfferAction => ({
  type: SET_HAS_CARD_OFFER,
  payload: status,
});

export const updateCardOfferUserData = (
  userData: Partial<CardOfferUserData>
): CardOfferAction => ({
  type: UPDATE_CARD_OFFER_USER_DATA,
  payload: userData,
});

export const UpdateCardOfferUserData_AddOwnerPlaceholder = (): CardOfferAction => ({
  type: UPDATE_CARD_OFFER_USER_DATA_ADD_OWNER_PLACEHOLDER,
});

export const UpdateCardOfferUserData_RemoveOwnerPlaceholder = (id: string) => ({
  type: UPDATE_CARD_OFFER_USER_DATA_REMOVE_OWNER_PLACEHOLDER,
  payload: id,
});

export const updateCardOfferUserData_UpdateOwner = (
  ownerId: string,
  fieldName: string,
  value: string
): CardOfferAction => ({
  type: UPDATE_CARD_OFFER_USER_DATA_UPDATE_OWNER,
  payload: {
    [fieldName]: value,
    id: ownerId,
  },
});

export const updateCardOfferUserData_UpdateSigner = (
  signerId: string,
  fieldName: string,
  value: string
): CardOfferAction => ({
  type: UPDATE_CARD_OFFER_USER_DATA_UPDATE_SIGNER,
  payload: {
    [fieldName]: value,
    signerId,
  },
});

const setCardOfferSubmitStatus = (status: boolean): CardOfferAction => ({
  type: SET_CARD_OFFER_SUBMIT_STATUS,
  payload: status,
});

const handleCardOfferData = (data: any[], dispatch: Dispatch) => {
  const offers = data.filter((offer: any) => !offer.message);
  if (offers.length > 0) {
    const mappedOffers = offers.map((cardOfferData: any) =>
      cardOfferDataMapper(cardOfferData)
    );
    dispatch(setCardOfferData(mappedOffers));
    dispatch(setHasCardOffer(true));
  } else {
    dispatch(setHasCardOffer(false));
  }
};

// ================================= Thunks ===================================
export const fetchCardOfferData = (companyIds: number[]): AppThunk => (
  dispatch
) => {
  const promises = companyIds.map((companyId) =>
    apiGetCardOfferData(companyId)
  );
  Promise.all(promises)
    .then((res: ApiListData) => {
      const data = res.map((obj) => obj.data);
      handleCardOfferData(data, dispatch);
    })
    .catch((err: any) => {
      dispatch(
        pushToSentryAction(err, 'apiGetCardOfferData', {
          companyIds: companyIds.join(),
          product: 'card',
        })
      );
    });
};

// For public offer page, pn is not available in redux and we have to send it in as an argument
export const fetchPublicCardOfferData = (
  companyIds: number[],
  market: string
): AppThunk => (dispatch) => {
  const promises = companyIds.map((companyId) =>
    apiGetPublicCardOfferData(companyId, market)
  );
  Promise.all(promises)
    .then((res: ApiListData) => {
      const data = res.map((obj) => obj.data);
      handleCardOfferData(data, dispatch);
    })
    .catch((err: any) => {
      dispatch(
        pushToSentryAction(err, 'apiGetPublicCardOfferData', {
          companyIds: companyIds.join(),
          product: 'card',
        })
      );
    });
};

export const signCardOffer = ({
  applicationUuid,
  market,
  clientId,
  isPublic,
}: PostCardOfferPayload): AppThunk => (dispatch, getState: GetStateFunc) => {
  dispatch(setCardOfferSubmitStatus(true));
  const { userData } = getState().cardOffer;
  apiPostCardOfferUserData({
    userData,
    applicationUuid,
    market,
    clientId,
    isPublic,
  })
    .then((res: any) => {
      const { data } = res;
      if (data && data.message) {
        throw new Error(data.message);
      }
      if (data) {
        window.location.assign(data.signatureUrl);
      }
    })
    .catch((err: any) => {
      dispatch(
        pushToSentryAction(err, 'apiPostCardOfferUserData', {
          clientId,
          public: isPublic,
          product: 'card',
        })
      );
    })
    .finally(() => {
      dispatch(setCardOfferSubmitStatus(false));
    });
};

export const postCardOffer = (
  { applicationUuid, market, clientId, isPublic }: PostCardOfferPayload,
  postCardOfferCallback: PostCardOfferCallback
): AppThunk => async (dispatch, getState: GetStateFunc) => {
  dispatch(setCardOfferSubmitStatus(true));
  const { userData } = getState().cardOffer;
  try {
    const response: AxiosResponse<PostCardOfferResponse> = await apiPostCardOfferUserData(
      {
        userData,
        applicationUuid,
        market,
        clientId,
        isPublic,
        apiVersion: V2,
      }
    );

    const { data } = response;
    if (data && data.message) {
      throw new Error(data.message);
    }
    if (data) {
      return postCardOfferCallback(data);
    }
    return postCardOfferCallback('');
  } catch (err: any) {
    dispatch(
      pushToSentryAction(err, 'apiPostCardOfferV2UserData', {
        clientId,
        public: isPublic,
        product: 'card',
      })
    );
    return postCardOfferCallback('');
  } finally {
    dispatch(setCardOfferSubmitStatus(false));
  }
};
