import React from 'react';
import StoreButtonAndQrCodeView from './StoreButtonAndQrCodeView/StoreButtonAndQrCodeView';

const ThankyouCardOnboardingOffer = () => {
  return (
    <StoreButtonAndQrCodeView
      title={'OnboardingCardOffer.youAreAllSet'}
      description1={'OnboardingCardThankYou.ScanQRCodeText'}
    />
  );
};

export default ThankyouCardOnboardingOffer;
