import React from 'react';
import styled from 'styled-components';
import {
  ArrangementProps,
  BaseArrangement,
} from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';

const TitleContainer = styled(BaseArrangement)`
  color: ${theme.colors.dark};
`;

const Title = styled.h1`
  font-size: 1.5em;
  display: inline-block;
  text-align: center;
  line-height: 1.2em;
`;

const PageTitle: React.FC<ArrangementProps> = (props) => (
  <TitleContainer {...props}>
    <Title>{props.children}</Title>
  </TitleContainer>
);

export default PageTitle;
