import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { Stack, Typography } from '@qred/components-library';
import { Content } from './ConnectToEskat.styled';

const ConnectToEskat = () => {
  const t = useTranslate();
  const {
    onboardingApplication: {
      form: { eskatConnected },
    },
  } = useSelector((state: RootState) => state);

  return (
    <Content>
      <Stack>
        <Typography weight={600} size="xl">
          {t('Onboarding.TaxReport')}
        </Typography>
        <Typography>{t('Onboarding.EskatText')}</Typography>
        {eskatConnected && (
          <Typography weight={600}>
            {t('Onboarding.EskatSuccessText')}
          </Typography>
        )}
      </Stack>
    </Content>
  );
};

export default ConnectToEskat;
