import { withScope } from '@sentry/react';
import { captureException } from '@sentry/browser';
import { RootState } from '~/store';
import { SentryNotifierException } from '~/store/middlewares/sentryNotifier/sentryNotifier.exception';
import { RejectedRTKAction } from '~/store/middlewares/sentryNotifier/sentryNotifier.type';
import { Company } from '~/Interfaces';
import {
  Dispatch,
  isRejected,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { isWhitelistedEndpoint } from '~/store/middlewares/middleware.helper';

const getUserId = (companies: Company[], activeCompany?: Company) => {
  if (activeCompany) {
    return activeCompany.id;
  }

  const activeCompanyLocalStorage = window.localStorage.getItem(
    'active_company'
  );

  if (activeCompanyLocalStorage) {
    return Number(activeCompanyLocalStorage);
  }

  if (companies.length > 0) {
    return companies[0].id;
  }

  return undefined;
};

export const sentryNotifierMiddleware: Middleware = (
  api: MiddlewareAPI<Dispatch, RootState>
) => (next) => (action) => {
  const state = api.getState();
  const userId = getUserId(state.user.companies, state.user.activeCompany);

  if (!isRejected(action)) {
    return next(action);
  }

  const rejectedRTKAction = action as RejectedRTKAction;

  if (!rejectedRTKAction.meta.arg.endpointName) {
    return next(action);
  }

  if (isWhitelistedEndpoint(rejectedRTKAction.meta.arg.endpointName)) {
    return next(action);
  }

  const status =
    rejectedRTKAction.payload?.originalStatus ||
    rejectedRTKAction.payload?.status;

  const errorMessage =
    rejectedRTKAction.payload?.data?.message ||
    rejectedRTKAction.payload?.error;

  withScope((scope) => {
    scope
      .setTransactionName(rejectedRTKAction.meta.arg.endpointName)
      .setFingerprint([
        rejectedRTKAction.type,
        rejectedRTKAction.meta.arg.endpointName,
      ])
      .setTags({
        'user.market': state.intl.market,
        'user.companyId': userId,
        'endpoint.name': rejectedRTKAction.meta.arg.endpointName,
        'endpoint.status': status,
        'endpoint.path': rejectedRTKAction.meta.baseQueryMeta?.request.url,
      })
      .setExtras({
        args: rejectedRTKAction.meta.arg.originalArgs,
        response: rejectedRTKAction?.payload?.data,
      });

    captureException(
      new SentryNotifierException(`[STATUS:${status}]: ${errorMessage}`)
    );
  });

  return next(action);
};
