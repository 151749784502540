import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepStatus } from '~/enums';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { goToNextStep } from '~/store/slices/loanOnboardingOffer.slice';
import { RootState } from '~/store/types/sharedTypes';

const useGoToNextStep = () => {
  const dispatch = useDispatch();
  const { currentStep } = useSelector(
    (state: RootState) => state.onboardingOffer
  );

  const onNextStepClick = useCallback(() => {
    dispatch(pushToGtmOnboardingAction({ actionName: 'next_button_pressed' }));
    dispatch(pushToGtmOnboardingAction({ stepStatus: StepStatus.Finished }));
    dispatch(goToNextStep());
  }, [dispatch, currentStep]);

  return onNextStepClick;
};

export default useGoToNextStep;
