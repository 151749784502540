import {
  Header as CLHeader,
  Group,
  QredLogo,
  useWindowProperties,
} from '@qred/components-library';
import React from 'react';
import { useSelector } from 'react-redux';
import MainMenu from '~/components/shared/Menu/MainMenu';
import { CountryCode } from '~/enums';
import { RootState } from '~/store/types/sharedTypes';

const OnboardingHeader = () => {
  const {
    intl: { market },
  } = useSelector((state: RootState) => state);
  const isQeld = market === CountryCode.NL;
  const { isMobile } = useWindowProperties();

  return (
    <CLHeader height={60} bg="primary.500" keepDesktopStyle={true}>
      <Group
        justify="space-between"
        align="center"
        px={isMobile ? '16px' : '120px'}
        miw="240px"
        h="100%"
      >
        <QredLogo width={65} color="neutral.2" qeld={isQeld} />
        <MainMenu showText={!isMobile} shouldDisplayTestVariant={true} />
      </Group>
    </CLHeader>
  );
};

export default OnboardingHeader;
