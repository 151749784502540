import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '~/components/shared/LogoOld/LogoOld';
import PageHeader from '~/components/shared/PageHeader/PageHeader';
import PageTitle from '~/components/shared/PageTitle/PageTitle';
import useQueryParams from '~/hooks/useQueryParams';
import useTranslate from '~/hooks/useTranslate';
import {
  getAiiaIntegrationUrl,
  getMarketLanguage,
  postAiiaIntegrationData,
} from '~/store/actions/aiiaActions';
import { BodyContainer, MainContainer } from '~/styles/BaseStyledComponents';
import withGtmEvent from '~/components/hoc/withGtmEvent';
import { defaultLanguageToMarket, setMarket } from '~/store/slices/intl.slice';
import { parseMarket } from '~/helpers/market.helper';
import ConnectToAiiaCard from './components/ConnectToAiiaCard';
import MissingParamsModel from './components/MissingParamsModel';
import RedirectFromAiiaModal from './components/RedirectFromAiiaModal';

const EmptyFooter = styled.div`
  height: 2em;
`;

const CustomerLink = () => {
  /* States */
  // This is the language to be sent to aiia, not the page language
  const [language, setLanguage] = useState('');
  const [paramMissing, setParamMissing] = useState(false);

  /* Selectors */

  /* Other hooks */
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const { type } = useParams<{ type: string }>();
  const translate = useTranslate();

  /* Effects */
  useEffect(() => {
    const qcode = queryParams.get('qcode');
    if (qcode) {
      localStorage.setItem('qcode', qcode);
    }

    const market = queryParams.get('market');
    if (market) {
      setLanguage(getMarketLanguage(market));
      localStorage.setItem('aiiaMarket', market);
    }
  }, [queryParams.get('qcode'), queryParams.get('market')]);

  useEffect(() => {
    // This entire effect is dedicated to the logic that should be run after redirect form aiia
    if (type && type === 'redirected') {
      const code = queryParams.get('code');
      const consentId = queryParams.get('consentId');
      const qcode = localStorage.getItem('qcode');
      const market = parseMarket(localStorage.getItem('aiiaMarket'));

      if (market) {
        dispatch(setMarket(market));
        dispatch(defaultLanguageToMarket(market));
      }

      // If there are both code and consentId present, it means that aiia has successfully redirected back after bank scraping.
      if (qcode && code && consentId && market) {
        setParamMissing(false);
        dispatch(
          postAiiaIntegrationData(
            qcode,
            code,
            consentId,
            `${window.location.origin}/customer-link/redirected/${qcode}`,
            market,
            true
          )
        );
      } else {
        // logs will be reflected in Sentry console
        console.log('code', code);
        console.log('qcode', qcode);
        console.log('consentId', consentId);
        Sentry.captureMessage('aiia-missing-params-after');
        setParamMissing(true);
      }
    }
  }, [queryParams.get('code'), queryParams.get('consentId')]);

  useEffect(() => {
    const market = parseMarket(queryParams.get('market'));
    if (market) {
      dispatch(setMarket(market));
      dispatch(defaultLanguageToMarket(market));
    }
  }, [queryParams.get('market')]);

  /* Handlers */
  const onConnectButtonClicked = () => {
    const qcode = localStorage.getItem('qcode');
    // if qcode is not found we do not want to start the aiia process
    if (qcode && language) {
      setParamMissing(false);
      dispatch(
        getAiiaIntegrationUrl(
          language,
          `${window.location.origin}/customer-link/redirected/${qcode}`,
          qcode
        )
      );
    } else {
      // logs will be reflected in Sentry console
      console.log('qcode', qcode);
      console.log('language', language);
      Sentry.captureMessage('aiia-missing-params-before');
      setParamMissing(true);
    }
  };

  const renderContent = () => {
    if (paramMissing) return <MissingParamsModel />;
    return type && type === 'redirected' ? (
      <RedirectFromAiiaModal />
    ) : (
      <ConnectToAiiaCard onClick={onConnectButtonClicked} />
    );
  };

  return (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
        <PageTitle mb={2}>{translate('AddInformation')}</PageTitle>
      </PageHeader>
      <BodyContainer>{renderContent()}</BodyContainer>
      <EmptyFooter />
    </MainContainer>
  );
};

export default withGtmEvent(CustomerLink);
