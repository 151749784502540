import React from 'react';
import styled from 'styled-components';
import {
  ArrangementProps,
  BaseArrangement,
  cssLenght,
  SizeProps,
} from '~/styles/BaseStyledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleDown,
  faBars,
  faBell as faBellSolid,
  faBuilding,
  faCalendar,
  faCalendarDay,
  faCaretDown,
  faCaretUp,
  faChartArea,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleExclamation,
  faCity,
  faClock,
  faCoins,
  faComment,
  faCommentDollar,
  faCommentDots,
  faComments,
  faDoorOpen,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationTriangle,
  faFileInvoice,
  faFont,
  faHandHoldingUsd,
  faHeadset,
  faHome,
  faHourglassStart,
  faInfoCircle,
  faMapMarkerAlt,
  faMoneyBillAlt,
  faMoneyBillWave,
  faPen,
  faPhone,
  faPlus,
  faQuestion,
  faExclamation,
  faRocket,
  faSave,
  faShippingFast,
  faSignOutAlt,
  faSmile,
  faTag,
  faThumbsUp,
  faTimes,
  faTrash,
  faUnlockAlt,
  faUserAlt,
  faWallet,
  faExclamationCircle,
  faPenToSquare,
  faCircleNotch,
  faXmark,
  faFileUpload,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

import {
  faBell as faBellRegular,
  faCreditCard,
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

import { faCreativeCommonsNc } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { theme } from '~/styles/themes';

const IconContainer = styled(BaseArrangement)<
  SizeProps & {
    padding?: number;
    backgroundColor?: string;
    margin?: number;
    clickable?: boolean;
  }
>`
  height: ${(props) => cssLenght(props, 1)};
  width: ${(props) => cssLenght(props, 1)};
  padding: ${(props) => (props.padding ? `${props.padding}em` : '')};
  margin: ${(props) => (props.margin ? `${props.margin}em` : '')};
  cursor: ${(props) => (props.clickable ? 'pointer' : undefined)};
  display: inline-block;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  box-sizing: content-box;
  border-radius: 0.5em;
`;

export const StyledIcon = styled(FontAwesomeIcon)<{ color?: string }>`
  color: ${(props) => props.color || theme.colors.secondaryGray};
`;

export const SvgSrc = {
  AngleDoubleDown: faAngleDoubleDown,
  ArrowRight: faArrowRight,
  Bars: faBars,
  Bell: faBellRegular,
  BellSolid: faBellSolid,
  Building: faBuilding,
  Calendar: faCalendar,
  CalendarDay: faCalendarDay,
  CaretUp: faCaretUp,
  CaretDown: faCaretDown,
  ChartArea: faChartArea,
  ChartLine: faChartLine,
  ChartPie: faChartPie,
  Clock: faClock,
  Coins: faCoins,
  Check: faCheck,
  CheckCircle: faCheckCircle,
  CircleExclamation: faCircleExclamation,
  City: faCity,
  ChevronRight: faChevronRight,
  ChevronLeft: faChevronLeft,
  ChevronDown: faChevronDown,
  ChevronUp: faChevronUp,
  CommentDollar: faCommentDollar,
  CommentDots: faCommentDots,
  Comment: faComment,
  Comments: faComments,
  CreativeCommonNc: faCreativeCommonsNc,
  CreditCard: faCreditCard,
  Edit: faEdit,
  EllipsisH: faEllipsisH,
  ExclamationTriangle: faExclamationTriangle,
  FileUpload: faFileUpload,
  FileInvoice: faFileInvoice,
  HandHoldingUsd: faHandHoldingUsd,
  Headset: faHeadset,
  Home: faHome,
  Hourglass: faHourglassStart,
  InfoCircle: faInfoCircle,
  Language: faFont,
  Mail: faEnvelope,
  MapMarker: faMapMarkerAlt,
  MoneyBillWave: faMoneyBillWave,
  MoneyBillAlt: faMoneyBillAlt,
  Phone: faPhone,
  Plus: faPlus,
  Pen: faPen,
  PenToSquare: faPenToSquare,
  QuestionMark: faQuestion,
  QuestionMarkCircle: faQuestionCircle,
  ExclamationMark: faExclamation,
  Rocket: faRocket,
  Smile: faSmile,
  ShippingFast: faShippingFast,
  SignOut: faSignOutAlt,
  Save: faSave,
  Spinner: faCircleNotch,
  Exit: faDoorOpen,
  Tag: faTag,
  ThumbsUp: faThumbsUp,
  Times: faTimes,
  Trash: faTrash,
  User: faUserAlt,
  UnlockAlt: faUnlockAlt,
  Wallet: faWallet,
  WarningCircle: faExclamationCircle,
  XMark: faXmark,
};

interface Props {
  src: IconProp;
  color?: string;
  padding?: number;
  margin?: number;
  className?: string;
  onClick?: () => void;
  dataCy?: string;
  backgroundColor?: string;
  clickable?: boolean;
}

const Icon: React.FC<Props & ArrangementProps & SizeProps> = (props) => (
  <IconContainer
    data-cy={props.dataCy}
    className={props.className}
    size={props.size}
    mt={props.mt}
    mb={props.mb}
    mr={props.mr}
    ml={props.ml}
    pt={props.pt}
    pb={props.pb}
    pr={props.pr}
    pl={props.pl}
    padding={props.padding}
    margin={props.margin}
    onClick={props.onClick}
    backgroundColor={props.backgroundColor}
    clickable={props.clickable}
  >
    <StyledIcon
      style={{
        width: '100%',
        height: '100%',
      }}
      color={props.color}
      icon={props.src}
    />
  </IconContainer>
);

export default Icon;
