import styled from 'styled-components';
import { theme } from '~/styles/themes';
import { MediaQueries } from '~/constants/constVars';

export const CustomerReviewItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
`;

export const Img = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
`;

export const SectionWrapper = styled.div`
  background-color: ${theme.colors.document};
  padding: 0 40px 100px 40px;
`;

export const ItemWrapper = styled.div`
  max-width: 400px;
  gap: 40px;
  @media ${MediaQueries.desktop} {
    width: 80%;
    max-width: 1200px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TitleWrapper = styled(ItemWrapper)`
  margin: 3em auto;
  text-align: center;
`;

export const CustomerReviewsWrapper = styled(ItemWrapper)`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
`;
