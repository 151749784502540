import {
  apiPostInitiateOTP,
  apiPostSignerConfirmation,
  apiPostRegenerateOTP,
  apiPostVerifyOTP,
  apiGetAdditionalSignerLoanApplication,
  apiGetAdditionalSignerCardApplication,
  apiPostSignerCardOfferData,
} from '~/services/api';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import { AppThunk, ApiStatus, GetStateFunc } from '../types/sharedTypes';
import { CountryCode } from '@qred/shared-enums';
import {
  setApiStatus,
  setOTPError,
  setOTPExpiryTimestamp,
  setAdditionalSignerLoanApplicationData,
  setAdditionalSignerCardApplicationData,
  setTimerCompleted,
  setOTP,
} from '../slices/additionalSigner.slice';
import { getCookieValue } from '~/helpers/utils';
import { GlobalErrorType, OTPErrorType } from '~/enums';
import { toggleGlobalErrorOn } from '../slices/globalError.slice';
import { V2 } from '~/constants/constVars';
import { SendSignersCardOfferDataV2Callback } from '~/types/types';
import { AxiosResponse } from 'axios';
import { PostSignersCardOfferV2Response } from '../types/cardOfferTypes';
import moment from 'moment';

export const initiateOTP = (market: CountryCode): AppThunk => (dispatch) => {
  const productType = getCookieValue('additionalSignerProductType');
  const signerId = getCookieValue('additionalSignerId');
  const hashCode = getCookieValue('additionalSignerHashCode');

  dispatch(setApiStatus({ initiateOTP: ApiStatus.Started }));
  apiPostInitiateOTP(market, signerId, hashCode, productType)
    .then((response) => {
      if (response.data.otpExpiryTimestamp) {
        dispatch(
          setTimerCompleted(
            moment(response.data.otpExpiryTimestamp).isBefore(Date.now())
          )
        );
        dispatch(setOTPExpiryTimestamp(response.data.otpExpiryTimestamp));
        dispatch(setApiStatus({ initiateOTP: ApiStatus.Completed }));
      } else {
        dispatch(setApiStatus({ initiateOTP: ApiStatus.Failed }));
      }
    })
    .catch((err) => {
      const errorMessageIsKnown = Object.values(OTPErrorType).includes(
        err?.response?.data?.message
      );
      dispatch(
        setOTPError(
          errorMessageIsKnown ? err.response.data.message : OTPErrorType.UNKNOWN
        )
      );
      dispatch(
        pushToSentryAction(err, 'apiInitiateOTP', {
          product: 'onboarding',
        })
      );
      dispatch(setApiStatus({ initiateOTP: ApiStatus.Failed }));
    });
};

export const regenerateOTP = (market: CountryCode): AppThunk => (dispatch) => {
  const signerId = getCookieValue('additionalSignerId');
  const hashCode = getCookieValue('additionalSignerHashCode');
  const productType = getCookieValue('additionalSignerProductType');

  dispatch(setOTPError(null));
  dispatch(setOTP(''));
  dispatch(
    setApiStatus({
      verifyOTP: ApiStatus.Idle,
      regenerateOTP: ApiStatus.Started,
    })
  );
  apiPostRegenerateOTP(market, signerId, hashCode, productType)
    .then((response) => {
      if (response.data.otpExpiryTimestamp) {
        dispatch(setOTPExpiryTimestamp(response.data.otpExpiryTimestamp));
        dispatch(setApiStatus({ regenerateOTP: ApiStatus.Completed }));
        dispatch(setTimerCompleted(false));
      } else {
        dispatch(setApiStatus({ regenerateOTP: ApiStatus.Failed }));
      }
    })
    .catch((err) => {
      const errorMessageIsKnown = Object.values(OTPErrorType).includes(
        err?.response?.data?.message
      );
      dispatch(
        setOTPError(
          errorMessageIsKnown ? err.response.data.message : OTPErrorType.UNKNOWN
        )
      );

      dispatch(
        pushToSentryAction(err, 'apiPostRegenerateOTP', {
          product: 'onboarding',
        })
      );
      dispatch(setApiStatus({ regenerateOTP: ApiStatus.Failed }));
    });
};

export const verifyOTP = (market: CountryCode, otp: string): AppThunk => (
  dispatch
) => {
  const signerId = getCookieValue('additionalSignerId');
  const hashCode = getCookieValue('additionalSignerHashCode');
  const productType = getCookieValue('additionalSignerProductType');

  dispatch(setApiStatus({ verifyOTP: ApiStatus.Started }));
  apiPostVerifyOTP(market, otp, signerId, hashCode, productType)
    .then((response) => {
      if (response.data) {
        if (response.data.accessToken) {
          document.cookie = `additionalSignerAccessToken=${response.data.accessToken}; Path=/; Max-Age=86400`;
        }

        dispatch(setApiStatus({ verifyOTP: ApiStatus.Completed }));
      } else {
        dispatch(setApiStatus({ verifyOTP: ApiStatus.Failed }));
      }
    })
    .catch((err) => {
      const errorMessageIsKnown = Object.values(OTPErrorType).includes(
        err?.response?.data?.message
      );
      dispatch(
        setOTPError(
          errorMessageIsKnown ? err.response.data.message : OTPErrorType.UNKNOWN
        )
      );
      dispatch(
        pushToSentryAction(err, 'apiVerifyOTP', {
          product: 'onboarding',
        })
      );
      dispatch(setApiStatus({ verifyOTP: ApiStatus.Failed }));
    });
};

export const confirmContractAction = (): AppThunk => (dispatch) => {
  dispatch(
    setApiStatus({ postAdditionalSignerLoanApplication: ApiStatus.Started })
  );
  apiPostSignerConfirmation()
    .then((response) => {
      if (response.data) {
        window.location.assign(response.data.signURL);
      } else {
        throw new Error(`Response object has no data.`);
      }
    })
    .catch((err) => {
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
      dispatch(
        setApiStatus({ postAdditionalSignerLoanApplication: ApiStatus.Failed })
      );
      dispatch(pushToSentryAction(err, 'apiPostSignerConfirmation'));
    });
};

export const fetchAdditionalSignerLoanApplication = (
  market: CountryCode
): AppThunk => (dispatch) => {
  const signerId = getCookieValue('additionalSignerId');

  dispatch(
    setApiStatus({ fetchAdditionalSignerLoanApplication: ApiStatus.Started })
  );
  apiGetAdditionalSignerLoanApplication(market, signerId)
    .then((response) => {
      if (response.data) {
        dispatch(setAdditionalSignerLoanApplicationData(response.data));
        dispatch(
          setApiStatus({
            fetchAdditionalSignerLoanApplication: ApiStatus.Completed,
          })
        );
      } else {
        dispatch(
          setApiStatus({
            fetchAdditionalSignerLoanApplication: ApiStatus.Failed,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(
        pushToSentryAction(err, 'apiGetAdditionalSignerLoanApplication', {
          product: 'onboarding',
        })
      );
      dispatch(
        setApiStatus({ fetchAdditionalSignerLoanApplication: ApiStatus.Failed })
      );
    });
};

export const fetchAdditionalSignerCardApplication = (
  market: CountryCode | null
): AppThunk => (dispatch) => {
  const signerId = getCookieValue('additionalSignerId');

  dispatch(
    setApiStatus({ fetchAdditionalSignerCardApplication: ApiStatus.Started })
  );
  apiGetAdditionalSignerCardApplication(market, signerId)
    .then((response) => {
      if (response.data) {
        dispatch(setAdditionalSignerCardApplicationData(response.data));
        dispatch(
          setApiStatus({
            fetchAdditionalSignerCardApplication: ApiStatus.Completed,
          })
        );
      } else {
        dispatch(
          setApiStatus({
            fetchAdditionalSignerCardApplication: ApiStatus.Failed,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(
        pushToSentryAction(err, 'apiGetAdditionalSignerLoanApplication', {
          product: 'onboarding',
        })
      );
      dispatch(
        setApiStatus({ fetchAdditionalSignerCardApplication: ApiStatus.Failed })
      );
    });
};

export const sendSignersCardOfferData = (): AppThunk => (
  dispatch,
  getState: GetStateFunc
) => {
  const {
    additionalSigner: { additionalSignerCardApplicationOffer: signerData },
    intl: { market },
  } = getState();
  dispatch(
    setApiStatus({ postAdditionalSignerCardApplication: ApiStatus.Started })
  );
  apiPostSignerCardOfferData(signerData, market)
    .then((res: any) => {
      const { data } = res;
      if (data?.message) {
        throw new Error(data.message);
      }
      if (data) {
        window.location.assign(data.signatureUrl);
      }
    })
    .catch((err: any) => {
      dispatch(pushToSentryAction(err, 'apiPostSignerCardOfferData'));
      dispatch(
        setApiStatus({ postAdditionalSignerCardApplication: ApiStatus.Failed })
      );
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    });
};

export const sendSignersCardOfferDataV2 = (
  sendSignersCardOfferDataV2Callback: SendSignersCardOfferDataV2Callback
): AppThunk => async (dispatch, getState: GetStateFunc) => {
  const {
    additionalSigner: { additionalSignerCardApplicationOffer: signerData },
    intl: { market },
  } = getState();
  dispatch(
    setApiStatus({ postAdditionalSignerCardApplication: ApiStatus.Started })
  );
  try {
    const response: AxiosResponse<PostSignersCardOfferV2Response> = await apiPostSignerCardOfferData(
      signerData,
      market,
      V2
    );
    const { data } = response;
    if (data?.message) {
      throw new Error(data.message);
    }
    if (data) {
      return sendSignersCardOfferDataV2Callback({ hash: data.hash });
    } else {
      throw new Error('No data in response');
    }
  } catch (err: any) {
    dispatch(pushToSentryAction(err, 'apiPostSignerCardOfferDataV2'));
    dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    dispatch(
      setApiStatus({ postAdditionalSignerCardApplication: ApiStatus.Failed })
    );
  }
};
