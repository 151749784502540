import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import Popup from '~/components/shared/Popup/Popup';
import { Typography } from '~/components/shared/Typography/Typography';
import {
  ContactEmails,
  ContactNumbers,
  ContactNumbersFormatted,
} from '~/constants/markets';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';

const LinkTag = styled.a`
  color: ${theme.colors.dark};
`;

const MissingParamsModel = () => {
  const market = useSelector((state: RootState) => state.intl.market);
  const translate = useTranslate();

  return (
    <Popup
      isOpen
      onOkButtonClick={() => window.location.assign('https://www.qred.com')}
    >
      <Typography
        fontSize={1}
        lineHeight={1.5}
        color={theme.colors.dark}
        element="p"
      >
        {translate('MissingParamsModel.Text1')}
      </Typography>
      <Typography
        fontSize={1}
        lineHeight={1.4}
        color={theme.colors.dark}
        element="p"
      >
        <Icon
          src={SvgSrc.Phone}
          size={ComponentSize.Small}
          mr={0.5}
          color={theme.colors.dark}
        />
        <LinkTag href={`tel:${ContactNumbers[market]}`}>
          {ContactNumbersFormatted[market]}
        </LinkTag>
      </Typography>
      <Typography
        fontSize={1}
        lineHeight={1.4}
        color={theme.colors.dark}
        element="p"
      >
        <Icon
          src={SvgSrc.Mail}
          size={ComponentSize.Small}
          mr={0.5}
          color={theme.colors.dark}
        />
        <LinkTag href={`mailto:${ContactEmails[market]}`}>
          {ContactEmails[market]}
        </LinkTag>
      </Typography>
    </Popup>
  );
};

export default MissingParamsModel;
