import { useState } from 'react';

interface UseAppShellProps {
  headerTitle: string;
}

export const useAppShell = ({ headerTitle }: UseAppShellProps) => {
  const [_headerTitle, setHeaderTitle] = useState(headerTitle);

  return {
    headerTitle: _headerTitle,
    setHeaderTitle,
  };
};
