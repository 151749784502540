import { ShippingDetails, ShippingDetailsUserData } from '~/Interfaces';
import { apiGetShippingDetails, apiPostShippingDetails } from '~/services/api';
import { cardShippingDetailsDataMapper } from '~/helpers/mappers.helper';
import { history } from '~/App';
import constVars from '~/constants/constVars';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import {
  SET_IS_CARD_CREATED_STATUS,
  SET_SET_PIN_STATUS,
  SET_SHIPPING_DETAILS_DATA,
  SET_SHIPPING_DETAILS_HASH,
  SET_SHIPPING_DETAILS_USER_DATA,
  ShippingDetailsAction,
  UPDATE_SHIPPING_DETAILS_USER_DATA,
} from '../types/shipingDetailsTypes';
import { AppThunk, GetStateFunc } from '../types/sharedTypes';
import { CountryCode } from '~/enums';

// ===========================  Action creators ===============================
export const setShippingDetailsData = (
  shippingDetailsData: ShippingDetails
): ShippingDetailsAction => ({
  type: SET_SHIPPING_DETAILS_DATA,
  payload: shippingDetailsData,
});

export const setShippingDetailsUserData = (
  shippingDetailsUserData: ShippingDetails
): ShippingDetailsAction => ({
  type: SET_SHIPPING_DETAILS_USER_DATA,
  payload: shippingDetailsUserData,
});

export const updateShippingDetailsUserData = (
  userData: Partial<ShippingDetailsUserData>
): ShippingDetailsAction => ({
  type: UPDATE_SHIPPING_DETAILS_USER_DATA,
  payload: userData,
});

const setSetPinStatus = (status: boolean): ShippingDetailsAction => ({
  type: SET_SET_PIN_STATUS,
  payload: status,
});

const setIsCardCreatedStatus = (status: boolean): ShippingDetailsAction => ({
  type: SET_IS_CARD_CREATED_STATUS,
  payload: status,
});

export const setShippingDetailsHash = (
  hash: string
): ShippingDetailsAction => ({
  type: SET_SHIPPING_DETAILS_HASH,
  payload: hash,
});

// ================================= Thunks ===================================
export const fetchShippingDetailsData = (
  applicationUuid: string,
  isPublic: boolean,
  market: CountryCode,
  hash: string
): AppThunk => (dispatch) => {
  apiGetShippingDetails(applicationUuid, market, hash)
    .then((res: any) => {
      if (res.data && res.data.message) {
        throw new Error(res.data.message);
      }
      if (res.data) {
        const shippingData = cardShippingDetailsDataMapper(res.data);
        dispatch(setShippingDetailsData(shippingData));
      }
    })
    .catch((err: any) => {
      dispatch(
        pushToSentryAction(err, 'apiGetShippingDetails', {
          product: 'card',
          public: isPublic,
        })
      );
    });
};

export const sendShippingDetailsData = (
  applicationUuid: string,
  isPublic: boolean
): AppThunk => (dispatch, getState: GetStateFunc) => {
  dispatch(setSetPinStatus(true));
  const { userData } = getState().shippingDetails;
  const { hash } = getState().shippingDetails;
  const { market } = getState().intl;
  apiPostShippingDetails(userData, applicationUuid, market, hash)
    .then((res: any) => {
      if (res.data && res.data.message) {
        throw new Error(res.data.message);
      }
      dispatch(setIsCardCreatedStatus(true));
      // Simple trick to let the progress bar move to 100% before pushing to thankyou page
      setTimeout(() => {
        history.push(
          `/${constVars.ROUTE_THANK_YOU}/${constVars.ROUTE_CARD_CREATED}`
        );
      }, 2000);
    })
    .catch((err: any) => {
      dispatch(setIsCardCreatedStatus(true));
      dispatch(
        pushToSentryAction(err, 'apiPostShippingDetails', {
          product: 'card',
          public: isPublic,
        })
      );
      setTimeout(() => {
        history.push(
          `/${constVars.ROUTE_THANK_YOU}/${constVars.ROUTE_CARD_CREATED}`
        );
      }, 2000);
    })
    .finally(() => {
      dispatch(setSetPinStatus(false));
    });
};
