import { CustomerApplicationStep } from '~/models/application/application';
import { LoanApplicationStatus } from '~/store/apis/dtos/companies/companies.dto';

export const getCustomerStep = (
  status: LoanApplicationStatus
): CustomerApplicationStep => {
  switch (status) {
    case 'WAIT_CLIENT_APPLICATION_CHANGE':
    case 'WAIT_USER_MANUAL_APROOVE':
    case 'WAIT_SYSTEM_PROCESSING':
    case 'WAIT_CLIENT_IDENTIFICATION':
    case 'WAIT_INSTANTOR':
    case 'WAIT_CLIENT_PRE_OFFER_ACCEPT':
      return CustomerApplicationStep.Review;
    case 'WAIT_SIGNER_ACCEPT':
    case 'WAIT_CLIENT_ACCEPT':
      return CustomerApplicationStep.Offer;
    case 'WAIT_USER_LAST_ACCEPT':
      return CustomerApplicationStep.Confirmation;

    default:
      return CustomerApplicationStep.None;
  }
};
