import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import {
  Accordion,
  Banner,
  Button,
  Divider,
  Group,
  Icon,
  Stack,
  Typography,
} from '@qred/components-library';
import { onChangeInputEvent, onFocusSelectEvent } from '~/types/types';
import { ValidationContext } from '~/components/hoc/withValidation';
import useModals from '~/hooks/useModals';
import useOnboardingMarketConfig from '~/hooks/useOnboardingMarketConfig';
import { useCardOnboardingOfferSelectors } from '~/store/selectors/cardOnboardingOffer.selector';
import { validateFetchedBeneficialOwner } from '~/helpers/onboarding.helper';
import BeneficialOwner from './BeneficialOwner';
import OwnerManagementModal from '../OwnershipAdditionalOwnersPanel/OwnerManagementModal';
import OwnershipUploadDocumentationPanel from '../OwnershipUploadDocumentationPanel/OwnershipUploadDocumentationPanel';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';

const FetchedBeneficialOwners = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const validationContext = useContext(ValidationContext);
  const {
    cardOnboardingOffer: { form },
    matchedMedia: { mobile },
  } = useSelector((state: RootState) => state);

  const {
    marketHasFetchedOwnershipSharesAsRanges,
    marketHasPersonalNumberFromRoaring,
    marketHasDateOfBirthFromRoaring,
    marketHasYearOfBirthFromRoaring,
  } = useOnboardingMarketConfig();

  const {
    ownersFromRoaringHasBeenChanged,
    totalAmountOfShares,
  } = useCardOnboardingOfferSelectors();

  const findFirstInvalidAccordionItem = () => {
    const invalidBeneficialOwner = form.owners.find(
      (beneficialOwner) => !validateFetchedBeneficialOwner(beneficialOwner)
    );
    if (invalidBeneficialOwner) {
      return invalidBeneficialOwner.id.toString();
    }

    return null;
  };
  const [accordionValue, setAccordionValue] = useState<string | null>(
    findFirstInvalidAccordionItem()
  );

  const onBlur = (event: onChangeInputEvent | onFocusSelectEvent | string) => {
    let name;
    if (typeof event === 'string') {
      name = event;
    } else {
      name = event.target.name;
    }
    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: `${name}_change`,
      })
    );
  };

  useEffect(() => {
    const fetchedBeneficialOwnersHasErrors = form.owners.some(
      (owner) => !validateFetchedBeneficialOwner(owner)
    );

    if (fetchedBeneficialOwnersHasErrors) {
      validationContext.addPropertyToValidationErrors(
        'fetchedBeneficialOwners'
      );
    }

    const firstInvalidAccordionItem = findFirstInvalidAccordionItem();
    setAccordionValue(firstInvalidAccordionItem);

    return () => {
      validationContext.removePropertyFromValidationErrors(
        'fetchedBeneficialOwners'
      );
    };
  }, [form.owners, totalAmountOfShares]);

  const modals = useModals();

  const openOwnerManagementModal = () => {
    dispatch(
      pushToGtmCardOnboardingAction({
        actionName: 'card_offer_add_owner',
      })
    );

    modals.openModal({
      header: (
        <Typography>{translate('OnboardingOffer.AddNewOwner')}</Typography>
      ),
      children: (
        <OwnerManagementModal
          ownershipPercentageAsRanges={marketHasFetchedOwnershipSharesAsRanges}
          askForPersonalNumber={marketHasPersonalNumberFromRoaring}
          askForDateOfBirth={marketHasDateOfBirthFromRoaring}
          askForYearOfBirth={marketHasYearOfBirthFromRoaring}
        />
      ),
      size: 'md',
      fullScreen: mobile,
      filledBackground: true,
      hasOwnFooter: true,
    });
  };

  const applicantCanAddAdditionalBeneficialOwners = totalAmountOfShares < 75;

  return (
    <>
      <Typography mb="2em">
        {translate('OnboardingOffer.OwnershipFetchedOwnersDescription')}
      </Typography>
      <Accordion value={accordionValue} onChange={setAccordionValue}>
        {!!form.owners?.length &&
          form.owners.map((beneficialOwner) => (
            <Accordion.Item
              value={beneficialOwner.id.toString()}
              key={beneficialOwner.id}
              style={{ marginTop: 0 }}
            >
              <Accordion.Control style={{ backgroundColor: 'transparent' }}>
                <Group align="center">
                  <Icon
                    src={
                      validateFetchedBeneficialOwner(beneficialOwner)
                        ? 'CheckCircle'
                        : 'CheckCircleUnfilled'
                    }
                    size="lg"
                    iconSize={16}
                  />
                  <Typography component="h3">
                    {beneficialOwner.fullName}
                  </Typography>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <BeneficialOwner
                  key={beneficialOwner.id}
                  beneficialOwner={beneficialOwner}
                  onBlur={onBlur}
                />
              </Accordion.Panel>
              <Divider color="neutral.16" mt="0.5em" mb="0.5em" />
            </Accordion.Item>
          ))}
      </Accordion>

      {applicantCanAddAdditionalBeneficialOwners && (
        <Stack align="center">
          <Typography>
            {translate('OnboardingOffer.FetchedBeneficialOwnersAddOwnerLabel')}
          </Typography>
          <Button
            onClick={openOwnerManagementModal}
            variant="secondary"
            fullWidth={mobile}
          >
            <Icon src="Plus" mr="0.5em" />
            {translate(
              'OnboardingOffer.AdditionalOwnersPanelAddOwnerButtonLabel'
            )}
          </Button>
        </Stack>
      )}

      <Banner
        state="critical"
        withCloseButton={false}
        message={translate('OnboardingOffer.TotalOwnershipSharesMoreThan100')}
        opened={
          validationContext.validationErrors.totalOwnershipSharesMoreThan100
        }
      />

      {ownersFromRoaringHasBeenChanged && <OwnershipUploadDocumentationPanel />}
    </>
  );
};

export default FetchedBeneficialOwners;
