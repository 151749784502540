import React, { useEffect } from 'react';
import withGtmEvent from '~/components/hoc/withGtmEvent';
import { useDispatch } from 'react-redux';
import Logo from '~/components/shared/LogoOld/LogoOld';
import PageHeader from '~/components/shared/PageHeader/PageHeader';
import { defaultLanguageToMarket, setMarket } from '~/store/slices/intl.slice';
import { BodyContainer, MainContainer } from '~/styles/BaseStyledComponents';
import { parseMarket } from '~/helpers/market.helper';
import ContractSignedCard from './components/ContractSignedCard';

const CardAccountCreditLimitIncreased = () => {
  const dispatch = useDispatch();

  const market = parseMarket(localStorage.getItem('qredMarket'));

  useEffect(() => {
    if (market) {
      dispatch(setMarket(market));
      dispatch(defaultLanguageToMarket(market));
    }
  }, [market]);

  return (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
      </PageHeader>
      <BodyContainer style={{ marginBottom: '2em' }}>
        <ContractSignedCard />
      </BodyContainer>
    </MainContainer>
  );
};

export default withGtmEvent(CardAccountCreditLimitIncreased);
