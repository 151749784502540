import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { orgNrAreTheSame } from '~/helpers/utils';
import {
  setApiStatus,
  setCompanyIsNew,
  setCompanyName,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import { debounce } from 'lodash';
import {
  ActionIcon,
  Autocomplete,
  Card,
  Group,
  MaterialIcon,
  OptionProps,
  Stack,
  Typography,
  components,
} from '@qred/components-library';
import { apiGetCompanyInfoBySearch } from '~/services/api/onboarding';
import { ValidationContext } from '~/components/hoc/withValidation';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { TLoanOnboardingApplicationDTO } from '~/types/DTO/loanOnboardingApplication';
import { ICompanySearchResult } from '~/interfaces/Onboarding';
import { logToSentry } from '~/helpers/loggers.helper';
import { digitValidator } from '~/helpers/validators.helper';
import { NoticeProps } from 'react-select';

const CompanySearchVariant = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const validationContext = useContext(ValidationContext);

  const { market } = useSelector((state: RootState) => state.intl);
  const selectedCompanyOtherRef = useRef<HTMLInputElement>(null);
  const otherCompanyInputRef = useRef<HTMLInputElement>(null);
  const { form, companyName, overview } = useSelector(
    (state: RootState) => state.onboardingApplication
  );
  const [searchValue, setSearchValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [requestHasBeenMade, setRequestHasBeenMade] = useState(false);

  const debounceChanges = useCallback(
    debounce(
      async (
        inputValue: string,
        callback: (options: ICompanySearchResult[]) => void
      ) => {
        // spaces and hyphens should be ignored when checking if the search value is only digits
        const searchValueIsOnlyDigits = !digitValidator(
          inputValue.replace(/[-\s]/g, '')
        );

        dispatch(
          pushToGtmOnboardingAction({
            actionName: searchValueIsOnlyDigits
              ? 'other_company_search_only_digits_value'
              : 'other_company_search_text_value',
          })
        );

        try {
          dispatch(setApiStatus({ companySearch: ApiStatus.Started }));
          const data: TLoanOnboardingApplicationDTO['getCompanySearchRes'] = await apiGetCompanyInfoBySearch(
            inputValue,
            market
          );
          callback(
            data.map((company) => ({
              label: (
                <Group justify="space-between" nowrap>
                  <Typography>{company.companyName}</Typography>
                  <Typography>{company.companyNumber}</Typography>
                </Group>
              ),
              value: {
                companyName: company.companyName,
                companyNumber: company.companyNumber,
                companyType: company.companyType,
              },
            }))
          );
          dispatch(setApiStatus({ companySearch: ApiStatus.Completed }));
        } catch (error) {
          dispatch(setApiStatus({ companySearch: ApiStatus.Failed }));
          callback([]);
          logToSentry(error, 'apiGetCompanyInfoBySearch');
        }
      },
      500
    ),
    []
  );

  const loadOptions = (
    inputValue: string,
    callback: (options: ICompanySearchResult[]) => void
  ) => {
    if (inputValue.length < 3) {
      setRequestHasBeenMade(false);
      return callback([]);
    }

    debounceChanges(inputValue, callback);
    setRequestHasBeenMade(true);
  };

  // === Extract the following functions into their own component files if we choose this variant ===

  const NoOptionsMessage = (
    props: NoticeProps<ICompanySearchResult, false>
  ) => (
    <components.NoOptionsMessage {...props}>
      {requestHasBeenMade
        ? t('Onboarding.SelectCompanyNoSearchResultFound')
        : t('Onboarding.SelectCompanySearchValueMissingPrompt')}
    </components.NoOptionsMessage>
  );

  const LoadingMessage = (props: NoticeProps<ICompanySearchResult, false>) => (
    <components.LoadingMessage {...props}>
      {t('Loading')}
    </components.LoadingMessage>
  );

  const CustomOption = (
    props: OptionProps<ICompanySearchResult | undefined, false>
  ) => <components.Option {...props} />;

  // === END ===

  const handleCompanySelect = (selectedOption: ICompanySearchResult) => {
    const searchedByOrgNr = orgNrAreTheSame(
      selectedOption.value.companyNumber,
      searchValue
    );

    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'other_company_search_result_selected',
      })
    );

    dispatch(
      pushToGtmOnboardingAction({
        actionName: searchedByOrgNr
          ? 'other_company_search_result_selected_by_company_nr_search'
          : 'other_company_search_result_selected_by_company_name_search',
      })
    );
    dispatch(
      updateForm({
        organizationNumber: selectedOption.value.companyNumber,
        companyType: selectedOption.value.companyType,
      })
    );
    dispatch(setCompanyName(selectedOption.value.companyName));
  };

  const clearSelectedCompany = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'other_company_search_result_cleared',
      })
    );
    dispatch(updateForm({ organizationNumber: '', companyType: '' }));
    dispatch(setCompanyName(''));
  };

  useEffect(() => {
    if (
      overview.clients?.some((client) =>
        orgNrAreTheSame(client.company_no, form.organizationNumber)
      )
    ) {
      dispatch(setCompanyIsNew(false));
    }
  }, [form.organizationNumber]);

  useEffect(() => {
    otherCompanyInputRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [otherCompanyInputRef?.current]);

  useEffect(() => {
    if (companyName) {
      selectedCompanyOtherRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedCompanyOtherRef?.current, companyName]);

  useEffect(() => {
    form.organizationNumber
      ? validationContext.removePropertyFromValidationErrors('MissingCompany')
      : validationContext.addPropertyToValidationErrors('MissingCompany');
    return () => {
      validationContext.removePropertyFromValidationErrors('MissingCompany');
    };
  }, [form.organizationNumber]);

  return form.organizationNumber ? (
    <>
      <Typography weight={400}>
        {t('Onboarding.SelectCompanyOtherCompanySelectedTitle')}
      </Typography>
      <Card ref={selectedCompanyOtherRef} p="0.75em" borderColor="neutral.16">
        <Group
          align="center"
          style={{
            flexWrap: 'nowrap',
          }}
        >
          <MaterialIcon src="check_circle" filled />
          <Stack spacing="xs" align="start">
            <Typography size="md">{companyName}</Typography>
            <Typography size="md">{form.organizationNumber}</Typography>
          </Stack>
          <ActionIcon
            onClick={clearSelectedCompany}
            icon="close"
            style={{ marginLeft: 'auto' }}
          />
        </Group>
      </Card>
    </>
  ) : (
    <>
      <Typography weight={400}>
        {t('Onboarding.SelectCompanySearchDescription')}
      </Typography>
      <Autocomplete
        placeholder={
          menuIsOpen
            ? ''
            : (t('Onboarding.SelectCompanySearchValueMissingPrompt') as string)
        }
        label={t('Onboarding.SelectCompanySearchInputLabel')}
        loadOptions={loadOptions}
        onChange={handleCompanySelect}
        inputValue={searchValue}
        onInputChange={setSearchValue}
        components={{
          DropdownIndicator: undefined,
          LoadingMessage,
          NoOptionsMessage,
          Option: CustomOption,
        }}
        onBlur={() => setRequestHasBeenMade(false)}
        ref={otherCompanyInputRef}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
        autoFocus
      />
    </>
  );
};

export default CompanySearchVariant;
