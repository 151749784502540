import { CountryCode } from '~/enums';

const Holidays = {
  [CountryCode.SE]: {
    "New Year's Day": '2022-01-01',
    'Epiphany Day': '2022-01-06',
    'Good Friday': '2022-04-15',
    'Easter Sunday': '2022-04-17',
    'Easter Monday': '2022-04-18',
    'May Day': '2022-05-01',
    'Ascension Day': '2022-05-26',
    'Whit Sunday': '2022-06-05',
    'National Day': '2022-06-06',
    'Midsummer Day': '2022-06-25',
    "All Saints' Day": '2022-11-05',
    'Christmas Day': '2022-12-25',
    '2nd Day of Christmas': '2022-12-26',
  },
  [CountryCode.BE]: {},
  [CountryCode.FI]: {},
  [CountryCode.NL]: {},
  [CountryCode.NO]: {},
  [CountryCode.DK]: {},
  // TODO: (DE) Add German holidays
  [CountryCode.DE]: {},
};

export const numberOfBusinessDaysFromToday = (
  market: CountryCode,
  businessDays = 0,
  payment_date: Date = new Date()
) => {
  const today = payment_date;
  const day = today.getDay();
  let result;

  if (day === 4 || day === 5) {
    result = today.setDate(today.getDate() + businessDays + 2);
  } else if (day === 6) {
    result = today.setDate(today.getDate() + businessDays + 1);
  } else {
    result = today.setDate(today.getDate() + businessDays);
  }

  while (isANonWorkingDay(today, market)) {
    result = today.setDate(today.getDate() + 1);
  }

  return result;
};

const isANonWorkingDay = (date: Date, market: CountryCode) =>
  isWeekend(date) || isPublicHoliday(date, market);

const isWeekend = (date: Date) => {
  const selectedDay = date.getDay();

  return !!(selectedDay === 0 || selectedDay === 6);
};

const isPublicHoliday = (date: Date, market: CountryCode) => {
  const arrayOfHolidays = Object.values(Holidays[market]);

  return arrayOfHolidays.some(
    (holiday) => new Date(holiday).toDateString() === date.toDateString()
  );
};
