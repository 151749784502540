import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import { formatAmount, removeNonDigits } from '~/helpers/formatters.helper';
import NumberInputField from '~/components/shared/Input/NumberInputField';
import { getCurrency } from '@qred/shared-component-library/src/currency';
import { LoanAmountDefaultValues } from '~/constants/markets';
import Slider from '../Slider/Slider';

export enum LoanAmountComponentTypes {
  SLIDER = 'slider',
}

interface LoanAmountProps {
  componentType?: LoanAmountComponentTypes;
  loanRemaining?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  defaultValue?: string;
  value?: string;
  label?: string;
  dataCy?: string;
}

const LoanAmount = ({
  loanRemaining,
  onBlur,
  onChange,
  value,
  name,
  label,
  componentType,
  dataCy,
}: LoanAmountProps) => {
  const { market } = useSelector((state: RootState) => state.intl);

  const loanRemainingValue = loanRemaining || 0;
  const availableMaxTopupAmount =
    LoanAmountDefaultValues[market].max - loanRemainingValue;
  const defaultAmount = LoanAmountDefaultValues[market].min * 10;

  const defaultValues = {
    onBlur,
    onChange,
    value: value || '',
    name,
    label,
  };

  switch (componentType) {
    case LoanAmountComponentTypes.SLIDER:
      return (
        <Slider
          min={LoanAmountDefaultValues[market].min}
          max={availableMaxTopupAmount}
          step={LoanAmountDefaultValues[market].min}
          {...defaultValues}
        />
      );
    default:
      return (
        <NumberInputField
          {...defaultValues}
          dataCy={dataCy}
          validationType="LoanAmount"
          placeholder={formatAmount(
            Number(removeNonDigits(defaultAmount.toString()))
          )}
          rightText={defaultValues.value ? getCurrency(market).unit : ''}
          data-record
        />
      );
  }
};

export default LoanAmount;
