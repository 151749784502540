import initialState from '~/store/initialState';
import { AiiaAction, SET_API_STATUS } from '../types/aiiaTypes';
import { ApiStatus } from '../types/sharedTypes';

interface AiiaState {
  readonly apiStatus: ApiStatus;
}

const aiiaReducer = (
  state = initialState.aiia,
  action: AiiaAction
): AiiaState => {
  switch (action.type) {
    case SET_API_STATUS:
      return { ...state, apiStatus: action.payload };

    default:
      return state;
  }
};

export default aiiaReducer;
