import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@qred/components-library';
import { numberOfBusinessDaysFromToday } from '~/helpers/getNextWorkingDay.helper';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import Group from '~/components/shared/Layout/Group';
import Stack from '~/components/shared/Layout/Stack';
import Button, { ButtonType } from '~/components/shared/Button/button';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import PaymentCalculatorModal from '~/pages/OnboardingOffer/components/PaymentCalculator/PaymentCalculatorModal';
import useModals from '~/hooks/useModals';
import Divider from '~/components/shared/Divider/Divider';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import useDateFormatter from '~/hooks/useDateFormatter';
import { useOnboardingOfferSelectors } from '~/store/selectors/onboardingOffer.selector';
import PersonalLiability from '../PersonalLiability/PersonalLiability';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { BottomContent, SummaryHeader } from './ContractSummary.styled';
import { useCurrencyFormatter } from '~/hooks/formatter/useCurrencyFormatter';

const ContractSummary = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const modals = useModals();

  const formatDate = useDateFormatter();
  const currencyFormatter = useCurrencyFormatter();

  const {
    onboardingOffer: { form, overview },
    intl: { market },
    matchedMedia,
  } = useSelector((state: RootState) => state);

  const {
    monthlyAmortization,
    monthlyFee,
    monthlyPayment,
    loanAmount,
  } = useOnboardingOfferSelectors();

  const payoutDate = new Date(numberOfBusinessDaysFromToday(market, 1));

  const handleOpenPaymentScheduleModal = () => {
    dispatch(
      pushToGtmOnboardingAction({
        actionName: 'loan_offer_payment_plan_open',
        stepActionNumber: 0.1,
      })
    );

    modals.openModal({
      header: (
        <Typography weight={600}>
          {translate('PaymentCalculator.PaymentSchedule')}
        </Typography>
      ),
      children: (
        <PaymentCalculatorModal
          amount={loanAmount}
          loanPeriod={form.loanPeriod}
          monthlyFee={monthlyFee}
          campaignType={overview.campaignType}
          campaignDiscount={overview.campaignDiscount}
          campaignPrincipalFreeMonths={overview.campaignPrincipalFreeMonths}
          campaignInterestRateDiscount={overview.campaignInterestRateDiscount}
        />
      ),
      footer: (
        <Button type={ButtonType.Outlined} onClick={modals.closeAll}>
          {translate('Ok')}
        </Button>
      ),
      fullScreen: matchedMedia.smallScreenPortrait,
      fixedHeaderAndFooter: matchedMedia.smallScreenPortrait,
      filledBackground: true,
      size: 'xl',
    });
  };

  return (
    <Stack spacing="lg" grow>
      <SummaryHeader>
        <Stack align="center" spacing="md">
          <Typography weight={600}>
            {translate('OnboardingOffer.QredPaysYou')}
          </Typography>
          <Typography weight={600} size={32} letterSpacing={0}>
            {currencyFormatter.format(form.payoutAmount)}
          </Typography>
          <Typography>
            {formatDate(payoutDate, {
              month: 'long',
              day: '2-digit',
              year: 'numeric',
            })}
          </Typography>
        </Stack>
      </SummaryHeader>
      <Stack justify="space-between">
        {!!overview.currentLoanAmountLeft && (
          <Stack spacing="xs">
            <Group justify="space-between">
              <Typography lineHeight="xl">{translate('TotalLoan')}</Typography>
              <Typography lineHeight="xl">
                {currencyFormatter.format(loanAmount)}
              </Typography>
            </Group>
            <Group justify="space-between">
              <Typography lineHeight="xl">
                {translate('OnboardingOffer.LoanTerms')}
              </Typography>
              <Typography lineHeight="xl">
                {`${form.loanPeriod} ${translate('Months')}`}
              </Typography>
            </Group>
          </Stack>
        )}
        <Stack spacing="xs">
          <Group justify="space-between">
            <Typography lineHeight="xl">
              {translate('MonthlyAmortization')}
            </Typography>
            <Typography lineHeight="xl">
              {currencyFormatter.format(monthlyAmortization)}
            </Typography>
          </Group>
          <Group justify="space-between">
            <Typography lineHeight="xl">{translate('MonthlyFee')}</Typography>
            <Typography lineHeight="xl">
              {currencyFormatter.format(monthlyFee)}
            </Typography>
          </Group>
          <Group justify="space-between">
            <Typography lineHeight="xl" weight={600}>
              {translate('OnboardingOffer.YouPayQred')}
            </Typography>
            <Typography lineHeight="xl" weight={600}>
              {currencyFormatter.format(monthlyPayment)}
            </Typography>
          </Group>
        </Stack>
      </Stack>
      <Stack>
        <Button
          type={ButtonType.Tertiary}
          onClick={handleOpenPaymentScheduleModal}
        >
          <Group align="center" justify="center" spacing="sm">
            <Icon src={SvgSrc.CalendarDay} size={ComponentSize.Small} />
            {translate('ViewPaymentSchedule')}
          </Group>
        </Button>
        <Divider />
      </Stack>

      <BottomContent spacing="sm">
        <PersonalLiability />
        <TermsAndConditions />
      </BottomContent>
    </Stack>
  );
};

export default ContractSummary;
