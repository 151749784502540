import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  FormStatus,
  SimpleEvent,
  ValidationContext,
  ValidationType,
} from '~/components/hoc/withValidation';
import pushToGtmHelper from '~/helpers/pushToGtm.helper';
import useTranslate from '~/hooks/useTranslate';
import { BaseArrangement } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import { Typography } from '@qred/components-library';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';

const SliderContainer = styled(BaseArrangement)`
  width: 100%;
  margin: 0.4em 0;
  color: ${theme.colors.primaryGray};
`;

const Label = styled.label<{ labelColor?: string }>`
  color: ${(props) => props.labelColor || theme.colors.secondaryGray};
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
`;

const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 0.7em;
  border-radius: 1em;
  background-color: ${theme.colors.backdrop};
  border: 1px solid ${theme.colors.primaryGray};
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-top: 1em;
  cursor: pointer;

  &::-webkit-slider-thumb {
    cursor: pointer;
    background-color: ${theme.colors.primaryGreen};
    border-radius: 5em;
    -webkit-appearance: none;
    appearance: none;
    width: 2em;
    height: 2em;
  }

  &:hover::-webkit-slider-thumb {
    background-color: ${theme.colors.hoverGreen};
  }

  &:active::-webkit-slider-thumb {
    background-color: ${theme.colors.primaryGreen};
  }

  &::-moz-range-thumb {
    cursor: pointer;
    background-color: ${theme.colors.primaryGreen};
    border-radius: 5em;
    -webkit-appearance: none;
    appearance: none;
    width: 2.5em;
    height: 2.5em;
  }

  &:hover::-moz-range-thumb {
    background-color: ${theme.colors.hoverGreen};
  }

  &:active::-moz-range-thumb {
    background-color: ${theme.colors.primaryGreen};
  }

  &::-ms-thumb {
    cursor: pointer;
    background-color: ${theme.colors.primaryGreen};
    border-radius: 5em;
    -webkit-appearance: none;
    appearance: none;
    width: 2.5em;
    height: 2.5em;
  }

  &:hover::-ms-thumb {
    background-color: ${theme.colors.hoverGreen};
  }

  &:active::-ms-thumb {
    background-color: ${theme.colors.primaryGreen};
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.5em;
`;

interface Props {
  min: number;
  max: number;
  step: number;
  name: string;
  label?: string;
  labelColor?: string;
  value?: string | number;
  defaultValue?: string | number;
  validationType?: ValidationType;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  dataCy?: string;
  minDescription?: string;
  maxDescription?: string;
}

const Slider: React.FC<Props> = (props) => {
  const id = `${props.name}slider-id`;

  const [touched, setTouched] = useState(false);
  const validationContext = useContext(ValidationContext);
  const translate = useTranslate();
  const { validationErrors } = validationContext;

  useEffect(() => {
    const fakeEvent: SimpleEvent = {
      target: {
        name: props.name,
        value: props.value || props.defaultValue,
        dataset: { validationType: props.validationType },
      },
    };
    validationContext.onChangeHOC(fakeEvent);
  }, [props.value, props.defaultValue]);

  useEffect(() => {
    if (touched) {
      pushToGtmHelper('q_user_a_slider_select');
    }
  }, [touched, props.value]);

  useEffect(() => {
    // values sent to this function are of type string, but in order to do calculation they must be of type number.
    // We can take advantage of JS's coercion capability here, therefore type "any" to relax TS.
    function generateBackground(value: any, min: any, max: any) {
      if (value === min) {
        return '';
      }

      const percentage = ((value - min) / (max - min)) * 100;
      return `linear-gradient(to right, #214749, #214749 ${percentage}%, #F2F3F0 ${percentage}%, #F2F3F0 100%)`;
    }

    function onInput(e: Event) {
      const sliderElement = e.target as HTMLInputElement;
      sliderElement.style.background = generateBackground(
        sliderElement.value,
        sliderElement.min,
        sliderElement.max
      );
    }

    const rangeElement = document.querySelector(`#${id}`) as HTMLInputElement;
    rangeElement?.addEventListener('input', onInput);

    if (rangeElement) {
      rangeElement.style.background = generateBackground(
        rangeElement.value,
        rangeElement.min,
        rangeElement.max
      );
    }

    return () => {
      rangeElement?.removeEventListener('input', onInput);
    };
    // The reason for adding 'props.max' is in some places we need to change the max value and we need to re-call this part.
  }, [props.value, props.max]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur && props.onBlur(e);
    setTouched(true);
  };

  const validationErrorShouldBeShown =
    (touched || validationContext.formStatus === FormStatus.SUBMITTED) &&
    props.validationType &&
    validationErrors[props.name];

  return (
    <SliderContainer>
      {props.label && (
        <Label labelColor={props.labelColor} htmlFor={id}>
          {props.label}
        </Label>
      )}
      <Input
        id={id}
        type="range"
        data-cy={props.dataCy}
        min={props.min}
        max={props.max}
        step={props.step}
        data-validation-type={props.validationType}
        name={props.name}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange}
        onBlur={handleBlur}
        key={`slider-key:${props.defaultValue}`}
        className={validationErrorShouldBeShown ? 'has-error' : ''}
      />
      {props.minDescription && props.maxDescription && (
        <FlexRow>
          <Typography color="secondary.300" size={'sm'} weight={900}>
            {props.minDescription}
          </Typography>
          <Typography color="secondary.300" size={'sm'} weight={900}>
            {props.maxDescription}
          </Typography>
        </FlexRow>
      )}
      {validationErrorShouldBeShown && (
        <ValidationErrorMessage>
          {translate(`ValidationErrors.${props.validationType?.split('_')[0]}`)}
        </ValidationErrorMessage>
      )}
    </SliderContainer>
  );
};

export default Slider;
