import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { theme } from '~/styles/themes';
import Button, { ButtonType } from '../Button/button';
import Card from '../Card/Card';

const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  align-items: center;
  justify-content: center;
  z-index: 9999;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
`;

const CloseButtonIcon = styled(Icon)`
  z-index: 9999;
  cursor: pointer;
  position: absolute;
  top: 0.5em;
  right: 0.5em;

  svg {
    color: ${theme.colors.dark};
  }

  svg:hover:not([disabled]) {
    color: ${theme.colors.hoverDark};
  }
`;

const PopupContainer = styled.div<{ wide?: boolean }>`
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  padding: 2em;
  width: 100%;
  @media (orientation: landscape) {
    max-width: ${(props) => (props.wide ? '60em' : '30em')};
  }
`;

const ScrollableContent = styled.div`
  max-height: 70vh;
  overflow: auto;
`;

const PopupContent = styled.div`
  padding: 1em;
`;

export interface PopupBaseProps {
  isOpen: boolean;
  onOkButtonClick: () => void;
  buttonText?: string;
  wide?: boolean;
  closeOnClickOutside?: boolean;
  persistent?: boolean;
}

// 'modal-root' is a sibling to 'app-root'
const modalRoot = document.getElementById('modal-root');

const Popup: React.FC<PopupBaseProps> = (props) => {
  // element to which the modal will be rendered
  const el = document.createElement('div');

  useEffect(() => {
    // append to root when the children of Modal are mounted
    modalRoot && modalRoot.appendChild(el);

    return () => {
      modalRoot && modalRoot.removeChild(el);
    };
  }, [el]);

  return props.isOpen
    ? createPortal(
        <>
          <Backdrop
            onClick={
              props.closeOnClickOutside || false
                ? props.onOkButtonClick
                : undefined
            }
            isOpen={props.isOpen}
          >
            <PopupContainer wide={props.wide}>
              <Card>
                {!props.persistent && (
                  <CloseButtonIcon
                    src={SvgSrc.Times}
                    onClick={props.onOkButtonClick}
                  />
                )}

                <PopupContent>
                  <ScrollableContent>{props.children}</ScrollableContent>
                  {!props.persistent && (
                    <Button
                      type={ButtonType.SecondaryInverted}
                      onClick={props.onOkButtonClick}
                      mt={1}
                    >
                      {props.buttonText || 'OK'}
                    </Button>
                  )}
                </PopupContent>
              </Card>
            </PopupContainer>
          </Backdrop>
        </>,
        el
      )
    : null;
};

export default Popup;
