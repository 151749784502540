import {
  OfferAction,
  SET_ACCESS_TOKEN_ERROR,
  SET_HAS_OFFER,
  SET_OFFER_DATA,
} from '~/store/types/loanOfferTypes';
import { apiGetOffer } from '~/services/api';
import { offerDataMapper } from '~/helpers/mappers.helper';
import { Offer } from '~/Interfaces';
import { GlobalErrorType } from '~/enums';
import { pushToSentryAction } from '~/store/actions/sentryActions';
import { AppThunk } from '../types/sharedTypes';
import { toggleGlobalErrorOn } from '../slices/globalError.slice';

// =========================== Action creators ===============================
export const setOfferData = (offerData: Offer[]): OfferAction => ({
  type: SET_OFFER_DATA,
  payload: offerData,
});

export const setHasOffer = (status: boolean): OfferAction => ({
  type: SET_HAS_OFFER,
  payload: status,
});

const loanOfferhasAccessTokenError = (status: boolean): OfferAction => ({
  type: SET_ACCESS_TOKEN_ERROR,
  payload: status,
});

// ============================== Thunks ================================
export const fetchOfferData = (companyId: number): AppThunk => async (
  dispatch
) => {
  try {
    const res = await apiGetOffer(companyId);
    const offers = res.data.offer?.map((o: any) => offerDataMapper(o));

    if (offers && offers.length) {
      dispatch(setOfferData(offers));
      dispatch(setHasOffer(true));
    } else {
      dispatch(setHasOffer(false));
    }
  } catch (err) {
    if (err.message === 'No ACCESSS_TOKEN found!') {
      dispatch(loanOfferhasAccessTokenError(true));
    } else {
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    }
    dispatch(pushToSentryAction(err, 'apiGetOffer'));
  }
};
