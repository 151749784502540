import {
  Application,
  Loan,
  PreviousLoans,
  TerminatedContract,
} from '~/Interfaces';
import { ApiStatus } from './sharedTypes';

export const UPDATE_LOANS_DATA = 'UPDATE_LOANS_DATA';
export const UPDATE_TERMINATED_CONTRACT_DATA =
  'UPDATE_TERMINATED_CONTRACT_DATA';
export const UPDATE_APPLICATIONS_DATA = 'UPDATE_APPLICATIONS_DATA';
export const TOGGLE_NOTIFICATIONS_STATUS = 'TOGGLE_NOTIFICATIONS_STATUS';
export const SET_API_APPLICATIONS_STATUS = 'SET_API_APPLICATIONS_STATUS';
export const SET_API_LOANS_STATUS = 'SET_API_LOANS_STATUS';
export const SET_API_AMOUNT_LEFT_STATUS = 'SET_API_AMOUNT_LEFT_STATUS';
export const SET_AMOUNT_TO_PAY_BACK = 'SET_AMOUNT_TO_PAY_BACK';
export const UPDATE_PREVIOUS_LOANS = 'UPDATE_PREVIOUS_LOANS';

interface UpdateLoansOverviewAction {
  type: typeof UPDATE_LOANS_DATA;
  payload: Array<Loan>;
}
interface UpdateApplicationsAction {
  type: typeof UPDATE_APPLICATIONS_DATA;
  payload: Array<Application>;
}
interface UpdateTerminatedContractAction {
  type: typeof UPDATE_TERMINATED_CONTRACT_DATA;
  payload: TerminatedContract;
}

interface ToggleNotificationsStatusAction {
  type: typeof TOGGLE_NOTIFICATIONS_STATUS;
  payload: boolean;
}
interface UpdateApiApplicationsStatusAction {
  type: typeof SET_API_APPLICATIONS_STATUS;
  payload: ApiStatus;
}

interface UpdateApiLoansStatusAction {
  type: typeof SET_API_LOANS_STATUS;
  payload: ApiStatus;
}

interface UpdateApiAmountLeftStatusAction {
  type: typeof SET_API_AMOUNT_LEFT_STATUS;
  payload: ApiStatus;
}

interface UpadateAmountToPayBack {
  type: typeof SET_AMOUNT_TO_PAY_BACK;
  payload: number;
}

interface UpdatePreviousLoans {
  type: typeof UPDATE_PREVIOUS_LOANS;
  payload: Array<PreviousLoans>;
}

export type LoanAction =
  | UpdateLoansOverviewAction
  | UpdateApplicationsAction
  | ToggleNotificationsStatusAction
  | UpdateTerminatedContractAction
  | UpdateApiApplicationsStatusAction
  | UpdateApiAmountLeftStatusAction
  | UpadateAmountToPayBack
  | UpdateApiLoansStatusAction
  | UpdatePreviousLoans;
