import {
  ApplicationStatus,
  ClientType,
  CountryCode,
  FlowTypes,
  GlobalErrorType,
  SignerType,
} from '~/enums';
import {
  GlobalErrorState,
  HelpPopupState,
  IntlState,
  MatchedMediaBreakPoints,
  ModalsState,
  OwnerType,
} from '~/Interfaces';
import { KlarnaKosmaFlowStatus } from '~/hooks/useKlarnaFlow/types';
import {
  defaultLanguagePerMarket,
  getLanguageFromLocalStorage,
  getMarketFromLocalStorage,
  languageIsValidForMarket,
} from '~/helpers/market.helper';
import { getMarketBasedOnHostname } from '~/helpers/utils';
import { CardInvoiceDay } from '~/types/types';
import { ApiStatus, Language } from './types/sharedTypes';
import { ICardOnboardingOfferState } from '~/interfaces/CardOnboardingOffer';
import { ILoanOnboardingApplicationState } from '~/interfaces/LoanOnboardingApplication';
import { ILoanOnboardingOfferState } from '~/interfaces/LoanOnboardingoffer';
import { IAdditionalSignerState } from '~/interfaces/AdditionalSigner';

export const onboardingApplicationInitialState = Object.freeze<ILoanOnboardingApplicationState>(
  {
    currentStep: 0,
    stepStartTimestamp: new Date().toString(),
    // Flowtype is manual unless user authenticates in launchview
    flowType: FlowTypes.Manual,
    basicApplicationSent: false,
    isCompanyTypeCategoryRequested: false,
    form: {
      clientType: ClientType.Unknown,
      guarantors: [],
      purposeOfLoan: '',
      organizationNumber: '',
      companyType: '',
      reason: '',
      sessionId: '',
      applicantIdentified: false,
      applicantAuthenticated: false,
      turnover: '',
      applicant: {},
      incomeReceivedInForeignCurrencies: undefined,
      companyTypeCategory: undefined,
    },
    overview: {
      clients: [],
    },
    apiStatus: {
      guarantors: ApiStatus.Idle,
      clients: ApiStatus.Idle,
      post: ApiStatus.Idle,
      patch: ApiStatus.Idle,
      companyLookupData: ApiStatus.Idle,
      application: ApiStatus.Idle,
      companySearch: ApiStatus.Idle,
    },
    applicationStatus: ApplicationStatus.None,
    companyIsNew: false,
  }
);

export const loanOnboardingOfferInitialState = Object.freeze<ILoanOnboardingOfferState>(
  {
    currentStep: 1,
    stepStartTimestamp: new Date().toString(),
    clientType: ClientType.Unknown,
    overview: {
      minPayoutAmount: 0,
      defaultPayoutAmount: 0,
      amountCannotBeChanged: false,
      defaultTerm: 0,
      maxPayoutAmount: 0,
      maxTerm: 0,
      minTerm: 0,
      currentLoanAmountLeft: 0,
      accountNumber: '',
      answeredDate: '',
      applicationId: 0,
      applicationUuid: '',
      bankClearing: '',
      campaignDiscount: 0,
      campaignInterestRateDiscount: 0,
      campaignPrincipalFreeMonths: 0,
      campaignType: null,
      clientId: 0,
      companyNumber: '',
      companyType: '',
      email: '',
      interestPercent: 0,
      landingUrl: '',
      language: '',
      lenderName: '',
      lenderRep: '',
      phone: '',
      loanPurpose: '',
      loanPurposeManual: '',
      owners: [],
      personalId: '',
      reason: '',
      rPartnerId: 0,
      signers: [],
      granter: '',
    },
    fetchedBeneficialOwners: [],
    apiStatus: {
      offer: ApiStatus.Idle,
      post: ApiStatus.Idle,
      fetchKycQuestions: ApiStatus.Idle,
      postKycAnswers: ApiStatus.Idle,
      fetchBeneficialOwners: ApiStatus.Idle,
    },
    form: {
      account: {},
      applicationId: 0,
      payoutAmount: 0,
      clientId: 0,
      guarantors: [],
      invoiceEmail: '',
      clientName: '',
      isPolicyAgreed: true,
      accountNumber: '',
      kycQuestionnaireQuestions: [],
      kycQuestionnaireAnswers: [],
      loanPeriod: 0,
      loanPurposeManual: '',
      offerIsAgreed: false,
      owners: [],
      ownershipFiles: [],
      personalLiabilityIsAgreed: false,
      personalNumber: '',
      purposeOfLoan: '',
      regularMailForInvoices: false,
      signers: [],
      applicant: {
        fullName: '',
        personalNumber: '',
        percentage: '',
        id: '',
      },
    },
  }
);

export const helpPopupInitialState: HelpPopupState = Object.freeze({
  isOpen: false,
});

export const globalErrorInitialState: GlobalErrorState = Object.freeze({
  hasError: false,
  errorType: GlobalErrorType.API_FAILURE,
  showBanner: false,
  showCardOfferBanner: false,
  isTokenSet: false,
  globalErrorPersistent: false,
});

export const matchedMediaInitialState: MatchedMediaBreakPoints = Object.freeze({
  mobile: false,
  smallScreen: false,
  mobileLandscape: false,
  smallScreenPortrait: false,
  mediumScreen: false,
  bigScreen: false,
  portrait: false,
  landscape: false,
  tablet: false,
  desktop: false,
});

const initialMarket = getMarketFromLocalStorage() ?? getMarketBasedOnHostname();
let initialLanguage;
const localStorageLanguage = getLanguageFromLocalStorage();

if (initialMarket) {
  if (
    localStorageLanguage &&
    languageIsValidForMarket(localStorageLanguage, initialMarket)
  ) {
    initialLanguage = localStorageLanguage;
  } else {
    initialLanguage = defaultLanguagePerMarket[initialMarket];
  }
}

export const intlInitialState: IntlState = Object.freeze({
  market: initialMarket ?? CountryCode.SE,
  marketIsSet: !!initialMarket,
  language: initialLanguage ?? Language.English,
  languageIsSet: !!initialLanguage,
});

export const globalModalInitialState: ModalsState = Object.freeze({
  modals: [],
  current: null,
});

window.__QRED_MARKET__ = initialMarket ?? CountryCode.SE;

const initialState = {
  aiia: {
    apiStatus: ApiStatus.Idle,
  },
  klarnaKosma: {
    transactionFlow: {
      status: 'idle' as KlarnaKosmaFlowStatus,
      error: '',
    },
  },
  eskat: {
    apiStatus: ApiStatus.Idle,
  },
  user: {
    info: {
      firstName: '',
      lastName: '',
      personalNumber: '',
      phone: '',
      email: '',
      city: '',
      zip: '',
      street: '',
      country: '',
      language: null,
    },
    userIsSet: false,
    companies: [],
    activeCompany: undefined,
    avatar: undefined,
    apiUserInfoStatus: ApiStatus.Idle,
  },
  loans: {
    overview: [],
    application: [],
    areNotificationsSeen: false,
    terminatedContract: [],
    apiApplicationsStatus: ApiStatus.Idle,
    apiLoansStatus: ApiStatus.Idle,
    apiAmountLeftStatus: ApiStatus.Idle,
    amountToPayBack: 0,
    previousLoans: [],
  },
  applyLoan: {
    newLoanData: {
      companyName: '',
      companyId: 0,
      selectedAmount: 0,
      purposeOfLoan: '',
      loanPurposeManual: '',
    },
    loanIsApplied: false,
    isSubmitting: false,
    isInitiated: false,
  },
  invoices: {
    relatedLoanId: 0,
    apiDownloadFinalInvoiceStatus: ApiStatus.Idle,
    unpaid: [],
    paid: [],
  },
  loanOffer: {
    overview: [],
    hasOffer: false,
    isSubmitting: false,
    hasAccessTokenError: false,
    userData: {
      amount: 0,
      toPayout: 0,
      yourOwnShares: '',
      owners: [
        {
          name: '',
          personalNumber: '',
          percentage: '',
          id: '01',
        },
      ],
      signers: [],
      accountNumber: '',
      invoiceEmail: '',
      purposeOfLoan: '',
      loanPurposeManual: '',
      ownerType: '',
      loanPeriod: 0,
      monthlyFee: 0,
      clientId: 0,
      applicationId: 0,
      personalNumber: '',
      clientName: '',
      monthlyAmortization: 0,
      offerIsAgreed: false,
      personalLiabilityIsAgreed: false,
      regularMailForInvoices: false,
      isPolicyAgreed: true,
    },
  },
  cardOffer: {
    overview: [],
    userData: {
      applicationUuid: '',
      creditLimit: 0,
      interest: 0,
      invoiceAddress: '',
      invoiceEmail: '',
      offeredCreditLimit: 0,
      isPaperInvoiceChosen: false,
      shippingAddress: '',
      companyName: '',
      companyId: 0,
      companyType: '',
      additionalSigners: [],
      invoiceDay: 'MID_OF_MONTH' as CardInvoiceDay,
      ownerStructure: '' as OwnerType,
      applicantOwnerShare: undefined,
      beneficialOwners: [],
      applicationReasons: [],
      hasAcceptedTerms: false,
      hasAcceptedPersonalGuarantee: false,
      isPersonalGuaranteeRequired: false,
      cardOfferAlreadySigned: false,
      companyNo: null,
      applicant: null,
    },
    hasOffer: false,
    isSubmitting: false,
  },
  shippingDetails: {
    overview: undefined,
    userData: {
      cardHolderFullName: {
        firstName: '',
        lastName: '',
      },
      cardHolderSelectedFullName: {
        firstName: '',
        lastName: '',
      },
      companyName: '',
      companyType: '',
      mobilePhone: '',
      email: '',
      addresses: [],
      companyNameOnCard: '',
      shouldHaveCompanyNameOnCard: true,
      address: {
        street: '',
        city: '',
        country: '',
        zipCode: '',
      },
      cardAlreadyCreated: false,
    },
    isSubmitting: false,
    isCardCreated: false,
    isCardCreationFailed: false,
    hash: undefined,
  },
};
export const cardOnboardingOfferInitialState = Object.freeze<ICardOnboardingOfferState>(
  {
    currentStep: 1,
    stepStartTimestamp: new Date().toString(),
    form: {
      clientId: 0,
      owners: [],
      signers: [],
      personalNumber: '',
      ownershipFiles: [],
      kycQuestionnaireQuestions: [],
      kycQuestionnaireAnswers: [],
      applicationReasons: [],
      applicant: {
        firstName: '',
        lastName: '',
        fullName: '',
        id: '',
        email: '',
        percentage: '',
        phoneNumber: '',
        signerId: 0,
      },
    },
    overview: {
      applicationUuid: '',
      creditLimit: 0,
      interest: 0,
      invoiceAddress: '',
      invoiceEmail: '',
      offeredCreditLimit: 0,
      isPaperInvoiceChosen: false,
      shippingAddress: '',
      companyName: '',
      clientId: 0,
      companyType: '',
      additionalSigners: [],
      invoiceDay: 'MID_OF_MONTH',
      beneficialOwners: [],
      applicationReasons: [],
      hasAcceptedTerms: false,
      hasAcceptedPersonalGuarantee: false,
      isPersonalGuaranteeRequired: false,
      cardOfferAlreadySigned: false,
      companyNo: '',
      applicant: {
        firstName: '',
        lastName: '',
        fullName: '',
        id: '',
        email: '',
        percentage: '',
        phoneNumber: '',
        signerId: 0,
      },
      alternativeBeneficialOwners: [],
    },
    apiStatus: {
      offer: ApiStatus.Idle,
      post: ApiStatus.Idle,
      fetchKycQuestions: ApiStatus.Idle,
      postKycAnswers: ApiStatus.Idle,
    },
  }
);

export const additionalSignerInitialState = Object.freeze<IAdditionalSignerState>(
  {
    otpExpiryTimestamp: null,
    otp: '',
    otpError: null,
    timerCompleted: false,
    additionalSignerLoanApplicationOffer: {
      amount: '',
      bank_account: '',
      company_name: '',
      company_no: '',
      first_name: '',
      landing_url: '',
      language: '',
      last_name: '',
      monthly_fee: '',
      monthly_payment: '',
      payment_due: '',
      personal_id: '',
      signer_type: SignerType.Signer,
      term: 0,
      granter: '',
    },
    additionalSignerCardApplicationOffer: {
      cardApplicationUuid: '',
      companyName: '',
      hasAcceptedPersonalGuarantee: false,
      hasAcceptedTerms: false,
      interest: 0,
      offeredCreditLimit: 0,
      signerUuid: '',
    },
    apiStatus: {
      initiateOTP: ApiStatus.Idle,
      regenerateOTP: ApiStatus.Idle,
      verifyOTP: ApiStatus.Idle,
      fetchAdditionalSignerLoanApplication: ApiStatus.Idle,
      fetchAdditionalSignerCardApplication: ApiStatus.Idle,
      postAdditionalSignerCardApplication: ApiStatus.Idle,
      postAdditionalSignerLoanApplication: ApiStatus.Idle,
    },
  }
);

export default initialState;
