import React, { useEffect, useState } from 'react';
import withGtmEvent from '~/components/hoc/withGtmEvent';
import { CountryCode } from '~/enums';
import { useDispatch } from 'react-redux';
import Logo from '~/components/shared/LogoOld/LogoOld';
import PageHeader from '~/components/shared/PageHeader/PageHeader';
import { defaultLanguageToMarket, setMarket } from '~/store/slices/intl.slice';
import { BodyContainer, MainContainer } from '~/styles/BaseStyledComponents';
import { Route } from 'react-router-dom';
import constVars from '~/constants/constVars';
import { parseMarket } from '~/helpers/market.helper';
import ThankYouCard from './components/ThankYouCard';
import ThankYouPageTitle from './components/ThankYouPageTitle';
import UserSurvey from './components/UserSurvey';

const ThankYou = () => {
  const dispatch = useDispatch();
  const [showSurvey, setShowSurvey] = useState(false);

  const market = parseMarket(localStorage.getItem('qredMarket'));

  useEffect(() => {
    const shouldShowSurvey = (marketValue: CountryCode) =>
      [CountryCode.SE].includes(marketValue);

    if (market) {
      dispatch(setMarket(market));
      dispatch(defaultLanguageToMarket(market));
      setShowSurvey(shouldShowSurvey(market));
    }
  }, [market]);

  return (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
        <ThankYouPageTitle />
      </PageHeader>
      <BodyContainer style={{ marginBottom: '2em' }}>
        <ThankYouCard />
      </BodyContainer>
      <Route
        path={[`/${constVars.ROUTE_THANK_YOU}/${constVars.ROUTE_CARD_CREATED}`]}
      >
        {showSurvey && <UserSurvey />}
      </Route>
    </MainContainer>
  );
};

export default withGtmEvent(ThankYou);
