import constVars from '~/constants/constVars';
import { ClientType, FlowTypes, StepStatus } from '~/enums';
import pushToGtmHelper from '~/helpers/pushToGtm.helper';
import { AppThunk, GetStateFunc } from '~/store/types/sharedTypes';
import { Dispatch } from 'redux';
import { Company } from '~/Interfaces';
import { selectStepConfig as selectLoanOnboardingOfferStepConfig } from '../selectors/onboardingOffer.selector';
import { selectStepConfig as selectCardOnboardingOfferStepConfig } from '../selectors/cardOnboardingOffer.selector';
import { selectStepConfig as selectLoanOnboardingApplicationStepConfig } from '../selectors/loanOnboardingApplication.selector';
import posthog from 'posthog-js';

type EventName =
  | 'home_page'
  | 'loan_overview'
  | 'main'
  | 'loan_application'
  | 'dashboard'
  | 'sidebar'
  | 'bank_integration_public';

interface GtmPropsBase {
  stepStatus?: StepStatus;
  actionName?: string;
  errorMessage?: string;
  stepActionNumber?: number;
}

interface GtmProps extends GtmPropsBase {
  eventName?: EventName;
}

const getUserId = (companies: Company[], activeCompany?: Company) => {
  if (activeCompany) {
    return activeCompany.id;
  }

  const activeCompanyLocalStorage = window.localStorage.getItem(
    'active_company'
  );

  if (activeCompanyLocalStorage) {
    return Number(activeCompanyLocalStorage);
  }

  if (companies.length > 0) {
    return companies[0].id;
  }

  return undefined;
};

export const pushToGtmAction = ({
  stepStatus,
  actionName,
  stepActionNumber,
  errorMessage,
  eventName,
}: GtmProps): AppThunk => (_dispatch: Dispatch, getState: GetStateFunc) => {
  const {
    user: { activeCompany, companies },
    intl: { market, language },
  } = getState();
  const myQredEventName = `myQred_${eventName}`;
  const userId = getUserId(companies, activeCompany);

  pushToGtmHelper(myQredEventName, {
    market,
    language,
    ...(userId && { userId }),
    eventName: myQredEventName,
    errorMessage,
    stepStatus,
    actionName,
    stepActionNumber,
  });
};

export const pushToGtmOnboardingAction = ({
  stepStatus,
  actionName,
  stepActionNumber,
  errorMessage,
}: GtmPropsBase): AppThunk => (_dispatch: Dispatch, getState: GetStateFunc) => {
  const state = getState();
  const { onboardingApplication, onboardingOffer, intl } = state;
  const loanOnboardingOfferStepConfig = selectLoanOnboardingOfferStepConfig(
    state
  );
  const loanOnboardingApplicationStepConfig = selectLoanOnboardingApplicationStepConfig(
    state
  );

  const { market, language } = intl;
  const { pathname } = window.location;

  const isOnboardingOffer = pathname.includes(
    `/${constVars.ROUTE_ONBOARDING_OFFER}`
  );

  const clientType = isOnboardingOffer
    ? onboardingOffer.clientType
    : onboardingApplication.form.clientType;

  const userId = isOnboardingOffer
    ? onboardingOffer.overview.clientId
    : onboardingApplication.userId;

  const {
    clientId,
    flowType: onboardingApplicationFlowType,
  } = onboardingApplication;

  const stepNumber = isOnboardingOffer
    ? onboardingOffer.currentStep
    : onboardingApplication.currentStep;

  const stepConfig = isOnboardingOffer
    ? loanOnboardingOfferStepConfig
    : loanOnboardingApplicationStepConfig;

  const currentStepConfig = stepConfig[stepNumber - 1];
  const stepName = currentStepConfig?.name;

  const eventName = isOnboardingOffer
    ? 'onboarding_offer_flow'
    : 'onboarding_application_flow';

  const { applicationId } = onboardingApplication.form;

  let timeSpentOnStep;

  if (
    actionName &&
    [
      'next_button_pressed',
      'submit_button_pressed',
      'back_button_pressed',
      'skip_button_pressed',
      'auth_button_pressed',
      'start_manual_flow_pressed',
    ].includes(actionName)
  ) {
    const stepStartTimestamp = isOnboardingOffer
      ? onboardingOffer.stepStartTimestamp
      : onboardingApplication.stepStartTimestamp;
    timeSpentOnStep = Math.ceil(
      (new Date().getTime() - new Date(stepStartTimestamp).getTime()) / 1000
    );
  }

  const eventProperties = {
    eventName,
    stepNumber,
    stepName: stepNumber === 0 ? 'landing_page' : stepName,
    clientType,
    ...((userId || clientId) && { userId: userId || clientId }),
    market,
    language,
    stepActionNumber: stepActionNumber
      ? stepNumber + stepActionNumber
      : undefined,
    errorMessage,
    actionName,
    stepStatus,
    flowType: !isOnboardingOffer ? onboardingApplicationFlowType : undefined,
    orderId: !isOnboardingOffer && !!applicationId ? applicationId : undefined,
    timeSpentOnStep,
    timestamp: new Date().toISOString(),
  };

  posthog.capture(eventName, eventProperties); // will not send to posthog if user has opted out of tracking
  pushToGtmHelper(eventName, eventProperties);
};

export const pushToGtmCardOnboardingAction = ({
  stepStatus,
  actionName,
  stepActionNumber,
  errorMessage,
}: GtmPropsBase): AppThunk => (_dispatch: Dispatch, getState: GetStateFunc) => {
  const state = getState();
  const { cardOnboardingOffer, intl } = state;
  const stepConfig = selectCardOnboardingOfferStepConfig(state);

  const { market, language } = intl;

  const {
    form: { clientId },
    overview: { applicationUuid },
    currentStep,
  } = cardOnboardingOffer;

  const stepNumber = currentStep;

  const currentStepConfig = stepConfig[stepNumber - 1];
  const stepName = currentStepConfig?.name;

  const cardOnboardingGtmEventName = 'card_onboarding_offer_flow';

  let timeSpentOnStep;

  if (
    actionName &&
    [
      'next_button_pressed',
      'submit_button_pressed',
      'back_button_pressed',
      'skip_button_pressed',
      'auth_button_pressed',
      'start_manual_flow_pressed',
      'confirm_button_pressed',
    ].includes(actionName)
  ) {
    const { stepStartTimestamp } = cardOnboardingOffer;

    timeSpentOnStep = Math.ceil(
      (new Date().getTime() - new Date(stepStartTimestamp).getTime()) / 1000
    );
  }

  pushToGtmHelper(cardOnboardingGtmEventName, {
    eventName: cardOnboardingGtmEventName,
    stepNumber,
    stepName: stepNumber === 0 ? 'landing_page' : stepName,
    clientType: ClientType.Unknown,
    ...(clientId && { userId: clientId }),
    market,
    language,
    stepActionNumber: stepActionNumber
      ? stepNumber + stepActionNumber
      : undefined,
    errorMessage,
    actionName,
    stepStatus,
    flowType: FlowTypes.Authenticated,
    orderId: applicationUuid ? Number(applicationUuid) : undefined,
    timeSpentOnStep,
    timestamp: new Date().toISOString(),
  });
};
