import React, { useCallback } from 'react';
import {
  Badge,
  Divider,
  Navbar,
  QredLogo,
  Tooltip,
  useWindowProperties,
} from '@qred/components-library';
import { useHistory, useLocation } from 'react-router';
import useLogout from '~/hooks/useLogout';
import { openBecomeTesterPage, openQredCardPage } from '~/services/links';
import useTranslate from '~/hooks/useTranslate';
import { SwitchCompanyButton } from './SwitchCompanyButton';
import { LanguageItemSideBar } from './LanguageItemSideBar';
import { CountryCode } from '~/enums';
import constVars from '~/constants/constVars';
import { posthogActionNames } from '~/constants/posthogActionNames';
import { usePostHogEvents } from '~/hooks/usePostHogEvents';
import { useAccount } from '~/hooks/useAccount';
import posthog from 'posthog-js';

const SideBar = () => {
  const { language, market, companies } = useAccount();
  const { isMobile, isTablet } = useWindowProperties();
  const { capture } = usePostHogEvents();
  const tabletSpacing = isTablet ? 25 : 28;

  const showQredCard = market === CountryCode.SE || market === CountryCode.FI;

  const isQeld = market === CountryCode.NL;

  const t = useTranslate();
  const logout = useLogout();
  const { pathname } = useLocation();
  const history = useHistory();

  const handleLogout = () => {
    logout();
    capture({
      action: posthogActionNames.sidebar.LOG_OUT,
      sidebar: true,
    });
  };

  const onOpenPage = useCallback(
    (name) => {
      switch (name) {
        case 'home':
          history.push('/');
          break;
        case 'qred-visa':
          openQredCardPage(market, language);
          break;
        case 'become-a-tester':
          openBecomeTesterPage(market, language);
          break;
        default:
          break;
      }
    },
    [market, language]
  );

  const handleRedirectToHelpSupportPage = () => {
    history.push(`/${constVars.ROUTE_HELP_AND_SUPPORT}`);
  };

  const handleOnClickBecomeTester = () => {
    onOpenPage('become-a-tester');
    capture({
      action: posthogActionNames.sidebar.BECOME_A_TESTER,
      sidebar: true,
    });
  };

  const handleOnClickQredVisa = () => {
    onOpenPage('qred-visa');
    capture({
      action: posthogActionNames.sidebar.QRED_VISA,
      sidebar: true,
    });
  };

  return (
    <Navbar
      width={247}
      collapseTooltipLabels={{
        open: `${t('Dashboard.OpenSidebar')}`,
        close: `${t('Dashboard.CloseSidebar')}`,
      }}
    >
      <Navbar.Section pl={32} pt={tabletSpacing} pb={40}>
        <QredLogo
          width={81.12}
          qeld={isQeld}
          onClick={() => history.push('/')}
        />
      </Navbar.Section>
      <Navbar.Section mb={20}>
        <Navbar.Link
          active={pathname === `/`}
          onClick={() => history.push(`/`)}
        >
          {t('Home')}
        </Navbar.Link>
        {isMobile && (
          <Navbar.Link
            active={pathname === `/${constVars.ROUTE_PROFILE}`}
            onClick={() => history.push(`/${constVars.ROUTE_PROFILE}`)}
          >
            {t('Profile')}
          </Navbar.Link>
        )}
        <Navbar.Link
          active={pathname === `/${constVars.ROUTE_DOCUMENTS}`}
          onClick={() => history.push(`/${constVars.ROUTE_DOCUMENTS}`)}
        >
          {t('NewDashboard.DocumentsTitle')}
        </Navbar.Link>
        {posthog.isFeatureEnabled('InvoicesPage_release') && (
          <Navbar.Link
            active={pathname === `/${constVars.ROUTE_INVOICES}`}
            onClick={() => history.push(`/${constVars.ROUTE_INVOICES}`)}
          >
            {t('Dashboard.Invoices')}{' '}
            <Tooltip label={t('NewFeature')}>
              <Badge
                color="information.700"
                ml="sm"
                style={{ fontWeight: 400 }}
              >
                {t('Invoices.New')}
              </Badge>
            </Tooltip>
          </Navbar.Link>
        )}
      </Navbar.Section>
      <Navbar.Section grow>
        <Navbar.Title>{t('Dashboard.Explore')}</Navbar.Title>
        {showQredCard && (
          <Navbar.Link onClick={handleOnClickQredVisa} rightIcon="open_in_new">
            {t('QredCard')}
          </Navbar.Link>
        )}
        <Navbar.Link
          onClick={handleOnClickBecomeTester}
          rightIcon="open_in_new"
        >
          {t('Dashboard.BecomeATester')}
        </Navbar.Link>
      </Navbar.Section>
      <Divider color="neutral.16" my="1.5em" />
      <Navbar.Section pb="1.7em">
        {isMobile && !isQeld && <LanguageItemSideBar />}
        <Navbar.Link rightIcon="help" onClick={handleRedirectToHelpSupportPage}>
          {t('HelpAndSupport.HeaderTitle')}
        </Navbar.Link>
        {companies.length > 1 && <SwitchCompanyButton />}
        <Navbar.Link rightIcon="logout" onClick={handleLogout}>
          {t('Logout')}
        </Navbar.Link>
      </Navbar.Section>
    </Navbar>
  );
};

export default SideBar;
