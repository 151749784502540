import { myQredApi } from '../../_baseApi/myQredApi.base.api';
import {
  GetAccountResponseDto,
  PatchAccountRequestDto,
} from '~/store/apis/dtos/accounts/accounts.dto';
import { getAccountMapper } from '~/store/apis/endpoints/myQredApi/accounts/accounts.mapper';
import { Account } from '~/models/accounts/accounts';

export const accountsApi = myQredApi.injectEndpoints({
  endpoints: (build) => ({
    getAccount: build.query<Account, void>({
      query: () => `/account/v1/accounts/me`,
      providesTags: ['Account'],
      transformResponse: (response: GetAccountResponseDto) =>
        getAccountMapper(response),
    }),
    updateAccount: build.mutation<unknown, PatchAccountRequestDto>({
      query: (args) => ({
        url: `/account/v1/accounts/me`,
        method: 'PATCH',
        body: args,
      }),
      invalidatesTags: ['Account'],
    }),
    getAccountFileExtensions: build.query<string[], void>({
      query: () => `/account/v1/accounts/file-extensions`,
      providesTags: ['AccountFileExtensions'],
    }),
    getAccountStatements: build.query<string[], void>({
      query: () => `/account/v1/accounts/statements`,
      providesTags: ['AccountStatements'],
    }),
  }),
});
export const {
  useUpdateAccountMutation,
  useLazyGetAccountQuery,
  useGetAccountStatementsQuery,
  useGetAccountFileExtensionsQuery,
} = accountsApi;
