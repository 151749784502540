import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import StepHeader from '~/components/shared/Stepper/components/StepHeader';
import { useShouldDisplayTestVariant } from '~/hooks/useShouldDisplayTestVariant';
import { RootState } from '~/store/types/sharedTypes';

const CompanySelectorHeader = () => {
  const {
    onboardingApplication: { form, overview },
  } = useSelector((state: RootState) => state);

  const showTestVariantOfCompanySearch = useShouldDisplayTestVariant([
    'LoanOnboardingApplication_CompanySelectorInput_IncreasedConversion_DK',
    'LoanOnboardingApplication_CompanySelectorInput_IncreasedConversion_FI',
  ]);

  const { title, description } = useMemo(() => {
    if (overview.clients?.length > 0) {
      return {
        title: 'Onboarding.SelectCompanySelectorTitle',
        description: 'Onboarding.SelectCompanySelectorDescriptionRlc',
      };
    }

    if (!showTestVariantOfCompanySearch && !overview.clients?.length) {
      return {
        title: 'Onboarding.SelectCompanySelectorTitle',
        description: 'Onboarding.SelectCompanySelectorDescriptionNlcAndManual',
      };
    }

    return {
      title: 'Onboarding.SelectCompanySelectorTitle',
      description: '',
    };
  }, [overview.clients, showTestVariantOfCompanySearch]);

  return <StepHeader title={title} description={description} />;
};

export default CompanySelectorHeader;
