import {
  SET_API_USER_INFO_STATUS,
  SET_COMPANIES_DATA,
  SET_PERSONAL_NUMBER,
  UPDATE_ACTIVE_COMPANY,
  UPDATE_ACTIVE_COMPANY_APPLICATION,
  UPDATE_ACTIVE_COMPANY_LOAN,
  UPDATE_ACTIVE_COMPANY_PREVIOUS_LOANS,
  UPDATE_AVATAR_URL,
  UPDATE_USER_DATA,
  UserAction,
} from '~/store/types/userTypes';
import { Company, User } from '~/Interfaces';
import initialState from '~/store/initialState';
import { ApiStatus } from '../types/sharedTypes';

interface UserState {
  readonly info: User;
  readonly companies: Array<Company>;
  readonly activeCompany: Company | undefined;
  readonly avatar: string | undefined;
  readonly apiUserInfoStatus: ApiStatus;
  readonly userIsSet: boolean;
}

const userReducer = (
  state: UserState = initialState.user,
  action: UserAction
): UserState => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        info: { ...state.info, ...action.payload },
        userIsSet: true,
      };

    case SET_COMPANIES_DATA:
      return {
        ...state,
        companies: action.payload,
      };

    case UPDATE_AVATAR_URL:
      return {
        ...state,
        avatar: action.payload,
      };

    case SET_PERSONAL_NUMBER:
      return {
        ...state,
        info: { ...state.info, personalNumber: action.payload },
      };

    case SET_API_USER_INFO_STATUS:
      return { ...state, apiUserInfoStatus: action.payload };

    case UPDATE_ACTIVE_COMPANY:
      return { ...state, activeCompany: action.payload };

    case UPDATE_ACTIVE_COMPANY_LOAN: {
      let loan;
      if (state.activeCompany?.loanId) {
        loan = action.payload.find(
          ({ id }) => id === state.activeCompany?.loanId
        );
        return {
          ...state,
          activeCompany: {
            ...state.activeCompany,
            loan,
          },
        };
      }
      return state;
    }

    case UPDATE_ACTIVE_COMPANY_PREVIOUS_LOANS: {
      return state.activeCompany
        ? {
            ...state,
            activeCompany: {
              ...state.activeCompany,
              previousLoans: action.payload,
            },
          }
        : state;
    }

    case UPDATE_ACTIVE_COMPANY_APPLICATION: {
      let application;
      if (state.activeCompany?.id) {
        application = action.payload.find(
          ({ id }) => id === state.activeCompany?.id
        );
        return {
          ...state,
          activeCompany: {
            ...state.activeCompany,
            application,
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default userReducer;
