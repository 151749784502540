import {
  FontWeights,
  Typography,
} from '~/components/shared/Typography/Typography';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { theme } from '~/styles/themes';
import { CampaignType, CountryCode } from '~/enums';
import { formatAmount } from '~/helpers/formatters.helper';
import Table, {
  CellData,
} from '~/pages/OnboardingOffer/components/PaymentCalculatorTable';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useTranslate, { useCampaignTypeTranslate } from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import Select from '~/components/shared/Select/Select';
import Stack from '~/components/shared/Layout/Stack';
import useDateFormatter from '~/hooks/useDateFormatter';

const HeaderContainer = styled.div`
  max-width: 22em;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
`;

const BodyContainer = styled.div``;

const CampaignTypeTranslateContainer = styled.div`
  margin-bottom: 1em;
`;

const CampaignAppliedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -1.2em;
  margin-top: 1em;
`;

const TagIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.3em;
  padding: 0.2em;
`;

const Asterisk = styled.sup`
  font-size: 0.8em;
  margin-right: 0.1em;
  top: 0;
`;

const ExplanationContainer = styled.div`
  margin-top: 1em;
`;

const EmptyPlaceholder = styled.div`
  height: 0.6em;
`;

const PaymentCalculatorModal: React.FC<{
  amount: number;
  loanPeriod: number;
  monthlyFee: number;
  campaignType: CampaignType | null;
  campaignDiscount?: number | null;
  campaignPrincipalFreeMonths?: number | null;
  campaignInterestRateDiscount?: number | null;
}> = (props) => {
  const [loanPeriod, setLoanPeriod] = useState(1);
  const { market } = useSelector((state: RootState) => state.intl);
  const formatDate = useDateFormatter();
  const translate = useTranslate();
  const [tableData, footers, savedAmount] = useMemo(() => {
    const _tableData: Array<Array<CellData>> = [];
    const defaultAmortization = Math.round(props.amount / props.loanPeriod);
    const defaultMonthlyFee = props.monthlyFee;
    let totalMonthlyFee = 0;
    let totalToPay = 0;
    let totalSavedAmount = 0;
    let totalAmortization = 0;

    for (let index = 1; index <= props.loanPeriod; index += 1) {
      // These values are recalculated for each row based on the selected loan period and campaign type
      let amortization = defaultAmortization;
      let monthlyFee = defaultMonthlyFee;
      let monthlySavedAmount = 0;
      let commissionDiscountInfo;

      // Step 1: applying campaign conditions on amortization and monthlyFee values
      if (props.campaignType) {
        switch (props.campaignType) {
          case CampaignType.FirstCommissionFree:
            if (index === 1) {
              monthlyFee = 0;
            }
            break;
          case CampaignType.FirstMonthFree:
            if (index === 1) {
              amortization = 0;
              monthlyFee = 0;
            } else {
              amortization = Math.round(props.amount / (props.loanPeriod - 1));
            }
            break;
          case CampaignType.CustomCommissionDiscount:
            if (index === 1 && props.campaignDiscount) {
              monthlyFee = Math.max(monthlyFee - props.campaignDiscount, 0); // In case discount number is bigger than monthly fee
            }
            break;
          case CampaignType.AmortizationFreeMonths:
            if (props.campaignPrincipalFreeMonths) {
              if (index <= props.campaignPrincipalFreeMonths) {
                amortization = 0;
              } else {
                amortization = Math.round(
                  props.amount /
                    (props.loanPeriod - props.campaignPrincipalFreeMonths)
                );
              }
            }
            break;
          case CampaignType.MonthlyInterestRateDiscount:
            if (
              props.campaignPrincipalFreeMonths &&
              props.campaignInterestRateDiscount
            ) {
              if (index <= props.campaignPrincipalFreeMonths) {
                const discountPercentage =
                  props.campaignInterestRateDiscount / 100;
                const discountAmount = monthlyFee * discountPercentage;
                monthlyFee -= discountAmount;
                if (index <= loanPeriod)
                  commissionDiscountInfo = `(-${props.campaignInterestRateDiscount}%)`;
              }
            }
            break;
          default:
            break;
        }
      }

      // Step 2: early payment conditions on amortization and monthlyFee values
      // This step takes precedence over step 1
      if (index === loanPeriod) {
        amortization = props.amount - totalAmortization;
      } else if (index > loanPeriod) {
        amortization = 0;
        monthlySavedAmount = monthlyFee;
        monthlyFee = 0;
      }

      const toPay = amortization + monthlyFee;

      // Must be a new Date object every time otherwise setting month on the same object will give incorrect results if month is bigger than 12
      const paymentDate = new Date();
      paymentDate.setMonth(new Date().getMonth() + index);

      const rowData: Array<CellData> = [
        { value: index },
        { value: formatDate(paymentDate) },
        { value: formatAmount(amortization) },
        {
          value: commissionDiscountInfo
            ? `${formatAmount(monthlyFee)} ${commissionDiscountInfo}`
            : formatAmount(monthlyFee),
        },
        { value: formatAmount(toPay), isFullWidthCell: true },
      ];

      totalToPay += toPay;
      totalMonthlyFee += monthlyFee;
      totalSavedAmount += monthlySavedAmount;
      totalAmortization += amortization;

      _tableData.push(rowData);
    }

    const _footers = [
      { value: '', isEmptyCell: true },
      { value: translate('Total') as string, isTotalLabel: true },
      { value: formatAmount(props.amount) },
      { value: formatAmount(totalMonthlyFee) },
      { value: formatAmount(totalToPay) },
    ];

    return [_tableData, _footers, totalSavedAmount];
  }, [props.amount, props.monthlyFee, props.campaignType, loanPeriod]);

  const generateLoanPeriodDropdownValues = (period: number) => {
    const values = [];
    for (let index = 1; index <= period; index += 1) {
      values.push({
        label: translate('PaymentCalculator.DropdownItem', {
          itemCount: index,
        }) as string,
        value: index,
      });
    }

    return values;
  };

  const loanPeriodOptions = useMemo(() => {
    const values = [];
    for (let index = 1; index <= props.loanPeriod; index += 1) {
      values.push({
        label: translate('PaymentCalculator.DropdownItem', {
          itemCount: index,
        }) as string,
        value: index,
      });
    }

    return values;
  }, [props.loanPeriod]);

  const headers = [
    { value: translate('Installment') as string, iconSrc: SvgSrc.ChartPie },
    { value: translate('DueDate') as string, iconSrc: SvgSrc.Clock },
    { value: translate('Amortization') as string, iconSrc: SvgSrc.ChartArea },
    { value: translate('MonthlyFee') as string, iconSrc: SvgSrc.Coins },
    { value: translate('ToPay') as string, iconSrc: SvgSrc.MoneyBillAlt },
  ];

  const campaignTypeTranslate = useCampaignTypeTranslate(
    props.campaignType,
    props.campaignPrincipalFreeMonths,
    props.campaignInterestRateDiscount
  );

  useEffect(() => {
    setLoanPeriod(props.loanPeriod);
  }, [props.loanPeriod]);

  return (
    <>
      <HeaderContainer>
        {props.campaignType ? (
          <>
            <CampaignAppliedContainer>
              <TagIconContainer>
                <Icon src={SvgSrc.Tag} />
              </TagIconContainer>
              <Typography
                uppercase
                fontSize={0.9}
                fontWeight={FontWeights.Thick}
                color={theme.colors.secondaryGray}
              >
                {translate('PaymentCalculator.CampaignApplied')}
              </Typography>
            </CampaignAppliedContainer>

            <CampaignTypeTranslateContainer>
              <Typography
                fontSize={0.7}
                centerAlign
                color={theme.colors.secondaryGray}
              >
                {campaignTypeTranslate}
              </Typography>
            </CampaignTypeTranslateContainer>
          </>
        ) : (
          <EmptyPlaceholder />
        )}
        <Stack spacing="sm">
          {market !== CountryCode.NO && (
            <Typography fontSize={0.7}>
              {translate('PaymentCalculator.HeaderExplanation')}
            </Typography>
          )}
          <Select
            fullWidth
            name="LoanPeriod"
            options={loanPeriodOptions}
            onChange={(v) => setLoanPeriod(Number(v?.value))}
            defaultValue={loanPeriodOptions[loanPeriodOptions.length - 1]}
          />
          {loanPeriod < props.loanPeriod && (
            <Typography
              fontWeight={FontWeights.Thick}
              color={theme.colors.primaryGreen}
              centerAlign
            >
              {translate('PaymentCalculator.YouSave', {
                amount: formatAmount(savedAmount),
              })}
            </Typography>
          )}
        </Stack>
      </HeaderContainer>
      <BodyContainer>
        <Table center data={tableData} headers={headers} footers={footers} />
        <ExplanationContainer>
          <Asterisk>*</Asterisk>
          <Typography fontSize={0.7} element="span">
            {translate('PaymentCalculator.FooterExplanation')}
          </Typography>
        </ExplanationContainer>
      </BodyContainer>
    </>
  );
};

export default PaymentCalculatorModal;
