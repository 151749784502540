import { accountsApi } from '../apis/endpoints/myQredApi/accounts/accounts.api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company, User } from '~/models/accounts/accounts';

export interface AccountsState {
  user: User;
  companies: Company[];
  selectedCompany: Company;
}

const initialState: AccountsState = {
  user: {} as User,
  companies: [],
  selectedCompany: {} as Company,
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setSelectedCompany: (state, action: PayloadAction<Company>) => {
      state.selectedCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      accountsApi.endpoints.getAccount.matchFulfilled,
      (state, { payload }) => ({
        ...state,
        user: payload.user,
        companies: payload.companies,
        selectedCompany: payload.selectedCompany,
      })
    );
  },
});

export const { setSelectedCompany } = accountsSlice.actions;

export default accountsSlice.reducer;
