import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepStatus } from '~/enums';
import { pushToGtmCardOnboardingAction } from '~/store/actions/gtmActions';
import { updateCurrentStep } from '~/store/slices/cardOnboardingOffer.slice';
import { RootState } from '~/store/types/sharedTypes';

const useGoToNextStep = () => {
  const dispatch = useDispatch();
  const { currentStep } = useSelector(
    (state: RootState) => state.cardOnboardingOffer
  );
  const onNextStepClick = useCallback(() => {
    dispatch(
      pushToGtmCardOnboardingAction({ actionName: 'next_button_pressed' })
    );
    dispatch(
      pushToGtmCardOnboardingAction({ stepStatus: StepStatus.Finished })
    );
    dispatch(updateCurrentStep(currentStep + 1));
  }, [dispatch, currentStep]);

  return onNextStepClick;
};

export default useGoToNextStep;
