import * as Sentry from '@sentry/react';
import { Dispatch } from 'redux';
import { GlobalErrorType } from '~/enums';
import {
  apiGetAiiaIntegrationUrl,
  apiPostAiiaIntegrationData,
} from '~/services/api';
import { AiiaAction, SET_API_STATUS } from '../types/aiiaTypes';
import { ApiStatus } from '../types/sharedTypes';
import { toggleGlobalErrorOn } from '../slices/globalError.slice';

const updateApiStatus = (status: ApiStatus): AiiaAction => ({
  type: SET_API_STATUS,
  payload: status,
});

// This is to be used specifically for aiia, it is not a general function
export const marketLanguageMapper = {
  se: 'sv',
  dk: 'da',
  fi: 'fi',
  no: 'no',
};

export const getMarketLanguage = (market: string) =>
  marketLanguageMapper[market as keyof typeof marketLanguageMapper] || 'en';

export const getAiiaIntegrationUrl = (
  language: string,
  redirectUri: string,
  qcode?: string
) => (dispatch: Dispatch) => {
  dispatch(updateApiStatus(ApiStatus.Started));
  apiGetAiiaIntegrationUrl(language, redirectUri)
    .then((res) => {
      window.location.assign(res.data);
    })
    .catch((err) => {
      Sentry.withScope((scope) => {
        // Group all aiia connection errors together
        scope.setFingerprint(['apiGetAiiaIntegrationUrl']);
        Sentry.captureException(err, {
          tags: { qcode },
        });
      });
      dispatch(updateApiStatus(ApiStatus.Failed));
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    });
};

export const postAiiaIntegrationData = (
  qcode: string,
  code: string,
  consentId: string,
  redirectUri: string,
  language: string,
  customerLink: boolean
) => async (dispatch: Dispatch) => {
  apiPostAiiaIntegrationData(
    qcode,
    code,
    consentId,
    redirectUri,
    language,
    customerLink
  )
    .catch((err) => {
      Sentry.withScope((scope) => {
        // Group all aiia post data failures together
        scope.setFingerprint(['apiPostAiiaIntegrationData']);
        Sentry.captureException(err, {
          tags: {
            qcode,
            code,
            consentId,
          },
        });
      });
      dispatch(toggleGlobalErrorOn(GlobalErrorType.API_FAILURE));
    })
    .finally(() => localStorage.removeItem('qcode'));
};
