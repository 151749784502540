import React from 'react';
import styled from 'styled-components';
import Button, { ButtonType } from '~/components/shared/Button/button';
import Card from '~/components/shared/Card/Card';
import {
  FontWeights,
  Typography,
} from '~/components/shared/Typography/Typography';
import Image from '~/components/shared/Image/Image';
import { theme } from '~/styles/themes';
import useTranslate from '~/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import SpinnerLinear from '~/components/shared/Spinner/SpinnerLinear';

const HeaderContainer = styled.div`
  margin-bottom: 1em;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (orientation: landscape) {
    max-width: 75%;
  }
`;

const ImageContainer = styled.div`
  margin: 0 1em;
  width: 25%;
  flex-basis: 20em;

  @media (orientation: landscape) {
    position: absolute;
    right: 0;
    top: 1em;
    bottom: 1em;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  @media (orientation: portrait) {
    height: 50%;
    margin-right: 0;
  }
`;

const ButtonContainer = styled.div`
  @media (orientation: landscape) {
    width: 14em;
    align-self: flex-start;
  }
`;

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const EskatCard: React.FC<Props> = (props) => {
  const translate = useTranslate();
  const { apiStatus } = useSelector((state: RootState) => state.eskat);

  return (
    <Card>
      <FlexRow>
        <FlexColumn>
          <HeaderContainer>
            <Typography
              fontWeight={FontWeights.Thick}
              fontSize={1}
              color={theme.colors.dark}
              lineHeight={1.5}
            >
              {translate('EskatCard.Title')}
            </Typography>
          </HeaderContainer>
          <Typography
            fontSize={1}
            color={theme.colors.secondaryBlack}
            fontWeight={400}
            lineHeight={1.5}
          >
            {translate('EskatCard.Text')}
          </Typography>
        </FlexColumn>
        <ImageContainer>
          <Image src="success.png" />
        </ImageContainer>
      </FlexRow>
      <ButtonContainer>
        <Button
          fullWidth
          type={ButtonType.Outlined}
          onClick={props.onClick}
          mt={1}
        >
          {apiStatus === ApiStatus.Started ? (
            <SpinnerLinear filled />
          ) : (
            translate('EskatCard.ButtonText')
          )}
        </Button>
      </ButtonContainer>
    </Card>
  );
};

export default EskatCard;
