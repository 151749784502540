import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import Checkbox from '~/components/shared/CheckBox/CheckBox';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import InputField from '~/components/shared/Input/InputField';
import RadioButtonGroup, {
  RadioButtonsType,
} from '~/components/shared/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '~/components/shared/Typography/Typography';
import useTranslate from '~/hooks/useTranslate';
import { updateCardOfferUserData } from '~/store/actions/cardOfferActions';
import { RootState } from '~/store/types/sharedTypes';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import { CardInvoiceDay } from '~/types/types';

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
`;

const CreditCardInvoiceInfoCard: React.FC = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const {
    invoiceDay,
    isPaperInvoiceChosen,
    invoiceAddress,
    invoiceEmail,
    cardOfferAlreadySigned,
  } = useSelector((state: RootState) => state.cardOffer.userData);

  const options: {
    value: CardInvoiceDay;
    label: string;
  }[] = [
    {
      value: 'MID_OF_MONTH',
      label: translate('CreditCardInvoiceInfoCard.Option1') as string,
    },
    {
      value: 'START_OF_MONTH',
      label: translate('CreditCardInvoiceInfoCard.Option2') as string,
    },
  ];

  const onUserDataChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (cardOfferAlreadySigned) return;

    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    dispatch(
      updateCardOfferUserData({
        [e.target.name]: value,
      })
    );
  };

  return (
    <Card noPadding dataTestId="credit-card-invoice-info">
      <CardHeader
        value={translate('CreditCardInvoiceInfoCard.Title') as string}
      />
      <CardSection
        title={
          translate(
            'CreditCardInvoiceInfoCard.SelectDesiredInvoiceDate'
          ) as string
        }
        explanation={translate('CreditCardInvoiceInfoCard.HelpText') as string}
        borderTop
      >
        <RadioButtonGroup
          name="invoiceDay"
          options={options}
          onChange={onUserDataChanged}
          value={invoiceDay}
          borderless
          type={RadioButtonsType.RadioButtonsOnRightSide}
        />
      </CardSection>
      <CardSection
        title={translate('CreditCardInvoiceInfoCard.InvoicingMethod') as string}
        borderTop
      >
        <Checkbox checked disabled>
          {translate('CreditCardInvoiceInfoCard.CheckboxText1') as string}
        </Checkbox>

        <InputField
          name="invoiceEmail"
          placeholder={
            translate('CreditCardInvoiceInfoCard.AddEmailAddress') as string
          }
          value={invoiceEmail}
          disabled={cardOfferAlreadySigned}
          onChange={onUserDataChanged}
          validationType="Email"
        />
      </CardSection>

      <CardSection borderTop>
        <Checkbox
          name="isPaperInvoiceChosen"
          checked={isPaperInvoiceChosen}
          onChange={onUserDataChanged}
        >
          {translate('CreditCardInvoiceInfoCard.CheckboxText2') as string}
        </Checkbox>
        <AddressContainer>
          <Icon
            src={SvgSrc.MapMarker}
            size={ComponentSize.Small}
            mr={0.5}
            color={theme.colors.secondaryGray}
          />
          <Typography>{invoiceAddress}</Typography>
        </AddressContainer>
      </CardSection>
    </Card>
  );
};

export default CreditCardInvoiceInfoCard;
