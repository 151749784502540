import styled from 'styled-components';
import { theme } from '~/styles/themes';

export const CheckboxWrapper = styled.div`
  margin-bottom: 1.25em;
  label {
    font-family: 'Poppins', sans-serif;
    color: ${theme.colors.midwayBlack};
    font-family: 'Poppins', sans-serif;
    font-size: 0.94em;
    font-weight: 400;
    line-height: 1.25em;
    letter-spacing: 0.016em;
  }
`;
export const checkboxStyle = {
  border: `0.1em solid ${theme.colors.midwaySecondary}`,
};
export const InputContainer = styled.div`
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0.016em;
  color: ${theme.colors.midwayBlack};
  border-radius: 0.25em;
  color: theme.colors.midwayBlack;
  h2 {
    font-weight: 500;
    line-height: 1.125em;
    letter-spacing: 0.016em;
    font-size: 0.94em;
    font-family: 'Poppins', sans-serif;
  }
`;
