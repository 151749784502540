import React from 'react';
import { Tooltip, Icon, useWindowProperties } from '@qred/qit-ui';
import { theme } from '~/styles/themes';
import { TooltipContainer } from './InputTooltip.styled';
import { Typography } from '@qred/components-library';

interface InputTooltipProps {
  label: string | React.ReactNode;
  tooltipCopy: string | React.ReactNode;
}

const InputTooltip = ({ label, tooltipCopy }: InputTooltipProps) => {
  const { isMobile } = useWindowProperties();

  return (
    <TooltipContainer isMobile={isMobile}>
      <Typography size="sm" weight={600}>
        {label}
      </Typography>
      <Tooltip label={tooltipCopy} width={isMobile ? '355px' : '465px'}>
        <Icon color={theme.colors.dark} size="md" src="QuestionMarkCircle" />
      </Tooltip>
    </TooltipContainer>
  );
};

export default InputTooltip;
