import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '~/hooks/useTranslate';
import { useParams } from 'react-router-dom';
import { RootState } from '~/store/types/sharedTypes';
import { compose } from 'redux';
import Logo from '~/components/shared/LogoOld/LogoOld';
import {
  BodyContainer,
  MainContainer,
  PageFooter,
} from '~/styles/BaseStyledComponents';
import withValidation, {
  WithValidationProps,
} from '~/components/hoc/withValidation';
import withGtmEvent from '~/components/hoc/withGtmEvent';
import {
  fetchShippingDetailsData,
  setShippingDetailsHash,
  setShippingDetailsUserData,
} from '~/store/actions/shippingDetailsActions';
import DownArrow from '~/components/shared/DownArrow/DownArrow';
import useQueryParams from '~/hooks/useQueryParams';
import { defaultLanguageToMarket, setMarket } from '~/store/slices/intl.slice';
import pushToGtmHelper from '~/helpers/pushToGtm.helper';
import SpinnerCircular from '~/components/shared/Spinner/SpinnerCircular';
import { parseMarket } from '~/helpers/market.helper';
import CreateCardCard from './components/CreateCardCard';
import DeliveryDetailsCard from './components/DeliveryDetailsCard';
import ContactInformationCard from './components/ContactInformationCard';
import CardNameCard from './components/CardNameCard';
import PageTitle from '../../components/shared/PageTitle/PageTitle';
import PageHeader from '../../components/shared/PageHeader/PageHeader';

const ShippingDetails: React.FC<WithValidationProps> = () => {
  /* Selectors */
  const shippingDetails = useSelector(
    (state: RootState) => state.shippingDetails.overview
  );
  const { languageIsSet } = useSelector((state: RootState) => state.intl);

  /* Other hooks */
  const t = useTranslate();
  const dispatch = useDispatch();
  const { applicationUuid } = useParams<{ applicationUuid: string }>();
  const queryParams = useQueryParams();

  const market = parseMarket(queryParams.get('market'));
  const hash = queryParams.get('hash');

  /* Effects */
  useEffect(() => {
    if (market) {
      dispatch(setMarket(market));
      dispatch(defaultLanguageToMarket(market));
      pushToGtmHelper(`q_user_v_${market.toLowerCase()}`);
    }
  }, [market]);

  useEffect(() => {
    if (applicationUuid && !shippingDetails && market && hash) {
      dispatch(fetchShippingDetailsData(applicationUuid, true, market, hash));
      dispatch(setShippingDetailsHash(hash));
    }
  }, [shippingDetails, applicationUuid, market, hash]);

  // It is important that we watch cardOffer from 'overview' and not from 'userData', otherwise all subsequent changes
  // in userdata will cause this effect to run and dispatch the action over and over again
  useEffect(() => {
    if (shippingDetails) dispatch(setShippingDetailsUserData(shippingDetails));
  }, [shippingDetails]);

  return languageIsSet ? (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
        <PageTitle mb={2}>{t('CreateQredCard')}</PageTitle>
      </PageHeader>
      {shippingDetails && (
        <BodyContainer>
          <CardNameCard shippingDetails={shippingDetails} />
          <DownArrow />
          <ContactInformationCard shippingDetails={shippingDetails} />
          <DownArrow />
          <DeliveryDetailsCard shippingDetails={shippingDetails} />
          <DownArrow />
          <CreateCardCard
            applicationUuid={applicationUuid}
            shippingDetails={shippingDetails}
            isPublic
          />
        </BodyContainer>
      )}
      <PageFooter />
    </MainContainer>
  ) : (
    <SpinnerCircular />
  );
};

export default compose(withGtmEvent, withValidation)(ShippingDetails);
