import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import constVars, { PRODUCTION } from '~/constants/constVars';
import styled from 'styled-components';
import Button, { ButtonType } from '~/components/shared/Button/button';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import Checkbox from '~/components/shared/CheckBox/CheckBox';
import { Typography } from '~/components/shared/Typography/Typography';
import useTranslate from '~/hooks/useTranslate';
import {
  postCardOffer,
  signCardOffer,
  updateCardOfferUserData,
} from '~/store/actions/cardOfferActions';
import { RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import { FormStatus, ValidationContext } from '~/components/hoc/withValidation';
import SpinnerLinear from '~/components/shared/Spinner/SpinnerLinear';
import {
  openQredCardGeneralTermsPage,
  openQredCardPricesPage,
} from '~/services/links';
import { store } from '~/App';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { CountryCode } from '~/enums';

type AppDispatch = typeof store.dispatch;

const environment = import.meta.env.MODE;
const isProd = environment === PRODUCTION;

const {
  AUTH0_FI_FTN_CONNECTION_PROD,
  AUTH0_FI_FTN_CONNECTION_PREPROD,
} = constVars;

const fiConnection = isProd
  ? AUTH0_FI_FTN_CONNECTION_PROD
  : AUTH0_FI_FTN_CONNECTION_PREPROD;

const HelpTextContainer = styled.div`
  margin: 0 auto;
  padding: 0 2.5em;
`;

const Link = styled.span`
  cursor: pointer;
  color: ${theme.colors.primaryGreen};
  border-bottom: 0.15em solid ${theme.colors.primaryGreen};
`;

interface Props {
  isPublic: boolean;
  applicationUuid?: string;
  market: CountryCode;
  clientId?: number;
  isSigner?: boolean;
  hasAcceptedTerms: boolean;
  isPersonalGuaranteeRequired: boolean;
  hasAcceptedPersonalGuarantee: boolean;
  isSubmitting: boolean;
}

const CreditCardTermsAndConditionsCard: React.FC<Props> = (props) => {
  /* Other Hooks */
  const { loginWithRedirect } = useAuth0();
  const language = useSelector((state: RootState) => state.intl.language);
  const { cardOfferAlreadySigned } = useSelector(
    (state: RootState) => state.cardOffer.userData
  );

  const translate = useTranslate();
  const dispatch = useDispatch<AppDispatch>();
  const validationContext = useContext(ValidationContext);

  const GeneralTermLink = () => (
    <Link onClick={() => openQredCardGeneralTermsPage(props.market, language)}>
      {translate('CreditCardTermsAndConditionsCard.Link1') as string}
    </Link>
  );

  const QredCardPricesLink = () => (
    <Link onClick={() => openQredCardPricesPage(props.market, language)}>
      {translate('CreditCardTermsAndConditionsCard.Link2Legacy') as string}
    </Link>
  );

  /* Handlers */
  const onUserDataChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateCardOfferUserData({
        [e.target.name]: e.target.checked,
      })
    );
  };

  const getSignButtonText = (market: string) =>
    market === CountryCode.FI
      ? translate('CreditCardTermsAndConditionsCard.IdentifyButtonText')
      : translate('CreditCardTermsAndConditionsCard.ButtonText');

  const fiCardOfferConfig = (hash: string): RedirectLoginOptions => ({
    authorizationParams: {
      environment,
      connection: fiConnection,
      hash,
      market: props.market,
      cardOfferUrl: window.location.href,
      cardOffer: true,
    },
  });

  const onSignButtonClicked = () => {
    // TODO: find a way to consolidate FormStatus of validation context with submitting status in redux
    validationContext.setFormStatus(FormStatus.SUBMITTING);
    if (validationContext.isFormValid) {
      if (props.applicationUuid && props.market && props.clientId) {
        const cardOfferPayload = {
          applicationUuid: props.applicationUuid,
          market: props.market,
          clientId: props.clientId,
          isPublic: props.isPublic,
        };

        // TODO: Remove conditional when we want to use this for SE
        if (props.market === CountryCode.FI) {
          if (props.isPersonalGuaranteeRequired) {
            dispatch(
              postCardOffer(
                cardOfferPayload,
                ({ signatureUrl }: { signatureUrl: string }) => {
                  window.location.assign(signatureUrl);
                }
              )
            );
          } else {
            dispatch(
              postCardOffer(cardOfferPayload, ({ hash }: { hash: string }) => {
                loginWithRedirect({
                  ...fiCardOfferConfig(hash),
                });
              })
            );
          }
        } else {
          dispatch(signCardOffer(cardOfferPayload));
        }
      }
    } else {
      validationContext.setFormStatus(FormStatus.SUBMITTED);
    }
  };

  return (
    <Card noPadding dataTestId="credit-card-terms-and-configuration-card">
      <CardHeader
        value={translate('CreditCardTermsAndConditionsCard.Title') as string}
      />
      <CardSection borderTop borderBottom>
        <Checkbox
          name="hasAcceptedTerms"
          checked={props.hasAcceptedTerms}
          disabled={cardOfferAlreadySigned}
          onChange={onUserDataChanged}
        >
          {translate('CreditCardTermsAndConditionsCard.Text1', {
            generalTermLink: <GeneralTermLink />,
            qredCardPricesLink: <QredCardPricesLink />,
          })}
        </Checkbox>
      </CardSection>
      {props.isPersonalGuaranteeRequired && !props.isSigner && (
        <CardSection borderTop>
          <Checkbox
            name="hasAcceptedPersonalGuarantee"
            checked={props.hasAcceptedPersonalGuarantee}
            disabled={!!props.isSigner || cardOfferAlreadySigned}
            onChange={onUserDataChanged}
          >
            {translate('CreditCardTermsAndConditionsCard.Text2')}
          </Checkbox>
        </CardSection>
      )}
      <CardSection borderTop>
        <Button
          type={ButtonType.Filled}
          onClick={onSignButtonClicked}
          disabled={
            Boolean(
              props.isPersonalGuaranteeRequired
                ? Boolean(
                    !props.hasAcceptedPersonalGuarantee ||
                      !props.hasAcceptedTerms
                  )
                : !props.hasAcceptedTerms
            ) || cardOfferAlreadySigned
          }
          mb={props.isPublic ? 0 : 1}
        >
          {props.isSubmitting ? (
            <SpinnerLinear />
          ) : (
            getSignButtonText(props.market)
          )}
        </Button>
        {!props.isPublic && (
          <HelpTextContainer>
            <Typography centerAlign fontSize={0.7} lineHeight={1.5}>
              {translate('CreditCardTermsAndConditionsCard.HelpText')}
            </Typography>
          </HelpTextContainer>
        )}
      </CardSection>
    </Card>
  );
};

export default CreditCardTermsAndConditionsCard;
