import React from 'react';
import useTranslate from '~/hooks/useTranslate';
import ApplicationDescriptionItem from './ApplicationDescriptionItem';
import { SectionWrapper } from './ApplicationDescriptionItem.styled';

const ApplicationDescriptionSection = () => {
  const translate = useTranslate();

  return (
    <SectionWrapper>
      <ApplicationDescriptionItem
        title={translate('Onboarding.FlowDescriptionStep1Title') as string}
        text={translate('Onboarding.FlowDescriptionStep1Text') as string}
      />
      <ApplicationDescriptionItem
        title={translate('Onboarding.FlowDescriptionStep2Title') as string}
        text={translate('Onboarding.FlowDescriptionStep2Text') as string}
      />
      <ApplicationDescriptionItem
        title={translate('Onboarding.FlowDescriptionStep3Title') as string}
        text={translate('Onboarding.FlowDescriptionStep3Text') as string}
      />
    </SectionWrapper>
  );
};

export default ApplicationDescriptionSection;
