import { useSelector } from 'react-redux';
import { RootState } from '~/store/types/sharedTypes';
import countries from 'i18n-iso-countries';
import English from 'i18n-iso-countries/langs/en.json';
import Swedish from 'i18n-iso-countries/langs/sv.json';
import Danish from 'i18n-iso-countries/langs/da.json';
import Norwegian from 'i18n-iso-countries/langs/no.json';
import Finnish from 'i18n-iso-countries/langs/fi.json';
import French from 'i18n-iso-countries/langs/fr.json';
import Dutch from 'i18n-iso-countries/langs/nl.json';
import { useMemo } from 'react';

countries.registerLocale(English);
countries.registerLocale(Swedish);
countries.registerLocale(Danish);
countries.registerLocale(Norwegian);
countries.registerLocale(Finnish);
countries.registerLocale(French);
countries.registerLocale(Dutch);

const useCountries = () => {
  const { language } = useSelector((state: RootState) => state.intl);
  /**
   * countries.getNames returns an object with alpha 2 keys as key and country name as the value.
   * E.g { SE: 'Sweden', NO: 'Norway', ... }
   * This object is then converted to an array of objects with label (country name in the selected language) and value (alpha 2 key).
   */
  const countriesList = useMemo(
    () =>
      Object.entries(
        countries.getNames(language.split('_')[0].toLowerCase(), {
          select: 'official',
        })
      ).map(([key, value]) => ({
        label: value,
        value: key,
      })),
    [language]
  );

  return { countriesList };
};

export default useCountries;
