import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatusPayload } from '~/Interfaces';
import { startAppListening } from '../middlewares/listener.middleware';
import { cardOnboardingOfferInitialState } from '../initialState';
import {
  ICardOnboardingOfferApplicant,
  ICardOnboardingOfferForm,
  ICardOnboardingOfferOverview,
} from '~/interfaces/CardOnboardingOffer';
import {
  IOnboardingBeneficialOwner,
  IOnboardingKYCQuestionnaireAnswer,
  IOnboardingSigner,
} from '~/interfaces/Onboarding';
import { KycQuestionnaireQuestionNames } from '~/enums';
import { syncOnboardingOfferPersons } from '~/helpers/onboarding.helper';

type IUpdatableFormFields = Omit<ICardOnboardingOfferForm, 'owners'>;

const cardOnboardingOfferSlice = createSlice({
  name: 'cardOnboardingOffer',
  initialState: cardOnboardingOfferInitialState,
  reducers: {
    updateCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    goToNextStep: (state) => {
      state.currentStep += 1;
    },
    goToPreviousStep: (state) => {
      state.currentStep -= 1;
    },
    updateForm: (
      state,
      action: PayloadAction<Partial<IUpdatableFormFields>>
    ) => {
      state.form = { ...state.form, ...action.payload };
    },
    updateOverview: (
      state,
      action: PayloadAction<ICardOnboardingOfferOverview>
    ) => {
      state.overview = { ...state.overview, ...action.payload };
    },
    setApiStatus: (state, action: PayloadAction<ApiStatusPayload>) => {
      state.apiStatus = {
        ...state.apiStatus,
        ...action.payload,
      };
    },
    setStepStartTimestamp: (state) => {
      state.stepStartTimestamp = new Date().toString();
    },
    updateSigner: (state, action: PayloadAction<IOnboardingSigner>) => {
      const { signers } = state.form;

      const updatedList = signers.map((s) => {
        if (s.signerId === action.payload.signerId) {
          return {
            ...s,
            ...action.payload,
          };
        }
        return s;
      });
      state.form.signers = updatedList;
    },
    updateAdditionalOwner: (
      state,
      action: PayloadAction<IOnboardingBeneficialOwner>
    ) => {
      const updatedOwners = state.form.owners.map((o) => {
        if (o.id === action.payload.id) {
          return action.payload;
        }
        return o;
      });

      state.form.owners = updatedOwners;
    },
    addAdditionalOwner: (
      state,
      action: PayloadAction<IOnboardingBeneficialOwner>
    ) => {
      state.form.owners.push(action.payload);
    },
    setBeneficialOwners: (
      state,
      action: PayloadAction<IOnboardingBeneficialOwner[]>
    ) => {
      state.form.owners = action.payload;
    },
    removeAdditionalOwnerById: (state, action: PayloadAction<string>) => {
      state.form.owners = state.form.owners.filter(
        (o) => o.id !== action.payload
      );
    },
    updateKYCAnswers: (
      state,
      action: PayloadAction<IOnboardingKYCQuestionnaireAnswer>
    ) => {
      const { kycQuestionnaireAnswers, kycQuestionnaireQuestions } = state.form;

      let updatedKYCAnswers = [...kycQuestionnaireAnswers];

      const questionHasAlreadyBeenAnswered = !!kycQuestionnaireAnswers.find(
        (item) => item.questionId === action.payload.questionId
      );
      if (questionHasAlreadyBeenAnswered) {
        updatedKYCAnswers = kycQuestionnaireAnswers.map((item) => {
          if (item.questionId === action.payload.questionId) {
            return action.payload;
          }
          return item;
        });
      } else {
        updatedKYCAnswers.push(action.payload);
      }

      const handleCashQuestionId = kycQuestionnaireQuestions.find(
        (question) =>
          question.name === KycQuestionnaireQuestionNames.handleCashQuestion
      )?.id;

      // to remove the answer to the question "How much cash do you handle?" in case user answered
      // "NO" to the question "Do you handle cash within your organization?"
      if (
        action.payload.questionId === handleCashQuestionId &&
        action.payload.answers[0].answer === 'NO'
      ) {
        const handleCashToWhatExtentQuestionId = kycQuestionnaireQuestions.find(
          (question) =>
            question.name ===
            KycQuestionnaireQuestionNames.handleCashToWhatExtentQuestion
        )?.id;
        updatedKYCAnswers = updatedKYCAnswers.filter(
          (item) => item.questionId !== handleCashToWhatExtentQuestionId
        );
      }

      state.form.kycQuestionnaireAnswers = updatedKYCAnswers;
    },
    updateApplicant: (
      state,
      action: PayloadAction<Partial<ICardOnboardingOfferApplicant>>
    ) => {
      state.form.applicant = {
        ...state.form.applicant,
        ...action.payload,
      };
    },
  },
});

export const {
  updateCurrentStep,
  goToNextStep,
  goToPreviousStep,
  updateForm,
  updateOverview,
  setApiStatus,
  setStepStartTimestamp,
  updateSigner,
  addAdditionalOwner,
  updateAdditionalOwner,
  removeAdditionalOwnerById,
  updateKYCAnswers,
  updateApplicant,
  setBeneficialOwners,
} = cardOnboardingOfferSlice.actions;

export type TCardOnboardingOfferSliceActions = typeof cardOnboardingOfferSlice.actions;

export default cardOnboardingOfferSlice.reducer;

startAppListening({
  matcher: isAnyOf(updateCurrentStep, goToNextStep, goToPreviousStep),
  effect: async (_action, listenerApi) => {
    listenerApi.dispatch(setStepStartTimestamp());
  },
});

// if signers, owners or the applicant is updated, we need to check if the same person is in the
// other lists and update it there as well
startAppListening({
  matcher: isAnyOf(
    addAdditionalOwner,
    updateAdditionalOwner,
    updateSigner,
    updateApplicant,
    setBeneficialOwners,
    removeAdditionalOwnerById
  ),
  effect: (action, listenerApi) => {
    syncOnboardingOfferPersons(
      action,
      listenerApi.getState().cardOnboardingOffer,
      listenerApi.getOriginalState().cardOnboardingOffer,
      listenerApi.dispatch,
      cardOnboardingOfferSlice.actions
    );
  },
});
