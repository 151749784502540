import React from 'react';
import styled from 'styled-components';
import { theme } from '~/styles/themes';

const FadingCircle = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
`;

const QredCircle = styled.div`
  pointer-events: none;
  width: inherit;
  height: inherit;
  position: absolute;
  right: 0;
  top: 0;

  @-webkit-keyframes qred-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
  }

  @keyframes qred-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${theme.colors.dark};
    border-radius: 100%;
    -webkit-animation: qred-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: qred-circleFadeDelay 1.2s infinite ease-in-out both;
  }
`;

const QredCircle1 = styled(QredCircle)``;
const QredCircle2 = styled(QredCircle)`
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);

  &:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
`;
const QredCircle3 = styled(QredCircle)`
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);

  &:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
`;
const QredCircle4 = styled(QredCircle)`
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);

  &:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
`;
const QredCircle5 = styled(QredCircle)`
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);

  &:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
`;
const QredCircle6 = styled(QredCircle)`
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);

  &:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
`;
const QredCircle7 = styled(QredCircle)`
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);

  &:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
`;
const QredCircle8 = styled(QredCircle)`
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
  &:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
`;
const QredCircle9 = styled(QredCircle)`
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);

  &:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
`;
const QredCircle10 = styled(QredCircle)`
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);

  &:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
`;
const QredCircle11 = styled(QredCircle)`
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
  &:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
`;
const QredCircle12 = styled(QredCircle)`
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
  &:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
`;

const SpinnerCircularSmall = () => (
  <FadingCircle>
    <QredCircle1 />
    <QredCircle2 />
    <QredCircle3 />
    <QredCircle4 />
    <QredCircle5 />
    <QredCircle6 />
    <QredCircle7 />
    <QredCircle8 />
    <QredCircle9 />
    <QredCircle10 />
    <QredCircle11 />
    <QredCircle12 />
  </FadingCircle>
);

export default SpinnerCircularSmall;
