import React from 'react';
import styled from 'styled-components';
import { theme } from '~/styles/themes';
import { ApplicationStep } from '~/enums';

const Dot = styled.div<{ index: number; currentStep: number }>`
  width: 0.8em;
  height: 0.8em;
  background-color: ${(props) =>
    props.currentStep > props.index
      ? theme.colors.primaryGreen
      : theme.colors.primaryGray};
  border-radius: 50%;
  margin: 0 0.5em;
`;
const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ProgressStepperContainer = styled.div`
  position: relative;
`;

interface Props {
  currentStep: ApplicationStep;
  steps: Array<any>;
}

const ProgressStepperDots: React.FC<Props> = (props) => (
  <ProgressStepperContainer>
    <DotsContainer>
      {props.steps.map((step: any, index: number) => (
        <Dot key={index} index={index} currentStep={props.currentStep} />
      ))}
    </DotsContainer>
  </ProgressStepperContainer>
);

ProgressStepperDots.defaultProps = {
  steps: [1, 2],
};

export default ProgressStepperDots;
