import styled from 'styled-components';

const Overlay = styled.div<{ zIndex?: number }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  align-items: center;
  justify-content: center;
  z-index: ${(props) => props.zIndex};
`;

Overlay.defaultProps = {
  zIndex: 0,
};

export default Overlay;
