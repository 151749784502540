import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
  html {
    font-size: 62.5%;
    overflow-x: hidden;
    width: 100%;
  }

  body {
    font-family: 'Poppins', sans-serif;
    font-size: 4vw;
    width: 100%;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-x: hidden;
    @media(orientation: landscape) {
      font-size: 3.5vmin;
    }
    @media(min-width: 689px) {
      font-size: 3vmin;
    }
    @media(min-width: 1312px) {
      font-size: 2em;
    }
  }
  
  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  *, body {
    line-height: 1
  }

  .verification-character {
    border: none;
    font-size: 16px;
    border-radius: 2px;
    padding-top: 17.5px;
    background-color: #F1F3F3;
    outline: none;
  }

  .verification-character--error {
    border: none;
    font-size: 16px;
    border-radius: 2px;
    padding-top: 17.5px;
    background-color: #FAE6E6;
    outline: none;
  }

  .verification-character--selected {
    position: relative;
    background-color: #E6EAEA;
    color: #1C1C1C;
  }

  .verification-character--selected--error {
    position: relative;
    background-color: #E5D0D0;
    color: #1C1C1C;
  }
   
  .verification-character--selected:empty::after {
    content: "";
    width: 1px;
    height: 30px;
    background-color: #1C1C1C;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }

  .verification-character--selected--error:empty::after {
    content: "";
    width: 1px;
    height: 30px;
    background-color: #1C1C1C;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }
   
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

`;

export default GlobalStyle;
