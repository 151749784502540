import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import Button, { ButtonType } from '~/components/shared/Button/button';
import Card from '~/components/shared/Card/Card';
import CardHeader from '~/components/shared/CardHeader/CardHeader';
import CardSection from '~/components/shared/CardSection/CardSection';
import Checkbox from '~/components/shared/CheckBox/CheckBox';
import DropDown from '~/components/shared/DropDown/DropDown';
import Image from '~/components/shared/Image/Image';
import { Typography } from '~/components/shared/Typography/Typography';
import useTranslate from '~/hooks/useTranslate';
import { ShippingDetails } from '~/Interfaces';
import { updateShippingDetailsUserData } from '~/store/actions/shippingDetailsActions';
import { RootState } from '~/store/types/sharedTypes';

const FlipBox = styled.div`
  background-color: transparent;
  width: 53.9mm;
  height: 85.6mm;
  perspective: 100em;
  margin: 1em auto 2em auto;
  color: #575f52;
`;

const FlipBoxInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.7s;
  transform-style: preserve-3d;
`;

const FlipBoxBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;

const FlipBoxFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const borderFading = keyframes`
  0% {
    left: 16mm;
  }
  50% {
    left: 15mm;
  }
  100% {
    left: 16mm;
  }
`;

const NameContainer = styled.span`
  position: absolute;
  top: 33mm;
  left: 16mm;
  font-size: 2.4mm;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &.animationClass {
    animation: ${borderFading} 0.4s ease-out 1;
  }
`;

const CompanyNameContainer = styled.span`
  position: absolute;
  top: 36mm;
  left: 16mm;
  font-size: 2.4mm;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const EmptyElement = styled.div`
  height: 1em;
`;

interface CardHolderName {
  firstName: string;
  lastName: string;
}

interface Props {
  shippingDetails: ShippingDetails;
}

const CardNameCard: React.FC<Props> = (props) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [flipStyle, setFlipStyle] = useState({
    frontSide: false,
    css: {},
  });
  const [animationClass, setAnimationClass] = useState('');
  const {
    companyNameOnCard,
    cardHolderSelectedFullName,
    shouldHaveCompanyNameOnCard,
  } = useSelector((state: RootState) => state.shippingDetails.userData);
  const { firstName, lastName } = props.shippingDetails.cardHolderFullName;
  const { companyName } = props.shippingDetails;

  const nameOnCardSuggestions = useMemo(() => {
    const suggestions: Array<CardHolderName> = [];

    const getFullNameLength = (cardHolderName: CardHolderName) =>
      `${cardHolderName.firstName} ${cardHolderName.lastName}`.length;

    const suggestion1 = {
      firstName,
      lastName,
    };

    if (getFullNameLength(suggestion1) <= 26) {
      suggestions.push(suggestion1);
    }

    const suggestion2 = {
      firstName: firstName.substring(0, 1),
      lastName,
    };

    if (getFullNameLength(suggestion2) <= 26) {
      suggestions.push(suggestion2);
    }

    const suggestion3 = {
      firstName,
      lastName: lastName.substring(0, 1),
    };

    if (getFullNameLength(suggestion3) <= 26) {
      suggestions.push(suggestion3);
    }

    // If none of the combinations above is less that 26 chars
    if (suggestions.length === 0) {
      const suggestion4 = {
        firstName: firstName.substring(0, 1),
        lastName: lastName.substring(0, 1),
      };
      suggestions.push(suggestion4);
    }

    return suggestions.map((suggestion, index) => ({
      label: `${suggestion.firstName} ${suggestion.lastName}`,
      value: index,
      suggestion,
    }));
  }, [props.shippingDetails]);

  // This effect is needed in order to update Redux's cardHolderSelectedFullName value.
  // It has a different name in order NOT to get overriden by SET_SHIPPING_DETAILS_USER_DATA
  useEffect(() => {
    dispatch(
      updateShippingDetailsUserData({
        cardHolderSelectedFullName: nameOnCardSuggestions[0].suggestion,
      })
    );
  }, [nameOnCardSuggestions]);

  const onCheckboxValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateShippingDetailsUserData({
        [e.target.name]: !e.target.checked,
      })
    );
  };

  const onDropDownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const suggestionIndex = Number(e.target.value);
    dispatch(
      updateShippingDetailsUserData({
        cardHolderSelectedFullName:
          nameOnCardSuggestions[suggestionIndex].suggestion,
      })
    );
    setAnimationClass('animationClass');
    setTimeout(() => {
      setAnimationClass('');
    }, 1000);
  };

  const shouldShowCompanyNameSection = () =>
    companyName !== `${firstName} ${lastName}`;

  const onFlipCardClicked = () => {
    setFlipStyle((prevState) => {
      const transformString = 'rotateY(180deg)';
      if (!prevState.frontSide)
        return {
          frontSide: true,
          css: {
            transform: transformString,
            MozTransform: transformString,
            WebkitTransform: transformString,
            MsTransform: transformString,
          },
        };
      return {
        frontSide: false,
        css: {},
      };
    });
  };

  return (
    <Card dataTestId="shipping-details-card-name-card" noPadding>
      <CardHeader value={translate('CardNameCard.Title') as string} />

      <CardSection borderTop>
        <FlipBox>
          <FlipBoxInner style={flipStyle.css}>
            <FlipBoxFront>
              <NameContainer className={animationClass}>
                {`${cardHolderSelectedFullName.firstName} ${cardHolderSelectedFullName.lastName}`}
              </NameContainer>
              {shouldHaveCompanyNameOnCard && (
                <CompanyNameContainer>{companyNameOnCard}</CompanyNameContainer>
              )}
              <Image src="QredCardBack.png" />
            </FlipBoxFront>
            <FlipBoxBack>
              <Image src="QredCardFront.png" />
            </FlipBoxBack>
          </FlipBoxInner>
        </FlipBox>
        <Button type={ButtonType.Outlined} onClick={onFlipCardClicked}>
          {flipStyle.frontSide
            ? (translate('CardNameCard.ShowBackSide') as string)
            : (translate('CardNameCard.ShowFrontSide') as string)}
        </Button>
      </CardSection>

      <CardSection
        title={translate('CardNameCard.SelectNameOnCard') as string}
        explanation={translate('CardNameCard.HelpText') as string}
      >
        <DropDown
          name="nameOnCard"
          defaultValue={nameOnCardSuggestions[0].value}
          dropDownValues={nameOnCardSuggestions}
          onChange={onDropDownChange}
          disabled={props.shippingDetails.cardAlreadyCreated}
        />
      </CardSection>
      {shouldShowCompanyNameSection() && (
        <>
          <CardSection
            title={translate('CardNameCard.CompanyNameOnCard') as string}
            borderTop
          >
            <Typography>{companyNameOnCard}</Typography>
            <EmptyElement />
            <Checkbox
              name="shouldHaveCompanyNameOnCard"
              checked={!shouldHaveCompanyNameOnCard}
              onChange={onCheckboxValueChanged}
              disabled={props.shippingDetails.cardAlreadyCreated}
            >
              {translate('CardNameCard.CheckboxText') as string}
            </Checkbox>
          </CardSection>
        </>
      )}
    </Card>
  );
};

export default CardNameCard;
