import React from 'react';
import { Typography, Group, Button } from '@qred/components-library';
import { ClickableText } from '~/styles/BaseStyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '~/styles/themes';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import {
  openQredCardGeneralTermsPage,
  openQredCardPricesPage,
} from '~/services/links';
import { Auth0ConnectionKey, CountryCode } from '~/enums';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { getConnection } from '~/helpers/authentication.helper';
import {
  sendSignersCardOfferData,
  sendSignersCardOfferDataV2,
} from '~/store/actions/additionalSignerActions';

const CardAdditionalSignerFooter = () => {
  const dispatch = useDispatch();

  const {
    intl: { market, language },
    matchedMedia: { mobile },
    additionalSigner: {
      apiStatus: { postAdditionalSignerCardApplication },
    },
  } = useSelector((state: RootState) => state);
  const translate = useTranslate();
  const { loginWithRedirect } = useAuth0();

  const fiCardOfferConfig = (hash: string): RedirectLoginOptions => ({
    authorizationParams: {
      environment: import.meta.env.MODE,
      connection: getConnection(Auth0ConnectionKey.FI_FTN),
      hash,
      market: market,
      cardOfferUrl: window.location.href,
      cardOffer: true,
    },
  });

  const onConfirmClick = () => {
    if (market === CountryCode.FI) {
      dispatch(
        sendSignersCardOfferDataV2(({ hash }: { hash: string }) => {
          loginWithRedirect({
            ...fiCardOfferConfig(hash),
          });
        })
      );
    } else {
      dispatch(sendSignersCardOfferData());
    }
  };

  return (
    <Group
      spacing={0}
      w={mobile ? 396 : 580}
      direction="column"
      p={mobile ? 'md' : 'xxl'}
      align="center"
      mb={mobile ? 36 : 62}
      style={{
        backgroundColor: `${theme.colors.neutral2}`,
        borderTop: mobile ? '' : `1px solid ${theme.colors.neutral16}`,
      }}
    >
      <Typography size="sm" lineHeight={20}>
        {translate('CreditCardTermsAndConditionsCard.Text1', {
          generalTermLink: (
            <ClickableText
              noUnderline
              typographyColor={theme.colors.midwayLinkDark}
              onClick={() => openQredCardGeneralTermsPage(market, language)}
            >
              {translate('CreditCardTermsAndConditionsCard.Link1')
                .toString()
                .toLowerCase()}
            </ClickableText>
          ),
          qredCardPricesLink: (
            <ClickableText
              noUnderline
              typographyColor={theme.colors.midwayLinkDark}
              onClick={() => openQredCardPricesPage(market, language)}
            >
              {translate('CreditCardTermsAndConditionsCard.Link2Legacy')
                .toString()
                .toLowerCase()}
            </ClickableText>
          ),
        })}
      </Typography>
      <Button
        fullWidth={mobile}
        mt={mobile ? 12 : 18}
        mb={mobile ? 24 : 0}
        onClick={onConfirmClick}
        isLoading={postAdditionalSignerCardApplication === ApiStatus.Started}
        variant="primary"
        size="md"
      >
        <Typography color="textLight.1" weight={500}>
          {translate('ConfirmTermsCard.ConfirmAgreement')}
        </Typography>
      </Button>
    </Group>
  );
};

export default CardAdditionalSignerFooter;
