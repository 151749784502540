import React from 'react';
import Logo from '~/components/shared/LogoOld/LogoOld';
import PageHeader from '~/components/shared/PageHeader/PageHeader';
import PageTitle from '~/components/shared/PageTitle/PageTitle';
import useTranslate from '~/hooks/useTranslate';
import { BodyContainer, MainContainer } from '~/styles/BaseStyledComponents';
import CreateCardProgressCard from '../SetCreditCardPin/components/CreateCardProgressCard';

const CreateCardProgress = () => {
  const translate = useTranslate();

  return (
    <MainContainer>
      <PageHeader hideAllMenus>
        <Logo mt={2} mb={4} />
        <PageTitle mb={2}>{translate('CreatingQredCard')}</PageTitle>
      </PageHeader>
      <BodyContainer>
        <CreateCardProgressCard />
      </BodyContainer>
    </MainContainer>
  );
};

export default CreateCardProgress;
