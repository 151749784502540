import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationContext } from '~/components/hoc/withValidation';
import useTranslate from '~/hooks/useTranslate';
import { ApiStatus, RootState } from '~/store/types/sharedTypes';
import { theme } from '~/styles/themes';
import { validateOrgNumber } from '@qred/shared-component-library/src/validators';
import Icon, { SvgSrc } from '~/components/shared/Icon/Icon';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { CountryCode } from '~/enums';
import {
  OrgNumberPlaceholders,
  orgNumberIsOnlyNumbers,
} from '~/constants/markets';
import ValidationErrorMessage from '~/components/shared/ValidationErrorMessage/ValidationErrorMessage';
import { pushToGtmOnboardingAction } from '~/store/actions/gtmActions';
import { orgNrAreTheSame } from '~/helpers/utils';
import { onChangeInputEvent } from '~/types/types';
import {
  setApiStatus,
  setCompanyIsNew,
  setCompanyIsReal,
  setCompanyName,
  updateForm,
} from '~/store/slices/onboardingApplication.slice';
import { fetchCompanyLookupData } from '~/store/actions/onboardingActions';
import { formatOrganizationNumber } from '~/helpers/formatters.helper';
import { normalizeOrgNumberHelper } from '~/helpers/normalizeOrgNumber.helper';
import InputField from '~/components/shared/Input/InputField';
import {
  CompanyNameContainer,
  CompanyNameResult,
} from '../CompanySelector.styled';
import { Typography } from '@qred/components-library';

const CompanySearch = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const validationContext = useContext(ValidationContext);

  const otherCompanyInput = useRef<HTMLInputElement>(null);

  const {
    form,
    companyIsReal,
    companyName,
    apiStatus,
    overview,
    companyIsNew,
  } = useSelector((state: RootState) => state.onboardingApplication);
  const { market } = useSelector((state: RootState) => state.intl);

  const isValidOrgNumberFormat =
    form.organizationNumber &&
    validateOrgNumber(
      normalizeOrgNumberHelper(form.organizationNumber),
      market
    );

  const leftText = market === CountryCode.BE ? 'BE' : '';

  const handleFetchCompanyLookupDebounce = () => {
    if (isValidOrgNumberFormat) {
      dispatch(setApiStatus({ companyLookupData: ApiStatus.Started }));
      if (timer) {
        clearTimeout(timer);
      }
      const delay = 500;
      const localTimer = setTimeout(() => {
        dispatch(fetchCompanyLookupData(form.organizationNumber));
      }, delay);
      setTimer(localTimer);
    } else if (timer) {
      clearTimeout(timer);
    }
  };

  const cleanUpErrors = () => {
    if (companyIsReal) {
      dispatch(setCompanyIsReal(false));
    }
    if (companyName) {
      dispatch(setCompanyName(''));
    }
  };

  useEffect(() => {
    if (
      overview.clients?.some((client) =>
        orgNrAreTheSame(client.company_no, form.organizationNumber)
      )
    ) {
      dispatch(setCompanyIsNew(false));
    } else if (companyIsNew && !companyName) {
      if (validationContext.validationErrors.CompanySelector) {
        cleanUpErrors();
      }

      handleFetchCompanyLookupDebounce();
    }
  }, [form.organizationNumber]);

  const onInputChange = (event: onChangeInputEvent) => {
    const { value } = event.target;
    dispatch(updateForm({ organizationNumber: value }));
    if (apiStatus.companyLookupData !== ApiStatus.Idle) {
      dispatch(setApiStatus({ companyLookupData: ApiStatus.Idle }));
    }
    if (form.companyType) {
      dispatch(updateForm({ companyType: '' }));
    }
    if (companyName) {
      dispatch(setCompanyName(''));
    }
  };

  const onInputBlur = () => {
    dispatch(
      pushToGtmOnboardingAction({
        stepActionNumber: overview.clients.length > 0 ? 0.2 : 0.1,
        actionName: 'other_company_input_change',
      })
    );
  };

  const validationErrorShouldBeShown =
    !companyIsReal &&
    !validationContext.validationErrors.CompanySelector &&
    apiStatus.companyLookupData === ApiStatus.Failed;

  useEffect(() => {
    // Disable next button while fetching company.
    companyIsReal || !companyIsNew
      ? validationContext.removePropertyFromValidationErrors('CompanyIsReal')
      : validationContext.addPropertyToValidationErrors('CompanyIsReal');
    return () => {
      validationContext.removePropertyFromValidationErrors('CompanyIsReal');
    };
  }, [companyIsReal, companyIsNew]);

  useEffect(() => {
    otherCompanyInput?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    otherCompanyInput?.current?.focus();
  }, [otherCompanyInput?.current]);

  useEffect(() => {
    if (companyName) {
      otherCompanyInput?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [otherCompanyInput?.current, companyName]);

  return (
    <CompanyNameResult>
      <InputField
        dataCy="organisation_step_org_number"
        name="CompanySelector"
        validationType="OrgNumber"
        value={formatOrganizationNumber(form.organizationNumber || '')}
        onChange={onInputChange}
        onBlur={onInputBlur}
        placeholder={OrgNumberPlaceholders[market]}
        label={t('Onboarding.OrganizationNumber') as string}
        ref={otherCompanyInput}
        isLoading={apiStatus.companyLookupData === ApiStatus.Started}
        leftText={leftText}
        inputMode={orgNumberIsOnlyNumbers(market) ? 'numeric' : 'text'}
      />

      {validationErrorShouldBeShown && (
        <ValidationErrorMessage>
          {t('ValidationErrors.CompanyIsReal')}
        </ValidationErrorMessage>
      )}

      {companyName && isValidOrgNumberFormat && (
        <CompanyNameContainer>
          <Typography size="lg">{companyName}</Typography>
          <Icon
            src={SvgSrc.Check}
            size={ComponentSize.Medium}
            color={theme.colors.active}
            ml={1}
          />
        </CompanyNameContainer>
      )}
    </CompanyNameResult>
  );
};

export default CompanySearch;
