import React, { useContext } from 'react';
import { FormStatus, ValidationContext } from '~/components/hoc/withValidation';
import useTranslate from '~/hooks/useTranslate';
import SpinnerLinear from '../../Spinner/SpinnerLinear';
import { Button, useWindowProperties } from '@qred/components-library';

interface NextButtonProps {
  label: string;
  showLoadingSpinner?: boolean;
  action: () => void;
}

const NextButton = ({ label, showLoadingSpinner, action }: NextButtonProps) => {
  const t = useTranslate();
  const validationContext = useContext(ValidationContext);
  const { isMobile } = useWindowProperties();

  const handleNextStepClick = () => {
    if (showLoadingSpinner) return;
    validationContext.setFormStatus(FormStatus.SUBMITTING);
    if (!validationContext.isFormValid) {
      validationContext.setFormStatus(FormStatus.SUBMITTED);
      return;
    }

    action();
  };

  return (
    <Button
      dataCy="onboarding_next_or_submit_button"
      onClick={handleNextStepClick}
      disabled={!validationContext.isFormValid}
      size="md"
      fullWidth={isMobile}
    >
      {showLoadingSpinner ? <SpinnerLinear /> : t(label)}
    </Button>
  );
};

export default NextButton;
