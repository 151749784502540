import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  ContactNumbers,
  ContactNumbersFormatted,
  ContactEmails,
} from '~/constants/markets';
import useHubspotChat from '~/hooks/useHubspotChat';
import { MediaQueries } from '~/constants/constVars';
import useTranslate from '~/hooks/useTranslate';
import { RootState } from '~/store/types/sharedTypes';
import { ComponentSize } from '~/styles/BaseStyledComponents';
import { theme } from '~/styles/themes';
import { toggleGlobalErrorOff } from '~/store/slices/globalError.slice';
import { closeHelpPopup } from '~/store/slices/helpPopup.slice';
import Button, { ButtonType } from '../Button/button';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Icon, { SvgSrc } from '../Icon/Icon';

const ContactButtonContent = styled.div`
  margin: 0 auto;
  text-align: left;
  display: flex;
  @media ${MediaQueries.smallScreen} {
    width: 10em;
  }
`;

interface MeansOfSupportProps {
  closePopupsOnChatOpen?: boolean;
}

const MeansOfSupport = ({
  closePopupsOnChatOpen = true,
}: MeansOfSupportProps) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { market } = useSelector((state: RootState) => state.intl);
  const { openChat } = useHubspotChat();

  const handleOpenChat = () => {
    if (closePopupsOnChatOpen) {
      dispatch(toggleGlobalErrorOff());
      dispatch(closeHelpPopup());
    }
    openChat();
  };

  return (
    <ButtonGroup>
      <Button
        mb={0.4}
        type={ButtonType.Outlined}
        link={`tel:${ContactNumbers[market]}`}
        smallSize
      >
        <ContactButtonContent>
          <Icon
            src={SvgSrc.Phone}
            size={ComponentSize.Small}
            mr={0.5}
            color={theme.colors.secondary}
          />
          {ContactNumbersFormatted[market]}
        </ContactButtonContent>
      </Button>
      <Button
        mb={0.4}
        type={ButtonType.Outlined}
        link={`mailto:${ContactEmails[market]}`}
        smallSize
      >
        <ContactButtonContent>
          <Icon
            src={SvgSrc.Mail}
            size={ComponentSize.Small}
            mr={0.5}
            color={theme.colors.secondary}
          />
          {ContactEmails[market]}
        </ContactButtonContent>
      </Button>
      <Button
        mb={0.4}
        smallSize
        type={ButtonType.Outlined}
        onClick={handleOpenChat}
      >
        <ContactButtonContent>
          <Icon
            src={SvgSrc.Comment}
            size={ComponentSize.Small}
            mr={0.5}
            color={theme.colors.secondary}
          />
          {translate('ContactChat') as string}
        </ContactButtonContent>
      </Button>
    </ButtonGroup>
  );
};

export default MeansOfSupport;
